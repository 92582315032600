import React from "react";
import { useNavigate } from "react-router-dom";
import { ArrowRight, Briefcase, Zap, Clock, CheckCircle, TrendingUp } from "lucide-react";
import Button from "./Button";
import { Frown } from "lucide-react";

const CancelPage = () => {
    const navigate = useNavigate();

    const handleRetryCheckout = () => {
        navigate("/apply");
    };

    return (
        <div className="min-h-screen flex flex-col items-center justify-center bg-gradient-to-r from-red-50 to-yellow-50 p-4">
            <div className="bg-white p-8 md:p-10 rounded-xl shadow-lg text-center space-y-6 max-w-lg">
                <div className="flex justify-center items-center space-x-2">
                    <Frown className="text-red-400 w-12 h-12" />
                    <h1 className="text-3xl md:text-4xl font-bold bg-gradient-to-r from-yellow-600 to-red-600 bg-clip-text text-transparent">
                        Wait! Don't Miss Out!
                    </h1>
                </div>

                <div className="bg-yellow-50 p-4 rounded-lg border border-yellow-200">
                    <p className="text-lg text-gray-700 font-medium">
                        Did you know? <span className="text-red-500 font-bold">92% of job seekers</span> who use AI-powered mass application tools land interviews within 2 weeks!
                    </p>
                </div>

                <div className="grid grid-cols-1 md:grid-cols-2 gap-4 text-left">
                    <div className="flex items-start space-x-3">
                        <Clock className="w-6 h-6 text-blue-500 flex-shrink-0" />
                        <p className="text-gray-600">Save <span className="font-bold">40+ hours</span> of manual job searching and applying</p>
                    </div>
                    <div className="flex items-start space-x-3">
                        <TrendingUp className="w-6 h-6 text-green-500 flex-shrink-0" />
                        <p className="text-gray-600"><span className="font-bold">3x higher</span> response rate from employers</p>
                    </div>
                </div>

                <div className="bg-blue-50 p-4 rounded-lg">
                    <h2 className="text-xl font-bold text-blue-800 mb-3">Special Offer Alert! 🎉</h2>
                    <p className="text-md text-gray-700">
                        Get an <span className="font-bold text-green-600">extra 10% off</span> text us @ 203-615-4022 using code ROBO with your email for <span className="font-bold text-red-600">10% off</span>
                    </p>
                </div>

                <div className="space-y-3">
                    <h3 className="text-lg font-semibold text-gray-700">What You're Missing:</h3>
                    <ul className="space-y-2">
                        {[
                            "AI-Powered Job Matching with 95% accuracy",
                            "Apply to 100+ relevant jobs in minutes",
                            "Smart Resume Optimization",
                            "Priority Application Submission",
                            "24/7 Application Tracking"
                        ].map((feature, index) => (
                            <li key={index} className="flex items-center space-x-2">
                                <CheckCircle className="w-5 h-5 text-green-500 flex-shrink-0" />
                                <span className="text-gray-600">{feature}</span>
                            </li>
                        ))}
                    </ul>
                </div>

                <div className="bg-gradient-to-r from-yellow-100 to-red-100 p-4 rounded-lg">
                    <p className="text-lg font-medium text-gray-700">
                        Your dream job isn't going to wait. <span className="text-red-600 font-bold">Every minute</span> you delay is another opportunity missed.
                    </p>
                </div>

                <Button
                    onClick={handleRetryCheckout}
                    className="w-full bg-gradient-to-r from-yellow-400 to-red-500 text-white text-lg py-4 rounded-lg hover:from-yellow-500 hover:to-red-600 transform hover:scale-105 transition-all duration-200 shadow-lg flex items-center justify-center space-x-2"
                >
                    <span>Secure Your Career Advantage Now</span>
                    <ArrowRight className="w-5 h-5" />
                </Button>

                <div className="space-y-2">
                    <p className="text-sm text-gray-500">
                        🔒 100% Secure Checkout | 30-Day Money-Back Guarantee
                    </p>
                    <p className="text-sm text-gray-500">
                        Need help? <a
                            href="mailto:sam@trycrispy.app?subject=RoboApply Support Request"
                            onClick={(e) => {
                                e.preventDefault();
                                window.location.href = "mailto:sam@trycrispy.app?subject=RoboApply Support Request&body=Hi Sam,%0D%0A%0D%0AI need help with RoboApply.%0D%0A%0D%0AUser ID: ";
                            }}
                            className="text-blue-600 hover:text-blue-800 underline"
                        >
                            Contact our friendly support team
                        </a></p>
                </div>
            </div>
        </div>
    );
};

export default CancelPage;