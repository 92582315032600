import React from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { Sparkles, RefreshCw, ExternalLink } from "lucide-react";
import Button from './Button';

const Modal = ({ isOpen, onClose, children }) => (
    <AnimatePresence>
        {isOpen && (
            <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                className="fixed inset-0 bg-black bg-opacity-50 z-50 flex items-center justify-center p-4"
                onClick={onClose}
            >
                <motion.div
                    initial={{ scale: 0.9, opacity: 0 }}
                    animate={{ scale: 1, opacity: 1 }}
                    exit={{ scale: 0.9, opacity: 0 }}
                    className="bg-white p-6 rounded-xl shadow-2xl w-full max-w-5xl max-h-[90vh] overflow-y-auto"
                    onClick={(e) => e.stopPropagation()}
                >
                    {children}
                </motion.div>
            </motion.div>
        )}
    </AnimatePresence>
);

export const CelebrationModal = ({ isOpen, onClose, successfulApplications, remainingCredits }) => (
    <Modal isOpen={isOpen} onClose={onClose}>
        <div className="text-center space-y-6">
            <Sparkles className="text-blue-600 w-20 h-20 mx-auto mb-6" />
            <h2 className="text-3xl font-bold text-blue-600 mb-4">Congratulations!</h2>
            <p className="text-xl text-gray-600 mb-6">
                You've successfully applied to {successfulApplications} jobs!
            </p>
            <p className="text-lg text-gray-600 mb-6">
                Remaining credits: {remainingCredits}
            </p>
            <Button onClick={onClose} className="bg-blue-600 text-white text-xl py-4 px-8 rounded-xl hover:bg-blue-700">
                Continue
            </Button>
        </div>
    </Modal>
);

export const PricingModal = ({ isOpen, onClose }) => (
    <Modal isOpen={isOpen} onClose={onClose}>
        <div className="text-center space-y-6">
            <button
                onClick={onClose}
                className="bg-blue-600 hover:bg-blue-700 text-white font-semibold py-2 px-4 rounded"
            >
                Continue
            </button>
        </div>
    </Modal>
);

export const IntroModal = ({ isOpen, onClose, onContinue }) => (
    <Modal isOpen={isOpen} onClose={onClose}>
        <div className="text-center space-y-6">
            <h2 className="text-3xl font-bold text-teal-600 mb-4">Welcome to RoboApply.ai!</h2>
            <p className="text-xl text-gray-600 mb-6">
                Before we begin, please ensure you have a Dice.com account and an uploaded resume.
            </p>
            <div className="flex flex-col sm:flex-row justify-center space-y-4 sm:space-y-0 sm:space-x-4">
                <a
                    href="https://www.dice.com/dashboard/login"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="inline-flex items-center justify-center px-6 py-3 border border-transparent text-lg font-medium rounded-xl text-white bg-blue-600 hover:bg-blue-700"
                >
                    Dice Login <ExternalLink size={20} className="ml-2" />
                </a>
                <a
                    href="https://www.dice.com/register/new-profile"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="inline-flex items-center justify-center px-6 py-3 border border-transparent text-lg font-medium rounded-xl text-white bg-blue-600 hover:bg-blue-700"
                >
                    Dice Signup <ExternalLink size={20} className="ml-2" />
                </a>
            </div>
            <Button
                onClick={onContinue}
                className="mt-6 bg-blue-600 text-white text-xl py-4 px-8 rounded-xl hover:bg-blue-700"
            >
                I'm Ready to Continue
            </Button>
        </div>
    </Modal>
);

export const ProcessingModal = ({ isOpen, onClose }) => (
    <Modal isOpen={isOpen} onClose={onClose}>
        <div className="text-center space-y-6">
            <RefreshCw className="text-blue-600 w-20 h-20 mx-auto mb-6 animate-spin" />
            <h2 className="text-3xl font-bold text-blue-600 mb-4">Applying to Jobs</h2>
            <p className="text-xl text-gray-600 mb-6">
                We're applying to jobs for you. This may take a few minutes.
                The dashboard will update in real-time as applications are submitted.
            </p>
        </div>
    </Modal>
);