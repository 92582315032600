import React, { useState, useEffect } from 'react';
import {
    Rocket,
    LayoutDashboard,
    Search,
    Settings,
    ChevronRight,
    History,
    FileText,
    User,
    Wallet,
    Mail,
    Shield,
    Sparkles,
    Menu,
    X
} from 'lucide-react';
import ResumeBuilder from '../components/ResumeBuilder';
import JobSearchPortal from '../components/JobSearchPortal';
import { SettingsContent } from '../components/Settings';
import ApplicationForm from './ApplicationForm';
import DashboardStats from './DashboardStats';
import ApplicationsGrid from './ApplicationsGrid';

const Sidebar = ({
    activeView,
    onViewChange,
    lastCampaign,
    handleCampaignSubmit,
    handleSubmit,
    loading,
    error,
    activelyApplying,
    user,
    profile,
    applications,
    dashboardData,
    supabase,
    setShowPricingScreen,
    setShowPricingModal
}) => {
    const [isExpanded, setIsExpanded] = useState(true);
    const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

    // Force Auto Apply to be the first tab on component mount
    useEffect(() => {
        if (activeView === 'dashboard') {
            onViewChange('apply');
        }
    }, []);

    // Close mobile menu on window resize
    useEffect(() => {
        const handleResize = () => {
            if (window.innerWidth >= 1024) {
                setIsMobileMenuOpen(false);
            }
        };

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const navigationItems = [
        {
            id: 'apply',
            label: (
                <div className="flex items-center gap-2">
                    Auto Apply
                    <Sparkles className="h-4 w-4 text-yellow-400 animate-pulse" />
                </div>
            ),
            icon: Rocket,
            highlight: true,
            forceFirst: true
        },
        {
            id: 'dashboard',
            label: 'Control Center',
            icon: LayoutDashboard
        },
        {
            id: 'resume',
            label: 'Resume Builder',
            icon: FileText
        },
        {
            id: 'history',
            label: 'Application History',
            icon: History
        },
        {
            id: 'job-search',
            label: 'Job Search',
            icon: Search
        },
        {
            id: 'settings',
            label: 'Settings',
            icon: Settings,
            subItems: [
                { id: 'personal', label: 'Personal Info', icon: User },
                { id: 'billing', label: 'Credits & Billing', icon: Wallet },
                { id: 'notifications', label: 'Notifications', icon: Mail },
                { id: 'security', label: 'Security', icon: Shield },
            ]
        }
    ].sort((a, b) => {
        if (a.forceFirst) return -1;
        if (b.forceFirst) return 1;
        return 0;
    });

    const MobileMenuButton = () => (
        <button
            onClick={() => setIsMobileMenuOpen(!isMobileMenuOpen)}
            className="lg:hidden fixed top-4 left-4 z-10 p-2 rounded-lg bg-white shadow-lg border border-gray-200"
        >
            {isMobileMenuOpen ? (
                <X className="h-6 w-6 text-gray-600" />
            ) : (
                <Menu className="h-6 w-6 text-gray-600" />
            )}
        </button>
    );

    const renderMainContent = () => {
        switch (activeView) {
            case 'dashboard':
                return (
                    <>
                        {lastCampaign && (
                            <div className="mb-8 p-4 bg-white rounded-lg shadow-sm border border-gray-200">
                                <h3 className="text-lg font-semibold text-gray-800 mb-2">
                                    Last Campaign
                                </h3>
                                <div className="grid grid-cols-2 md:grid-cols-4 gap-4">
                                    <div>
                                        <span className="text-gray-600">Job Type:</span>
                                        <p className="font-medium">{lastCampaign.job_type}</p>
                                    </div>
                                    <div>
                                        <span className="text-gray-600">Location:</span>
                                        <p className="font-medium">{lastCampaign.location}</p>
                                    </div>
                                    <div>
                                        <span className="text-gray-600">Applications:</span>
                                        <p className="font-medium">{lastCampaign.num_jobs}</p>
                                    </div>
                                    <div>
                                        <button
                                            onClick={() => handleCampaignSubmit(lastCampaign)}
                                            className="text-blue-500 hover:text-blue-600 font-medium"
                                        >
                                            Run Again
                                        </button>
                                    </div>
                                </div>
                            </div>
                        )}

                        <div className="grid grid-cols-1 lg:grid-cols-2 gap-8">
                            <ApplicationForm
                                handleSubmit={handleSubmit}
                                loading={loading}
                                error={error}
                                activelyApplying={activelyApplying}
                                userId={user?.id}
                                most_recent_resume_link={profile?.most_recent_resume_url}
                                userEmail={user?.email}
                                diceEmail={profile?.dice_specific_email || user?.email}
                                supabase={supabase}
                                setShowPricingScreen={setShowPricingScreen}
                                setShowPricingModal={setShowPricingModal}
                            />
                            <DashboardStats
                                activelyApplying={activelyApplying}
                                applicationProgress={dashboardData.applicationProgress}
                                totalJobsApplied={dashboardData.totalJobsApplied}
                                dataLoading={dashboardData.dataLoading}
                                dataError={dashboardData.dataError}
                                applicationData={dashboardData.applicationData}
                                userId={user?.id}
                            />
                        </div>

                        <ApplicationsGrid
                            applications={applications}
                        />
                    </>
                );

            case 'resume':
                return (
                    <section className="w-full max-w-[103rem] mx-auto px-4 mb-16">
                        <ResumeBuilder />
                    </section>
                );

            case 'apply':
                return (
                    <div className="p-6 bg-white rounded-lg shadow-sm">
                        <h2 className="text-2xl font-bold text-gray-800 mb-6">Start Auto Apply Campaign</h2>
                        <ApplicationForm
                            handleSubmit={handleSubmit}
                            loading={loading}
                            error={error}
                            activelyApplying={activelyApplying}
                            userId={user?.id}
                            most_recent_resume_link={profile?.most_recent_resume_url}
                            userEmail={user?.email}
                            diceEmail={profile?.dice_specific_email || user?.email}
                            supabase={supabase}
                            setShowPricingScreen={setShowPricingScreen}
                            setShowPricingModal={setShowPricingModal}
                        />
                    </div>
                );

            case 'history':
                return (
                    <div className="p-6 bg-white rounded-lg shadow-sm">
                        <h2 className="text-2xl font-bold text-gray-800 mb-6">Application History</h2>
                        <ApplicationsGrid
                            applications={applications}
                        />
                    </div>
                );

            case 'job-search':
                return (
                    <div className="p-6 bg-white rounded-lg shadow-sm">
                        <h2 className="text-2xl font-bold text-gray-800 mb-6">Job Search Portal</h2>
                        <JobSearchPortal userId={user?.id} />
                    </div>
                );

            case 'settings':
            case 'personal':
            case 'billing':
            case 'notifications':
            case 'security':
                return (
                    <div className="p-6 bg-white rounded-lg shadow-sm">
                        <SettingsContent
                            activeSection={activeView}
                            userData={user}
                        />
                    </div>
                );

            default:
                return <div>Page not found</div>;
        }
    };

    return (
        <div className="flex h-screen relative">
            <MobileMenuButton />

            {/* Mobile overlay */}
            {isMobileMenuOpen && (
                <div
                    className="lg:hidden fixed inset-0 bg-black bg-opacity-50 z-5"
                    onClick={() => setIsMobileMenuOpen(false)}
                />
            )}

            {/* Sidebar */}
            <div
                className={`${isMobileMenuOpen ? 'translate-x-0' : '-translate-x-full'
                    } lg:translate-x-0 fixed lg:relative z-10 h-screen bg-white border-r border-gray-200 
    transition-all duration-300 flex flex-col overflow-y-auto
    ${isExpanded ? 'w-72' : 'w-20'}`}
            >
                <nav className="flex-1 overflow-y-auto py-2">
                    {navigationItems.map((item) => (
                        <div key={item.id}>
                            <button
                                onClick={() => {
                                    onViewChange(item.id);
                                    setIsMobileMenuOpen(false);
                                }}
                                className={`w-full px-6 py-4 flex items-center justify-between transition-all duration-200
                                    group hover:bg-blue-50/80
                                    ${activeView === item.id || (item.subItems?.some(sub => sub.id === activeView))
                                        ? 'bg-blue-50 border-r-4 border-blue-500 shadow-sm'
                                        : 'hover:border-r-4 hover:border-blue-300'}
                                    ${item.highlight ? 'bg-gradient-to-r from-blue-50 to-indigo-50 hover:from-blue-100 hover:to-indigo-100' : ''}`}
                            >
                                <div className="flex items-center min-w-0">
                                    <item.icon
                                        className={`h-6 w-6 flex-shrink-0 transition-colors duration-200
                                            ${activeView === item.id ? 'text-blue-500' : 'text-gray-500 group-hover:text-blue-500'}`}
                                    />
                                    {isExpanded && (
                                        <span className={`ml-4 text-base font-medium transition-colors duration-200
                                            ${activeView === item.id ? 'text-blue-700' : 'text-gray-700 group-hover:text-blue-700'}`}>
                                            {item.label}
                                        </span>
                                    )}
                                </div>
                                {item.subItems && (
                                    <ChevronRight
                                        className={`h-5 w-5 text-gray-400 transition-transform duration-200 group-hover:text-blue-500
                                            ${activeView === item.id || item.subItems.some(sub => sub.id === activeView) ? 'transform rotate-90' : ''}`}
                                    />
                                )}
                            </button>

                            {item.subItems && (activeView === item.id || item.subItems.some(sub => sub.id === activeView)) && (
                                <div className="py-2 space-y-1 bg-gray-50">
                                    {item.subItems.map((subItem) => (
                                        <button
                                            key={subItem.id}
                                            onClick={() => {
                                                onViewChange(subItem.id);
                                                setIsMobileMenuOpen(false);
                                            }}
                                            className={`w-full px-6 py-3 flex items-center transition-all duration-200
                                                group hover:bg-blue-50
                                                ${activeView === subItem.id
                                                    ? 'bg-blue-50 text-blue-700'
                                                    : 'text-gray-600 hover:text-blue-700'}`}
                                        >
                                            <subItem.icon className="h-5 w-5 mr-4 group-hover:text-blue-500" />
                                            {isExpanded && (
                                                <span className="text-base group-hover:text-blue-700">
                                                    {subItem.label}
                                                </span>
                                            )}
                                        </button>
                                    ))}
                                </div>
                            )}
                        </div>
                    ))}
                </nav>

                <button
                    onClick={() => setIsExpanded(!isExpanded)}
                    className="hidden lg:flex p-4 border-t border-gray-200 items-center justify-center 
                        hover:bg-blue-50 transition-colors duration-200 group"
                >
                    <ChevronRight
                        className={`h-6 w-6 text-gray-400 transition-transform duration-300 group-hover:text-blue-500
                        ${isExpanded ? 'transform rotate-180' : ''}`}
                    />
                </button>
            </div>

            {/* Main Content */}
            <div className="flex-1 overflow-auto relative">
                <main className="container mx-auto px-4 lg:px-8 py-12 mt-16 lg:mt-0">
                    {renderMainContent()}
                </main>
            </div>
        </div>
    );
};

export default Sidebar;