import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { Star, Menu, X, Clock, Mail, Lock, Check, User, Camera, CreditCard, Sparkle, LogOut, Zap, Rocket, Target, Facebook, Twitter, Linkedin, Instagram } from "lucide-react";
import supabase from '../supabaseConfig';
import { loadStripe } from '@stripe/stripe-js';
import ProfileCreation from './ProfileCreation';
import Confetti from 'react-confetti';
import { toast } from 'react-hot-toast';
import { motion, AnimatePresence } from 'framer-motion';
import ResumeEnhancement from './ResumeEnhancement';
import Workflow from './Workflow';
import Dag from './DAG';

import {
    MailIcon,
    LockIcon,
    CheckIcon,
    AlertCircleIcon,
    ArrowRightIcon,
    LoaderIcon,
    GoogleIcon,
    RefreshCwIcon,
    BookmarkPlus,
    Link,
    FileText,
    MapPin, Briefcase, Globe, DollarSign, Users, ExternalLink, ChevronDown, ChevronLeft, ChevronRight, Building2, ArrowUp, ArrowDown, ArrowLeft, ArrowRight, Share2
} from 'lucide-react';
import JobSearchPortal from './JobSearchPortal';
import ResumeBuilder from './ResumeBuilder';


// Stripe setup
const stripePromise = loadStripe('your_stripe_publishable_key');

// Button Component
const Button = ({ className, children, ...props }) => (
    <button
        className={`px-6 py-3 rounded-full font-semibold transition-all duration-300 hover:scale-105 ${className}`}
        {...props}
    >
        {children}
    </button>
);

// Input Component
const Input = ({ icon: Icon, ...props }) => (
    <div className="relative">
        <Icon className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400" size={20} />
        <input
            className="w-full py-2 pl-10 pr-3 bg-gray-100 border border-gray-300 rounded-md text-gray-800 placeholder-gray-400"
            {...props}
        />
    </div>
);

const fadeIn = {
    initial: { opacity: 0, y: 20 },
    animate: { opacity: 1, y: 0 },
    exit: { opacity: 0, y: -20 }
};


const cn = (...classes) => classes.filter(Boolean).join(' ');

// Card Components
const Card = React.forwardRef(({ className, ...props }, ref) => (
    <div
        ref={ref}
        className={cn("rounded-lg border bg-card text-card-foreground shadow-sm", className)}
        {...props}
    />
));
Card.displayName = "Card";

// Modal Component
const Modal = ({ isOpen, onClose, children }) => {
    if (!isOpen) return null;

    return (
        <div className="fixed inset-0 bg-black bg-opacity-50 z-50 flex items-center justify-center">
            <div className="bg-white p-8 rounded-xl shadow-2xl max-w-md w-full">
                {children}
                <Button onClick={onClose} className="mt-4 w-full bg-gray-200 text-gray-800 hover:bg-gray-300">
                    Close
                </Button>
            </div>
        </div>
    );
};

const LogoutButton = ({ className, variant = "default", size = "default" }) => {
    const handleLogout = async () => {
        try {
            const { error } = await supabase.auth.signOut();
            if (error) throw error;

            // Clear any local storage items
            localStorage.removeItem(`sb-${process.env.REACT_APP_SUPABASE_PROJECT_ID}-auth-token`);
            localStorage.removeItem('userId');

            toast.success('Logged out successfully', {
                description: 'Come back soon!',
                duration: 3000,
            });

            // Optional: Redirect to home page
            // window.location.href = '/';

        } catch (error) {
            console.error('Logout error:', error);
            toast.error('Error logging out', {
                description: error.message || 'Please try again',
                duration: 4000,
            });
        }
    };

    return (
        <Button
            onClick={handleLogout}
            variant={variant}
            size={size}
            className={`flex items-center gap-2 ${className}`}
        >
            <LogOut className="w-4 h-4" />
            <span>Log Out</span>
        </Button>
    );
};


// PaywallModal Component
// PaywallModal Component
const PaywallModal = ({ onClose }) => {
    const [email, setEmail] = useState('');
    const [step, setStep] = useState('EMAIL');
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);
    const [resendTimeout, setResendTimeout] = useState(0);

    useEffect(() => {
        let interval;
        if (resendTimeout > 0) {
            interval = setInterval(() => {
                setResendTimeout(prev => prev - 1);
            }, 1000);
        }
        return () => clearInterval(interval);
    }, [resendTimeout]);

    const handleMagicLink = async (email) => {
        setIsLoading(true);
        try {
            const { data, error } = await supabase.auth.signInWithOtp({
                email,
                options: {
                    shouldCreateUser: true,
                }
            });

            if (error) throw error;

            setStep('MAGIC_LINK_SENT');
            setResendTimeout(60);
            toast.success('Check your email for the magic link!', {
                description: 'Make sure to check your spam folder',
                duration: 5000,
            });
        } catch (error) {
            console.error('Magic link error:', error);
            setError(error.message);
            toast.error('Authentication failed', {
                description: error.message || 'Please try again later',
                duration: 4000,
            });
        } finally {
            setIsLoading(false);
        }
    };

    const handleGoogleSignIn = async () => {
        try {
            const { data, error } = await supabase.auth.signInWithOAuth({
                provider: 'google'
            });
            if (error) throw error;
        } catch (error) {
            console.error('Google auth error:', error);
            setError(error.message);
            toast.error('Google sign in failed', {
                description: error.message || 'Please try again later',
                duration: 4000,
            });
        }
    };

    const handleEmailSubmit = async (e) => {
        e.preventDefault();
        if (!email) {
            setError('Email is required');
            return;
        }
        await handleMagicLink(email);
    };

    return (
        <AnimatePresence>
            <motion.div
                initial={{ opacity: 0, scale: 0.95 }}
                animate={{ opacity: 1, scale: 1 }}
                exit={{ opacity: 0, scale: 0.95 }}
                className="bg-white rounded-2xl shadow-xl p-8 max-w-md w-full mx-auto relative overflow-hidden"
            >
                <AnimatePresence mode="wait">
                    {step === 'EMAIL' && (
                        <motion.div
                            key="email-step"
                            initial={{ opacity: 0, y: 20 }}
                            animate={{ opacity: 1, y: 0 }}
                            exit={{ opacity: 0, y: -20 }}
                            className="space-y-6"
                        >
                            <div className="text-center space-y-2">
                                <h2 className="text-2xl font-bold text-gray-800">
                                    Welcome to RoboApply
                                </h2>
                                <p className="text-gray-600">
                                    Magic Link Coming Soon !
                                </p>
                            </div>

                            <form onSubmit={handleEmailSubmit} className="space-y-4">
                                {/* <Input
                                    icon={MailIcon}
                                    type="email"
                                    placeholder="Enter your email"
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                    disabled={isLoading}
                                    error={error}
                                    required
                                /> */}

                                {/* <Button
                                    type="submit"
                                    isLoading={isLoading}
                                    className="w-full"
                                >
                                    Send Magic Link (Coming Soon)
                                    <ArrowRightIcon className="ml-2 w-5 h-5" />
                                </Button> */}
                            </form>

                            <div className="relative">
                                <div className="absolute inset-0 flex items-center">
                                    <div className="w-full border-t border-gray-300" />
                                </div>
                                <div className="relative flex justify-center text-sm">
                                    <span className="px-2 bg-white text-gray-500">
                                        please continue with
                                    </span>
                                </div>
                            </div>

                            <Button
                                variant="secondary"
                                onClick={handleGoogleSignIn}
                                className="w-full"
                            >
                                <img
                                    src="https://www.gstatic.com/firebasejs/ui/2.0.0/images/auth/google.svg"
                                    alt="Google"
                                    className="w-5 h-5 mr-2"
                                />
                                Continue with Google
                            </Button>
                        </motion.div>
                    )}

                    {step === 'MAGIC_LINK_SENT' && (
                        <motion.div
                            key="magic-link-step"
                            initial={{ opacity: 0, y: 20 }}
                            animate={{ opacity: 1, y: 0 }}
                            exit={{ opacity: 0, y: -20 }}
                            className="text-center space-y-6"
                        >
                            <div className="space-y-2">
                                <h2 className="text-2xl font-bold text-gray-800">
                                    Check your email
                                </h2>
                                <p className="text-gray-600">
                                    We've sent a magic link to
                                </p>
                                <p className="font-medium text-gray-800">
                                    {email}
                                </p>
                            </div>

                            <div className="space-y-4">
                                <button
                                    onClick={() => handleMagicLink(email)}
                                    disabled={resendTimeout > 0 || isLoading}
                                    className={`
                                        text-sm text-green-500 hover:text-green-600 
                                        flex items-center justify-center gap-2
                                        disabled:opacity-50 disabled:cursor-not-allowed
                                    `}
                                >
                                    <RefreshCwIcon className="w-4 h-4" />
                                    {resendTimeout > 0
                                        ? `Resend in ${resendTimeout}s`
                                        : "Resend magic link"
                                    }
                                </button>

                                <button
                                    onClick={() => setStep('EMAIL')}
                                    className="text-sm text-gray-500 hover:text-gray-600"
                                >
                                    Use a different email
                                </button>
                            </div>
                        </motion.div>
                    )}
                </AnimatePresence>
            </motion.div>
        </AnimatePresence>
    );
};
// Enhanced JobModal Component
const JobModal = ({ job, onClose, siteInfo }) => {
    const [isAnimating, setIsAnimating] = useState(false);
    const [isCompanyExpanded, setIsCompanyExpanded] = useState(window.innerWidth >= 768);
    const [shareUrl, setShareUrl] = useState('');
    const modalRef = useRef(null);

    useEffect(() => {
        setIsAnimating(true);
        document.body.style.overflow = 'hidden';

        // Generate share URL
        const baseUrl = window.location.origin;
        setShareUrl(`${baseUrl}/${job.id}`);

        const handleEscape = (e) => {
            if (e.key === 'Escape') onClose();
        };

        window.addEventListener('keydown', handleEscape);
        return () => {
            document.body.style.overflow = 'unset';
            window.removeEventListener('keydown', handleEscape);
        };
    }, [job.id, onClose]);

    const handleShare = async () => {
        try {
            if (navigator.share) {
                await navigator.share({
                    title: `${job.title} at ${job.company}`,
                    text: `Check out this job opportunity: ${job.title} at ${job.company}`,
                    url: shareUrl
                });
            } else {
                await navigator.clipboard.writeText(shareUrl);
                alert('Link copied to clipboard!'); // Consider using a toast notification
            }
        } catch (err) {
            console.error('Share failed:', err);
        }
    };

    const ActionButton = ({ icon: Icon, label, primary, onClick }) => (
        <button
            onClick={onClick}
            className={`flex items-center space-x-2 px-4 py-2 rounded-full transition-all duration-200 ${primary
                ? 'bg-emerald-500 text-white hover:bg-emerald-600'
                : 'border border-gray-200 hover:border-gray-300 text-gray-700 hover:bg-gray-50'
                }`}
        >
            <Icon className="w-4 h-4" />
            <span className="font-medium">{label}</span>
        </button>
    );

    const InfoTag = ({ icon: Icon, text, color = "gray" }) => (
        <div className={`flex items-center space-x-2 px-3 py-2 rounded-lg bg-${color}-50 text-${color}-700`}>
            <Icon className="w-4 h-4" />
            <span className="text-sm">{text}</span>
        </div>
    );

    return (
        <div
            className={`fixed inset-0 bg-black/50 flex items-center justify-center p-4 z-50 transition-opacity duration-200 ${isAnimating ? 'opacity-100' : 'opacity-0'
                }`}
            onClick={onClose}
        >
            <div
                ref={modalRef}
                className={`bg-white rounded-2xl shadow-xl w-full max-w-4xl max-h-[90vh] overflow-hidden transition-all duration-300 ${isAnimating ? 'translate-y-0 opacity-100' : 'translate-y-4 opacity-0'
                    }`}
                onClick={e => e.stopPropagation()}
            >
                {/* Header Section */}
                <div className="p-6 border-b border-gray-100">
                    <div className="flex justify-between items-start mb-6">
                        <div className="flex items-center space-x-4">
                            <div className={`w-16 h-16 rounded-2xl flex items-center justify-center ${siteInfo.color}`}>
                                <img
                                    src={job.company_info?.logo_url || siteInfo.icon}
                                    alt={job.company}
                                    className="w-12 h-12 object-contain rounded-xl bg-white p-1"
                                />
                            </div>
                            <div>
                                <h2 className="text-2xl font-bold text-gray-900">{job.title}</h2>
                                <p className="text-lg text-gray-600 flex items-center space-x-2">
                                    <span>{job.company}</span>
                                    <span className="text-gray-300">•</span>
                                    <img src={siteInfo.icon} alt={siteInfo.name} className="w-4 h-4 opacity-70" />
                                </p>
                            </div>
                        </div>
                        <button
                            onClick={onClose}
                            className="p-2 hover:bg-gray-100 rounded-full transition-colors duration-200"
                        >
                            <X className="w-6 h-6 text-gray-400" />
                        </button>
                    </div>

                    {/* Quick Actions */}
                    <div className="flex flex-wrap gap-3">
                        <ActionButton icon={Share2} label="Share" onClick={handleShare} />
                        <ActionButton icon={BookmarkPlus} label="Save" onClick={() => { }} />
                        <ActionButton icon={Mail} label="Email" onClick={() => { }} />
                        <Link to="/login" className="ml-auto">
                            <ActionButton icon={FileText} label="Create Custom Resume" primary onClick={() => { }} />
                        </Link>
                    </div>
                </div>

                {/* Content Section */}
                <div className="flex flex-col md:flex-row divide-y md:divide-y-0 md:divide-x divide-gray-100 h-[calc(90vh-200px)]">
                    {/* Main Content */}
                    <div className="flex-1 overflow-y-auto p-6">
                        {/* Job Details Grid */}
                        <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 mb-8">
                            <InfoTag icon={MapPin} text={`${job.location.city}, ${job.location.state}`} />
                            {job.job_type && <InfoTag icon={Briefcase} text={job.job_type} color="blue" />}
                            {job.is_remote && <InfoTag icon={Globe} text="Remote Position" color="green" />}
                            {job.salary_range && <InfoTag icon={DollarSign} text={job.salary_range} color="emerald" />}
                            {job.company_size && <InfoTag icon={Users} text={job.company_size} />}
                        </div>

                        {/* Job Description */}
                        <div className="prose max-w-none">
                            <div className="space-y-6">
                                <section>
                                    <h3 className="text-xl font-semibold text-gray-900 mb-4">About the Role</h3>
                                    <div className="text-gray-700 whitespace-pre-wrap leading-relaxed">
                                        {job.description}
                                    </div>
                                </section>

                                {job.requirements && (
                                    <section>
                                        <h3 className="text-xl font-semibold text-gray-900 mb-4">Requirements</h3>
                                        <div className="text-gray-700 whitespace-pre-wrap leading-relaxed">
                                            {job.requirements}
                                        </div>
                                    </section>
                                )}

                                {job.benefits && (
                                    <section>
                                        <h3 className="text-xl font-semibold text-gray-900 mb-4">Benefits</h3>
                                        <div className="text-gray-700 whitespace-pre-wrap leading-relaxed">
                                            {job.benefits}
                                        </div>
                                    </section>
                                )}
                            </div>
                        </div>
                    </div>

                    {/* Company Sidebar */}
                    <div className="md:w-80 w-full overflow-y-auto bg-gray-50">
                        <button
                            onClick={() => setIsCompanyExpanded(!isCompanyExpanded)}
                            className="w-full p-4 flex items-center justify-between md:hidden border-b border-gray-200"
                        >
                            <span className="font-medium">Company Information</span>
                            <ChevronDown
                                className={`w-5 h-5 transition-transform duration-200 ${isCompanyExpanded ? 'transform rotate-180' : ''
                                    }`}
                            />
                        </button>
                        <div className={`p-6 ${isCompanyExpanded ? 'block' : 'hidden md:block'}`}>
                            <div className="space-y-6">
                                <section>
                                    <h3 className="text-lg font-semibold text-gray-900 mb-3">About {job.company}</h3>
                                    <p className="text-gray-600 text-sm leading-relaxed">
                                        {job.company_info?.description ||
                                            `${job.company} is a leading company in their industry, focused on innovation and growth.`}
                                    </p>
                                </section>

                                {job.company_info?.founded && (
                                    <section>
                                        <h4 className="text-sm font-medium text-gray-900 mb-2">Founded</h4>
                                        <p className="text-gray-600 text-sm">{job.company_info.founded}</p>
                                    </section>
                                )}

                                {job.company_info?.industry && (
                                    <section>
                                        <h4 className="text-sm font-medium text-gray-900 mb-2">Industry</h4>
                                        <p className="text-gray-600 text-sm">{job.company_info.industry}</p>
                                    </section>
                                )}

                                {job.company_info?.website && (
                                    <section>
                                        <h4 className="text-sm font-medium text-gray-900 mb-2">Website</h4>
                                        <a
                                            href={job.company_info.website}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            className="text-emerald-600 hover:text-emerald-700 text-sm flex items-center space-x-1"
                                        >
                                            <span>Visit website</span>
                                            <ExternalLink className="w-3 h-3" />
                                        </a>
                                    </section>
                                )}
                            </div>
                        </div>
                    </div>
                </div>

                {/* Footer Actions */}
                <div className="p-6 border-t border-gray-100 bg-white">
                    <div className="flex flex-wrap justify-between items-center gap-4">
                        <div className="flex flex-wrap items-center gap-4">
                            <a
                                href={job.job_url}
                                target="_blank"
                                rel="noopener noreferrer"
                                className="px-6 py-2.5 bg-emerald-500 text-white font-medium rounded-full hover:bg-emerald-600 transition-colors duration-200 flex items-center space-x-2"
                            >
                                <span>Apply on {new URL(job.job_url).hostname}</span>
                                <ExternalLink className="w-4 h-4" />
                            </a>
                            {job.company_url && (
                                <a
                                    href={job.company_url}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    className="px-6 py-2.5 border border-gray-200 hover:border-gray-300 rounded-full transition-colors duration-200 flex items-center space-x-2"
                                >
                                    <Building2 className="w-4 h-4" />
                                    <span>Company Profile</span>
                                </a>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

// Pagination Component
const Pagination = ({ currentPage, totalPages, onPageChange }) => {
    const getPageNumbers = () => {
        const pages = [];
        if (totalPages <= 5) {
            for (let i = 1; i <= totalPages; i++) {
                pages.push(i);
            }
        } else {
            if (currentPage <= 3) {
                for (let i = 1; i <= 5; i++) {
                    pages.push(i);
                }
            } else if (currentPage >= totalPages - 2) {
                for (let i = totalPages - 4; i <= totalPages; i++) {
                    pages.push(i);
                }
            } else {
                for (let i = currentPage - 2; i <= currentPage + 2; i++) {
                    pages.push(i);
                }
            }
        }
        return pages;
    };

    return (
        <div className="flex items-center justify-center space-x-2 mt-8">
            <button
                onClick={() => onPageChange(currentPage - 1)}
                disabled={currentPage === 1}
                className="p-2 rounded-full hover:bg-gray-100 disabled:opacity-50 disabled:cursor-not-allowed transition-colors duration-200"
                aria-label="Previous page"
            >
                <ChevronLeft className="w-5 h-5" />
            </button>

            {getPageNumbers().map(page => (
                <button
                    key={page}
                    onClick={() => onPageChange(page)}
                    className={`w-10 h-10 rounded-full flex items-center justify-center transition-colors duration-200 ${currentPage === page
                        ? 'bg-emerald-500 text-white'
                        : 'hover:bg-gray-100 text-gray-700'
                        }`}
                    aria-label={`Page ${page}`}
                    aria-current={currentPage === page ? 'page' : undefined}
                >
                    {page}
                </button>
            ))}

            <button
                onClick={() => onPageChange(currentPage + 1)}
                disabled={currentPage === totalPages}
                className="p-2 rounded-full hover:bg-gray-100 disabled:opacity-50 disabled:cursor-not-allowed transition-colors duration-200"
                aria-label="Next page"
            >
                <ChevronRight className="w-5 h-5" />
            </button>
        </div>
    );
};





// User Profile Display Component
const UserProfileDisplay = ({ user, credits }) => {
    const [profile, setProfile] = useState(null);

    useEffect(() => {
        const fetchProfile = async () => {
            if (user?.id) {
                const { data, error } = await supabase
                    .from('profiles')
                    .select('*')
                    .eq('id', user.id)
                    .single();

                if (!error && data) {
                    setProfile(data);
                }
            }
        };

        fetchProfile();
    }, [user]);

    return (
        <div className="flex items-center space-x-4">
            <div className="bg-green-100 p-3 rounded-lg flex items-center space-x-4 shadow-md hover:shadow-lg transition-all duration-300">
                <img
                    src={profile?.avatar_url || "./einstein.png"}
                    alt={profile?.username || "User"}
                    className="w-10 h-10 rounded-full border-2 border-green-500 shadow-sm"
                />
                <div>
                    <p className="text-gray-800 font-bold">
                        {profile?.username || user?.email?.split('@')[0] || "User"}
                    </p>
                    <p className="text-gray-600 text-sm">
                        {profile?.job_title || "Job Seeker"}
                    </p>
                </div>
                <div className="flex items-center space-x-1 bg-green-50 px-3 py-1 rounded-full">
                    <Sparkle className="w-4 h-4 text-green-500" />
                    <span className="text-green-600 font-semibold">
                        {profile?.credits || 0} Credits
                    </span>
                </div>
            </div>
        </div>
    );
};

const scrollToSection = (sectionId) => {
    const element = document.getElementById(sectionId);
    if (element) {
        const offset = 100; // Offset from the top to account for fixed header
        const elementPosition = element.getBoundingClientRect().top;
        const offsetPosition = elementPosition + window.pageYOffset - offset;

        window.scrollTo({
            top: offsetPosition,
            behavior: 'smooth'
        });
    }
};

// Feature Card Component
const FeatureCard = ({ icon, title, description }) => (
    <div className="bg-white p-6 rounded-xl shadow-lg hover:shadow-xl transition-shadow duration-300 transform hover:scale-105 border-t-4 border-green-500">
        <div className="text-green-500 mb-4">
            {icon}
        </div>
        <h3 className="text-xl font-semibold text-gray-800 mb-2">{title}</h3>
        <p className="text-gray-600">{description}</p>
    </div>
);

// Job Card Component
const JobCard = ({ title, company, location, img, companyColor }) => (
    <Card className="bg-white/90 backdrop-blur-sm p-6 rounded-xl shadow-lg border-l-4 border-green-500 hover:shadow-xl transition-all duration-300">
        <div className="flex items-start space-x-4">
            <div className="flex-shrink-0">
                <div className={`w-12 h-12 rounded-lg bg-gradient-to-br ${companyColor} flex items-center justify-center text-white font-bold text-xl shadow-lg`}>
                    {company[0]}
                </div>
            </div>
            <div className="flex-grow">
                <h3 className="text-xl font-semibold text-gray-800">{title}</h3>
                <p className="text-md font-medium text-gray-700">{company}</p>
                <div className="flex items-center space-x-2 mt-1">
                    <div className="text-sm text-gray-500 flex items-center">
                        <Target className="w-4 h-4 mr-1" />
                        {location}
                    </div>
                </div>
                <div className="mt-3">
                    <Button className={`text-sm text-white bg-gradient-to-r ${companyColor} hover:opacity-90 py-2 px-4`}>
                        Quick Apply
                    </Button>
                </div>
            </div>
        </div>
    </Card>
);

// Pricing Plan Component
const FeatureItem = ({ icon: Icon, text, highlight }) => (
    <div className="flex items-center space-x-2">
        <Icon className="h-5 w-5 text-blue-500 shrink-0" />
        <span className="text-gray-600 text-sm">
            {text}
            {highlight && (
                <span className="font-semibold text-gray-900">{highlight}</span>
            )}
        </span>
    </div>
);

const PricingPlan = ({ title, price, metrics, features, isPopular, onSelect }) => (
    <div className={`bg-white p-6 rounded-lg shadow-md ${isPopular ? 'border-2 border-blue-500' : ''} relative`}>
        {isPopular && (
            <div className="absolute -top-3 left-1/2 transform -translate-x-1/2 bg-blue-500 text-white px-4 py-1 rounded-full text-sm font-medium">
                Most Popular
            </div>
        )}
        <div className="mt-4">
            <h3 className="text-xl font-bold text-gray-800 mb-2">{title}</h3>
            <div className="flex items-baseline mb-4">
                <span className="text-3xl font-bold text-gray-900">{price}</span>
            </div>

            <div className="mb-6 space-y-4">
                <div className="flex items-center justify-between text-sm">
                    <span className="text-gray-500">Cost per application</span>
                    <span className="font-semibold text-green-600">{metrics.costPerApp}</span>
                </div>
                <div className="h-px bg-gray-100" />
            </div>

            <div className="space-y-1 mb-6">
                <FeatureItem
                    icon={Clock}
                    text="Save "
                    highlight={metrics.hoursSaved}
                />
                <FeatureItem
                    icon={Target}
                    text="Interview success rate "
                    highlight={metrics.successRate}
                />
            </div>

            <div className="space-y-3 mb-6">
                {features.map((feature, index) => (
                    <FeatureItem
                        key={index}
                        icon={Check}
                        text={feature}
                    />
                ))}
            </div>

            <Button
                onClick={onSelect}
                className={`w-full ${isPopular ? 'bg-blue-500 hover:bg-blue-600' : 'bg-gray-800 hover:bg-gray-900'} text-white font-medium`}
            >
                Get Started Now
            </Button>
        </div>
    </div>
);


export default function JobApplyAILandingPage() {
    const [currentJobIndex, setCurrentJobIndex] = useState(0);
    const [typedText, setTypedText] = useState("");
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const [isPaywallOpen, setIsPaywallOpen] = useState(false);
    const [isProfileCreationOpen, setIsProfileCreationOpen] = useState(false);
    const [user, setUser] = useState(null);
    const [hasProfile, setHasProfile] = useState(false);
    const [credits, setCredits] = useState(0);
    const [loading, setLoading] = useState(true);
    const navigate = useNavigate();
    const [applicationCount, setApplicationCount] = useState(0);


    useEffect(() => {
        const fetchApplicationCount = async () => {
            const { data, error, count } = await supabase
                .from('applications')
                .select('*', { count: 'exact' }); // Fetch with an exact count

            if (!error && count !== null) {
                setApplicationCount(count); // Set the count to state
            } else {
                console.error("Error fetching application count:", error);
            }
        };

        fetchApplicationCount();
    }, []);

    const fullText = [
        "Running RoboApply ✓",
        "Finding jobs ✓",
        "Generating resume ✓",
        "Applying ✓",
        "Repeating 1000x times ✓"
    ];
    const PEOPLE = [
        {
            "image_link": "https://lh3.googleusercontent.com/a/ACg8ocJA_NReprwRTtSuDCxzvAjaKW_EDWPKZbLhDMmARR3CRhAM7Tef=s96-c",
            "first_name": "Christy",
            "last_initial": "L",
            "review": "Been job hunting for months until I found Robo. The auto-application feature saved me so much time - applied to 50+ jobs in one sitting. Got 3 interviews last week!",
            "stars": 5
        },
        {
            "image_link": "https://lh3.googleusercontent.com/a/ACg8ocI_eANc9OejE1CXSdpXBZuhGs3O9ZuL6lkUfH9fFb0Wf1rvt2mG=s96-c",
            "first_name": "Mitch",
            "last_initial": "L",
            "review": "Tried several job application tools before this. Others charge $60-70/month and don't even have auto-apply. This is way more affordable and actually works.",
            "stars": 4
        },
        {
            "image_link": "https://lh3.googleusercontent.com/a/ACg8ocKGP_brZV4rJv2tBR5H5b4SibtM1enWrBEQoDYwGhNmTceBMH7PcA=s96-c",
            "first_name": "Sudha",
            "last_initial": "S",
            "review": "The resume parsing worked great for most applications, though I had to manually fix a few. Applied to about 200 positions in 2 weeks and got 6 callbacks. Worth every penny!",
            "stars": 5
        },
        {
            "image_link": "https://lh3.googleusercontent.com/a/ACg8ocIPOlk8cNwkkxX76wAHFJluZPP9AgIYdWZLQPvy9HoUjS6mnqnGHg=s96-c",
            "first_name": "Abhineet",
            "last_initial": "K",
            "review": "Really helped with my job search as a software engineer. The AI does a good job matching skills to job requirements. Would be nice if they added more job boards though.",
            "stars": 4
        },
        {
            "image_link": "https://lh3.googleusercontent.com/a/ACg8ocKzdc0Za_11gUuVWBhpi9EKYRauYUY-jFAcnox61KYP7k6AO8tU=s96-c",
            "first_name": "Tom",
            "last_initial": "C",
            "review": "The resume converter is a nice bonus feature. Support also was easy to reach. Pretty good value overall.",
            "stars": 4
        },
        {
            "image_link": "https://lh3.googleusercontent.com/a/ACg8ocIRPVsaxATGRgFP4_07hg0gT6rqkUo7GBP8bMnpfcIquj6CI7vGyA=s96-c",
            "first_name": "Vijay",
            "last_initial": "A",
            "review": "The core functionality is solid. Applied to 75 jobs in my first week. Already had 4 interviews and one offer!",
            "stars": 4
        },
        {
            "image_link": "https://lh3.googleusercontent.com/a/ACg8ocJiTv4JGldhqOZTt4RdE9Nh-L9J9llNVIwX8skbxKy70yy6fnw3IA=s96-c",
            "first_name": "Shubham",
            "last_initial": "J",
            "review": "Love how it handles multiple resume versions and auto-converts them. Applied to 100+ jobs in under 2 hours. Some formatting issues but nothing major.",
            "stars": 5
        },
        {
            "image_link": "https://lh3.googleusercontent.com/a/ACg8ocII-OShlB_qEJNuIFwv9dNfOtMYaRfXadAhJsQKuoj8XgTCAAbw=s96-c",
            "first_name": "Vi",
            "last_initial": "D",
            "review": "Not perfect but definitely better than manually applying everywhere. Got a few mismatched applications but the time saved was worth it.",
            "stars": 4
        },
        {
            "image_link": "https://lh3.googleusercontent.com/a/ACg8ocIuuFZv34UedAIvdr85r5gxOCAaMd8cK6N9P-hFvLWuyx4Zorw=s96-c",
            "first_name": "Gurdeep",
            "last_initial": "S",
            "review": "Great for tech positions. The AI customizes cover letters pretty well. Wish they had more filters for job search but otherwise solid platform.",
            "stars": 4
        },
        {
            "image_link": "https://lh3.googleusercontent.com/a/ACg8ocJw07sMJt2ye8z8txp9bOPSNNTz8YAN_FU0b2H42srx7z7iQAc=s96-c",
            "first_name": "Parth",
            "last_initial": "P",
            "review": "Been using it for 3 weeks now. The AI is pretty smart at matching qualifications. Had one payment issue but support sorted it out quickly.",
            "stars": 4.5
        },
        {
            "image_link": "https://lh3.googleusercontent.com/a/ACg8ocIpOT44jSN2u4UA-nHY155mGzrUyFUuacCoEbPE_jjOtq1CKxyM=s96-c",
            "first_name": "Victoria",
            "last_initial": "L",
            "review": "Applied to 30 jobs my first day. Already have 2 interviews lined up which is crazy!",
            "stars": 5
        },
        {
            "image_link": "https://lh3.googleusercontent.com/a/ACg8ocIIXGIT-KeCzWCOIeI4to6jzDnyhGRWcOyVc2tKT6nb4sbXTe7v=s96-c",
            "first_name": "Gehad",
            "last_initial": "S",
            "review": "Finally an AI tool that actually helps with job hunting! The automated applications aren't perfect but save tons of time. Much better than filling out forms manually.",
            "stars": 4.5
        }
    ];

    const jobs = [
        {
            title: "Senior Data Engineer",
            company: "Netflix",
            location: "Remote",
            img: "./netflix.png",
            companyColor: "from-[#E50914] to-[#B20710]" // Netflix red
        },
        {
            title: "Data Scientist",
            company: "Meta",
            location: "Menlo Park, CA",
            img: "./meta.png",
            companyColor: "from-[#0668E1] to-[#0078FF]" // Meta blue
        },
        {
            title: "UX Designer",
            company: "Google",
            location: "Mountain View, CA",
            img: "./google.png",
            companyColor: "from-[#4285F4] to-[#34A853]" // Google colors
        },
        {
            title: "Product Manager",
            company: "Amazon",
            location: "Seattle, WA",
            img: "./amazon.png",
            companyColor: "from-[#FF9900] to-[#232F3E]" // Amazon orange
        },
        {
            title: "Marketing Specialist",
            company: "Apple",
            location: "Cupertino, CA",
            img: "./apple.png",
            companyColor: "from-[#555555] to-[#000000]" // Apple black
        },
        {
            title: "Machine Learning Engineer",
            company: "Microsoft",
            location: "Redmond, WA",
            img: "./microsoft.png",
            companyColor: "from-[#F25022] to-[#7FBA00]" // Microsoft colors
        },
        {
            title: "Cloud Solutions Architect",
            company: "IBM",
            location: "Armonk, NY",
            img: "./ibm.png",
            companyColor: "from-[#0F3B6C] to-[#D9E3F0]" // IBM blue
        },
        {
            title: "Cybersecurity Analyst",
            company: "Cisco Systems",
            location: "San Jose, CA",
            img: "/cisco.svg",
            companyColor: "from-[#1BA0E2] to-[#005CC5]" // Cisco blue
        },
        {
            title: "Full Stack Developer",
            company: "Salesforce",
            location: "San Francisco, CA",
            img: "./salesforce.png",
            companyColor: "from-[#00A1E0] to-[#0070D2]" // Salesforce blue
        },
        {
            title: "DevOps Engineer",
            company: "Tesla",
            location: "Palo Alto, CA",
            img: "./tesla.png",
            companyColor: "from-[#E82127] to-[#C9C9C9]" // Tesla red
        },
        {
            title: "AI Research Scientist",
            company: "OpenAI",
            location: "San Francisco, CA",
            img: "./openai.png",
            companyColor: "from-[#A500B5] to-[#FF6F20]" // OpenAI colors
        },
        {
            title: "Software Engineer Intern",
            company: "Stripe",
            location: "Remote",
            img: "./stripe.jpeg",
            companyColor: "from-[#6772E5] to[#5469D4]" // Stripe blue
        },
        {
            title: "Data Analyst",
            company: "Uber",
            location: "San Francisco, CA",
            img: "./uber.jpg",
            companyColor: "from[#000000] to[#FFFFFF]" // Uber black and white
        },
        {
            title: "Systems Administrator",
            company: "Adobe",
            location: "San Jose, CA",
            img: "./adobe.png",
            companyColor: "from[#FF0000] to[#FF7F50]" // Adobe red
        },
        {
            title: "Network Engineer",
            company: "NVIDIA",
            location: "Santa Clara, CA",
            img: "./Nvidia_logo.svg.png",
            companyColor: "from[#76B900] to[#4B8BBE]" // NVIDIA green and blue
        },
        {
            title: "Mobile App Developer",
            company: "Spotify",
            location: "Stockholm, Sweden",
            img: "./spotify.png",
            companyColor: "from[#1DB954] to[#191414]" // Spotify green and black
        },
        {
            title: "Quality Assurance Tester",
            company: "Samsung Electronics",
            location: "Suwon-si, South Korea",
            img: "./samsung.png",
            companyColor: "from[#1428A0] to[#FFFFFF]" // Samsung blue and white
        },
        {
            title: "IT Security Specialist",
            company: "Palantir Technologies",
            location: "Denver, CO",
            img: "./palantir.png",
            companyColor: "from[#1A1A1A] to[#FFFFFF]" // Palantir black and white
        },
        {
            title: "Robotics Engineer",
            company: "Boston Dynamics",
            location: "Waltham, MA",
            img: "./boston.png",
            companyColor: "from[#D3D3D3] to[#000000]" // Boston Dynamics gray and black
        },
        {
            title: "Game Developer",
            company: "Epic Games",
            location: "Cary, NC",
            img: "./epic.png",
            companyColor: "from[#313131] to[#00BFFF]" // Epic Games black and blue
        }
    ];





    const getCompanyStyle = (company) => {
        const styles = {
            'Netflix': 'bg-gradient-to-r from-[#E50914] to-[#B20710]',
            'Meta': 'bg-gradient-to-r from-[#0668E1] to-[#0078FF]',
            'Google': 'bg-gradient-to-r from-[#4285F4] to-[#34A853]',
            'Amazon': 'bg-gradient-to-r from-[#FF9900] to-[#232F3E]',
            'Apple': 'bg-gradient-to-r from-[#555555] to-[#000000]',
            'Microsoft': 'bg-gradient-to-r from-[#00A4EF] to-[#7FBA00]',
            'OpenAI': 'bg-gradient-to-r from-[#412991] to-[#6B47B8]',
            'Tesla': 'bg-gradient-to-r from-[#CC0000] to-[#8E0000]',
            'Spotify': 'bg-gradient-to-r from-[#1DB954] to-[#1ED760]',
            'Twitter': 'bg-gradient-to-r from-[#1DA1F2] to-[#0D8EDA]',
            'Airbnb': 'bg-gradient-to-r from-[#FF5A5F] to-[#FC642D]',
            'Uber': 'bg-gradient-to-r from-[#000000] to-[#333333]',
            'GitHub': 'bg-gradient-to-r from-[#24292E] to-[#1B1F23]',
            'Intel': 'bg-gradient-to-r from-[#0071C5] to-[#005DAA]',
            'IBM': 'bg-gradient-to-r from-[#1F70C1] to-[#1A5BA4]',
            'Oracle': 'bg-gradient-to-r from-[#C74634] to-[#A23B2A]',
            'Adobe': 'bg-gradient-to-r from-[#FF0000] to-[#D40000]',
        };

        // Default gradient for companies not in the list
        return styles[company] || 'bg-gradient-to-r from-gray-600 to-gray-800';
    };

    const messages = [
        "Top companies - no manual work",
        "Accelerate your job search!",
        "Apply effortlessly to leading firms",
        "Your career, powered by RoboApply",
        "Seamlessly apply to tech giants",
    ];


    const checkUserProfile = async (userId) => {
        const { data, error } = await supabase
            .from('profiles')
            .select('*')
            .eq('id', userId)
            .single();

        if (error) {
            console.error('Error fetching profile:', error);
            return { hasProfile: false, profileData: null };
        }

        setCredits(data?.credits || 0);
        return { hasProfile: data?.has_profile || false, profileData: data };
    };

    useEffect(() => {
        const initializeApp = async () => {
            const storedSession = JSON.parse(localStorage.getItem(`sb-${process.env.REACT_APP_SUPABASE_PROJECT_ID}-auth-token`));
            if (storedSession && storedSession.user) {
                setUser(storedSession.user);
                localStorage.setItem('userId', storedSession.user.id);
                const { hasProfile, profileData } = await checkUserProfile(storedSession.user.id);
                setHasProfile(hasProfile);
                if (!hasProfile) {
                    setIsProfileCreationOpen(true);
                }
            }

            const { data: authListener } = supabase.auth.onAuthStateChange(async (event, session) => {
                if (event === 'SIGNED_IN' && session) {
                    setUser(session.user);
                    localStorage.setItem(`sb-${process.env.REACT_APP_SUPABASE_PROJECT_ID}-auth-token`, JSON.stringify(session));
                    localStorage.setItem('userId', session.user.id);
                    const { hasProfile, profileData } = await checkUserProfile(session.user.id);
                    setHasProfile(hasProfile);
                    if (!hasProfile) {
                        setIsProfileCreationOpen(true);
                    }
                } else if (event === 'SIGNED_OUT') {
                    setUser(null);
                    setHasProfile(false);
                    setCredits(0);
                    localStorage.removeItem(`sb-${process.env.REACT_APP_SUPABASE_PROJECT_ID}-auth-token`);
                    localStorage.removeItem('userId');
                }
            });

            setLoading(false);

            return () => {
                authListener.subscription.unsubscribe();
            };
        };

        initializeApp();
    }, []);

    useEffect(() => {
        const interval = setInterval(() => {
            setCurrentJobIndex((prevIndex) => (prevIndex + 1) % PEOPLE.length);
        }, 3000);

        return () => clearInterval(interval);
    }, []);

    useEffect(() => {
        let currentLine = 0;
        let currentChar = 0;
        const typingInterval = setInterval(() => {
            if (currentLine < fullText.length) {
                if (currentChar < fullText[currentLine].length) {
                    setTypedText(prev => prev + fullText[currentLine][currentChar]);
                    currentChar++;
                } else {
                    setTypedText(prev => prev + "\n");
                    currentLine++;
                    currentChar = 0;
                }
            } else {
                clearInterval(typingInterval);
            }
        }, 50);

        return () => clearInterval(typingInterval);
    }, []);

    const handleLogin = async (session) => {
        if (session) {
            setUser(session.user);
            localStorage.setItem(`sb-${process.env.REACT_APP_SUPABASE_PROJECT_ID}-auth-token`, JSON.stringify(session));
            localStorage.setItem('userId', session.user.id);
            const { hasProfile, profileData } = await checkUserProfile(session.user.id);
            setHasProfile(hasProfile);

            if (!hasProfile) {
                setIsProfileCreationOpen(true);
            } else {
                navigate('/apply');
            }
        }
        setIsPaywallOpen(false);
    }

    const handleProfileComplete = (profileData) => {
        setUser(prevUser => ({
            ...prevUser,
            ...profileData
        }));
        setHasProfile(true);
        setIsProfileCreationOpen(false);
        navigate('/apply');
    };

    if (loading) {
        return <div>Loading...</div>;
    }



    return (

        <div className="min-h-screen bg-gradient-to-br from-green-400 via-green-500 to-blue-500 flex flex-col items-center justify-between p-4 overflow-hidden">
            <style jsx global>{`
        @import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700;800&display=swap');
        
        /* Define font variables */
        :root {
          --font-poppins: 'Poppins', sans-serif;
        }

        /* Apply font globally */
        html {
          font-family: var(--font-poppins);
        }

        /* Optional: Customize specific text elements */
        h1, h2, h3 {
          font-family: var(--font-poppins);
          font-weight: 700;
        }

        p, span, div {
          font-family: var(--font-poppins);
          font-weight: 400;
        }

        button {
          font-family: var(--font-poppins);
          font-weight: 600;
        }
      `}</style>
            <div className="absolute top-0 left-0 w-3/4 h-full bg-white rounded-br-[100px] md:rounded-br-[200px]" />

            {/* Header */}
            <header className="w-full max-w-6xl mx-auto flex items-center justify-between p-4 relative z-50">
                <div className="flex items-center space-x-3">
                    <img src="/upLine.svg" alt="RoboApply Logo" className="h-10 w-10" />
                    <span className="text-3xl font-bold bg-clip-text text-transparent bg-gradient-to-r from-green-400 to-blue-500">
                        RoboApply
                    </span>
                </div>
                <nav className="hidden md:flex items-center space-x-6">
                    {/* <a href="#features" className="text-gray-700 hover:text-gray-900">Features</a> */}
                    <a href="/jobs" className="text-gray-700 hover:text-gray-900">Jobs</a>
                    <a href="/resume" className="text-gray-700 hover:text-gray-900">Resume Builder</a>
                    {user && hasProfile ? (
                        <>
                            <UserProfileDisplay user={user} credits={credits} />
                            <Button
                                onClick={() => navigate('/apply')}
                                className="bg-blue-500 hover:bg-blue-600 text-white"
                            >
                                Go to Apply
                            </Button>
                            <Button
                                onClick={async () => {
                                    try {
                                        const { error } = await supabase.auth.signOut();
                                        if (error) throw error;
                                        localStorage.removeItem(`sb-${process.env.REACT_APP_SUPABASE_PROJECT_ID}-auth-token`);
                                        localStorage.removeItem('userId');
                                        toast.success('Logged out successfully');
                                    } catch (error) {
                                        toast.error('Error logging out');
                                    }
                                }}
                                className="bg-gradient-to-r from-red-400 to-red-500 text-white hover:from-red-500 hover:to-red-600"
                            >
                                <LogOut className="w-5 h-4 mr-1" />
                                Log Out
                            </Button>
                        </>
                    ) : (
                        <>
                            <Button
                                onClick={() => setIsPaywallOpen(true)}
                                className="bg-gradient-to-r from-green-400 to-blue-500 text-white hover:from-green-500 hover:to-blue-600"
                            >
                                Sign In
                            </Button>
                        </>
                    )}
                </nav>
                {isMenuOpen && (
                    <div className="md:hidden fixed top-[3.5rem] left-0 right-0 flex flex-col space-y-4 w-full p-4 bg-white rounded-lg shadow-lg z-[60] mx-auto max-w-6xl border-t border-gray-100">
                        <a href="#features" className="text-gray-700 hover:text-gray-900" onClick={(e) => {
                            e.preventDefault();
                            document.querySelector('#features').scrollIntoView({ behavior: 'smooth' });
                            setIsMenuOpen(false);
                        }}>Features</a>
                        <a href="#jobs" className="text-gray-700 hover:text-gray-900" onClick={(e) => {
                            e.preventDefault();
                            document.querySelector('#jobs').scrollIntoView({ behavior: 'smooth' });
                            setIsMenuOpen(false);
                        }}>Jobs</a>
                        {user && hasProfile ? (
                            <>
                                <UserProfileDisplay user={user} credits={credits} />
                                <Button
                                    onClick={() => navigate('/apply')}
                                    className="bg-blue-500 hover:bg-blue-600 text-white w-full"
                                >
                                    Go to Apply
                                </Button>
                                <Button
                                    onClick={async () => {
                                        try {
                                            const { error } = await supabase.auth.signOut();
                                            if (error) throw error;
                                            localStorage.removeItem(`sb-${process.env.REACT_APP_SUPABASE_PROJECT_ID}-auth-token`);
                                            localStorage.removeItem('userId');
                                            toast.success('Logged out successfully');
                                        } catch (error) {
                                            toast.error('Error logging out');
                                        }
                                    }}
                                    className="bg-gradient-to-r from-red-400 to-red-500 text-white hover:from-red-500 hover:to-red-600 w-full"
                                >
                                    <LogOut className="w-4 h-4 mr-2" />
                                    Log Out
                                </Button>
                            </>
                        ) : (
                            <Button
                                onClick={() => setIsPaywallOpen(true)}
                                className="bg-gradient-to-r from-green-400 to-blue-500 text-white hover:from-green-500 hover:to-blue-600 w-full"
                            >
                                Sign In
                            </Button>
                        )}
                    </div>
                )}
                <Button className="md:hidden text-gray-800" onClick={() => setIsMenuOpen(!isMenuOpen)}>
                    {isMenuOpen ? <X /> : <Menu />}
                </Button>
            </header>
            {/* Main Content */}
            <main className="flex-grow w-full">


                <div className="relative w-full max-w-[90rem] mx-auto flex flex-col md:flex-row items-center justify-between gap-12 z-10 mt-8 px-6">
                    <div className="w-full md:w-1/2 space-y-12">
                        <h1 className="text-5xl md:text-5xl font-bold leading-tight bg-clip-text text-transparent bg-gradient-to-r from-green-600 to-blue-600">
                            Automate Job Applications<br />
                        </h1>

                        <p className="text-xl text-gray-900 max-w-xl leading-relaxed whitespace-normal break-words">
                            Use AI agents to apply to 100's of jobs - one click!
                        </p>
                        {user && hasProfile ? (
                            <Button
                                onClick={() => navigate('/apply')}
                                className="bg-gradient-to-r from-green-500 to-blue-500 hover:from-green-600 hover:to-blue-600 text-white px-10 py-5 text-xl font-semibold shadow-lg hover:shadow-xl"
                            >
                                Land a job now →
                            </Button>
                        ) : (
                            <Button
                                onClick={() => setIsPaywallOpen(true)}
                                className="bg-gradient-to-r from-green-500 to-blue-500 hover:from-green-600 hover:to-blue-600 text-white px-10 py-5 text-xl font-semibold shadow-lg hover:shadow-xl"
                            >
                                Land a job now →
                            </Button>
                        )}
                        <div className="flex items-center gap-4">
                            {/* User Images */}
                            <div className="hidden lg:flex -space-x-3">
                                {["/person1.png", "/person2.png", "/person3.png", "/person4.png"].map((src, i) => (
                                    <img
                                        key={i}
                                        src={src}
                                        alt={`User ${i + 1}`}
                                        className="w-12 h-12 rounded-full border-2 border-white object-cover"
                                    />
                                ))}
                            </div>

                            {/* Stars */}
                            <div className="hidden lg:flex items-center">
                                {[...Array(5)].map((_, i) => (
                                    <Star key={i} className="w-5 h-5 fill-yellow-400 stroke-yellow-400" />
                                ))}
                            </div>

                            {/* Application Count */}
                            <span className="text-lg font-semibold text-gray-700">
                                Already used to send over {(applicationCount * 10).toLocaleString()} applications!
                            </span>
                        </div>
                    </div>
                    <div className="w-full md:w-1/2 space-y-6">
                        <Card className="bg-white p-6 rounded-xl shadow-lg mb-6 h-[215px] overflow-hidden">
                            <div className="flex items-center gap-3 mb-4">
                                <div className="w-4 h-4 bg-green-500 rounded-full animate-pulse" />
                                <h2 className="text-xl font-semibold">RoboApply Process</h2>
                            </div>
                            <div className="h-[140px] overflow-hidden">
                                <pre className="font-mono text-lg whitespace-pre-wrap text-green-1000">{typedText}</pre>
                            </div>
                        </Card>

                        {/* Fixed height container for job cards */}
                        <div className="h-[280px] relative overflow-hidden">
                            <div className="absolute inset-0">
                                {PEOPLE.map((job, index) => (
                                    <div
                                        key={index}
                                        className="absolute top-0 left-0 w-full transition-all duration-500 ease-in-out"
                                        style={{
                                            transform: `translateY(${(index - currentJobIndex) * 100}%)`,
                                            opacity: index === currentJobIndex ? 1 : 0,
                                            pointerEvents: index === currentJobIndex ? 'auto' : 'none'
                                        }}
                                    >
                                        <Card className="bg-white/90 backdrop-blur-sm p-6 rounded-xl shadow-lg border-l-4 border-green-500 hover:shadow-xl transition-all duration-300">
                                            <div className="flex items-start space-x-4">
                                                <div className="flex-shrink-0">
                                                    <img
                                                        src={job.image_link || `/api/placeholder/64/64`}
                                                        alt={`${job.first_name} logo`}
                                                        className="w-16 h-16 rounded-xl shadow-lg object-cover ring-2 ring-white/50
                                transform hover:scale-105 transition-all duration-300"
                                                    />
                                                </div>
                                                <div className="flex-grow">
                                                    <h3 className="text-xl font-bold text-gray-800 mb-1">{job.review}</h3>
                                                    <p className="text-lg font-semibold text-transparent bg-clip-text bg-gradient-to-r 
                            ${getCompanyStyle(job.company)}">{job.first_name} {job.last_initial}.</p>
                                                    <div className="flex items-center gap-1 mt-2">
                                                        {[...Array(5)].map((_, index) => (
                                                            <Star
                                                                key={index}
                                                                className={`w-4 h-4 ${index < job.stars
                                                                    ? 'text-yellow-400 fill-yellow-400'
                                                                    : 'text-gray-300 fill-gray-300'
                                                                    }`}
                                                            />
                                                        ))}
                                                    </div>
                                                    <Button
                                                        className={`mt-4 text-white ${getCompanyStyle(job.company)} 
        hover:opacity-90 transform hover:scale-105 transition-all duration-300`}
                                                    >
                                                        {messages[currentJobIndex % messages.length]}
                                                    </Button>
                                                </div>
                                            </div>
                                        </Card>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>

                </div>

                {/* Features Section */}
                <section id="features" className="w-full max-w-[90rem] mx-auto py-32 relative z-10 px-6">


                    <h2 className="text-5xl font-bold text-center text-gray-800 mb-16">Supercharge Your Job Search</h2>
                    <div className="grid grid-cols-1 md:grid-cols-3 gap-12">
                        <FeatureCard
                            icon={<Zap className="w-16 h-16" />}
                            title="Auto apply to 1000's of jobs"
                            description="Apply to thousands of jobs in minutes, not hours - no more endless scrolling & clicking"
                        />
                        <FeatureCard
                            icon={<Target className="w-16 h-16" />}
                            title="Instant AI resume conversions - no more reformatting for ATS "
                            description="We use the latest generative-ai technology to convert your resume that beats 95% of ATS systems"
                        />
                        <FeatureCard
                            icon={<Rocket className="w-16 h-16" />}
                            title="Job Aggregation & Tracking Tools"
                            description="We update 100,000's of jobs per week from every major job board, so you don't have to go to multiple places"
                        />

                    </div>

                </section>

                {/* <section className="w-full max-w-[103rem] mx-auto px-4 mb-16">
                    <Workflow />
                </section> */}

                <section className="w-full max-w-[103rem] mx-auto px-4 mb-16">
                    <ResumeBuilder />
                </section>

                <div className="max-w-4xl mx-auto p-6 bg-white rounded-lg shadow-sm">
                    <h2 className="text-2xl font-bold text-gray-800 mb-6">Platform Tutorial</h2>
                    <div className="relative w-full pt-[56.25%]">
                        <iframe
                            src="https://www.youtube.com/embed/BfXKvUXstcg"
                            title="Platform Tutorial"
                            className="absolute top-0 left-0 w-full h-full rounded-lg shadow-lg"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                            allowFullScreen
                            frameBorder="0"
                        />
                    </div>
                </div>





                <section id="pricing" className="w-full max-w-6xl mx-auto py-16 px-4">
                    <h2 className="text-3xl font-bold text-center text-gray-800 mb-3">Choose Your Career Accelerator</h2>
                    <p className="text-center text-gray-600 mb-12 max-w-2xl mx-auto">
                        Join over 1000+ professionals who are hacking the system and getting jobs 350% faster!
                    </p>

                </section>


            </main >

            {/* Footer */}
            < footer className="w-full bg-gray-100 py-12 px-4 relative z-10" >
                <div className="max-w-6xl mx-auto grid grid-cols-1 md:grid-cols-4 gap-8">
                    <div>
                        <h3 className="text-lg font-semibold mb-4">RoboApply.ai</h3>
                        <p className="text-gray-600">Empowering careers with AI.</p>
                    </div>
                    <div>
                        <h3 className="text-lg font-semibold mb-4">Product</h3>
                        <ul className="space-y-2">
                            <li><a href="#features" className="text-gray-600 hover:text-gray-900">Features</a></li>
                            <li><a href="#jobs" className="text-gray-600 hover:text-gray-900">Jobs</a></li>
                            <li><a href="#" className="text-gray-600 hover:text-gray-900">FAQ</a></li>
                        </ul>
                    </div>
                    <div>
                        <h3 className="text-lg font-semibold mb-4">Company</h3>
                        <ul className="space-y-2">
                            <li><a href="#" className="text-gray-600 hover:text-gray-900">About Us</a></li>
                            <li><a href="#" className="text-gray-600 hover:text-gray-900">Careers</a></li>
                            <li><a href="#" className="text-gray-600 hover:text-gray-900">Contact</a></li>
                        </ul>
                    </div>
                    <div>
                        <h3 className="text-lg font-semibold mb-4">Legal</h3>
                        <ul className="space-y-2">
                            <li><a href="#" className="text-gray-600 hover:text-gray-900">Privacy Policy</a></li>
                            <li><a href="#" className="text-gray-600 hover:text-gray-900">Terms of Service</a></li>
                        </ul>
                    </div>
                </div>
                <div className="max-w-6xl mx-auto mt-8 pt-8 border-t border-gray-200 flex flex-col md:flex-row justify-between items-center">
                    <p className="text-gray-600">&copy; 2023 RoboApply.ai. All rights reserved.</p>
                    <div className="flex space-x-4 mt-4 md:mt-0">
                        <a href="#" className="text-gray-600 hover:text-gray-900"><Facebook size={24} /></a>
                        <a href="#" className="text-gray-600 hover:text-gray-900"><Twitter size={24} /></a>
                        <a href="#" className="text-gray-600 hover:text-gray-900"><Linkedin size={24} /></a>
                        <a href="#" className="text-gray-600 hover:text-gray-900"><Instagram size={24} /></a>
                    </div>
                </div>
            </footer >

            {/* Modals */}
            < Modal isOpen={isPaywallOpen} onClose={() => setIsPaywallOpen(false)
            }>
                <PaywallModal onLogin={handleLogin} />
            </Modal >

            <Modal isOpen={isProfileCreationOpen} onClose={() => setIsProfileCreationOpen(false)}>
                <ProfileCreation
                    user={user}
                    onComplete={handleProfileComplete}
                />
            </Modal>
        </div >
    );
}







// import React, { useState, useEffect } from 'react';
// import { useNavigate } from 'react-router-dom';
// import { Menu, X, Mail, Lock, Check, User, Camera, CreditCard } from "lucide-react";
// import supabase from '../supabaseConfig';
// import { loadStripe } from '@stripe/stripe-js';
// import ProfileCreation from './ProfileCreation';

// // Stripe setup
// const stripePromise = loadStripe('your_stripe_publishable_key');

// // Button Component
// const Button = ({ className, children, ...props }) => (
//     <button className={`px-6 py-3 rounded-full font-semibold transition-all duration-300 ${className}`} {...props}>
//         {children}
//     </button>
// );

// // Input Component
// const Input = ({ icon: Icon, ...props }) => (
//     <div className="relative">
//         <Icon className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400" size={20} />
//         <input
//             className="w-full py-2 pl-10 pr-3 bg-gray-100 border border-gray-300 rounded-md text-gray-800 placeholder-gray-400"
//             {...props}
//         />
//     </div>
// );

// // Modal Component
// const Modal = ({ isOpen, onClose, children }) => {
//     if (!isOpen) return null;

//     return (
//         <div className="fixed inset-0 bg-black bg-opacity-50 z-50 flex items-center justify-center">
//             <div className="bg-white p-8 rounded-xl shadow-2xl max-w-md w-full">
//                 {children}
//                 <Button onClick={onClose} className="mt-4 w-full bg-gray-200 text-gray-800">
//                     Close
//                 </Button>
//             </div>
//         </div>
//     );
// };

// // Profile Creation Component
// const ProfileCreation = ({ user, onComplete }) => {
//     const [username, setUsername] = useState('');
//     const [jobTitle, setJobTitle] = useState('');
//     const [avatar, setAvatar] = useState(null);
//     const [error, setError] = useState(null);

//     const handleSubmit = async (e) => {
//         e.preventDefault();
//         setError(null);

//         try {
//             let avatarUrl = user.user_metadata.avatar_url;

//             if (avatar) {
//                 const fileExt = avatar.name.split('.').pop();
//                 const fileName = `${Math.random()}.${fileExt}`;
//                 const { data, error: uploadError } = await supabase.storage
//                     .from('avatars')
//                     .upload(`${user.id}/${fileName}`, avatar);

//                 if (uploadError) throw uploadError;

//                 const { data: { publicUrl } } = supabase.storage
//                     .from('avatars')
//                     .getPublicUrl(data.path);

//                 avatarUrl = publicUrl;
//             }

//             const { data, error } = await supabase
//                 .from('profiles')
//                 .upsert({ id: user.id, username, job_title: jobTitle, avatar_url: avatarUrl, has_profile: true });

//             if (error) throw error;
//             onComplete({ username, job_title: jobTitle, avatar_url: avatarUrl });
//         } catch (error) {
//             setError(error.message);
//         }
//     };

//     return (
//         <form onSubmit={handleSubmit} className="space-y-4">
//             <h2 className="text-2xl font-bold text-gray-800 mb-4">Complete Your Profile</h2>
//             <Input
//                 icon={User}
//                 type="text"
//                 value={username}
//                 onChange={(e) => setUsername(e.target.value)}
//                 placeholder="Choose a username"
//                 minLength={3}
//                 required
//             />
//             <Input
//                 icon={CreditCard}
//                 type="text"
//                 value={jobTitle}
//                 onChange={(e) => setJobTitle(e.target.value)}
//                 placeholder="Your job title"
//                 required
//             />
//             <div className="flex items-center space-x-4">
//                 <div className="w-20 h-20 rounded-full bg-gray-200 flex items-center justify-center overflow-hidden">
//                     {avatar ? (
//                         <img src={URL.createObjectURL(avatar)} alt="Avatar preview" className="w-full h-full object-cover" />
//                     ) : (
//                         <Camera size={32} className="text-gray-400" />
//                     )}
//                 </div>
//                 <input
//                     type="file"
//                     accept="image/*"
//                     onChange={(e) => setAvatar(e.target.files[0])}
//                     className="hidden"
//                     id="avatar-upload"
//                 />
//                 <label htmlFor="avatar-upload" className="cursor-pointer bg-blue-500 text-white px-4 py-2 rounded-full hover:bg-blue-600 transition-colors">
//                     Upload Avatar
//                 </label>
//             </div>
//             <Button type="submit" className="w-full bg-gradient-to-r from-blue-500 to-purple-500 text-white">
//                 Complete Profile
//             </Button>
//             {error && <p className="text-red-500">{error}</p>}
//         </form>
//     );
// };

// // Paywall Modal Component
// const PaywallModal = ({ onLogin }) => {
//     const [email, setEmail] = useState('');
//     const [password, setPassword] = useState('');
//     const [error, setError] = useState(null);

//     const handleSubmit = async (e) => {
//         e.preventDefault();
//         setError(null);

//         try {
//             const { data, error } = await supabase.auth.signInWithPassword({ email, password });
//             if (error) throw error;
//             onLogin(data);
//         } catch (error) {
//             setError(error.message);
//         }
//     };

//     const handleGoogleLogin = async () => {
//         try {
//             const { data, error } = await supabase.auth.signInWithOAuth({ provider: 'google' });
//             if (error) throw error;
//             onLogin(data);
//         } catch (error) {
//             setError(error.message);
//         }
//     };

//     return (
//         <div className="space-y-4">
//             <h2 className="text-2xl font-bold text-gray-800 mb-4">Sign In or Create Account</h2>
//             <form onSubmit={handleSubmit} className="space-y-4">
//                 <Input
//                     icon={Mail}
//                     type="email"
//                     placeholder="Email"
//                     value={email}
//                     onChange={(e) => setEmail(e.target.value)}
//                     required
//                 />
//                 <Input
//                     icon={Lock}
//                     type="password"
//                     placeholder="Password"
//                     value={password}
//                     onChange={(e) => setPassword(e.target.value)}
//                     required
//                 />
//                 <Button type="submit" className="w-full bg-gradient-to-r from-blue-500 to-purple-500 text-white">
//                     Sign In / Sign Up
//                 </Button>
//             </form>
//             <Button onClick={handleGoogleLogin} className="w-full bg-white text-gray-800 border border-gray-300 flex items-center justify-center">
//                 <img src="https://www.gstatic.com/firebasejs/ui/2.0.0/images/auth/google.svg" alt="Google" className="w-6 h-6 mr-2" />
//                 Sign in with Google
//             </Button>
//             {error && <p className="text-red-500">{error}</p>}
//         </div>
//     );
// };

// // User Profile Display Component
// const UserProfileDisplay = ({ user, credits }) => (
//     <div className="flex items-center space-x-4">
//         <img src={user.avatar_url} alt={user.username} className="w-10 h-10 rounded-full" />
//         <div>
//             <p className="text-gray-800 font-bold">{user.username}</p>
//             <p className="text-gray-600 text-sm">{user.job_title}</p>
//         </div>
//         <span className="text-gray-800">Credits: {credits}</span>
//     </div>
// );

// // Main Component
// export default function JobApplyAILandingPage() {
//     const [isMenuOpen, setIsMenuOpen] = useState(false);
//     const [isPaywallOpen, setIsPaywallOpen] = useState(false);
//     const [isProfileCreationOpen, setIsProfileCreationOpen] = useState(false);
//     const [user, setUser] = useState(null);
//     const [hasProfile, setHasProfile] = useState(false);
//     const [credits, setCredits] = useState(0);
//     const [loading, setLoading] = useState(true);
//     const navigate = useNavigate();

//     const checkUserProfile = async (userId) => {
//         const { data, error } = await supabase
//             .from('profiles')
//             .select('*')
//             .eq('id', userId)
//             .single();

//         if (error) {
//             console.error('Error fetching profile:', error);
//             return { hasProfile: false, profileData: null };
//         }

//         setCredits(data?.credits || 0);
//         return { hasProfile: data?.has_profile || false, profileData: data };
//     };

//     useEffect(() => {
//         const initializeApp = async () => {
//             const storedSession = JSON.parse(localStorage.getItem(`sb-${process.env.REACT_APP_SUPABASE_PROJECT_ID}-auth-token`));
//             if (storedSession && storedSession.user) {
//                 setUser(storedSession.user);
//                 const { hasProfile, profileData } = await checkUserProfile(storedSession.user.id);
//                 setHasProfile(hasProfile);
//                 if (!hasProfile) {
//                     setIsProfileCreationOpen(true);
//                 }
//             }

//             const { data: authListener } = supabase.auth.onAuthStateChange(async (event, session) => {
//                 if (event === 'SIGNED_IN' && session) {
//                     setUser(session.user);
//                     localStorage.setItem(`sb-${process.env.REACT_APP_SUPABASE_PROJECT_ID}-auth-token`, JSON.stringify(session));
//                     const { hasProfile, profileData } = await checkUserProfile(session.user.id);
//                     setHasProfile(hasProfile);
//                     if (!hasProfile) {
//                         setIsProfileCreationOpen(true);
//                     }
//                 } else if (event === 'SIGNED_OUT') {
//                     setUser(null);
//                     setHasProfile(false);
//                     setCredits(0);
//                     localStorage.removeItem(`sb-${process.env.REACT_APP_SUPABASE_PROJECT_ID}-auth-token`);
//                 }
//             });

//             setLoading(false);

//             return () => {
//                 authListener.subscription.unsubscribe();
//             };
//         };

//         initializeApp();
//     }, []);

//     const handleLogin = async (session) => {
//         if (session) {
//             setUser(session.user);
//             localStorage.setItem(`sb-${process.env.REACT_APP_SUPABASE_PROJECT_ID}-auth-token`, JSON.stringify(session));
//             const { hasProfile, profileData } = await checkUserProfile(session.user.id);
//             setHasProfile(hasProfile);

//             if (!hasProfile) {
//                 setIsProfileCreationOpen(true);
//             }
//         }
//         setIsPaywallOpen(false);
//     };

//     const handleProfileComplete = (profileData) => {
//         setUser(prevUser => ({
//             ...prevUser,
//             ...profileData
//         }));
//         setHasProfile(true);
//         setIsProfileCreationOpen(false);
//     };

//     if (loading) {
//         return <div>Loading...</div>;
//     }

//     return (
//         <div className="min-h-screen bg-white relative">
//             {/* Neo-tech background */}
//             <div className="absolute inset-0 bg-grid-pattern opacity-5 pointer-events-none" />

//             <header className="container mx-auto px-6 py-8 relative z-10">
//                 <nav className="flex items-center justify-between">
//                     <div className="text-3xl font-bold bg-clip-text text-transparent bg-gradient-to-r from-blue-500 to-purple-500">
//                         RoboApply.ai
//                     </div>
//                     <div className="hidden md:flex items-center space-x-6">
//                         {user && hasProfile && (
//                             <>
//                                 <UserProfileDisplay user={user} credits={credits} />
//                                 <Button onClick={() => navigate('/apply')} className="bg-gradient-to-r from-blue-500 to-purple-500 text-white">
//                                     Go to Apply
//                                 </Button>
//                             </>
//                         )}
//                         {!user && (
//                             <Button onClick={() => setIsPaywallOpen(true)} className="bg-gradient-to-r from-blue-500 to-purple-500 text-white">
//                                 Sign In
//                             </Button>
//                         )}
//                     </div>
//                     <Button className="md:hidden text-gray-800" onClick={() => setIsMenuOpen(!isMenuOpen)}>
//                         {isMenuOpen ? <X /> : <Menu />}
//                     </Button>
//                 </nav>

//                 {isMenuOpen && (
//                     <div className="md:hidden mt-6 flex flex-col space-y-4">
//                         {user && hasProfile && (
//                             <>
//                                 <UserProfileDisplay user={user} credits={credits} />
//                                 <Button onClick={() => navigate('/apply')} className="bg-gradient-to-r from-blue-500 to-purple-500 text-white">
//                                     Go to Apply
//                                 </Button>
//                             </>
//                         )}
//                         {!user && (
//                             <Button onClick={() => setIsPaywallOpen(true)} className="bg-gradient-to-r from-blue-500 to-purple-500 text-white">
//                                 Sign In
//                             </Button>
//                         )}
//                     </div>
//                 )}
//             </header>

// <main className="container mx-auto px-6 relative z-10">
//     <section className="py-20 text-center">
//         <h1 className="text-6xl font-bold bg-clip-text text-transparent bg-gradient-to-r from-blue-500 to-purple-500 mb-6">
//             Automate Your Job Search
//         </h1>
//         <p className="text-2xl text-gray-600 mb-10 max-w-2xl mx-auto">
//             Apply to thousands of jobs with a single click using AI-powered technology.
//         </p>
//         {user && hasProfile ? (
//             <Button
//                 onClick={() => navigate('/apply')}
//                 className="bg-gradient-to-r from-blue-500 to-purple-500 text-white hover:from-purple-500 hover:to-blue-500 shadow-lg hover:shadow-blue-500/50 transform hover:scale-105 transition-all duration-300"
//             >
//                 Start Applying
//             </Button>
//         ) : (
//             <Button
//                 onClick={() => setIsPaywallOpen(true)}
//                 className="bg-gradient-to-r from-blue-500 to-purple-500 text-white hover:from-purple-500 hover:to-blue-500 shadow-lg hover:shadow-blue-500/50 transform hover:scale-105 transition-all duration-300"
//             >
//                 Get Started
//             </Button>
//         )}
//     </section>
//     <section className="py-20">
//         <h2 className="text-4xl font-bold text-center text-gray-800 mb-12">Features</h2>
//         <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
//             <div className="bg-white p-6 rounded-xl shadow-lg hover:shadow-xl transition-shadow duration-300">
//                 <div className="text-blue-500 mb-4">
//                     <svg className="w-12 h-12" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
//                         <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 5H7a2 2 0 00-2 2v12a2 2 0 002 2h10a2 2 0 002-2V7a2 2 0 00-2-2h-2M9 5a2 2 0 002 2h2a2 2 0 002-2M9 5a2 2 0 012-2h2a2 2 0 012 2m-3 7h3m-3 4h3m-6-4h.01M9 16h.01" />
//                     </svg>
//                 </div>
//                 <h3 className="text-xl font-semibold text-gray-800 mb-2">One-Click Apply</h3>
//                 <p className="text-gray-600">Apply to hundreds of jobs instantly with a single click.</p>
//             </div>
//             <div className="bg-white p-6 rounded-xl shadow-lg hover:shadow-xl transition-shadow duration-300">
//                 <div className="text-purple-500 mb-4">
//                     <svg className="w-12 h-12" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
//                         <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 19v-6a2 2 0 00-2-2H5a2 2 0 00-2 2v6a2 2 0 002 2h2a2 2 0 002-2zm0 0V9a2 2 0 012-2h2a2 2 0 012 2v10m-6 0a2 2 0 002 2h2a2 2 0 002-2m0 0V5a2 2 0 012-2h2a2 2 0 012 2v14a2 2 0 01-2 2h-2a2 2 0 01-2-2z" />
//                     </svg>
//                 </div>
//                 <h3 className="text-xl font-semibold text-gray-800 mb-2">Application Tracking</h3>
//                 <p className="text-gray-600">Monitor your applications and success rates in real-time.</p>
//             </div>
//             <div className="bg-white p-6 rounded-xl shadow-lg hover:shadow-xl transition-shadow duration-300">
//                 <div className="text-blue-500 mb-4">
//                     <svg className="w-12 h-12" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
//                         <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M13 10V3L4 14h7v7l9-11h-7z" />
//                     </svg>
//                 </div>
//                 <h3 className="text-xl font-semibold text-gray-800 mb-2">AI-Powered Matching</h3>
//                 <p className="text-gray-600">Get matched with jobs that fit your skills and experience.</p>
//             </div>
//         </div>
//     </section>
// </main>

//             <footer className="bg-gray-100 py-10 text-center text-gray-600 relative z-10">
//                 <p>&copy; 2023 RoboApply.ai. All rights reserved.</p>
//             </footer>

//             <Modal isOpen={isPaywallOpen} onClose={() => setIsPaywallOpen(false)}>
//                 <PaywallModal onLogin={handleLogin} />
//             </Modal>

//             <Modal isOpen={isProfileCreationOpen} onClose={() => setIsProfileCreationOpen(false)}>
//                 <ProfileCreation
//                     user={user}
//                     onComplete={handleProfileComplete}
//                 />
//             </Modal>
//         </div>
//     );
// }

// import React, { useState, useEffect } from 'react';
// import { useNavigate } from 'react-router-dom';
// import { Menu, X, Mail, Lock, Check, User, Camera, CreditCard } from "lucide-react";
// import supabase from '../supabaseConfig';
// import { loadStripe } from '@stripe/stripe-js';

// // Stripe setup
// const stripePromise = loadStripe('your_stripe_publishable_key');

// // Button Component
// const Button = ({ className, children, ...props }) => (
//     <button className={`px-6 py-3 rounded-full font-semibold transition-all duration-300 ${className}`} {...props}>
//         {children}
//     </button>
// );

// // Input Component
// const Input = ({ icon: Icon, ...props }) => (
//     <div className="relative">
//         <Icon className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400" size={20} />
//         <input
//             className="w-full py-2 pl-10 pr-3 bg-gray-100 border border-gray-300 rounded-md text-gray-800 placeholder-gray-400"
//             {...props}
//         />
//     </div>
// );

// // Modal Component
// const Modal = ({ isOpen, onClose, children }) => {
//     if (!isOpen) return null;

//     return (
//         <div className="fixed inset-0 bg-black bg-opacity-50 z-50 flex items-center justify-center">
//             <div className="bg-white p-8 rounded-xl shadow-2xl max-w-md w-full">
//                 {children}
//                 <Button onClick={onClose} className="mt-4 w-full bg-gray-200 text-gray-800">
//                     Close
//                 </Button>
//             </div>
//         </div>
//     );
// };

// // Profile Creation Component
// const ProfileCreation = ({ user, onComplete }) => {
//     const [username, setUsername] = useState('');
//     const [jobTitle, setJobTitle] = useState('');
//     const [avatar, setAvatar] = useState(null);
//     const [error, setError] = useState(null);

//     const handleSubmit = async (e) => {
//         e.preventDefault();
//         setError(null);

//         try {
//             let avatarUrl = user.user_metadata.avatar_url;

//             if (avatar) {
//                 const fileExt = avatar.name.split('.').pop();
//                 const fileName = `${Math.random()}.${fileExt}`;
//                 const { data, error: uploadError } = await supabase.storage
//                     .from('avatars')
//                     .upload(`${user.id}/${fileName}`, avatar);

//                 if (uploadError) throw uploadError;

//                 const { data: { publicUrl } } = supabase.storage
//                     .from('avatars')
//                     .getPublicUrl(data.path);

//                 avatarUrl = publicUrl;
//             }

//             const { data, error } = await supabase
//                 .from('profiles')
//                 .upsert({ id: user.id, username, job_title: jobTitle, avatar_url: avatarUrl, has_profile: true });

//             if (error) throw error;
//             onComplete({ username, job_title: jobTitle, avatar_url: avatarUrl });
//         } catch (error) {
//             setError(error.message);
//         }
//     };

//     return (
//         <form onSubmit={handleSubmit} className="space-y-4">
//             <h2 className="text-2xl font-bold text-gray-800 mb-4">Complete Your Profile</h2>
//             <Input
//                 icon={User}
//                 type="text"
//                 value={username}
//                 onChange={(e) => setUsername(e.target.value)}
//                 placeholder="Choose a username"
//                 minLength={3}
//                 required
//             />
//             <Input
//                 icon={CreditCard}
//                 type="text"
//                 value={jobTitle}
//                 onChange={(e) => setJobTitle(e.target.value)}
//                 placeholder="Your job title"
//                 required
//             />
//             <div className="flex items-center space-x-4">
//                 <div className="w-20 h-20 rounded-full bg-gray-200 flex items-center justify-center overflow-hidden">
//                     {avatar ? (
//                         <img src={URL.createObjectURL(avatar)} alt="Avatar preview" className="w-full h-full object-cover" />
//                     ) : (
//                         <Camera size={32} className="text-gray-400" />
//                     )}
//                 </div>
//                 <input
//                     type="file"
//                     accept="image/*"
//                     onChange={(e) => setAvatar(e.target.files[0])}
//                     className="hidden"
//                     id="avatar-upload"
//                 />
//                 <label htmlFor="avatar-upload" className="cursor-pointer bg-blue-500 text-white px-4 py-2 rounded-full hover:bg-blue-600 transition-colors">
//                     Upload Avatar
//                 </label>
//             </div>
//             <Button type="submit" className="w-full bg-gradient-to-r from-blue-500 to-purple-500 text-white">
//                 Complete Profile
//             </Button>
//             {error && <p className="text-red-500">{error}</p>}
//         </form>
//     );
// };

// // Paywall Modal Component
// const PaywallModal = ({ onLogin }) => {
//     const [email, setEmail] = useState('');
//     const [password, setPassword] = useState('');
//     const [error, setError] = useState(null);

//     const handleSubmit = async (e) => {
//         e.preventDefault();
//         setError(null);

//         try {
//             const { data, error } = await supabase.auth.signInWithPassword({ email, password });
//             if (error) throw error;
//             onLogin(data);
//         } catch (error) {
//             setError(error.message);
//         }
//     };

//     const handleGoogleLogin = async () => {
//         try {
//             const { data, error } = await supabase.auth.signInWithOAuth({ provider: 'google' });
//             if (error) throw error;
//             onLogin(data);
//         } catch (error) {
//             setError(error.message);
//         }
//     };

//     return (
//         <div className="space-y-4">
//             <h2 className="text-2xl font-bold text-gray-800 mb-4">Sign In or Create Account</h2>
//             <form onSubmit={handleSubmit} className="space-y-4">
//                 <Input
//                     icon={Mail}
//                     type="email"
//                     placeholder="Email"
//                     value={email}
//                     onChange={(e) => setEmail(e.target.value)}
//                     required
//                 />
//                 <Input
//                     icon={Lock}
//                     type="password"
//                     placeholder="Password"
//                     value={password}
//                     onChange={(e) => setPassword(e.target.value)}
//                     required
//                 />
//                 <Button type="submit" className="w-full bg-gradient-to-r from-blue-500 to-purple-500 text-white">
//                     Sign In / Sign Up
//                 </Button>
//             </form>
//             <Button onClick={handleGoogleLogin} className="w-full bg-white text-gray-800 border border-gray-300 flex items-center justify-center">
//                 <img src="https://www.gstatic.com/firebasejs/ui/2.0.0/images/auth/google.svg" alt="Google" className="w-6 h-6 mr-2" />
//                 Sign in with Google
//             </Button>
//             {error && <p className="text-red-500">{error}</p>}
//         </div>
//     );
// };

// // User Profile Display Component
// const UserProfileDisplay = ({ user, credits }) => (
//     <div className="flex items-center space-x-4">
//         <img src={user.avatar_url} alt={user.username} className="w-10 h-10 rounded-full" />
//         <div>
//             <p className="text-gray-800 font-bold">{user.username}</p>
//             <p className="text-gray-600 text-sm">{user.job_title}</p>
//         </div>
//         <span className="text-gray-800">Credits: {credits}</span>
//     </div>
// );

// // Main Component
// export default function JobApplyAILandingPage() {
//     const [isMenuOpen, setIsMenuOpen] = useState(false);
//     const [isPaywallOpen, setIsPaywallOpen] = useState(false);
//     const [isProfileCreationOpen, setIsProfileCreationOpen] = useState(false);
//     const [user, setUser] = useState(null);
//     const [hasProfile, setHasProfile] = useState(false);
//     const [credits, setCredits] = useState(0);
//     const [loading, setLoading] = useState(true);
//     const navigate = useNavigate();

//     const checkUserProfile = async (userId) => {
//         const { data, error } = await supabase
//             .from('profiles')
//             .select('*')
//             .eq('id', userId)
//             .single();

//         if (error) {
//             console.error('Error fetching profile:', error);
//             return { hasProfile: false, profileData: null };
//         }

//         setCredits(data?.credits || 0);
//         return { hasProfile: data?.has_profile || false, profileData: data };
//     };

//     useEffect(() => {
//         const initializeApp = async () => {
//             const storedSession = JSON.parse(localStorage.getItem(`sb-${process.env.REACT_APP_SUPABASE_PROJECT_ID}-auth-token`));
//             if (storedSession && storedSession.user) {
//                 setUser(storedSession.user);
//                 const { hasProfile, profileData } = await checkUserProfile(storedSession.user.id);
//                 setHasProfile(hasProfile);
//                 if (!hasProfile) {
//                     setIsProfileCreationOpen(true);
//                 }
//             }

//             const { data: authListener } = supabase.auth.onAuthStateChange(async (event, session) => {
//                 if (event === 'SIGNED_IN' && session) {
//                     setUser(session.user);
//                     localStorage.setItem(`sb-${process.env.REACT_APP_SUPABASE_PROJECT_ID}-auth-token`, JSON.stringify(session));
//                     const { hasProfile, profileData } = await checkUserProfile(session.user.id);
//                     setHasProfile(hasProfile);
//                     if (!hasProfile) {
//                         setIsProfileCreationOpen(true);
//                     }
//                 } else if (event === 'SIGNED_OUT') {
//                     setUser(null);
//                     setHasProfile(false);
//                     setCredits(0);
//                     localStorage.removeItem(`sb-${process.env.REACT_APP_SUPABASE_PROJECT_ID}-auth-token`);
//                 }
//             });

//             setLoading(false);

//             return () => {
//                 authListener.subscription.unsubscribe();
//             };
//         };

//         initializeApp();
//     }, []);

//     const handleLogin = async (session) => {
//         if (session) {
//             setUser(session.user);
//             localStorage.setItem(`sb-${process.env.REACT_APP_SUPABASE_PROJECT_ID}-auth-token`, JSON.stringify(session));
//             const { hasProfile, profileData } = await checkUserProfile(session.user.id);
//             setHasProfile(hasProfile);

//             if (!hasProfile) {
//                 setIsProfileCreationOpen(true);
//             }
//         }
//         setIsPaywallOpen(false);
//     };

//     const handleProfileComplete = (profileData) => {
//         setUser(prevUser => ({
//             ...prevUser,
//             ...profileData
//         }));
//         setHasProfile(true);
//         setIsProfileCreationOpen(false);
//     };

//     if (loading) {
//         return <div>Loading...</div>;
//     }

//     return (
//         <div className="min-h-screen bg-white relative">
//             {/* Neo-tech background */}
//             <div className="absolute inset-0 bg-grid-pattern opacity-5 pointer-events-none" />

//             <header className="container mx-auto px-6 py-8 relative z-10">
//                 <nav className="flex items-center justify-between">
//                     <div className="text-3xl font-bold bg-clip-text text-transparent bg-gradient-to-r from-blue-500 to-purple-500">
//                         RoboApply.ai
//                     </div>
//                     <div className="hidden md:flex items-center space-x-6">
//                         {user && hasProfile && (
//                             <>
//                                 <UserProfileDisplay user={user} credits={credits} />
//                                 <Button onClick={() => navigate('/apply')} className="bg-gradient-to-r from-blue-500 to-purple-500 text-white">
//                                     Go to Apply
//                                 </Button>
//                             </>
//                         )}
//                         {!user && (
//                             <Button onClick={() => setIsPaywallOpen(true)} className="bg-gradient-to-r from-blue-500 to-purple-500 text-white">
//                                 Sign In
//                             </Button>
//                         )}
//                     </div>
//                     <Button className="md:hidden text-gray-800" onClick={() => setIsMenuOpen(!isMenuOpen)}>
//                         {isMenuOpen ? <X /> : <Menu />}
//                     </Button>
//                 </nav>

//                 {isMenuOpen && (
//                     <div className="md:hidden mt-6 flex flex-col space-y-4">
//                         {user && hasProfile && (
//                             <>
//                                 <UserProfileDisplay user={user} credits={credits} />
//                                 <Button onClick={() => navigate('/apply')} className="bg-gradient-to-r from-blue-500 to-purple-500 text-white">
//                                     Go to Apply
//                                 </Button>
//                             </>
//                         )}
//                         {!user && (
//                             <Button onClick={() => setIsPaywallOpen(true)} className="bg-gradient-to-r from-blue-500 to-purple-500 text-white">
//                                 Sign In
//                             </Button>
//                         )}
//                     </div>
//                 )}
//             </header>

//             <main className="container mx-auto px-6 relative z-10">
//                 <section className="py-20 text-center">
//                     <h1 className="text-6xl font-bold bg-clip-text text-transparent bg-gradient-to-r from-blue-500 to-purple-500 mb-6">
//                         Automate Your Job Search
//                     </h1>
//                     <p className="text-2xl text-gray-600 mb-10 max-w-2xl mx-auto">
//                         Apply to thousands of jobs with a single click using AI-powered technology.
//                     </p>
//                     {user && hasProfile ? (
//                         <Button
//                             onClick={() => navigate('/apply')}
//                             className="bg-gradient-to-r from-blue-500 to-purple-500 text-white hover:from-purple-500 hover:to-blue-500 shadow-lg hover:shadow-blue-500/50 transform hover:scale-105 transition-all duration-300"
//                         >
//                             Start Applying
//                         </Button>
//                     ) : (
//                         <Button
//                             onClick={() => setIsPaywallOpen(true)}
//                             className="bg-gradient-to-r from-blue-500 to-purple-500 text-white hover:from-purple-500 hover:to-blue-500 shadow-lg hover:shadow-blue-500/50 transform hover:scale-105 transition-all duration-300"
//                         >
//                             Get Started
//                         </Button>
//                     )}
//                 </section>

//                 <section className="py-20">
//                     <h2 className="text-4xl font-bold text-center text-gray-800 mb-12">Features</h2>
//                     <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
//                         <div className="bg-white p-6 rounded-xl shadow-lg hover:shadow-xl transition-shadow duration-300">
//                             <div className="text-blue-500 mb-4">
//                                 <svg className="w-12 h-12" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
//                                     <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 5H7a2 2 0 00-2 2v12a2 2 0 002 2h10a2 2 0 002-2V7a2 2 0 00-2-2h-2M9 5a2 2 0 002 2h2a2 2 0 002-2M9 5a2 2 0 012-2h2a2 2 0 012 2m-3 7h3m-3 4h3m-6-4h.01M9 16h.01" />
//                                 </svg>
//                             </div>
//                             <h3 className="text-xl font-semibold text-gray-800 mb-2">One-Click Apply</h3>
//                             <p className="text-gray-600">Apply to hundreds of jobs instantly with a single click.</p>
//                         </div>
//                         <div className="bg-white p-6 rounded-xl shadow-lg hover:shadow-xl transition-shadow duration-300">
//                             <div className="text-purple-500 mb-4">
//                                 <svg className="w-12 h-12" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
//                                     <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 19v-6a2 2 0 00-2-2H5a2 2 0 00-2 2v6a2 2 0 002 2h2a2 2 0 002-2zm0 0V9a2 2 0 012-2h2a2 2 0 012 2v10m-6 0a2 2 0 002 2h2a2 2 0 002-2m0 0V5a2 2 0 012-2h2a2 2 0 012 2v14a2 2 0 01-2 2h-2a2 2 0 01-2-2z" />
//                                 </svg>
//                             </div>
//                             <h3 className="text-xl font-semibold text-gray-800 mb-2">Application Tracking</h3>
//                             <p className="text-gray-600">Monitor your applications and success rates in real-time.</p>
//                         </div>
//                         <div className="bg-white p-6 rounded-xl shadow-lg hover:shadow-xl transition-shadow duration-300">
//                             <div className="text-blue-500 mb-4">
//                                 <svg className="w-12 h-12" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
//                                     <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M13 10V3L4 14h7v7l9-11h-7z" />
//                                 </svg>
//                             </div>
//                             <h3 className="text-xl font-semibold text-gray-800 mb-2">AI-Powered Matching</h3>
//                             <p className="text-gray-600">Get matched with jobs that fit your skills and experience.</p>
//                         </div>
//                     </div>
//                 </section>
//             </main>

//             <footer className="bg-gray-100 py-10 text-center text-gray-600 relative z-10">
//                 <p>&copy; 2023 RoboApply.ai. All rights reserved.</p>
//             </footer>

//             <Modal isOpen={isPaywallOpen} onClose={() => setIsPaywallOpen(false)}>
//                 <PaywallModal onLogin={handleLogin} />
//             </Modal>

//             <Modal isOpen={isProfileCreationOpen} onClose={() => setIsProfileCreationOpen(false)}>
//                 <ProfileCreation
//                     user={user}
//                     onComplete={handleProfileComplete}
//                 />
//             </Modal>
//         </div>
//     );
// }



// import React, { useState, useEffect } from 'react';
// import { useNavigate } from 'react-router-dom';
// import { FileText, Briefcase, PieChart, Menu, X, Check, Mail, Lock, User, Camera, CreditCard } from "lucide-react";
// import supabase from '../supabaseConfig';
// import { loadStripe } from '@stripe/stripe-js';
// import { Elements, CardElement, useStripe, useElements } from '@stripe/react-stripe-js';

// const stripePromise = loadStripe('your_stripe_publishable_key');

// // Button Component
// const Button = ({ className, children, ...props }) => (
//     <button className={`px-6 py-3 rounded-full font-semibold transition-all duration-300 ${className}`} {...props}>
//         {children}
//     </button>
// );

// // Input Component
// const Input = ({ icon: Icon, ...props }) => (
//     <div className="relative">
//         <Icon className="absolute left-3 top-1/2 transform -translate-y-1/2 text-pink-300" size={20} />
//         <input
//             className="w-full py-2 pl-10 pr-3 bg-purple-900 bg-opacity-50 border border-pink-300 rounded-md text-white placeholder-pink-200"
//             {...props}
//         />
//     </div>
// );

// // Modal Component
// const Modal = ({ isOpen, onClose, children }) => {
//     if (!isOpen) return null;

//     return (
//         <div className="fixed inset-0 bg-black bg-opacity-50 z-50 flex items-center justify-center">
//             <div className="bg-gradient-to-br from-purple-800 to-pink-700 p-8 rounded-xl shadow-2xl max-w-md w-full">
//                 {children}
//                 <Button onClick={onClose} className="mt-4 w-full bg-transparent border border-pink-300 text-pink-300">
//                     Close
//                 </Button>
//             </div>
//         </div>
//     );
// };

// // Profile Creation Component
// const ProfileCreation = ({ user, onComplete }) => {
//     const [username, setUsername] = useState('');
//     const [jobTitle, setJobTitle] = useState('');
//     const [avatar, setAvatar] = useState(null);
//     const [error, setError] = useState(null);

//     const handleSubmit = async (e) => {
//         e.preventDefault();
//         setError(null);

//         try {
//             let avatarUrl = user.user_metadata.avatar_url;

//             if (avatar) {
//                 const fileExt = avatar.name.split('.').pop();
//                 const fileName = `${Math.random()}.${fileExt}`;
//                 const { data, error: uploadError } = await supabase.storage
//                     .from('avatars')
//                     .upload(`${user.id}/${fileName}`, avatar);

//                 if (uploadError) throw uploadError;

//                 const { data: { publicUrl } } = supabase.storage
//                     .from('avatars')
//                     .getPublicUrl(data.path);

//                 avatarUrl = publicUrl;
//             }

//             const { data, error } = await supabase
//                 .from('profiles')
//                 .update({ username, job_title: jobTitle, avatar_url: avatarUrl, has_profile: true })
//                 .eq('id', user.id);

//             if (error) throw error;
//             onComplete({ username, job_title: jobTitle, avatar_url: avatarUrl });
//         } catch (error) {
//             setError(error.message);
//         }
//     };

//     return (
//         <form onSubmit={handleSubmit} className="space-y-4">
//             <h2 className="text-2xl font-bold text-white mb-4">Complete Your Profile</h2>
//             <Input
//                 icon={User}
//                 type="text"
//                 value={username}
//                 onChange={(e) => setUsername(e.target.value)}
//                 placeholder="Choose a username"
//                 minLength={3}
//                 required
//             />
//             <Input
//                 icon={Briefcase}
//                 type="text"
//                 value={jobTitle}
//                 onChange={(e) => setJobTitle(e.target.value)}
//                 placeholder="Your job title"
//                 required
//             />
//             <div className="flex items-center space-x-4">
//                 <div className="w-20 h-20 rounded-full bg-purple-600 flex items-center justify-center overflow-hidden">
//                     {avatar ? (
//                         <img src={URL.createObjectURL(avatar)} alt="Avatar preview" className="w-full h-full object-cover" />
//                     ) : (
//                         <Camera size={32} className="text-white" />
//                     )}
//                 </div>
//                 <input
//                     type="file"
//                     accept="image/*"
//                     onChange={(e) => setAvatar(e.target.files[0])}
//                     className="hidden"
//                     id="avatar-upload"
//                 />
//                 <label htmlFor="avatar-upload" className="cursor-pointer bg-pink-500 text-white px-4 py-2 rounded-full hover:bg-pink-600 transition-colors">
//                     Upload Avatar
//                 </label>
//             </div>
//             <Button type="submit" className="w-full bg-gradient-to-r from-purple-500 to-pink-500 text-white">
//                 Complete Profile
//             </Button>
//             {error && <p className="text-red-400">{error}</p>}
//         </form>
//     );
// };

// // Credit Purchase Component
// const CreditPurchase = ({ user, onSuccess }) => {
//     const stripe = useStripe();
//     const elements = useElements();
//     const [loading, setLoading] = useState(false);
//     const [error, setError] = useState(null);

//     const handleSubmit = async (event) => {
//         event.preventDefault();
//         setLoading(true);
//         setError(null);

//         if (!stripe || !elements) {
//             setError("Stripe hasn't loaded yet. Please try again.");
//             setLoading(false);
//             return;
//         }

//         const { error: stripeError, paymentMethod } = await stripe.createPaymentMethod({
//             type: 'card',
//             card: elements.getElement(CardElement),
//         });

//         if (stripeError) {
//             setError(stripeError.message);
//             setLoading(false);
//             return;
//         }

//         // Send payment info to your server
//         const response = await fetch('/api/create-payment-intent', {
//             method: 'POST',
//             headers: { 'Content-Type': 'application/json' },
//             body: JSON.stringify({
//                 amount: 1000, // $10 for 10 credits
//                 payment_method_id: paymentMethod.id,
//                 user_id: user.id
//             }),
//         });

//         const { clientSecret, error: serverError } = await response.json();

//         if (serverError) {
//             setError(serverError.message);
//             setLoading(false);
//             return;
//         }

//         const { error: confirmError } = await stripe.confirmCardPayment(clientSecret);

//         if (confirmError) {
//             setError(confirmError.message);
//         } else {
//             // Payment successful, add credits to user's account
//             const { data, error: creditsError } = await supabase.rpc('add_credits', {
//                 user_id: user.id,
//                 amount: 10
//             });

//             if (creditsError) {
//                 setError("Payment successful, but failed to add credits. Please contact support.");
//             } else {
//                 onSuccess(data);
//             }
//         }

//         setLoading(false);
//     };

//     return (
//         <form onSubmit={handleSubmit} className="space-y-4">
//             <h2 className="text-2xl font-bold text-white mb-4">Purchase Credits</h2>
//             <CardElement className="bg-purple-900 bg-opacity-50 p-3 rounded-md" />
//             <Button type="submit" disabled={!stripe || loading} className="w-full bg-gradient-to-r from-purple-500 to-pink-500 text-white">
//                 Purchase 10 Credits for $10
//             </Button>
//             {error && <p className="text-red-400">{error}</p>}
//         </form>
//     );
// };

// // Paywall Modal Component
// const PaywallModal = ({ onLogin }) => {
//     const [email, setEmail] = useState('');
//     const [password, setPassword] = useState('');
//     const [error, setError] = useState(null);

//     const handleSubmit = async (e) => {
//         e.preventDefault();
//         setError(null);

//         try {
//             const { data, error } = await supabase.auth.signInWithPassword({ email, password });
//             if (error) throw error;
//             onLogin(data);
//         } catch (error) {
//             setError(error.message);
//         }
//     };

//     const handleGoogleLogin = async () => {
//         try {
//             const { data, error } = await supabase.auth.signInWithOAuth({ provider: 'google' });
//             if (error) throw error;
//             onLogin(data);
//         } catch (error) {
//             setError(error.message);
//         }
//     };

//     return (
//         <div className="space-y-4">
//             <h2 className="text-2xl font-bold text-white mb-4">Sign In or Create Account</h2>
//             <form onSubmit={handleSubmit} className="space-y-4">
//                 <Input
//                     icon={Mail}
//                     type="email"
//                     placeholder="Email"
//                     value={email}
//                     onChange={(e) => setEmail(e.target.value)}
//                     required
//                 />
//                 <Input
//                     icon={Lock}
//                     type="password"
//                     placeholder="Password"
//                     value={password}
//                     onChange={(e) => setPassword(e.target.value)}
//                     required
//                 />
//                 <Button type="submit" className="w-full bg-gradient-to-r from-purple-500 to-pink-500 text-white">
//                     Sign In / Sign Up
//                 </Button>
//             </form>
//             <Button onClick={handleGoogleLogin} className="w-full bg-white text-gray-800 flex items-center justify-center">
//                 <img src="https://www.gstatic.com/firebasejs/ui/2.0.0/images/auth/google.svg" alt="Google" className="w-6 h-6 mr-2" />
//                 Sign in with Google
//             </Button>
//             {error && <p className="text-red-400">{error}</p>}
//         </div>
//     );
// };

// // Feature Component
// const Feature = ({ icon: Icon, title, description }) => (
//     <div className="bg-gradient-to-br from-purple-800 to-pink-700 p-6 rounded-xl shadow-xl">
//         <Icon className="w-12 h-12 text-pink-400 mb-4" />
//         <h3 className="text-xl font-bold text-white mb-2">{title}</h3>
//         <p className="text-pink-200">{description}</p>
//     </div>
// );

// // Pricing Plan Component
// const PricingPlan = ({ title, price, features, isPopular }) => (
//     <div className={`bg-gradient-to-br from-purple-800 to-pink-700 p-6 rounded-xl shadow-xl ${isPopular ? 'border-4 border-pink-400' : ''}`}>
//         <h3 className="text-2xl font-bold text-white mb-2">{title}</h3>
//         <p className="text-3xl font-bold text-pink-400 mb-4">{price}</p>
//         <ul className="text-pink-200 mb-6">
//             {features.map((feature, index) => (
//                 <li key={index} className="flex items-center mb-2">
//                     <Check className="w-5 h-5 mr-2 text-pink-400" />
//                     {feature}
//                 </li>
//             ))}
//         </ul>
//         <Button className="w-full bg-gradient-to-r from-purple-500 to-pink-500 text-white">
//             Choose Plan
//         </Button>
//     </div>
// );

// // Animated Background Component
// const AnimatedBackground = () => (
//     <div className="fixed inset-0 z-0">
//         <div className="absolute inset-0 bg-gradient-to-br from-purple-900 via-indigo-900 to-pink-900" />
//         <div className="absolute inset-0 opacity-30">
//             {[...Array(100)].map((_, i) => (
//                 <div
//                     key={i}
//                     className="absolute rounded-full bg-white"
//                     style={{
//                         top: `${Math.random() * 100}%`,
//                         left: `${Math.random() * 100}%`,
//                         width: `${Math.random() * 3}px`,
//                         height: `${Math.random() * 3}px`,
//                         animation: `twinkle ${Math.random() * 5 + 3}s infinite`,
//                     }}
//                 />
//             ))}
//         </div>
//     </div>
// );

// // User Profile Display Component
// const UserProfileDisplay = ({ user, credits }) => (
//     <div className="flex items-center space-x-4">
//         <img src={user.avatar_url} alt={user.username} className="w-10 h-10 rounded-full" />
//         <div>
//             <p className="text-white font-bold">{user.username}</p>
//             <p className="text-pink-300 text-sm">{user.job_title}</p>
//         </div>
//         <span className="text-white">Credits: {credits}</span>
//     </div>
// );

// // Main Component
// export default function JobApplyAILandingPage() {
//     const [isMenuOpen, setIsMenuOpen] = useState(false);
//     const [isPaywallOpen, setIsPaywallOpen] = useState(false);
//     const [isProfileCreationOpen, setIsProfileCreationOpen] = useState(false);
//     const [isCreditPurchaseOpen, setIsCreditPurchaseOpen] = useState(false);
//     const [user, setUser] = useState(null);
//     const [hasProfile, setHasProfile] = useState(false);
//     const [credits, setCredits] = useState(0);
//     const [loading, setLoading] = useState(true);
//     // const navigate = useNavigate();

//     const checkUserProfile = async (userId) => {
//         console.log(`Checking profile for user ID: ${userId}`);
//         const { data, error } = await supabase
//             .from('profiles')
//             .select('*')
//             .eq('id', userId)
//             .single();

//         if (error) {
//             console.error('Error fetching profile:', error);
//             return { hasProfile: false, profileData: null };
//         }

//         console.log('Profile data:', data);
//         setCredits(data?.credits || 0);
//         return { hasProfile: data?.has_profile || false, profileData: data };
//     };

//     useEffect(() => {
//         const initializeApp = async () => {
//             console.log('Initializing app...');

//             const storedSession = JSON.parse(localStorage.getItem(`sb-${process.env.REACT_APP_SUPABASE_PROJECT_ID}-auth-token`));
//             if (storedSession && storedSession.user) {
//                 console.log('Existing session found:', storedSession);
//                 setUser(storedSession.user);
//                 const profileStatus = await checkUserProfile(storedSession.user.id);


//                 console.log(`Profile status for user ${storedSession.user.id}: ${profileStatus}`);
//                 setHasProfile(profileStatus);
//                 if (!profileStatus) {
//                     setIsProfileCreationOpen(true);
//                 }
//             } else {
//                 console.log('No existing session found');
//             }

//             const { data: authListener } = supabase.auth.onAuthStateChange(async (event, session) => {
//                 console.log('Auth state change event:', event);
//                 if (event === 'SIGNED_IN' && session) {
//                     console.log('User signed in:', session.user);
//                     setUser(session.user);
//                     localStorage.setItem(`sb-${process.env.REACT_APP_SUPABASE_PROJECT_ID}-auth-token`, JSON.stringify(session));
//                     const profileStatus = await checkUserProfile(session.user.id);
//                     console.log(`Profile status for user ${session.user.id}: ${profileStatus}`);
//                     setHasProfile(profileStatus);
//                     if (!profileStatus) {
//                         setIsProfileCreationOpen(true);
//                     }
//                 } else if (event === 'SIGNED_OUT') {
//                     console.log('User signed out');
//                     setUser(null);
//                     setHasProfile(false);
//                     setCredits(0);
//                     localStorage.removeItem(`sb-${process.env.REACT_APP_SUPABASE_PROJECT_ID}-auth-token`);
//                 }
//             });

//             setLoading(false);
//             console.log('App initialization complete');

//             return () => {
//                 authListener.subscription.unsubscribe();
//             };
//         };

//         initializeApp();
//     }, []);

//     const handleGetStarted = () => {
//         if (user && hasProfile) {
//             updateStreamlitApp();
//         } else {
//             setIsPaywallOpen(true);
//         }
//     };

//     const handleLogin = async (session) => {
//         if (session) {
//             console.log('Login successful:', session);
//             setUser(session.user);
//             localStorage.setItem(`sb-${process.env.REACT_APP_SUPABASE_PROJECT_ID}-auth-token`, JSON.stringify(session));
//             const profileStatus = await checkUserProfile(session.user.id);
//             console.log(`Profile status after login for user ${session.user.id}: ${profileStatus}`);
//             setHasProfile(profileStatus);

//             if (!profileStatus) {
//                 setIsProfileCreationOpen(true);
//             } else {
//                 updateStreamlitApp();
//             }
//         }
//         setIsPaywallOpen(false);
//     };

//     const handleProfileComplete = (profileData) => {
//         setUser(prevUser => ({
//             ...prevUser,
//             ...profileData
//         }));
//         setHasProfile(true);
//         setIsProfileCreationOpen(false);
//         updateStreamlitApp();
//     };


//     const updateStreamlitApp = () => {
//         console.log("updateStreamlitApp function called");
//         console.log("hello im here!")
//         console.log('Updating Streamlit App...');
//         console.log('Current user:', user);
//         console.log('Current credits:', credits);

//         const params = {
//             email: user?.email,
//             user_id: user?.id,
//             access_token: user?.access_token,
//             credits: credits,
//             username: user?.username,
//             job_title: user?.job_title,
//             avatar_url: user?.avatar_url,
//         };

//         console.log('Raw Streamlit App Parameters:', params);

//         const streamlitAppUrl = new URL('https://dice-apply.streamlit.app/?embedded=true');

//         Object.entries(params).forEach(([key, value]) => {
//             if (value !== undefined && value !== null) {
//                 console.log(`Appending parameter: ${key} = ${value}`);
//                 streamlitAppUrl.searchParams.append(key, encodeURIComponent(value));
//             } else {
//                 console.log(`Skipping parameter ${key} because it's undefined or null`);
//             }
//         });

//         console.log('Final Streamlit App URL:', streamlitAppUrl.toString());

//         const iframe = document.querySelector('.streamlit-container iframe');
//         if (iframe) {
//             console.log('Updating iframe src...');
//             iframe.src = streamlitAppUrl.toString();
//             console.log('Iframe src updated successfully');
//         } else {
//             console.error('Iframe not found. Make sure the iframe is rendered before calling this function.');
//         }
//     };





//     const handleCreditPurchase = (newCredits) => {
//         setCredits(newCredits);
//         setIsCreditPurchaseOpen(false);
//         updateStreamlitApp();
//     };

//     if (loading) {
//         return <div>Loading...</div>;
//     }

//     return (

//         <div className="min-h-screen relative overflow-hidden font-sans">
//             <AnimatedBackground />
//             <style jsx global>{`
//             @import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;600;700&display=swap');
//             body {
//                 font-family: 'Inter', sans-serif;
//             }
//             @keyframes twinkle {
//                 0%, 100% { opacity: 0.2; }
//                 50% { opacity: 1; }
//             }
//             @keyframes float {
//                 0%, 100% { transform: translateY(0); }
//                 50% { transform: translateY(-20px); }
//             }
//             .streamlit-container {
//                 position: relative;
//                 width: 100%;
//                 height: 0;
//                 padding-bottom: 75%;
//                 overflow: hidden;
//                 box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
//                 border-radius: 8px;
//                 margin-top: 2rem;
//             }
//             .streamlit-container iframe {
//                 position: absolute;
//                 top: 0;
//                 left: 0;
//                 width: 100%;
//                 height: 100%;
//                 border: 0;
//             }
//         `}</style>

//             <div className="relative z-10">
//                 <header className="container mx-auto px-6 py-8">
//                     <nav className="flex items-center justify-between">
//                         <div className="text-3xl font-bold bg-clip-text text-transparent bg-gradient-to-r from-purple-400 to-pink-400">
//                             RoboApply.ai
//                         </div>
//                         <div className="hidden md:flex items-center space-x-6">
//                             {["Features", "Pricing", "Contact"].map((item) => (
//                                 <Button key={item} className="text-white hover:text-pink-300 transition-colors">
//                                     {item}
//                                 </Button>
//                             ))}
//                             {user && hasProfile && (
//                                 <>
//                                     <UserProfileDisplay user={user} credits={credits} />
//                                     <Button onClick={() => setIsCreditPurchaseOpen(true)} className="bg-pink-500 text-white">
//                                         Buy Credits
//                                     </Button>
//                                 </>
//                             )}
//                         </div>
//                         <Button className="md:hidden text-white" onClick={() => setIsMenuOpen(!isMenuOpen)}>
//                             {isMenuOpen ? <X /> : <Menu />}
//                         </Button>
//                     </nav>

//                     {isMenuOpen && (
//                         <div className="md:hidden mt-6 flex flex-col space-y-4">
//                             {["Features", "Pricing", "Contact"].map((item) => (
//                                 <Button key={item} className="text-white hover:text-pink-300 transition-colors">
//                                     {item}
//                                 </Button>
//                             ))}
//                             {user && hasProfile && (
//                                 <>
//                                     <UserProfileDisplay user={user} credits={credits} />
//                                     <Button onClick={() => setIsCreditPurchaseOpen(true)} className="bg-pink-500 text-white">
//                                         Buy Credits
//                                     </Button>
//                                 </>
//                             )}
//                         </div>
//                     )}
//                 </header>

//                 <main className="container mx-auto px-6">
//                     <section className="py-20 text-center">
//                         <h1 className="text-6xl font-bold bg-clip-text text-transparent bg-gradient-to-r from-purple-400 to-pink-400 mb-6">
//                             Automate Your Job Search
//                         </h1>
//                         <p className="text-2xl text-gray-300 mb-10 max-w-2xl mx-auto">
//                             Apply to thousands of jobs with a single click using AI-powered technology.
//                         </p>
//                         {user && hasProfile ? (
//                             <div className="streamlit-container">
//                                 <iframe
//                                     src={`https://dice-apply.streamlit.app/?embedded=true`}

//                                     frameBorder="0"
//                                     allowFullScreen
//                                     title="Dice Apply AI"
//                                 />
//                             </div>
//                         ) : (
//                             <Button
//                                 onClick={handleGetStarted}
//                                 className="bg-gradient-to-r from-purple-500 to-pink-500 text-white hover:from-pink-500 hover:to-purple-500 shadow-lg hover:shadow-pink-500/50 transform hover:scale-105 transition-all duration-300"
//                             >
//                                 Start Applying
//                             </Button>
//                         )}
//                     </section>

//                     <section className="py-20">
//                         <h2 className="text-4xl font-bold text-center text-white mb-12">Features</h2>
//                         <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
//                             <Feature
//                                 icon={Briefcase}
//                                 title="One-Click Apply"
//                                 description="Apply to hundreds of jobs instantly with a single click."
//                             />
//                             <Feature
//                                 icon={PieChart}
//                                 title="Application Tracking"
//                                 description="Monitor your applications and success rates in real-time."
//                             />
//                             <Feature
//                                 icon={FileText}
//                                 title="AI-Powered Matching"
//                                 description="Get matched with jobs that fit your skills and experience."
//                             />
//                         </div>
//                     </section>

//                     <section className="py-20">
//                         <h2 className="text-4xl font-bold text-center text-white mb-12">Pricing</h2>
//                         <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
//                             <PricingPlan
//                                 title="Basic"
//                                 price="$9.99/mo"
//                                 features={[
//                                     "50 job applications/month",
//                                     "Basic job matching",
//                                     "Email support"
//                                 ]}
//                             />
//                             <PricingPlan
//                                 title="Pro"
//                                 price="$19.99/mo"
//                                 features={[
//                                     "Unlimited job applications",
//                                     "Advanced AI job matching",
//                                     "Priority support",
//                                     "Resume optimization"
//                                 ]}
//                                 isPopular={true}
//                             />
//                             <PricingPlan
//                                 title="Enterprise"
//                                 price="Custom"
//                                 features={[
//                                     "Custom integration",
//                                     "Dedicated account manager",
//                                     "Custom AI model training",
//                                     "API access"
//                                 ]}
//                             />
//                         </div>
//                     </section>
//                 </main>

//                 <footer className="bg-purple-900 bg-opacity-50 py-10 text-center text-gray-400">
//                     <p>&copy; 2023 RoboApply.ai. All rights reserved.</p>
//                 </footer>
//             </div>

//             <Modal isOpen={isPaywallOpen} onClose={() => setIsPaywallOpen(false)}>
//                 <PaywallModal onLogin={handleLogin} />
//             </Modal>

//             <Modal isOpen={isProfileCreationOpen} onClose={() => setIsProfileCreationOpen(false)}>
//                 <ProfileCreation
//                     user={user}
//                     onComplete={handleProfileComplete}
//                 />
//             </Modal>

//             <Modal isOpen={isCreditPurchaseOpen} onClose={() => setIsCreditPurchaseOpen(false)}>
//                 <Elements stripe={stripePromise}>
//                     <CreditPurchase user={user} onSuccess={handleCreditPurchase} />
//                 </Elements>
//             </Modal>
//         </div>
//     );
// }
// import React, { useState, useEffect } from 'react';
// import { useNavigate } from 'react-router-dom';
// import supabase from '../supabaseConfig';
// import { Elements } from '@stripe/react-stripe-js';
// import { loadStripe } from '@stripe/stripe-js';
// import Button from './Button';
// import Input from './Input';
// import Modal from './Modal';
// import ApplyPage from './ApplyPage';
// import DashboardPage from './DashboardPage';
// import UserProfileDisplay from './UserProfileDisplay';


// const stripePromise = loadStripe('your_stripe_publishable_key'); // Replace with your key

// conts JobApplyAILandingPage() {
//     const [user, setUser] = useState(null);
//     const [hasProfile, setHasProfile] = useState(false);
//     const [credits, setCredits] = useState(0);
//     const [loading, setLoading] = useState(true);
//     const [isCreditPurchaseOpen, setIsCreditPurchaseOpen] = useState(false);
//     const [isProfileCreationOpen, setIsProfileCreationOpen] = useState(false);

//     const navigate = useNavigate();

//     const checkUserProfile = async (userId) => {
//         const { data, error } = await supabase
//             .from('profiles')
//             .select('*')
//             .eq('id', userId)
//             .single();

//         if (error) {
//             console.error('Error fetching profile:', error);
//             return { hasProfile: false, credits: 0 };
//         }

//         setCredits(data?.credits || 0);
//         return { hasProfile: data?.has_profile || false };
//     };

//     useEffect(() => {
//         const initializeApp = async () => {
//             const storedSession = JSON.parse(
//                 localStorage.getItem(`sb-${process.env.REACT_APP_SUPABASE_PROJECT_ID}-auth-token`)
//             );

//             if (storedSession && storedSession.user) {
//                 setUser(storedSession.user);
//                 const { hasProfile, credits } = await checkUserProfile(storedSession.user.id);
//                 setHasProfile(hasProfile);
//                 setCredits(credits);
//                 if (!hasProfile) setIsProfileCreationOpen(true);
//             }

//             const { data: authListener } = supabase.auth.onAuthStateChange(async (event, session) => {
//                 if (event === 'SIGNED_IN' && session) {
//                     setUser(session.user);
//                     const { hasProfile, credits } = await checkUserProfile(session.user.id);
//                     setHasProfile(hasProfile);
//                     setCredits(credits);
//                     if (!hasProfile) setIsProfileCreationOpen(true);
//                 } else if (event === 'SIGNED_OUT') {
//                     setUser(null);
//                     setHasProfile(false);
//                     setCredits(0);
//                 }
//             });

//             setLoading(false);

//             return () => authListener.subscription.unsubscribe();
//         };

//         initializeApp();
//     }, []);

//     const handleCreditPurchase = (newCredits) => {
//         setCredits((prev) => prev + newCredits);
//         setIsCreditPurchaseOpen(false);
//     };

//     const handleProfileComplete = (profileData) => {
//         setUser((prev) => ({ ...prev, ...profileData }));
//         setHasProfile(true);
//         setIsProfileCreationOpen(false);
//     };

//     if (loading) return <div>Loading...</div>;

//     return (
//         <div className="min-h-screen relative">
//             <header className="container mx-auto px-6 py-8">
//                 <nav className="flex items-center justify-between">
//                     <div className="text-3xl font-bold bg-clip-text text-transparent bg-gradient-to-r from-purple-400 to-pink-400">
//                         RoboApply.ai
//                     </div>
//                     {user && hasProfile && (
//                         <div className="flex items-center space-x-6">
//                             <UserProfileDisplay user={user} credits={credits} />
//                             <Button
//                                 onClick={() => setIsCreditPurchaseOpen(true)}
//                                 className="bg-pink-500 text-white"
//                             >
//                                 Buy Credits
//                             </Button>
//                             <Button
//                                 onClick={() => navigate('/dashboard')}
//                                 className="bg-purple-500 text-white"
//                             >
//                                 Dashboard
//                             </Button>
//                             <Button
//                                 onClick={() => navigate('/apply')}
//                                 className="bg-gradient-to-r from-purple-500 to-pink-500 text-white"
//                             >
//                                 Apply Now
//                             </Button>
//                         </div>
//                     )}
//                 </nav>
//             </header>

//             <main className="container mx-auto px-6">
//                 <section className="text-center py-20">
//                     <h1 className="text-6xl font-bold text-transparent bg-clip-text bg-gradient-to-r from-purple-400 to-pink-400">
//                         Automate Your Job Search
//                     </h1>
//                     <p className="text-gray-300 text-2xl mt-4">
//                         Apply to thousands of jobs with a single click using AI-powered technology.
//                     </p>
//                     {user && hasProfile ? (
//                         <Button
//                             onClick={() => navigate('/apply')}
//                             className="mt-8 bg-gradient-to-r from-purple-500 to-pink-500 text-white"
//                         >
//                             Start Applying
//                         </Button>
//                     ) : (
//                         <Button
//                             onClick={() => setIsProfileCreationOpen(true)}
//                             className="mt-8 bg-gradient-to-r from-pink-500 to-purple-500 text-white"
//                         >
//                             Complete Your Profile
//                         </Button>
//                     )}
//                 </section>
//             </main>

//             {/* Credit Purchase Modal */}
//             <Modal isOpen={isCreditPurchaseOpen} onClose={() => setIsCreditPurchaseOpen(false)}>
//                 <Elements stripe={stripePromise}>
//                     {/* Include your Credit Purchase logic here */}
//                 </Elements>
//             </Modal>

//             {/* Profile Creation Modal */}
//             <Modal isOpen={isProfileCreationOpen} onClose={() => setIsProfileCreationOpen(false)}>
//                 {/* Include your Profile Creation logic here */}
//             </Modal>
//         </div>
//     );
// }
// export default JobApplyAILandingPage;
