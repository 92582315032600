import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Sparkles } from "lucide-react";
import Button from "./Button";
import confetti from 'canvas-confetti';

const SuccessPage = () => {
    const navigate = useNavigate();
    const [credits, setCredits] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    const triggerConfetti = () => {
        confetti({
            particleCount: 100,
            spread: 70,
            origin: { y: 0.6 },
            colors: ['#4CAF50', '#2196F3', '#FFC107']
        });
    };

    useEffect(() => {
        const verifyPayment = async () => {
            try {
                const urlParams = new URLSearchParams(window.location.search);
                const sessionId = urlParams.get("session_id");
                const userId = urlParams.get("user_id");

                if (!sessionId || !userId) {
                    setError("Session ID or User ID is missing from the URL.");
                    setLoading(false);
                    return;
                }
                const response = await fetch(
                    `https://payment-backend-git-main-samthedatamans-projects.vercel.app/verify-session?session_id=${sessionId}&user_id=${userId}`,
                    {
                        method: 'GET',
                        headers: {
                            'Accept': 'application/json',
                            'Content-Type': 'application/json'
                        }
                    }
                );

                const data = await response.json();

                if (!response.ok) {
                    throw new Error(data.detail || 'Payment verification failed');
                }

                setCredits(data.credits);
                setLoading(false);
                triggerConfetti(); // Trigger confetti on success

                // Redirect after 5 seconds
                setTimeout(() => {
                    navigate('/apply');
                }, 5000);

            } catch (err) {
                console.error("Payment verification error:", err);
                setError(err.message);
                setLoading(false);
            }
        };

        verifyPayment();
    }, [navigate]);

    if (loading) {
        return (
            <div className="min-h-screen flex flex-col items-center justify-center bg-gradient-to-r from-green-50 to-blue-50">
                <div className="bg-white p-8 rounded-xl shadow-lg text-center space-y-4">
                    <div className="animate-spin">
                        <Sparkles className="text-blue-400 w-12 h-12" />
                    </div>
                    <p className="text-lg text-gray-600 font-medium">Verifying your payment...</p>
                </div>
            </div>
        );
    }

    if (error) {
        return (
            <div className="min-h-screen flex flex-col items-center justify-center bg-gradient-to-r from-green-50 to-blue-50">
                <div className="bg-white p-10 rounded-xl shadow-lg text-center space-y-6 max-w-md">
                    <Sparkles className="text-red-400 w-16 h-16 mx-auto mb-4" />
                    <h1 className="text-3xl font-bold text-red-600">Error</h1>
                    <p className="text-xl text-gray-600">{error}</p>
                    <div className="space-y-4">
                        <Button
                            onClick={() => navigate("/apply")}
                            className="w-full bg-red-500 text-white text-lg py-3 rounded-lg hover:bg-red-600 transition-colors"
                        >
                            Back to Dashboard
                        </Button>
                        <Button
                            onClick={() => window.location.reload()}
                            className="w-full bg-gray-500 text-white text-lg py-3 rounded-lg hover:bg-gray-600 transition-colors"
                        >
                            Try Again
                        </Button>
                    </div>
                </div>
            </div>
        );
    }

    return (
        <div className="min-h-screen flex flex-col items-center justify-center bg-gradient-to-r from-green-50 to-blue-50">
            <div className="bg-white p-10 rounded-xl shadow-lg text-center space-y-6 max-w-md">
                <Sparkles className="text-green-400 w-16 h-16 mx-auto mb-4" />
                <h1 className="text-4xl font-bold text-blue-600">
                    Payment Successful! 🎉
                </h1>
                <div className="space-y-4">
                    <p className="text-xl text-gray-600">
                        Congratulations! Your payment has been processed successfully.
                    </p>
                    <div className="bg-green-50 p-4 rounded-lg">
                        <p className="text-2xl font-bold text-green-600">
                            +{credits} Credits Added
                        </p>
                    </div>
                    <p className="text-lg text-gray-600">
                        Redirecting to dashboard in 5 seconds...
                    </p>
                </div>
                <Button
                    onClick={() => navigate("/apply")}
                    className="w-full bg-gradient-to-r from-green-400 to-blue-500 text-white text-lg py-3 rounded-lg hover:from-green-500 hover:to-blue-600 transition-all transform hover:scale-105"
                >
                    Go to Dashboard Now
                </Button>
            </div>
        </div>
    );
};

export default SuccessPage;