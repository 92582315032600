// ResumeBuilder.js
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import supabase from '../supabaseConfig';
import { ChevronLeft, ChevronRight, Check } from 'lucide-react';

// Import Steps
import UploadSection from './UploadSection';
import EnhanceSection from './EnhanceSection';
import ThemeSection from './ThemeSection';
import PreviewSection from './PreviewSection';
import LoginModal2 from './LoginModal2';

export default function ResumeBuilder() {
    const navigate = useNavigate();
    const [currentStep, setCurrentStep] = useState(0);
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const [showLoginModal, setShowLoginModal] = useState(false);

    // Core resume data state
    const [basics, setBasics] = useState({ name: "", label: "", email: "", phone: "", summary: "", location: {} });
    const [work, setWork] = useState([]);
    const [education, setEducation] = useState([]);
    const [resumeContent, setResumeContent] = useState('');
    const [jobAnalysis, setJobAnalysis] = useState(null);
    const [resumeScore, setResumeScore] = useState(0);

    // Theme state
    const [selectedTheme, setSelectedTheme] = useState('elegant');
    const [previewHtml, setPreviewHtml] = useState('');
    const [previewMode, setPreviewMode] = useState('edit');


    // Add these new state variables
    const [userId, setUserId] = useState(localStorage.getItem('user_id'));
    const [recordId, setRecordId] = useState(localStorage.getItem('record_id'));

    // Check authentication on mount
    useEffect(() => {
        const checkAuth = async () => {
            const { data: { user }, error } = await supabase.auth.getUser();
            setIsAuthenticated(!!user);
        };
        checkAuth();

        const { data: { subscription } } = supabase.auth.onAuthStateChange((_event, session) => {
            setIsAuthenticated(!!session?.user);
            if (session?.user) {
                setShowLoginModal(false);
            }
        });

        return () => subscription.unsubscribe();
    }, []);

    const steps = [
        {
            title: "Upload",
            component: (
                <UploadSection
                    isAuthenticated={isAuthenticated}
                    setShowLoginModal={setShowLoginModal}
                    setBasics={setBasics}
                    setWork={setWork}
                    setEducation={setEducation}
                    setResumeContent={setResumeContent}
                    setCurrentStep={setCurrentStep}
                    setUserId={setUserId}  // Add this if you want to manage state in parent
                    setRecordId={setRecordId}  // Add this if you want to manage state in parent
                />
            )
        },
        {
            title: "Enhance",
            component: (
                <EnhanceSection
                    isAuthenticated={isAuthenticated}
                    setShowLoginModal={setShowLoginModal}
                    basics={basics}
                    setBasics={setBasics}
                    work={work}
                    setWork={setWork}
                    education={education}
                    setEducation={setEducation}
                    setJobAnalysis={setJobAnalysis}
                    jobAnalysis={jobAnalysis}
                    setResumeScore={setResumeScore}
                    resumeScore={resumeScore}
                    selectedTheme={selectedTheme}
                    previewHtml={previewHtml}
                    setSelectedTheme={setSelectedTheme}
                    setPreviewHtml={setPreviewHtml}
                />
            )
        },
        {
            title: "Theme",
            component: (
                <ThemeSection
                    basics={basics}
                    work={work}
                    education={education}
                    selectedTheme={selectedTheme}
                    setSelectedTheme={setSelectedTheme}
                    setPreviewHtml={setPreviewHtml}
                />
            )
        },
        // {
        //     title: "Final",
        //     component: (
        //         <PreviewSection
        //             basics={basics}
        //             setBasics={setBasics}
        //             work={work}
        //             setWork={setWork}
        //             education={education}
        //             setEducation={setEducation}
        //             jobAnalysis={jobAnalysis}
        //             resumeScore={resumeScore}
        //             previewHtml={previewHtml}
        //             previewMode={previewMode}
        //             setPreviewMode={setPreviewMode}
        //             selectedTheme={selectedTheme}
        //         />
        //     )
        // }
    ];

    return (
        <div className="min-h-screen bg-gray-50 p-8">
            <div className="max-w-7xl mx-auto">
                {/* Header */}
                <div className="text-center mb-12">
                    <h1 className="text-4xl font-bold text-gray-900 mb-2">
                        AI Resume Builder
                    </h1>
                    <p className="text-gray-600">
                        Create the perfect resume for your next job
                    </p>
                </div>

                {/* Main Content */}
                <div className="bg-white rounded-2xl shadow-xl p-8">
                    {/* Progress Bar */}
                    <div className="mb-8">
                        <div className="flex justify-between mb-2">
                            {steps.map((step, index) => (
                                <div
                                    key={index}
                                    className={`flex items-center ${index === steps.length - 1 ? '' : 'flex-1'}`}
                                >
                                    <div
                                        className={`w-8 h-8 rounded-full flex items-center justify-center ${index < currentStep
                                            ? 'bg-green-500 text-white'
                                            : index === currentStep
                                                ? 'bg-blue-500 text-white'
                                                : 'bg-gray-200'
                                            }`}
                                    >
                                        {index < currentStep ? (
                                            <Check className="w-5 h-5" />
                                        ) : (
                                            index + 1
                                        )}
                                    </div>
                                    {index < steps.length - 1 && (
                                        <div
                                            className={`h-1 flex-1 mx-2 ${index < currentStep
                                                ? 'bg-green-500'
                                                : 'bg-gray-200'
                                                }`}
                                        />
                                    )}
                                </div>
                            ))}
                        </div>
                        <div className="flex justify-between px-2">
                            {steps.map((step, index) => (
                                <span key={index} className="text-sm text-gray-500">
                                    {step.title}
                                </span>
                            ))}
                        </div>
                    </div>

                    {/* Current Step */}
                    <div className="mb-12">
                        {steps[currentStep].component}

                        {/* Navigation Buttons */}
                        <div className="flex justify-between mt-8">
                            <button
                                onClick={() => setCurrentStep(prev => Math.max(prev - 1, 0))}
                                disabled={currentStep === 0}
                                className="px-6 py-2 border rounded-lg flex items-center gap-2 disabled:opacity-50 hover:bg-gray-50 transition-colors"
                            >
                                <ChevronLeft className="w-4 h-4" />
                                Previous
                            </button>

                            <button
                                onClick={() => setCurrentStep(prev => Math.min(prev + 1, steps.length - 1))}
                                disabled={currentStep === steps.length - 1}
                                className="px-6 py-2 bg-blue-500 text-white rounded-lg flex items-center gap-2 disabled:opacity-50 hover:bg-blue-600 transition-colors"
                            >
                                Next
                                <ChevronRight className="w-4 h-4" />
                            </button>
                        </div>
                    </div>
                </div>

                <LoginModal2
                    isOpen={showLoginModal}
                    onClose={() => setShowLoginModal(false)}
                />
            </div>
        </div>
    );
}

// import React, { useState, useCallback, useEffect, useRef } from 'react';
// import {
//     FileText, Upload, ExternalLink, ScrollText, Star,
//     Sparkles, Zap, Wand2, Check, AlertCircle,
//     ChevronLeft, ChevronRight, XCircle, Loader2,
//     PlusCircle, Trash2, Download, Edit2, Eye, ArrowDown
// } from 'lucide-react';


// import LoginModal2 from './LoginModal2';

// import supabase from '../supabaseConfig'; // Adjust the path based on your project structure
// import { useNavigate } from 'react-router-dom';

// export const resumeTemplates = [
//     {
//         id: '@jsonresume/jsonresume-theme-class',
//         name: "Class",
//         packageName: '@jsonresume/jsonresume-theme-class',
//         users: "25,600+",
//         preview: "Modern, ATS-friendly theme with dark mode support",
//         featured: true
//     },
//     {
//         id: 'jsonresume-theme-elegant',
//         name: "Elegant",
//         packageName: 'jsonresume-theme-elegant',
//         users: "12,400+",
//         preview: "Clean and professional design with excellent typography",
//         featured: true
//     },
//     {
//         id: 'jsonresume-theme-stackoverflow',
//         name: "Stack Overflow",
//         packageName: 'jsonresume-theme-stackoverflow',
//         users: "18,200+",
//         preview: "Familiar layout inspired by Stack Overflow careers"
//     }
// ];


// const ThemeStep = ({
//     selectedTheme,
//     setSelectedTheme,
//     themes,
//     previewHtml,
//     loading,
//     setLoading,
//     basics,         // Add these props to destructuring
//     work,
//     education
// }) => {
//     const [currentIndex, setCurrentIndex] = useState(0);
//     const [localPreviewHtml, setLocalPreviewHtml] = useState(previewHtml);
//     const [isRocking, setIsRocking] = useState(false);
//     const [direction, setDirection] = useState(0);

//     // Function to fetch preview from API
//     const fetchPreview = async (themeId) => {
//         console.log("Fetching preview for theme:", themeId); // Debug log
//         setLoading(true);
//         try {
//             const response = await fetch('https://e0a0-74-88-7-3.ngrok-free.app/api/preview', {
//                 method: 'POST',
//                 headers: {
//                     'Content-Type': 'application/json',
//                 },
//                 body: JSON.stringify({
//                     resume_data: { basics, work, education },
//                     theme_name: themeId
//                 })
//             });

//             const html = await response.text();
//             console.log("Received preview HTML"); // Debug log
//             setLocalPreviewHtml(html);
//         } catch (error) {
//             console.error('Error fetching preview:', error);
//         } finally {
//             setLoading(false);
//         }
//     };

//     // Handle theme navigation
//     const handleNavigate = async (delta) => {
//         if (loading) return;

//         setIsRocking(true);
//         setDirection(delta);

//         // Calculate new index
//         const newIndex = (currentIndex + delta + themes.length) % themes.length;
//         const newTheme = themes[newIndex];

//         console.log("Navigating to theme:", newTheme?.id); // Debug log

//         if (newTheme) {
//             setCurrentIndex(newIndex);
//             setSelectedTheme(newTheme.id);
//             await fetchPreview(newTheme.id);
//         }

//         setTimeout(() => setIsRocking(false), 500);
//     };

//     // Initialize first theme
//     useEffect(() => {
//         if (themes.length > 0 && selectedTheme) {
//             const index = themes.findIndex(t => t.id === selectedTheme);
//             setCurrentIndex(index >= 0 ? index : 0);
//         }
//     }, [themes, selectedTheme]);

//     return (
//         <div className="space-y-6">
//             <div className="relative">
//                 {/* Navigation Buttons */}
//                 <button
//                     onClick={() => handleNavigate(-1)}
//                     disabled={loading}
//                     className={`absolute left-4 top-1/2 transform -translate-y-1/2 z-10 p-3 bg-white rounded-full shadow-lg hover:bg-gray-50 transition-all disabled:opacity-50 ${isRocking && direction === -1 ? 'animate-rock-left' : ''
//                         }`}
//                 >
//                     <ChevronLeft className="w-6 h-6 text-gray-700" />
//                 </button>

//                 <button
//                     onClick={() => handleNavigate(1)}
//                     disabled={loading}
//                     className={`absolute right-4 top-1/2 transform -translate-y-1/2 z-10 p-3 bg-white rounded-full shadow-lg hover:bg-gray-50 transition-all disabled:opacity-50 ${isRocking && direction === 1 ? 'animate-rock-right' : ''
//                         }`}
//                 >
//                     <ChevronRight className="w-6 h-6 text-gray-700" />
//                 </button>

//                 {/* Preview Window */}
//                 <div className="relative h-[800px] overflow-hidden rounded-xl border border-gray-200 bg-white">
//                     {loading ? (
//                         <div className="h-full flex items-center justify-center">
//                             <div className="flex flex-col items-center gap-3">
//                                 <Loader2 className="w-8 h-8 animate-spin text-blue-500" />
//                                 <p className="text-sm text-gray-500">Loading preview...</p>
//                             </div>
//                         </div>
//                     ) : (
//                         <iframe
//                             srcDoc={localPreviewHtml}
//                             className="w-full h-full"
//                             title="Resume Preview"
//                             style={{ border: 'none' }}
//                         />
//                     )}
//                 </div>

//                 {/* Theme Counter */}
//                 <div className="absolute bottom-4 left-1/2 transform -translate-x-1/2 bg-white/90 backdrop-blur-sm px-4 py-2 rounded-full shadow-md">
//                     <div className="text-sm text-gray-600">
//                         Theme {currentIndex + 1} of {themes.length}
//                     </div>
//                 </div>
//             </div>

//             {/* Current Theme Info */}
//             {themes[currentIndex] && (
//                 <div className="bg-gray-50 p-4 rounded-lg">
//                     <h3 className="font-medium text-gray-900 mb-1">
//                         {themes[currentIndex].name}
//                     </h3>
//                     <p className="text-sm text-gray-600">
//                         {themes[currentIndex].description}
//                     </p>
//                 </div>
//             )}
//         </div>
//     );
// };

// const ExportButtons = ({ basics, work, education, skills, projects, jobAnalysis, selectedTheme }) => {
//     const [isExporting, setIsExporting] = useState(false);

//     const handleExport = async (format) => {
//         setIsExporting(true);
//         try {
//             const exportData = {
//                 basics,
//                 work,
//                 education,
//                 skills,
//                 projects
//             };

//             // Simulate export delay
//             await new Promise(resolve => setTimeout(resolve, 1500));

//             console.log(`Exporting as ${format}`, exportData);
//             // Here you would implement actual export logic

//         } catch (error) {
//             console.error('Export error:', error);
//         } finally {
//             setIsExporting(false);
//         }
//     };

//     return (
//         <div className="space-y-3">
//             <button
//                 onClick={() => handleExport('pdf')}
//                 disabled={isExporting}
//                 className="w-full py-3 bg-blue-500 text-white rounded-lg flex items-center justify-center gap-2 hover:bg-blue-600 transition-all disabled:opacity-50"
//             >
//                 {isExporting ? (
//                     <Loader2 className="w-5 h-5 animate-spin" />
//                 ) : (
//                     <Download className="w-5 h-5" />
//                 )}
//                 Export as PDF
//             </button>

//             <button
//                 onClick={() => handleExport('docx')}
//                 disabled={isExporting}
//                 className="w-full py-3 border border-gray-300 rounded-lg flex items-center justify-center gap-2 hover:bg-gray-50 transition-all disabled:opacity-50"
//             >
//                 <FileText className="w-5 h-5" />
//                 Export as DOCX
//             </button>
//         </div>
//     );
// };
// // Template Selector Component (imported from your second file)
// const TemplateSelector = ({
//     activeTemplateIndex,
//     onSelectTemplate,
//     containerRef
// }) => {
//     const handleScroll = (direction) => {
//         if (containerRef.current) {
//             const scrollAmount = 300;
//             containerRef.current.scrollLeft += direction * scrollAmount;
//         }
//     };

//     return (
//         <div className="space-y-6">
//             <div className="text-2xl font-bold text-gray-800">Choose a Template</div>

//             <div className="relative">
//                 <button
//                     onClick={() => handleScroll(-1)}
//                     className="absolute left-0 top-1/2 -translate-y-1/2 -translate-x-4 w-8 h-8 bg-white rounded-full shadow-lg flex items-center justify-center z-10 hover:bg-gray-50"
//                 >
//                     <ChevronLeft className="w-5 h-5 text-gray-600" />
//                 </button>

//                 <button
//                     onClick={() => handleScroll(1)}
//                     className="absolute right-0 top-1/2 -translate-y-1/2 translate-x-4 w-8 h-8 bg-white rounded-full shadow-lg flex items-center justify-center z-10 hover:bg-gray-50"
//                 >
//                     <ChevronRight className="w-5 h-5 text-gray-600" />
//                 </button>

//                 <div
//                     ref={containerRef}
//                     className="flex gap-4 overflow-x-auto snap-x snap-mandatory scrollbar-hide pb-4"
//                 >
//                     {resumeTemplates.map((template, index) => (
//                         <div
//                             key={template.id}
//                             className={`flex-none w-72 snap-start ${index === activeTemplateIndex
//                                 ? 'ring-2 ring-blue-500'
//                                 : 'hover:border-blue-200'
//                                 } cursor-pointer bg-white rounded-xl border border-gray-200 overflow-hidden transition-all`}
//                             onClick={() => onSelectTemplate(index)}
//                         >
//                             <div className="aspect-video bg-gray-100 relative">
//                                 <img
//                                     src={`/api/placeholder/400/225`}
//                                     alt={template.name}
//                                     className="w-full h-full object-cover"
//                                 />
//                                 {template.featured && (
//                                     <div className="absolute top-2 right-2 bg-blue-500 text-white text-xs px-2 py-1 rounded-full flex items-center gap-1">
//                                         <Star className="w-3 h-3" />
//                                         Featured
//                                     </div>
//                                 )}
//                             </div>

//                             <div className="p-4">
//                                 <div className="flex items-center justify-between mb-2">
//                                     <h3 className="font-medium text-gray-900">
//                                         {template.name}
//                                     </h3>
//                                     <span className="text-xs text-gray-500">
//                                         {template.users} users
//                                     </span>
//                                 </div>
//                                 <p className="text-sm text-gray-600">
//                                     {template.preview}
//                                 </p>
//                             </div>
//                         </div>
//                     ))}
//                 </div>
//             </div>
//         </div>
//     );
// };

// const JobURLSelectorModal = ({ isOpen, onClose, onSelectURL }) => {
//     const [url, setUrl] = useState('');
//     const [selectedSite, setSelectedSite] = useState(null);

//     // Add these imports at the top

//     // Add this component above your main component


//     // Add these inside your main component, with your other state declarations

//     // Add these functions inside your main component

//     const jobSites = [
//         {
//             name: 'LinkedIn',
//             domain: 'linkedin.com',
//             icon: (
//                 <svg viewBox="0 0 40 40" className="w-8 h-8" fill="none">
//                     <rect width="40" height="40" rx="8" fill="#0A66C2" />
//                     <path d="M15 16h-4v12h4V16zm-2-6.8a2.4 2.4 0 110 4.8 2.4 2.4 0 010-4.8zM29 28h-4v-6.2c0-3.2-4-3-4 0V28h-4V16h4v1.8c1.4-2.6 8-2.8 8 2.5V28z" fill="white" />
//                 </svg>
//             )
//         },
//         {
//             name: 'Indeed',
//             domain: 'indeed.com',
//             icon: (
//                 <svg viewBox="0 0 40 40" className="w-8 h-8" fill="none">
//                     <rect width="40" height="40" rx="8" fill="#2164F3" />
//                     <path d="M12 10h6v20h-6z" fill="#FFFFFF" />
//                     <path d="M22 10h6v20h-6z" fill="#FFFFFF" />
//                 </svg>
//             )
//         },
//         {
//             name: 'Glassdoor',
//             domain: 'glassdoor.com',
//             icon: (
//                 <svg viewBox="0 0 40 40" className="w-8 h-8" fill="none">
//                     <rect width="40" height="40" rx="8" fill="#0CAA41" />
//                     <path d="M20 6c-7.7 0-14 6.3-14 14s6.3 14 14 14 14-6.3 14-14S27.7 6 20 6zm0 22c-4.4 0-8-3.6-8-8s3.6-8 8-8 8 3.6 8 8-3.6 8-8 8zm0-13c-2.8 0-5 2.2-5 5s2.2 5 5 5 5-2.2 5-5-2.2-5-5-5z" fill="white" />
//                 </svg>
//             )
//         },
//         {
//             name: 'Dice',
//             domain: 'dice.com',
//             icon: (
//                 <svg viewBox="0 0 40 40" className="w-8 h-8" fill="none">
//                     <rect width="40" height="40" rx="8" fill="#EB1C26" />
//                     <path d="M10 10h20v20H10z" fill="white" />
//                     <circle cx="15" cy="15" r="2" fill="#EB1C26" />
//                     <circle cx="25" cy="15" r="2" fill="#EB1C26" />
//                     <circle cx="15" cy="25" r="2" fill="#EB1C26" />
//                     <circle cx="25" cy="25" r="2" fill="#EB1C26" />
//                 </svg>
//             )
//         },
//         {
//             name: 'Monster',
//             domain: 'monster.com',
//             icon: (
//                 <svg viewBox="0 0 40 40" className="w-8 h-8" fill="none">
//                     <rect width="40" height="40" rx="8" fill="#6E45A5" />
//                     <path d="M20 10c-5.5 0-10 4.5-10 10s4.5 10 10 10 10-4.5 10-10-4.5-10-10-10zm0 15c-2.8 0-5-2.2-5-5s2.2-5 5-5 5 2.2 5 5-2.2 5-5 5z" fill="white" />
//                 </svg>
//             )
//         },
//         {
//             name: 'Talent.com',
//             domain: 'talent.com',
//             icon: (
//                 <svg viewBox="0 0 40 40" className="w-8 h-8" fill="none">
//                     <rect width="40" height="40" rx="8" fill="#1D2C4D" />
//                     <path d="M8 20.5L15 28l3-3-4-4 4-4-3-3L8 20.5z" fill="white" />
//                     <path d="M32 20.5L25 28l-3-3 4-4-4-4 3-3L32 20.5z" fill="white" />
//                 </svg>
//             )
//         },
//         {
//             name: 'Greenhouse',
//             domain: 'greenhouse.io',
//             icon: (
//                 <svg viewBox="0 0 40 40" className="w-8 h-8" fill="none">
//                     <rect width="40" height="40" rx="8" fill="#3AB549" />
//                     <path d="M20 8c-6.6 0-12 5.4-12 12s5.4 12 12 12 12-5.4 12-12S26.6 8 20 8zm0 18c-3.3 0-6-2.7-6-6s2.7-6 6-6 6 2.7 6 6-2.7 6-6 6z" fill="white" />
//                     <path d="M20 16c-2.2 0-4 1.8-4 4s1.8 4 4 4 4-1.8 4-4-1.8-4-4-4zm0 6c-1.1 0-2-.9-2-2s.9-2 2-2 2 .9 2 2-.9 2-2 2z" fill="white" />
//                 </svg>
//             )
//         }
//     ];

//     const handleContinue = () => {
//         if (url) {
//             onSelectURL(url);
//             onClose();
//             setUrl('');
//         }
//     };

//     if (!isOpen) return null;

//     return (
//         <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4 z-50">
//             <div className="bg-white rounded-2xl p-6 max-w-lg w-full">
//                 <div className="mb-6">
//                     <h2 className="text-xl font-bold text-gray-900">
//                         Paste a link to the job listing
//                     </h2>
//                     <p className="mt-2 text-sm text-gray-500">
//                         We'll analyze the job description to optimize your resume
//                     </p>
//                 </div>

//                 <div className="space-y-6">
//                     <div className="relative">
//                         <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
//                             <span className="text-gray-400">🔒</span>
//                         </div>
//                         <input
//                             type="url"
//                             value={url}
//                             onChange={(e) => setUrl(e.target.value)}
//                             className="w-full pl-10 pr-4 py-3 border rounded-lg bg-gray-50 text-gray-900 placeholder:text-gray-400"
//                             placeholder="jobs.com/company/position"
//                         />
//                     </div>

//                     <div>
//                         <h4 className="text-sm font-medium text-gray-500 mb-3">
//                             Supported Job Sites
//                         </h4>
//                         <div className="grid grid-cols-2 gap-4">
//                             {jobSites.map((site) => (
//                                 <div
//                                     key={site.domain}
//                                     onClick={() => setSelectedSite(site.name)}
//                                     className={`flex items-center gap-3 p-3 rounded-lg cursor-pointer transition-all duration-200 ${selectedSite === site.name
//                                         ? 'bg-blue-50 border-2 border-blue-200'
//                                         : 'border-2 border-transparent hover:bg-gray-50'
//                                         }`}
//                                 >
//                                     {site.icon}
//                                     <span className="text-sm font-medium text-gray-600">
//                                         {site.name}
//                                     </span>
//                                 </div>
//                             ))}
//                         </div>
//                     </div>

//                     <div className="flex justify-end gap-4 mt-6">
//                         <button
//                             onClick={onClose}
//                             className="px-4 py-2 rounded-lg border hover:bg-gray-50"
//                         >
//                             Cancel
//                         </button>
//                         <button
//                             onClick={handleContinue}
//                             disabled={!url}
//                             className="px-4 py-2 rounded-lg bg-blue-600 text-white hover:bg-blue-700 disabled:opacity-50 disabled:cursor-not-allowed"
//                         >
//                             Continue
//                         </button>
//                     </div>
//                 </div>
//             </div>
//         </div>
//     );
// };


// // Add these imports at the top

// // Add this component above your main component


// export default function ResumeBuilder() {
//     const navigate = useNavigate();
//     // Theme and preview state
//     const [selectedTheme, setSelectedTheme] = useState('elegant');
//     const [themes, setThemes] = useState([]);
//     const [loading, setLoading] = useState(false);
//     const [previewHtml, setPreviewHtml] = useState('');
//     const [previewMode, setPreviewMode] = useState('edit');

//     // Step tracking
//     const [currentStep, setCurrentStep] = useState(0);
//     const [uploadProgress, setUploadProgress] = useState(0);
//     const [isUploading, setIsUploading] = useState(false);
//     const [error, setError] = useState(null);
//     const [simulatedProgress, setSimulatedProgress] = useState(0);
//     const [analysisStep, setAnalysStep] = useState(0);

//     // File and data state
//     const [file, setFile] = useState(null);
//     const [resumeContent, setResumeContent] = useState('');
//     const [jobAnalysis, setJobAnalysis] = useState(null);
//     const [resumeScore, setResumeScore] = useState(0);
//     const [isEnhancing, setIsEnhancing] = useState(false);



//     /// Scraping Section 
//     const [isURLModalOpen, setIsURLModalOpen] = useState(false);
//     const [jobLink, setJobLink] = useState('');
//     const [isScrapingJob, setIsScrapingJob] = useState(false);
//     const [scrapeError, setScrapeError] = useState('');
//     const [processingStep, setProcessingStep] = useState(0);
//     const [processingProgress, setProcessingProgress] = useState(0);
//     const [loadingMessage, setLoadingMessage] = useState('');
//     // Add these state declarations with your other useState declarations
//     const [isAuthenticated, setIsAuthenticated] = useState(false);
//     const [showLoginModal, setShowLoginModal] = useState(false);

//     // Add this useEffect for authentication
//     useEffect(() => {
//         const checkAuth = async () => {
//             const { data: { user }, error } = await supabase.auth.getUser();
//             setIsAuthenticated(!!user);
//         };
//         checkAuth();

//         // Subscribe to auth changes
//         const { data: { subscription } } = supabase.auth.onAuthStateChange((_event, session) => {
//             setIsAuthenticated(!!session?.user);
//             if (session?.user) {
//                 setShowLoginModal(false);
//             }
//         });

//         return () => {
//             subscription.unsubscribe();
//         };
//     }, []);


//     // Basic information state
//     const [basics, setBasics] = useState({
//         name: "",
//         label: "",
//         image: "",
//         email: "",
//         phone: "",
//         url: "",
//         summary: "",
//         location: {
//             address: "",
//             postalCode: "",
//             city: "",
//             countryCode: "",
//             region: ""
//         },
//         profiles: []
//     });

//     // Resume sections state
//     const [work, setWork] = useState([{
//         name: "",
//         position: "",
//         startDate: "",
//         endDate: "",
//         highlights: []
//     }]);

//     const [education, setEducation] = useState([{
//         institution: "",
//         area: "",
//         studyType: "",
//         startDate: "",
//         endDate: ""
//     }]);

//     const [jobText, setJobText] = useState('');
//     const [pufferyLevel, setPufferyLevel] = useState(0.5);

//     // Analysis steps
//     const analysisSteps = [
//         "Initializing document scanner...",
//         "Reading document structure...",
//         "Extracting work experience...",
//         "Analyzing job titles and roles...",
//         "Processing educational background...",
//         "Identifying key skills and technologies...",
//         "Parsing professional achievements...",
//         "Evaluating career progression...",
//         "Organizing resume sections...",
//         "Finalizing resume analysis..."
//     ];

//     // Add this with your other handlers in the ResumeBuilder component
//     const handleEnhance = async () => {
//         if (!isAuthenticated) {
//             setShowLoginModal(true);
//             return;
//         }

//         // Get current user
//         const { data: { user }, error: authError } = await supabase.auth.getUser();
//         if (authError || !user) {
//             setError('Authentication error. Please sign in again.');
//             setShowLoginModal(true);
//             return;
//         }

//         setIsEnhancing(true);
//         setError(null);
//         setScrapeError('');
//         setProcessingStep(0);
//         setProcessingProgress(0);

//         try {
//             // Start progress simulation
//             let progress = 0;
//             const progressInterval = setInterval(() => {
//                 progress += 5;
//                 if (progress <= 90) {
//                     setProcessingProgress(progress);
//                 }
//                 setProcessingStep(Math.floor((progress / 100) * processingSteps.length));
//             }, 500);

//             // Create FormData object
//             const formData = new FormData();
//             formData.append('user_id', user.id);
//             formData.append('job_description', jobText);
//             formData.append('match_job_titles', 'true');
//             formData.append('puffery_level', pufferyLevel.toString());

//             console.log('Sending enhance request with:', {
//                 user_id: user.id,
//                 job_description_length: jobText.length,
//             });

//             const response = await fetch('https://e0a0-74-88-7-3.ngrok-free.app/api/v1/resume/enhance', {
//                 method: 'POST',
//                 body: formData,
//             });

//             clearInterval(progressInterval);
//             setProcessingProgress(100);

//             if (!response.ok) {
//                 const errorData = await response.json();
//                 throw new Error(`Enhancement failed: ${JSON.stringify(errorData)}`);
//             }

//             const data = await response.json();

//             if (data.success) {
//                 // Update work experience with enhanced data
//                 if (data.enhanced_resume?.work) {
//                     const enhancedWork = data.enhanced_resume.work.map((job) => ({
//                         ...job,
//                         original_position: job.original_position || job.position,
//                         startDate: formatDate(job.startDate) || '2000-01-01',
//                         endDate: formatDate(job.endDate) || new Date().toISOString().split('T')[0],
//                     }));
//                     setWork(enhancedWork);
//                 }

//                 // Update job analysis
//                 if (data.job_analysis) {
//                     setJobAnalysis(data.job_analysis);
//                 }

//                 // Update resume score
//                 setResumeScore((prev) => Math.min(prev + 35, 100));
//             }

//             setLoadingMessage('Enhancement complete!');

//             setTimeout(() => {
//                 setProcessingProgress(100);
//                 setIsEnhancing(false);
//             }, 1000);
//         } catch (err) {
//             console.error('Enhancement error:', err);
//             setError(err.message);
//             setScrapeError(err.message);
//         } finally {
//             setIsEnhancing(false);
//             setLoadingMessage('');
//         }
//     };


//     const ProcessingSteps = ({ currentStep, steps, progress }) => {
//         return (
//             <div className="space-y-4">
//                 {steps.map((step, index) => (
//                     <div
//                         key={step}
//                         className={`flex items-center gap-3 ${index <= currentStep ? 'text-blue-600' : 'text-gray-400'
//                             }`}
//                     >
//                         <div className="w-6 h-6 flex-shrink-0">
//                             {index < currentStep ? (
//                                 <Check className="w-6 h-6 text-green-500" />
//                             ) : index === currentStep ? (
//                                 <Loader2 className="w-6 h-6 animate-spin" />
//                             ) : (
//                                 <div className="w-6 h-6 rounded-full border-2 border-current" />
//                             )}
//                         </div>
//                         <span className="text-sm font-medium">{step}</span>
//                     </div>
//                 ))}
//                 <div className="mt-4">
//                     <div className="h-2 bg-gray-100 rounded-full">
//                         <div
//                             className="h-full bg-blue-600 rounded-full transition-all duration-500"
//                             style={{ width: `${progress}%` }}
//                         />
//                     </div>
//                 </div>
//             </div>
//         );
//     };

//     const [processingSteps] = useState([
//         "Analyzing Job Requirements",
//         "Identifying Key Skills",
//         "Matching Experience",
//         "Optimizing Keywords",
//         "Calculating Match Score"
//     ]);


//     // Fetch themes on mount
//     useEffect(() => {
//         fetch('https://e0a0-74-88-7-3.ngrok-free.app/api/themes')
//             .then(res => res.json())
//             .then(data => setThemes(data.themes))
//             .catch(console.error);
//     }, []);

//     const simulateProcessing = () => {
//         setProcessingStep(0);
//         setProcessingProgress(0);

//         const stepDuration = 1000;
//         const progressInterval = 50;
//         const totalSteps = processingSteps.length;

//         let currentStep = 0;
//         let currentProgress = 0;

//         const stepTimer = setInterval(() => {
//             if (currentStep >= totalSteps) {
//                 clearInterval(stepTimer);
//                 return;
//             }

//             setProcessingStep(currentStep);
//             currentStep++;
//         }, stepDuration);

//         const progressTimer = setInterval(() => {
//             if (currentProgress >= 100) {
//                 clearInterval(progressTimer);
//                 return;
//             }

//             currentProgress += 1;
//             setProcessingProgress(currentProgress);
//         }, progressInterval);
//     };

//     const handleSearch = () => {
//         if (jobLink) {
//             handleJobLinkSelect(jobLink);
//         }
//     };


//     const handleJobLinkSelect = async (url) => {
//         setJobLink(url);
//         setIsScrapingJob(true);
//         setScrapeError('');
//         setProcessingStep(0);
//         setProcessingProgress(0);

//         try {
//             const response = await fetch('https://e0a0-74-88-7-3.ngrok-free.app/scrape-job', {
//                 method: 'POST',
//                 headers: {
//                     'Content-Type': 'application/json',
//                 },
//                 body: JSON.stringify({ url })
//             });

//             const data = await response.json();

//             if (data.error) {
//                 setScrapeError(data.error);
//                 return;
//             }

//             if (data.raw_text) {
//                 setJobText(data.raw_text);
//                 simulateProcessing();
//             }
//         } catch (error) {
//             setScrapeError('Failed to fetch job description. Please try again.');
//         } finally {
//             setIsScrapingJob(false);
//         }
//     };
//     // Upload progress simulation
//     useEffect(() => {
//         let progressInterval;
//         if (isUploading) {
//             progressInterval = setInterval(() => {
//                 setSimulatedProgress(prev => {
//                     if (prev < 30) return prev + 0.8;
//                     if (prev < 60) return prev + 0.5;
//                     if (prev < 85) return prev + 0.2;
//                     if (prev < 90) return prev + 0.1;
//                     return prev;
//                 });
//             }, 100);
//         }
//         return () => clearInterval(progressInterval);
//     }, [isUploading]);

//     // Analysis step rotation
//     useEffect(() => {
//         let analysisInterval;
//         if (isUploading) {
//             analysisInterval = setInterval(() => {
//                 setAnalysStep(prev => (prev + 1) % analysisSteps.length);
//             }, 2000);
//         }
//         return () => clearInterval(analysisInterval);
//     }, [isUploading]);

//     // Preview update on theme change
//     useEffect(() => {
//         if (selectedTheme) {
//             updatePreview();
//         }
//     }, [selectedTheme, basics, work, education]);

//     // Handlers
//     const handleExportPDF = async () => {
//         try {
//             const response = await fetch('https://e0a0-74-88-7-3.ngrok-free.app/api/export-pdf', {
//                 method: 'POST',
//                 headers: { 'Content-Type': 'application/json' },
//                 body: JSON.stringify({
//                     resume_data: { basics, work, education },
//                     theme_name: selectedTheme
//                 })
//             });

//             const blob = await response.blob();
//             const url = window.URL.createObjectURL(blob);
//             const a = document.createElement('a');
//             a.href = url;
//             a.download = `resume-${selectedTheme}.pdf`;
//             document.body.appendChild(a);
//             a.click();
//             window.URL.revokeObjectURL(url);
//             document.body.removeChild(a);
//         } catch (error) {
//             console.error('Error exporting PDF:', error);
//         }
//     };

//     const updatePreview = async () => {
//         setLoading(true);
//         try {
//             const response = await fetch('https://e0a0-74-88-7-3.ngrok-free.app/api/preview', {
//                 method: 'POST',
//                 headers: { 'Content-Type': 'application/json' },
//                 body: JSON.stringify({
//                     resume_data: { basics, work, education },
//                     theme_name: selectedTheme
//                 })
//             });
//             const html = await response.text();
//             setPreviewHtml(html);
//         } catch (error) {
//             console.error('Error updating preview:', error);
//         } finally {
//             setLoading(false);
//         }
//     };

//     const formatDate = (dateString) => {
//         if (!dateString) return "1970-01-01"; // Default date instead of null

//         // Clean the input
//         const cleaned = String(dateString).trim()
//             .replace(/\s+/g, ' ')                // Normalize spaces
//             .replace(/(\d)(st|nd|rd|th)/gi, '$1') // Remove ordinal indicators
//             .replace(/,/g, '')                    // Remove commas
//             .toLowerCase();

//         try {
//             // Try parsing as ISO date first
//             const isoDate = new Date(cleaned);
//             if (isoDate.toString() !== 'Invalid Date') {
//                 return isoDate.toISOString().split('T')[0];
//             }

//             // Common date patterns
//             const patterns = [
//                 // Year only
//                 {
//                     regex: /^(\d{4})$/,
//                     handler: (m) => `${m[1]}-01-01`
//                 },
//                 // Year-month
//                 {
//                     regex: /^(\d{4})-(\d{1,2})$/,
//                     handler: (m) => `${m[1]}-${padZero(m[2])}-01`
//                 },
//                 // Month Year (Jan 2020 or January 2020)
//                 {
//                     regex: /^([a-z]+)\s+(\d{4})$/,
//                     handler: (m) => `${m[2]}-${getMonthNumber(m[1])}-01`
//                 },
//                 // Year Month (2020 Jan or 2020 January)
//                 {
//                     regex: /^(\d{4})\s+([a-z]+)$/,
//                     handler: (m) => `${m[1]}-${getMonthNumber(m[2])}-01`
//                 },
//                 // MM/DD/YYYY or MM-DD-YYYY
//                 {
//                     regex: /^(\d{1,2})[-\/](\d{1,2})[-\/](\d{4})$/,
//                     handler: (m) => `${m[3]}-${padZero(m[1])}-${padZero(m[2])}`
//                 },
//                 // YYYY/MM/DD or YYYY-MM-DD
//                 {
//                     regex: /^(\d{4})[-\/](\d{1,2})[-\/](\d{1,2})$/,
//                     handler: (m) => `${m[1]}-${padZero(m[2])}-${padZero(m[3])}`
//                 },
//                 // Month DD YYYY (January 1 2020 or Jan 1 2020)
//                 {
//                     regex: /^([a-z]+)\s+(\d{1,2})\s+(\d{4})$/,
//                     handler: (m) => `${m[3]}-${getMonthNumber(m[1])}-${padZero(m[2])}`
//                 },
//                 // DD Month YYYY (1 January 2020 or 1 Jan 2020)
//                 {
//                     regex: /^(\d{1,2})\s+([a-z]+)\s+(\d{4})$/,
//                     handler: (m) => `${m[3]}-${getMonthNumber(m[2])}-${padZero(m[1])}`
//                 },
//                 // Relative dates (current, present, now, today)
//                 {
//                     regex: /^(current|present|now|today)$/,
//                     handler: () => new Date().toISOString().split('T')[0]
//                 },
//                 // Handle "since YYYY" or "from YYYY"
//                 {
//                     regex: /^(?:since|from)\s+(\d{4})$/,
//                     handler: (m) => `${m[1]}-01-01`
//                 },
//                 // Quarter formats (Q1 2020, 2020 Q1)
//                 {
//                     regex: /^(?:q([1-4])\s+(\d{4})|(\d{4})\s+q([1-4]))$/,
//                     handler: (m) => {
//                         const year = m[2] || m[3];
//                         const quarter = m[1] || m[4];
//                         const month = ((parseInt(quarter) - 1) * 3 + 1).toString().padStart(2, '0');
//                         return `${year}-${month}-01`;
//                     }
//                 }
//             ];

//             // Try each pattern
//             for (const pattern of patterns) {
//                 const match = cleaned.match(pattern.regex);
//                 if (match) {
//                     const result = pattern.handler(match);
//                     // Validate the resulting date
//                     if (isValidDate(result)) {
//                         return result;
//                     }
//                 }
//             }

//             // If no patterns match, try to extract a year
//             const yearMatch = cleaned.match(/\b(19|20)\d{2}\b/);
//             if (yearMatch) {
//                 return `${yearMatch[0]}-01-01`;
//             }

//             // Last resort: return a default date
//             console.warn(`Could not parse date format: ${dateString}, using default date`);
//             return "1970-01-01";

//         } catch (error) {
//             console.error(`Error formatting date: ${dateString}`, error);
//             return "1970-01-01";
//         }
//     };

//     const getMonthNumber = (month) => {
//         const months = {
//             'jan': '01', 'january': '01',
//             'feb': '02', 'february': '02',
//             'mar': '03', 'march': '03',
//             'apr': '04', 'april': '04',
//             'may': '05',
//             'jun': '06', 'june': '06',
//             'jul': '07', 'july': '07',
//             'aug': '08', 'august': '08',
//             'sep': '09', 'september': '09',
//             'oct': '10', 'october': '10',
//             'nov': '11', 'november': '11',
//             'dec': '12', 'december': '12'
//         };

//         const monthKey = month.toLowerCase().slice(0, 3);
//         return months[monthKey] || '01';
//     };

//     const padZero = (num) => {
//         return String(num).padStart(2, '0');
//     };

//     const isValidDate = (dateStr) => {
//         const [year, month, day] = dateStr.split('-').map(Number);
//         const date = new Date(year, month - 1, day);
//         return date instanceof Date && !isNaN(date) &&
//             date.getFullYear() === year &&
//             date.getMonth() === month - 1 &&
//             date.getDate() === day;
//     };

//     const handleUpload = async (selectedFile) => {
//         if (!isAuthenticated) {
//             setShowLoginModal(true);
//             return;
//         }

//         setIsUploading(true);
//         setError(null);
//         setUploadProgress(0);

//         try {
//             const { data: { user }, error: authError } = await supabase.auth.getUser();
//             if (authError || !user) {
//                 throw new Error('Authentication error. Please sign in again.');
//             }

//             // Create FormData
//             const formData = new FormData();
//             formData.append('file', selectedFile);
//             formData.append('user_id', user.id);
//             formData.append('job_description', '');
//             formData.append('enhance', 'false');
//             formData.append('mode', 'new');

//             // Progress simulation
//             const progressInterval = setInterval(() => {
//                 setUploadProgress((prev) => Math.min(prev + 10, 90));
//             }, 500);

//             const response = await fetch('https://e0a0-74-88-7-3.ngrok-free.app/api/v1/resume/upload_small', {
//                 method: 'POST',
//                 body: formData,
//             });

//             clearInterval(progressInterval);
//             setUploadProgress(100);

//             if (!response.ok) {
//                 const errorData = await response.json();
//                 throw new Error(`Upload failed: ${JSON.stringify(errorData, null, 2)}`);
//             }

//             const data = await response.json();

//             // Update state with resume data
//             if (data.resume_json) {
//                 // Format all dates in work experience
//                 const formattedWork = data.resume_json.work.map((job) => ({
//                     ...job,
//                     original_position: job.position,
//                     startDate: formatDate(job.startDate) || '2000-01-01',
//                     endDate: formatDate(job.endDate) || new Date().toISOString().split('T')[0],
//                 }));

//                 // Format all dates in education
//                 const formattedEducation = (data.resume_json.education || []).map((edu) => ({
//                     ...edu,
//                     startDate: formatDate(edu.startDate) || '2000-01-01',
//                     endDate: formatDate(edu.endDate) || new Date().toISOString().split('T')[0],
//                 }));

//                 // Update state with formatted dates
//                 setBasics(data.resume_json.basics || basics);
//                 setWork(formattedWork);
//                 setEducation(formattedEducation);
//                 setResumeContent(data.resume_text || '');

//                 setCurrentStep(1);
//             }
//         } catch (error) {
//             console.error('Upload error:', error);
//             setError(`Failed to upload resume: ${error.message}`);
//         } finally {
//             setIsUploading(false);
//         }
//     };


//     const handleFileChange = (e) => {
//         if (e.target.files?.[0]) {
//             setFile(e.target.files[0]);
//             handleUpload(e.target.files[0]);
//         }
//     };

//     const handleDrop = useCallback((e) => {
//         e.preventDefault();
//         const droppedFile = e.dataTransfer.files[0];
//         if (droppedFile) {
//             setFile(droppedFile);
//             handleUpload(droppedFile);
//         }
//     }, []);

//     const handleDragOver = useCallback((e) => {
//         e.preventDefault();
//     }, []);

//     const handleArrayChange = (setter, index, field, value) => {
//         setter(prev => {
//             const newArray = [...prev];
//             newArray[index] = { ...newArray[index], [field]: value };
//             return newArray;
//         });
//     };

//     // Component definitions
//     const RenderUploadStep = () => {
//         const displayProgress = Math.min(simulatedProgress, uploadProgress);

//         return (
//             <div className="space-y-6">
//                 <div className="text-2xl font-bold text-gray-800">Upload Your Resume</div>
//                 <LoginModal2
//                     isOpen={showLoginModal}
//                     onClose={() => setShowLoginModal(false)}
//                 />
//                 <div
//                     className="w-full"
//                     onDrop={handleDrop}
//                     onDragOver={handleDragOver}
//                 >
//                     <div className="relative">
//                         {isUploading && (
//                             <div className="absolute -inset-0.5 bg-gradient-to-r from-blue-300 via-blue-400 to-blue-300 rounded-lg blur opacity-75 animate-pulse-border" />
//                         )}

//                         <label className={`relative flex flex-col items-center justify-center w-full h-64 ${isUploading ? 'border-2 border-transparent' : 'border-2 border-dashed border-gray-300'} rounded-lg cursor-pointer bg-gray-50 hover:bg-gray-100 transition-all duration-300`}>
//                             {isUploading ? (
//                                 <div className="space-y-4 text-center">
//                                     <div className="relative">
//                                         <Loader2 className="w-10 h-10 animate-spin text-blue-500 mx-auto" />
//                                         <div className="absolute -top-1 -right-1 w-3 h-3 bg-blue-500 rounded-full animate-ping" />
//                                     </div>

//                                     <div className="space-y-2">
//                                         <p className="text-sm font-medium text-gray-800">
//                                             {analysisSteps[analysisStep]}
//                                         </p>
//                                         <p className="text-xs text-gray-500">
//                                             Processing your resume can take up to 60 seconds
//                                         </p>
//                                     </div>

//                                     <div className="relative w-48 mx-auto">
//                                         <div className="h-2 bg-gray-200 rounded-full overflow-hidden">
//                                             <div
//                                                 className="h-full bg-blue-500 transition-all duration-300"
//                                                 style={{ width: `${displayProgress}%` }}
//                                             />
//                                         </div>
//                                         <span className="absolute -right-8 top-1/2 -translate-y-1/2 text-xs text-gray-500">
//                                             {Math.round(displayProgress)}%
//                                         </span>
//                                     </div>

//                                     <div className="flex justify-center gap-1">
//                                         {[...Array(3)].map((_, i) => (
//                                             <div
//                                                 key={i}
//                                                 className="w-1.5 h-1.5 rounded-full bg-blue-500 animate-bounce"
//                                                 style={{
//                                                     animationDelay: `${i * 200}ms`
//                                                 }}
//                                             />
//                                         ))}
//                                     </div>
//                                 </div>
//                             ) : (
//                                 <div className="flex flex-col items-center justify-center pt-5 pb-6">
//                                     <Upload className="w-10 h-10 mb-3 text-gray-400" />
//                                     <p className="mb-2 text-sm text-gray-500">
//                                         <span className="font-semibold">Click to upload</span> or drag and drop
//                                     </p>
//                                     <p className="text-xs text-gray-500">PDF, DOCX, or TXT (MAX. 5MB)</p>
//                                 </div>
//                             )}
//                             <input
//                                 type="file"
//                                 className="hidden"
//                                 onChange={handleFileChange}
//                                 accept=".pdf,.docx,.txt"
//                                 disabled={isUploading}
//                             />
//                         </label>
//                     </div>
//                 </div>

//                 {file && !isUploading && (
//                     <div className="flex items-center gap-2 text-sm text-gray-600">
//                         <Check className="w-4 h-4 text-green-500" />
//                         <span>File uploaded: {file.name}</span>
//                     </div>
//                 )}

//                 {error && (
//                     <div className="bg-red-50 border border-red-200 rounded-lg p-4 text-sm text-red-600">
//                         {error}
//                     </div>
//                 )}

//                 <style jsx>{`
//                     @keyframes pulse-border {
//                         0% { opacity: 0.5; }
//                         50% { opacity: 1; }
//                         100% { opacity: 0.5; }
//                     }
//                     .animate-pulse-border {
//                         animation: pulse-border 2s cubic-bezier(0.4, 0, 0.6, 1) infinite;
//                     }
//                 `}</style>
//             </div>
//         );
//     };

//     const steps = [
//         {
//             title: "Upload",
//             component: RenderUploadStep
//         },
//         {
//             title: "Details",
//             component: () => (
//                 <div className="space-y-6">
//                     <div className="text-2xl font-bold text-gray-800">AI Job Enhancement</div>

//                     {/* Job URL Section */}
//                     <div className="bg-blue-50/50 p-6 rounded-xl shadow-sm border border-blue-100">
//                         <label className="text-lg font-semibold text-gray-700 flex items-center gap-2">
//                             <ExternalLink className="w-5 h-5 text-blue-500" />
//                             Post the Link to the Job You Want
//                         </label>
//                         <input
//                             type="url"
//                             placeholder="https://www.linkedin.com/jobs/view/..."
//                             value={jobLink}
//                             onClick={() => setIsURLModalOpen(true)}
//                             className="w-full px-4 py-3 mt-2 border rounded-xl bg-white/70"
//                             readOnly
//                         />
//                         <div className="mt-2">
//                             <p className="text-xs text-gray-500 mb-2">Supported job sites:</p>
//                             <div className="grid grid-cols-7 gap-1">
//                                 {/* LinkedIn */}
//                                 <div onClick={() => setIsURLModalOpen(true)}
//                                     className="flex items-center justify-center p-1.5 rounded-lg hover:bg-blue-50 transition-all cursor-pointer">
//                                     <svg viewBox="0 0 40 40" className="w-5 h-5" fill="none">
//                                         <rect width="40" height="40" rx="8" fill="#0A66C2" />
//                                         <path d="M15 16h-4v12h4V16zm-2-6.8a2.4 2.4 0 110 4.8 2.4 2.4 0 010-4.8zM29 28h-4v-6.2c0-3.2-4-3-4 0V28h-4V16h4v1.8c1.4-2.6 8-2.8 8 2.5V28z" fill="white" />
//                                     </svg>
//                                 </div>

//                                 {/* Indeed */}
//                                 <div onClick={() => setIsURLModalOpen(true)}
//                                     className="flex items-center justify-center p-1.5 rounded-lg hover:bg-blue-50 transition-all cursor-pointer">
//                                     <svg viewBox="0 0 40 40" className="w-5 h-5" fill="none">
//                                         <rect width="40" height="40" rx="8" fill="#2164F3" />
//                                         <path d="M12 10h6v20h-6z" fill="#FFFFFF" />
//                                         <path d="M22 10h6v20h-6z" fill="#FFFFFF" />
//                                     </svg>
//                                 </div>

//                                 {/* Glassdoor */}
//                                 <div onClick={() => setIsURLModalOpen(true)}
//                                     className="flex items-center justify-center p-1.5 rounded-lg hover:bg-blue-50 transition-all cursor-pointer">
//                                     <svg viewBox="0 0 40 40" className="w-5 h-5" fill="none">
//                                         <rect width="40" height="40" rx="8" fill="#0CAA41" />
//                                         <path d="M20 6c-7.7 0-14 6.3-14 14s6.3 14 14 14 14-6.3 14-14S27.7 6 20 6zm0 22c-4.4 0-8-3.6-8-8s3.6-8 8-8 8 3.6 8 8-3.6 8-8 8zm0-13c-2.8 0-5 2.2-5 5s2.2 5 5 5 5-2.2 5-5-2.2-5-5-5z" fill="white" />
//                                     </svg>
//                                 </div>

//                                 {/* Dice */}
//                                 <div onClick={() => setIsURLModalOpen(true)}
//                                     className="flex items-center justify-center p-1.5 rounded-lg hover:bg-blue-50 transition-all cursor-pointer">
//                                     <svg viewBox="0 0 40 40" className="w-5 h-5" fill="none">
//                                         <rect width="40" height="40" rx="8" fill="#EB1C26" />
//                                         <path d="M10 10h20v20H10z" fill="white" />
//                                         <circle cx="15" cy="15" r="2" fill="#EB1C26" />
//                                         <circle cx="25" cy="15" r="2" fill="#EB1C26" />
//                                         <circle cx="15" cy="25" r="2" fill="#EB1C26" />
//                                         <circle cx="25" cy="25" r="2" fill="#EB1C26" />
//                                     </svg>
//                                 </div>

//                                 {/* Monster */}
//                                 <div onClick={() => setIsURLModalOpen(true)}
//                                     className="flex items-center justify-center p-1.5 rounded-lg hover:bg-blue-50 transition-all cursor-pointer">
//                                     <svg viewBox="0 0 40 40" className="w-5 h-5" fill="none">
//                                         <rect width="40" height="40" rx="8" fill="#6E45A5" />
//                                         <path d="M20 10c-5.5 0-10 4.5-10 10s4.5 10 10 10 10-4.5 10-10-4.5-10-10-10zm0 15c-2.8 0-5-2.2-5-5s2.2-5 5-5 5 2.2 5 5-2.2 5-5 5z" fill="white" />
//                                     </svg>
//                                 </div>

//                                 {/* ZipRecruiter */}
//                                 <div onClick={() => setIsURLModalOpen(true)}
//                                     className="flex items-center justify-center p-1.5 rounded-lg hover:bg-blue-50 transition-all cursor-pointer">
//                                     <svg viewBox="0 0 40 40" className="w-5 h-5" fill="none">
//                                         <rect width="40" height="40" rx="8" fill="#1D2C4D" />
//                                         <path d="M8 20.5L15 28l3-3-4-4 4-4-3-3L8 20.5z" fill="white" />
//                                         <path d="M32 20.5L25 28l-3-3 4-4-4-4 3-3L32 20.5z" fill="white" />
//                                     </svg>
//                                 </div>

//                                 {/* CareerBuilder */}
//                                 <div onClick={() => setIsURLModalOpen(true)}
//                                     className="flex items-center justify-center p-1.5 rounded-lg hover:bg-blue-50 transition-all cursor-pointer">
//                                     <svg viewBox="0 0 40 40" className="w-5 h-5" fill="none">
//                                         <rect width="40" height="40" rx="8" fill="#3AB549" />
//                                         <path d="M20 8c-6.6 0-12 5.4-12 12s5.4 12 12 12 12-5.4 12-12S26.6 8 20 8zm0 18c-3.3 0-6-2.7-6-6s2.7-6 6-6 6 2.7 6 6-2.7 6-6 6z" fill="white" />
//                                         <path d="M20 16c-2.2 0-4 1.8-4 4s1.8 4 4 4 4-1.8 4-4-1.8-4-4-4zm0 6c-1.1 0-2-.9-2-2s.9-2 2-2 2 .9 2 2-.9 2-2 2z" fill="white" />
//                                     </svg>
//                                 </div>
//                                 {/* Add other job site icons similarly */}
//                             </div>
//                         </div>
//                         {jobLink && !isScrapingJob && (
//                             <button
//                                 onClick={handleSearch}
//                                 className="w-full mt-4 bg-gradient-to-r from-green-500 to-blue-500 hover:from-green-600 hover:to-blue-600 text-white px-4 py-3 rounded-xl flex items-center justify-center gap-2 transition-all duration-300"
//                             >
//                                 <ArrowDown className="w-4 h-4" />
//                                 Analyze Job
//                             </button>
//                         )}
//                     </div>

//                     {/* Manual Job Description Input */}
//                     <div className="relative">
//                         <label className="block text-sm font-medium text-gray-700 mb-2">
//                             Or copy & past the Job Description
//                         </label>
//                         <div className="relative">
//                             <ScrollText className="absolute left-3 top-3 w-5 h-5 text-gray-400" />
//                             <textarea
//                                 value={jobText}
//                                 onChange={(e) => setJobText(e.target.value)}
//                                 placeholder="Paste the job description here..."
//                                 className="w-full pl-10 pr-4 py-2 border rounded-lg h-48 resize-none"
//                             />
//                         </div>
//                     </div>

//                     {/* Add the Enhance Button here */}
//                     {jobText && !isEnhancing && (
//                         <button
//                             onClick={handleEnhance}
//                             className="w-full bg-gradient-to-r from-green-500 to-blue-500 hover:from-green-600 hover:to-blue-600 text-white px-4 py-3 rounded-xl flex items-center justify-center gap-2 transition-all duration-300"
//                         >
//                             <ArrowDown className="w-4 h-4" />
//                             Enhance Resume
//                         </button>
//                     )}

//                     {/* Loading State */}
//                     {isEnhancing && (
//                         <div className="w-full bg-blue-100 text-blue-700 px-4 py-3 rounded-xl flex items-center justify-center gap-2">
//                             <Loader2 className="w-4 h-4 animate-spin" />
//                             Enhancing your resume...
//                         </div>
//                     )}

//                     {/* Error Message */}
//                     {error && (
//                         <div className="mt-4 bg-red-50 border border-red-200 rounded-xl p-4">
//                             <div className="flex items-center gap-2 text-red-600">
//                                 <AlertCircle className="w-5 h-5" />
//                                 <span>{error}</span>
//                             </div>
//                         </div>
//                     )}

//                     {/* Processing Status */}
//                     {isEnhancing && (
//                         <div className="bg-white/50 p-6 rounded-xl shadow-sm border border-blue-100">
//                             <div className="flex items-center justify-between mb-4">
//                                 <h3 className="text-lg font-semibold text-blue-700">
//                                     Enhancing your resume...
//                                 </h3>
//                                 <Loader2 className="w-5 h-5 animate-spin text-blue-500" />
//                             </div>
//                             <ProcessingSteps
//                                 currentStep={processingStep}
//                                 steps={[
//                                     "Analyzing job requirements",
//                                     "Identifying key skills",
//                                     "Optimizing experience",
//                                     "Tailoring content",
//                                     "Finalizing enhancements"
//                                 ]}
//                                 progress={processingProgress}
//                             />
//                         </div>
//                     )}

//                     {/* Processing Status */}
//                     {isScrapingJob && (
//                         <div className="bg-white/50 p-6 rounded-xl shadow-sm border border-blue-100">
//                             <div className="flex items-center justify-between mb-4">
//                                 <h3 className="text-lg font-semibold text-blue-700">
//                                     {loadingMessage || "Analyzing job description..."}
//                                 </h3>
//                                 <Loader2 className="w-5 h-5 animate-spin text-blue-500" />
//                             </div>
//                             <ProcessingSteps
//                                 currentStep={processingStep}
//                                 steps={processingSteps}
//                                 progress={processingProgress}
//                             />
//                         </div>
//                     )}

//                     {/* Job Analysis Results */}
//                     {jobAnalysis && (
//                         <div className="space-y-4">
//                             <div className="bg-blue-50 p-4 rounded-lg">
//                                 <h3 className="font-medium text-blue-800 mb-2">Target Role</h3>
//                                 <p className="text-sm text-blue-700">{jobAnalysis.target_role}</p>
//                             </div>
//                         </div>
//                     )}

//                     {/* Error Message */}
//                     {scrapeError && (
//                         <div className="bg-red-50 border border-red-200 rounded-xl p-4">
//                             <div className="flex items-center gap-2 text-red-600">
//                                 <AlertCircle className="w-5 h-5" />
//                                 <span>{scrapeError}</span>
//                             </div>
//                         </div>
//                     )}
//                 </div>
//             )
//         },
//         {
//             title: "Theme",
//             component: () => (
//                 <ThemeStep
//                     basics={basics}
//                     work={work}
//                     education={education}
//                     selectedTheme={selectedTheme}
//                     setSelectedTheme={setSelectedTheme}
//                     themes={themes}
//                     previewHtml={previewHtml}
//                     loading={loading}
//                     setLoading={setLoading}
//                 />

//             )
//         },
//         {
//             title: "Preview",
//             component: () => null
//         }
//     ];

//     return (
//         <div className="min-h-screen bg-gray-50 p-8">
//             <div className="max-w-7xl mx-auto">
//                 {/* Header */}
//                 <div className="text-center mb-12">
//                     <h1 className="text-4xl font-bold text-gray-900 mb-2">
//                         AI Resume Builder
//                     </h1>
//                     <p className="text-gray-600">
//                         Create the perfect resume for your next job
//                     </p>
//                 </div>

//                 {/* Main Content */}
//                 <div className="bg-white rounded-2xl shadow-xl p-8">
//                     {/* Progress Bar */}
//                     <div className="mb-8">
//                         <div className="flex justify-between mb-2">
//                             {steps.map((step, index) => (
//                                 <div
//                                     key={index}
//                                     className={`flex items-center ${index === steps.length - 1 ? '' : 'flex-1'}`}
//                                 >
//                                     <div
//                                         className={`w-8 h-8 rounded-full flex items-center justify-center ${index < currentStep
//                                             ? 'bg-green-500 text-white'
//                                             : index === currentStep
//                                                 ? 'bg-blue-500 text-white'
//                                                 : 'bg-gray-200'
//                                             }`}
//                                     >
//                                         {index < currentStep ? (
//                                             <Check className="w-5 h-5" />
//                                         ) : (
//                                             index + 1
//                                         )}
//                                     </div>
//                                     {index < steps.length - 1 && (
//                                         <div
//                                             className={`h-1 flex-1 mx-2 ${index < currentStep
//                                                 ? 'bg-green-500'
//                                                 : 'bg-gray-200'
//                                                 }`}
//                                         />
//                                     )}
//                                 </div>
//                             ))}
//                         </div>
//                         <div className="flex justify-between px-2">
//                             {steps.map((step, index) => (
//                                 <span key={index} className="text-sm text-gray-500">
//                                     {step.title}
//                                 </span>
//                             ))}
//                         </div>
//                     </div>

//                     {/* Current Step */}
//                     <div className="mb-12">
//                         {steps[currentStep].component()}

//                         {/* Navigation Buttons */}
//                         <div className="flex justify-between mt-8">
//                             <button
//                                 onClick={() => setCurrentStep(prev => Math.max(prev - 1, 0))}
//                                 disabled={currentStep === 0}
//                                 className="px-6 py-2 border rounded-lg flex items-center gap-2 disabled:opacity-50 hover:bg-gray-50 transition-colors"
//                             >
//                                 <ChevronLeft className="w-4 h-4" />
//                                 Previous
//                             </button>

//                             <button
//                                 onClick={() => setCurrentStep(prev => Math.min(prev + 1, steps.length - 1))}
//                                 disabled={currentStep === steps.length - 1 || isUploading}
//                                 className="px-6 py-2 bg-blue-500 text-white rounded-lg flex items-center gap-2 disabled:opacity-50 hover:bg-blue-600 transition-colors"
//                             >
//                                 Next
//                                 <ChevronRight className="w-4 h-4" />
//                             </button>
//                         </div>
//                     </div>

//                     {/* Preview Section */}
//                     <div className="mt-8 border-t pt-8">
//                         <div className="space-y-6">
//                             {/* Header with Export Button */}
//                             <div className="flex items-center justify-between mb-6">
//                                 <div className="flex items-center gap-4">
//                                     <h3 className="text-xl font-bold text-gray-800">
//                                         Resume Preview
//                                     </h3>
//                                     <div className="flex bg-gray-100 rounded-lg p-1">
//                                         <button
//                                             onClick={() => setPreviewMode('edit')}
//                                             className={`px-4 py-2 rounded-md flex items-center gap-2 transition-colors ${previewMode === 'edit'
//                                                 ? 'bg-white shadow-sm text-blue-600'
//                                                 : 'text-gray-600 hover:text-gray-900'
//                                                 }`}
//                                         >
//                                             <Edit2 className="w-4 h-4" />
//                                             Edit
//                                         </button>
//                                         <button
//                                             onClick={() => setPreviewMode('preview')}
//                                             className={`px-4 py-2 rounded-md flex items-center gap-2 transition-colors ${previewMode === 'preview'
//                                                 ? 'bg-white shadow-sm text-blue-600'
//                                                 : 'text-gray-600 hover:text-gray-900'
//                                                 }`}
//                                         >
//                                             <Eye className="w-4 h-4" />
//                                             Preview
//                                         </button>
//                                     </div>
//                                 </div>
//                                 <div className="flex items-center gap-4">
//                                     <div className="flex items-center gap-2 bg-blue-50 px-3 py-1 rounded-full">
//                                         <Star className="w-4 h-4 text-blue-500" />
//                                         <span className="text-blue-600 font-medium">
//                                             {resumeScore}%
//                                         </span>
//                                     </div>
//                                     <button
//                                         onClick={handleExportPDF}
//                                         className="flex items-center gap-2 px-4 py-2 bg-blue-500 text-white rounded-lg hover:bg-blue-600 transition-colors"
//                                     >
//                                         <Download className="w-4 h-4" />
//                                         Export PDF
//                                     </button>
//                                 </div>
//                             </div>

//                             {/* Grid Layout for Edit/Preview */}
//                             <div className={`grid ${previewMode === 'preview' ? 'grid-cols-1' : 'grid-cols-2'} gap-6`}>
//                                 {/* Edit Form */}
//                                 {previewMode === 'edit' && (
//                                     <div className="space-y-6">
//                                         <div className="bg-gray-50 border-b p-4 rounded-lg">
//                                             <input
//                                                 type="text"
//                                                 value={basics.name || ''}
//                                                 onChange={(e) => setBasics(prev => ({ ...prev, name: e.target.value }))}
//                                                 placeholder="Your Name"
//                                                 className="text-lg font-medium text-gray-900 w-full bg-transparent border-none focus:ring-2 focus:ring-blue-500 rounded px-2"
//                                             />
//                                             <input
//                                                 type="text"
//                                                 value={jobAnalysis?.target_role || basics.label || ''}
//                                                 onChange={(e) => setBasics(prev => ({ ...prev, label: e.target.value }))}
//                                                 placeholder="Your Title"
//                                                 className="text-sm text-gray-600 w-full bg-transparent border-none focus:ring-2 focus:ring-blue-500 rounded px-2 mt-1"
//                                             />
//                                         </div>

//                                         <div className="p-4 space-y-6">
//                                             {/* Contact Info */}
//                                             <div className="flex gap-4 text-sm text-gray-600">
//                                                 <div className="flex items-center gap-1">
//                                                     <FileText className="w-4 h-4" />
//                                                     <input
//                                                         type="email"
//                                                         value={basics.email || ''}
//                                                         onChange={(e) => setBasics(prev => ({ ...prev, email: e.target.value }))}
//                                                         placeholder="Email"
//                                                         className="bg-transparent border-none focus:ring-2 focus:ring-blue-500 rounded px-2"
//                                                     />
//                                                 </div>
//                                                 <div className="flex items-center gap-1">
//                                                     <FileText className="w-4 h-4" />
//                                                     <input
//                                                         type="tel"
//                                                         value={basics.phone || ''}
//                                                         onChange={(e) => setBasics(prev => ({ ...prev, phone: e.target.value }))}
//                                                         placeholder="Phone"
//                                                         className="bg-transparent border-none focus:ring-2 focus:ring-blue-500 rounded px-2"
//                                                     />
//                                                 </div>
//                                             </div>

//                                             {/* Summary */}
//                                             <div>
//                                                 <textarea
//                                                     value={basics.summary || ''}
//                                                     onChange={(e) => setBasics(prev => ({ ...prev, summary: e.target.value }))}
//                                                     placeholder="Professional Summary..."
//                                                     className="w-full text-sm text-gray-700 bg-transparent border rounded-md focus:ring-2 focus:ring-blue-500 p-3 min-h-[120px]"
//                                                 />
//                                             </div>

//                                             {/* Work Experience */}
//                                             {work.length > 0 && (
//                                                 <div className="space-y-4">
//                                                     <div className="font-medium text-gray-900 flex justify-between items-center">
//                                                         <span>Experience</span>
//                                                         <button
//                                                             onClick={() => setWork([...work, { position: '', name: '', startDate: '', endDate: '', highlights: [] }])}
//                                                             className="text-blue-500 hover:text-blue-600"
//                                                         >
//                                                             <PlusCircle className="w-4 h-4" />
//                                                         </button>
//                                                     </div>
//                                                     {work.map((job, index) => (
//                                                         <div key={index} className="text-sm space-y-3 relative group border rounded-lg p-4 hover:shadow-sm transition-shadow">
//                                                             <button
//                                                                 onClick={() => setWork(work.filter((_, i) => i !== index))}
//                                                                 className="absolute -right-2 -top-2 opacity-0 group-hover:opacity-100 transition-opacity"
//                                                             >
//                                                                 <Trash2 className="w-4 h-4 text-red-500 hover:text-red-600" />
//                                                             </button>
//                                                             <input
//                                                                 value={job.position || ''}
//                                                                 onChange={(e) => handleArrayChange(setWork, index, 'position', e.target.value)}
//                                                                 placeholder="Position"
//                                                                 className="font-medium w-full bg-transparent border-none focus:ring-2 focus:ring-blue-500 rounded px-2"
//                                                             />
//                                                             <input
//                                                                 value={job.name || ''}
//                                                                 onChange={(e) => handleArrayChange(setWork, index, 'name', e.target.value)}
//                                                                 placeholder="Company"
//                                                                 className="text-gray-600 w-full bg-transparent border-none focus:ring-2 focus:ring-blue-500 rounded px-2"
//                                                             />
//                                                             <div className="flex gap-2">
//                                                                 <input
//                                                                     type="date"
//                                                                     value={job.startDate || ''}
//                                                                     onChange={(e) => handleArrayChange(setWork, index, 'startDate', e.target.value)}
//                                                                     className="text-gray-500 bg-transparent border rounded px-2"
//                                                                 />
//                                                                 -
//                                                                 <input
//                                                                     type="date"
//                                                                     value={job.endDate || ''}
//                                                                     onChange={(e) => handleArrayChange(setWork, index, 'endDate', e.target.value)}
//                                                                     className="text-gray-500 bg-transparent border rounded px-2"
//                                                                 />
//                                                             </div>
//                                                             <div className="space-y-2">
//                                                                 {job.highlights.map((highlight, idx) => (
//                                                                     <div key={idx} className="relative group flex gap-2 items-center">
//                                                                         <span className="text-gray-400">•</span>
//                                                                         <input
//                                                                             value={highlight}
//                                                                             onChange={(e) => {
//                                                                                 const newWork = [...work];
//                                                                                 newWork[index].highlights[idx] = e.target.value;
//                                                                                 setWork(newWork);
//                                                                             }}
//                                                                             className="flex-1 text-gray-700 bg-transparent border-none focus:ring-2 focus:ring-blue-500 rounded px-2 py-1"
//                                                                         />
//                                                                     </div>
//                                                                 ))}
//                                                                 <button
//                                                                     onClick={() => {
//                                                                         const newWork = [...work];
//                                                                         newWork[index].highlights.push('');
//                                                                         setWork(newWork);
//                                                                     }}
//                                                                     className="text-sm text-blue-500 hover:text-blue-600 ml-6"
//                                                                 >
//                                                                     + Add Highlight
//                                                                 </button>
//                                                             </div>
//                                                         </div>
//                                                     ))}
//                                                 </div>
//                                             )}

//                                             {/* Education */}
//                                             {education.length > 0 && (
//                                                 <div className="space-y-4">
//                                                     <div className="font-medium text-gray-900 flex justify-between items-center">
//                                                         <span>Education</span>
//                                                         <button
//                                                             onClick={() => setEducation([...education, { institution: '', area: '', studyType: '', startDate: '', endDate: '' }])}
//                                                             className="text-blue-500 hover:text-blue-600"
//                                                         >
//                                                             <PlusCircle className="w-4 h-4" />
//                                                         </button>
//                                                     </div>
//                                                     {education.map((edu, index) => (
//                                                         <div key={index} className="text-sm space-y-2 relative group border rounded-lg p-4 hover:shadow-sm transition-shadow">
//                                                             <button
//                                                                 onClick={() => setEducation(education.filter((_, i) => i !== index))}
//                                                                 className="absolute -right-2 -top-2 opacity-0 group-hover:opacity-100 transition-opacity"
//                                                             >
//                                                                 <Trash2 className="w-4 h-4 text-red-500 hover:text-red-600" />
//                                                             </button>
//                                                             <input
//                                                                 value={edu.studyType || ''}
//                                                                 onChange={(e) => handleArrayChange(setEducation, index, 'studyType', e.target.value)}
//                                                                 placeholder="Degree Type"
//                                                                 className="font-medium w-full bg-transparent border-none focus:ring-2 focus:ring-blue-500 rounded px-2"
//                                                             />
//                                                             <input
//                                                                 value={edu.area || ''}
//                                                                 onChange={(e) => handleArrayChange(setEducation, index, 'area', e.target.value)}
//                                                                 placeholder="Field of Study"
//                                                                 className="w-full bg-transparent border-none focus:ring-2 focus:ring-blue-500 rounded px-2"
//                                                             />
//                                                             <input
//                                                                 value={edu.institution || ''}
//                                                                 onChange={(e) => handleArrayChange(setEducation, index, 'institution', e.target.value)}
//                                                                 placeholder="Institution"
//                                                                 className="text-gray-600 w-full bg-transparent border-none focus:ring-2 focus:ring-blue-500 rounded px-2"
//                                                             />
//                                                             <div className="flex gap-2">
//                                                                 <input
//                                                                     type="date"
//                                                                     value={edu.startDate || ''}
//                                                                     onChange={(e) => handleArrayChange(setEducation, index, 'startDate', e.target.value)}
//                                                                     className="text-gray-500 bg-transparent border rounded px-2"
//                                                                 />
//                                                                 -
//                                                                 <input
//                                                                     type="date"
//                                                                     value={edu.endDate || ''}
//                                                                     onChange={(e) => handleArrayChange(setEducation, index, 'endDate', e.target.value)}
//                                                                     className="text-gray-500 bg-transparent border rounded px-2"
//                                                                 />
//                                                             </div>
//                                                         </div>
//                                                     ))}
//                                                 </div>
//                                             )}
//                                         </div>
//                                     </div>
//                                 )}

//                                 {/* Preview Panel */}
//                                 <div className={previewMode === 'preview' ? 'col-span-2' : ''}>
//                                     <div className="border rounded-xl overflow-hidden bg-white h-[800px]">
//                                         {loading ? (
//                                             <div className="h-full flex items-center justify-center">
//                                                 <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-blue-500" />
//                                             </div>
//                                         ) : (
//                                             <iframe
//                                                 srcDoc={previewHtml}
//                                                 className="w-full h-full"
//                                                 title="Resume Preview"
//                                             />
//                                         )}
//                                     </div>
//                                 </div>


//                             </div>


//                             {/* Theme Selector - Only visible in preview mode */}
//                             {previewMode === 'preview' && (
//                                 <div className="mt-6">
//                                     <h4 className="text-sm font-medium text-gray-700 mb-3">Available Templates</h4>
//                                     <div className="grid grid-cols-3 gap-4">
//                                         {themes.map((theme) => (
//                                             <button
//                                                 key={theme.id}
//                                                 onClick={() => setSelectedTheme(theme.id)}
//                                                 className={`p-4 rounded-lg border transition-all ${selectedTheme === theme.id
//                                                     ? 'border-blue-500 bg-blue-50 text-blue-700'
//                                                     : 'border-gray-200 hover:border-blue-200 text-gray-600'
//                                                     }`}
//                                             >
//                                                 <div className="text-sm font-medium">{theme.name}</div>
//                                                 <div className="text-xs mt-1 text-gray-500">{theme.description}</div>
//                                             </button>
//                                         ))}
//                                     </div>

//                                 </div>



//                             )}
//                         </div>
//                     </div>
//                 </div>
//                 <JobURLSelectorModal
//                     isOpen={isURLModalOpen}
//                     onClose={() => setIsURLModalOpen(false)}
//                     onSelectURL={handleJobLinkSelect}
//                 />
//             </div>
//         </div>
//     );
// }


// export default function ResumeBuilder() {
//     // Step tracking
//     const [currentStep, setCurrentStep] = useState(0);
//     const [uploadProgress, setUploadProgress] = useState(0);
//     const [isUploading, setIsUploading] = useState(false);
//     const [error, setError] = useState(null);
//     const [activeTemplateIndex, setActiveTemplateIndex] = useState(0);
//     const [selectedTemplate, setSelectedTemplate] = useState(null);
//     const templateContainerRef = useRef(null);
//     // File and preview state
//     const [file, setFile] = useState(null);
//     const [resumeContent, setResumeContent] = useState('');
//     const [activeSection, setActiveSection] = useState("basics");

//     // Enhancement state
//     const [isEnhancing, setIsEnhancing] = useState(false);
//     const [enhancedData, setEnhancedData] = useState(null);
//     const [jobAnalysis, setJobAnalysis] = useState(null);
//     const [analysisStep, setAnalysStep] = useState(0);
//     const [simulatedProgress, setSimulatedProgress] = useState(0);

//     useEffect(() => {
//         let progressInterval;
//         if (isUploading) {
//             progressInterval = setInterval(() => {
//                 setSimulatedProgress(prev => {
//                     if (prev < 30) return prev + 0.8;
//                     if (prev < 60) return prev + 0.5;
//                     if (prev < 85) return prev + 0.2;
//                     if (prev < 90) return prev + 0.1;
//                     return prev;
//                 });
//             }, 100);

//             if (uploadProgress === 100) {
//                 clearInterval(progressInterval);
//                 setSimulatedProgress(100);
//             }
//         }
//         return () => clearInterval(progressInterval);
//     }, [isUploading, uploadProgress]);

//     useEffect(() => {
//         let analysisInterval;
//         if (isUploading) {
//             analysisInterval = setInterval(() => {
//                 setAnalysStep(prev => (prev + 1) % analysisSteps.length);
//             }, 2000);
//         }
//         return () => clearInterval(analysisInterval);
//     }, [isUploading]);

//     const analysisSteps = [
//         "Initializing document scanner...",
//         "Reading document structure...",
//         "Extracting work experience...",
//         "Analyzing job titles and roles...",
//         "Processing educational background...",
//         "Identifying key skills and technologies...",
//         "Parsing professional achievements...",
//         "Evaluating career progression...",
//         "Organizing resume sections...",
//         "Finalizing resume analysis..."
//     ];

//     // Basic information state
//     const [basics, setBasics] = useState({
//         name: "",
//         label: "",
//         image: "",
//         email: "",
//         phone: "",
//         url: "",
//         summary: "",
//         location: {
//             address: "",
//             postalCode: "",
//             city: "",
//             countryCode: "",
//             region: ""
//         },
//         profiles: []
//     });

//     // Resume sections state
//     const [work, setWork] = useState([{
//         name: "",
//         position: "",
//         original_position: "", // Added to track original position
//         url: "",
//         startDate: "",
//         endDate: "",
//         summary: "",
//         highlights: []
//     }]);

//     const [education, setEducation] = useState([{
//         institution: "",
//         url: "",
//         area: "",
//         studyType: "",
//         startDate: "",
//         endDate: "",
//         score: "",
//         courses: []
//     }]);

//     const [skills, setSkills] = useState([{
//         name: "",
//         level: "",
//         keywords: []
//     }]);

//     const [projects, setProjects] = useState([{
//         name: "",
//         startDate: "",
//         endDate: "",
//         description: "",
//         highlights: [],
//         url: ""
//     }]);

//     // Template and job details state
//     const [jobLink, setJobLink] = useState('');
//     const [jobText, setJobText] = useState('');
//     const [pufferyLevel, setPufferyLevel] = useState(0.5);
//     const [isLoading, setIsLoading] = useState(false);
//     const [resumeScore, setResumeScore] = useState(0);
//     const handleTemplateSelect = (index) => {
//         setActiveTemplateIndex(index);
//         setSelectedTemplate(resumeTemplates[index].id);
//     };
//     // Upload handler
//     const handleUpload = async (selectedFile) => {
//         setIsUploading(true);
//         setError(null);
//         setUploadProgress(0);

//         try {
//             const userId = localStorage.getItem('user_id') || `user_${Math.random().toString(36).slice(2, 9)}`;

//             // Create FormData
//             const formData = new FormData();
//             formData.append('file', selectedFile);
//             formData.append('user_id', userId);
//             formData.append('job_description', '');
//             formData.append('enhance', 'false');
//             formData.append('mode', 'new');

//             // Progress simulation
//             const progressInterval = setInterval(() => {
//                 setUploadProgress(prev => Math.min(prev + 10, 90));
//             }, 500);

//             const response = await fetch('https://db8b-74-88-7-3.ngrok-free.app/api/v1/resume/upload_small', {
//                 method: 'POST',
//                 body: formData,
//             });

//             clearInterval(progressInterval);
//             setUploadProgress(100);

//             if (!response.ok) {
//                 const errorData = await response.json();
//                 throw new Error(`Upload failed: ${JSON.stringify(errorData, null, 2)}`);
//             }

//             const data = await response.json();

//             // Store IDs
//             if (data.record_id) {
//                 localStorage.setItem('record_id', data.record_id);
//             }
//             localStorage.setItem('user_id', userId);

//             if (data.resume_json) {
//                 // Format all dates in work experience
//                 const formattedWork = data.resume_json.work.map(job => ({
//                     ...job,
//                     original_position: job.position,
//                     startDate: formatDate(job.startDate) || "2000-01-01",  // Provide default dates if null
//                     endDate: formatDate(job.endDate) || new Date().toISOString().split('T')[0]  // Use current date if null
//                 }));

//                 // Format all dates in education
//                 const formattedEducation = (data.resume_json.education || []).map(edu => ({
//                     ...edu,
//                     startDate: formatDate(edu.startDate) || "2000-01-01",  // Provide default dates if null
//                     endDate: formatDate(edu.endDate) || new Date().toISOString().split('T')[0]  // Use current date if null
//                 }));

//                 // Update state with formatted dates
//                 setBasics(data.resume_json.basics || basics);
//                 setWork(formattedWork);
//                 setEducation(formattedEducation);
//                 setResumeContent(data.resume_text || '');

//                 setCurrentStep(1);
//             }

//         } catch (error) {
//             console.error('Upload error:', error);
//             setError(`Failed to upload resume: ${error.message}`);
//         } finally {
//             setIsUploading(false);
//         }
//     };
//     const formatDate = (dateString) => {
//         if (!dateString) return null;

//         // Clean the input
//         const cleaned = String(dateString).trim();

//         try {
//             // Handle year-only format (e.g., "2020")
//             if (/^\d{4}$/.test(cleaned)) {
//                 return `${cleaned}-01-01`;
//             }

//             // Handle year-month format (e.g., "2020-05")
//             if (/^\d{4}-\d{2}$/.test(cleaned)) {
//                 return `${cleaned}-01`;
//             }

//             // Handle full date format (e.g., "2020-05-15")
//             if (/^\d{4}-\d{2}-\d{2}$/.test(cleaned)) {
//                 return cleaned;
//             }

//             // Handle text month format (e.g., "Jan 2020" or "January 2020")
//             const monthMatch = cleaned.match(/([a-zA-Z]+)\s+(\d{4})/);
//             if (monthMatch) {
//                 const month = getMonthNumber(monthMatch[1]);
//                 const year = monthMatch[2];
//                 return `${year}-${month}-01`;
//             }

//             console.warn(`Invalid date format: ${dateString}, defaulting to null`);
//             return null;
//         } catch (error) {
//             console.error(`Error formatting date: ${dateString}`, error);
//             return null;
//         }
//     };

//     const getMonthNumber = (month) => {
//         const months = {
//             'jan': '01', 'january': '01',
//             'feb': '02', 'february': '02',
//             'mar': '03', 'march': '03',
//             'apr': '04', 'april': '04',
//             'may': '05',
//             'jun': '06', 'june': '06',
//             'jul': '07', 'july': '07',
//             'aug': '08', 'august': '08',
//             'sep': '09', 'september': '09',
//             'oct': '10', 'october': '10',
//             'nov': '11', 'november': '11',
//             'dec': '12', 'december': '12'
//         };

//         const monthKey = month.toLowerCase().slice(0, 3);
//         return months[monthKey] || '01';
//     };

//     // Enhancement handler
//     const handleEnhance = async () => {
//         setIsEnhancing(true);
//         setError(null);

//         try {
//             // Get required fields with validation
//             const userId = localStorage.getItem('user_id');
//             const recordId = localStorage.getItem('record_id');

//             if (!userId || !recordId || !jobText) {
//                 console.log('Missing required fields:', { userId, recordId, jobText });
//                 throw new Error('Missing required fields: user_id, record_id, or job_description');
//             }

//             // Create FormData object
//             const formData = new FormData();
//             formData.append('user_id', userId);
//             formData.append('job_description', jobText);
//             formData.append('record_id', recordId);
//             formData.append('match_job_titles', 'true'); // Optional: set to true if you want to match job titles

//             console.log('Sending enhance request with:', {
//                 user_id: userId,
//                 record_id: recordId,
//                 job_description_length: jobText.length,
//             });

//             const response = await fetch('https://db8b-74-88-7-3.ngrok-free.app/api/v1/resume/enhance', {
//                 method: 'POST',
//                 body: formData // Send as FormData, not JSON
//             });

//             if (!response.ok) {
//                 const errorData = await response.json();
//                 throw new Error(`Enhancement failed: ${JSON.stringify(errorData)}`);
//             }

//             const data = await response.json();

//             if (data.success) {
//                 // Update work experience with enhanced data
//                 if (data.enhanced_resume?.work) {
//                     const enhancedWork = data.enhanced_resume.work.map(job => ({
//                         ...job,
//                         original_position: job.original_position || job.position,
//                         startDate: formatDate(job.startDate),
//                         endDate: formatDate(job.endDate)
//                     }));
//                     setWork(enhancedWork);
//                 }

//                 // Update job analysis
//                 if (data.job_analysis) {
//                     setJobAnalysis(data.job_analysis);
//                 }

//                 // Update resume score
//                 setResumeScore(prev => Math.min(prev + 35, 100));

//                 // Create enhanced content preview
//                 const enhancedContent = `
//     ${basics.name}
//     ${data.job_analysis?.target_role || basics.label}

//     PROFESSIONAL SUMMARY
//     ${basics.summary}

//     WORK EXPERIENCE
//     ${data.enhanced_resume?.work?.map(job => `
//     ${job.position}${job.original_position ? ` (Originally: ${job.original_position})` : ''}
//     ${job.name}
//     ${formatDate(job.startDate)} - ${job.endDate ? formatDate(job.endDate) : 'Present'}
//     ${job.highlights.join('\n')}
//     `).join('\n') || ''}
//     `;

//                 setResumeContent(enhancedContent);
//             }

//         } catch (error) {
//             console.error('Enhancement error:', error);
//             const errorMessage = error.message || 'Failed to enhance resume';
//             setError(errorMessage);
//         } finally {
//             setIsEnhancing(false);
//         }
//     };

//     // File handlers
//     const handleFileChange = (e) => {
//         if (e.target.files?.[0]) {
//             setFile(e.target.files[0]);
//             handleUpload(e.target.files[0]);
//         }
//     };

//     const handleDrop = useCallback((e) => {
//         e.preventDefault();
//         const droppedFile = e.dataTransfer.files[0];
//         if (droppedFile) {
//             setFile(droppedFile);
//             handleUpload(droppedFile);
//         }
//     }, []);



//     const handleDragOver = useCallback((e) => {
//         e.preventDefault();
//     }, []);

//     // Form field handlers
//     const handleBasicsChange = (field, value) => {
//         setBasics(prev => ({ ...prev, [field]: value }));
//     };

//     const handleLocationChange = (field, value) => {
//         setBasics(prev => ({
//             ...prev,
//             location: { ...prev.location, [field]: value }
//         }));
//     };

//     const handleArrayChange = (
//         setter,
//         index,
//         field,
//         value
//     ) => {
//         setter(prev => {
//             const newArray = [...prev];
//             newArray[index] = { ...newArray[index], [field]: value };
//             return newArray;
//         });
//     };

//     // Template selection
//     const templates = [
//         { id: 'modern', name: 'Modern', icon: '📄' },
//         { id: 'professional', name: 'Professional', icon: '💼' },
//         { id: 'creative', name: 'Creative', icon: '🎨' },
//         { id: 'technical', name: 'Technical', icon: '💻' },
//     ];

//     // Resume sections
//     const sections = [
//         { id: "basics", title: "Basic Information" },
//         { id: "work", title: "Work Experience" },
//         { id: "education", title: "Education" },
//         { id: "skills", title: "Skills" },
//         { id: "projects", title: "Projects" }
//     ];

//     const RenderUploadStep = () => {
//         const displayProgress = Math.min(simulatedProgress, uploadProgress);

//         return (
//             <div className="space-y-6">
//                 <div className="text-2xl font-bold text-gray-800">Upload Your Resume</div>
//                 <div
//                     className="w-full"
//                     onDrop={handleDrop}
//                     onDragOver={handleDragOver}
//                 >
//                     <div className="relative">
//                         {isUploading && (
//                             <div className="absolute -inset-0.5 bg-gradient-to-r from-blue-300 via-blue-400 to-blue-300 rounded-lg blur opacity-75 animate-pulse-border" />
//                         )}

//                         <label className={`relative flex flex-col items-center justify-center w-full h-64 ${isUploading ? 'border-2 border-transparent' : 'border-2 border-dashed border-gray-300'} rounded-lg cursor-pointer bg-gray-50 hover:bg-gray-100 transition-all duration-300`}>
//                             {isUploading ? (
//                                 <div className="space-y-4 text-center">
//                                     <div className="relative">
//                                         <Loader2 className="w-10 h-10 animate-spin text-blue-500 mx-auto" />
//                                         <div className="absolute -top-1 -right-1 w-3 h-3 bg-blue-500 rounded-full animate-ping" />
//                                     </div>

//                                     <div className="space-y-2">
//                                         <p className="text-sm font-medium text-gray-800">
//                                             {analysisSteps[analysisStep]}
//                                         </p>
//                                         <p className="text-xs text-gray-500">
//                                             Processing your resume can take up to 60 seconds
//                                         </p>
//                                     </div>

//                                     <div className="relative w-48 mx-auto">
//                                         <div className="h-2 bg-gray-200 rounded-full overflow-hidden">
//                                             <div
//                                                 className="h-full bg-blue-500 transition-all duration-300"
//                                                 style={{ width: `${displayProgress}%` }}
//                                             />
//                                         </div>
//                                         <span className="absolute -right-8 top-1/2 -translate-y-1/2 text-xs text-gray-500">
//                                             {Math.round(displayProgress)}%
//                                         </span>
//                                     </div>

//                                     <div className="flex justify-center gap-1">
//                                         {[...Array(3)].map((_, i) => (
//                                             <div
//                                                 key={i}
//                                                 className="w-1.5 h-1.5 rounded-full bg-blue-500 animate-bounce"
//                                                 style={{
//                                                     animationDelay: `${i * 200}ms`
//                                                 }}
//                                             />
//                                         ))}
//                                     </div>
//                                 </div>
//                             ) : (
//                                 <div className="flex flex-col items-center justify-center pt-5 pb-6">
//                                     <Upload className="w-10 h-10 mb-3 text-gray-400" />
//                                     <p className="mb-2 text-sm text-gray-500">
//                                         <span className="font-semibold">Click to upload</span> or drag and drop
//                                     </p>
//                                     <p className="text-xs text-gray-500">PDF, DOCX, or TXT (MAX. 5MB)</p>
//                                 </div>
//                             )}
//                             <input
//                                 type="file"
//                                 className="hidden"
//                                 onChange={handleFileChange}
//                                 accept=".pdf,.docx,.txt"
//                                 disabled={isUploading}
//                             />
//                         </label>
//                     </div>
//                 </div>

//                 {file && !isUploading && (
//                     <div className="flex items-center gap-2 text-sm text-gray-600">
//                         <Check className="w-4 h-4 text-green-500" />
//                         <span>File uploaded: {file.name}</span>
//                     </div>
//                 )}

//                 {error && (
//                     <div className="bg-red-50 border border-red-200 rounded-lg p-4 text-sm text-red-600">
//                         {error}
//                     </div>
//                 )}

//                 <style jsx>{`
//                     @keyframes pulse-border {
//                         0% { opacity: 0.5; }
//                         50% { opacity: 1; }
//                         100% { opacity: 0.5; }
//                     }
//                     .animate-pulse-border {
//                         animation: pulse-border 2s cubic-bezier(0.4, 0, 0.6, 1) infinite;
//                     }
//                 `}</style>
//             </div>
//         );
//     };


//     // Step con
//     const renderJobDetailsStep = () => (
//         <div className="space-y-6">
//             <div className="text-2xl font-bold text-gray-800">Job Details</div>
//             <div className="space-y-4">
//                 <div>
//                     <label className="block text-sm font-medium text-gray-700 mb-2">
//                         Job Description
//                     </label>
//                     <div className="relative">
//                         <ScrollText className="absolute left-3 top-3 w-5 h-5 text-gray-400" />
//                         <textarea
//                             value={jobText}
//                             onChange={(e) => setJobText(e.target.value)}
//                             placeholder="Paste the job description here..."
//                             className="w-full pl-10 pr-4 py-2 border rounded-lg h-48 resize-none"
//                         />
//                     </div>
//                 </div>

//                 {jobAnalysis && (
//                     <div className="space-y-4 mt-6">
//                         <div className="bg-blue-50 p-4 rounded-lg">
//                             <h3 className="font-medium text-blue-800 mb-2">Target Role</h3>
//                             <p className="text-sm text-blue-700">{jobAnalysis.target_role}</p>
//                         </div>

//                         <div className="bg-green-50 p-4 rounded-lg">
//                             <h3 className="font-medium text-green-800 mb-2">Required Skills</h3>
//                             <div className="text-sm text-green-700"
//                                 dangerouslySetInnerHTML={{ __html: jobAnalysis.required_skills }}
//                             />
//                         </div>

//                         <div className="bg-purple-50 p-4 rounded-lg">
//                             <h3 className="font-medium text-purple-800 mb-2">Tech Stack</h3>
//                             <div className="text-sm text-purple-700"
//                                 dangerouslySetInnerHTML={{ __html: jobAnalysis.tech_stack }}
//                             />
//                         </div>
//                     </div>
//                 )}
//             </div>
//         </div>
//     );

//     const renderEnhanceStep = () => (
//         <div className="space-y-6">
//             <div className="text-2xl font-bold text-gray-800">Enhance Your Resume</div>
//             <div className="bg-white p-6 rounded-xl border border-gray-200">
//                 <div className="flex items-center gap-2 mb-4">
//                     <Wand2 className="w-5 h-5 text-blue-500" />
//                     <label className="font-medium text-gray-700">Enhancement Level</label>
//                 </div>
//                 <input
//                     type="range"
//                     min="0"
//                     max="1"
//                     step="0.1"
//                     value={pufferyLevel}
//                     onChange={(e) => setPufferyLevel(parseFloat(e.target.value))}
//                     className="w-full h-2 bg-gray-200 rounded-lg appearance-none cursor-pointer"
//                 />
//                 <div className="mt-2 text-right text-sm text-gray-600">
//                     {(pufferyLevel * 100).toFixed(0)}%
//                 </div>
//             </div>

//             {/* Position Changes Preview */}
//             {work.length > 0 && (
//                 <div className="bg-white p-6 rounded-xl border border-gray-200">
//                     <h3 className="font-medium text-gray-800 mb-4">Position Changes</h3>
//                     <div className="space-y-3">
//                         {work.map((job, index) => (
//                             <div key={index} className="text-sm">
//                                 <div className="text-gray-600">{job.name}</div>
//                                 <div className="flex items-center gap-2">
//                                     <span className="text-gray-500">
//                                         {job.original_position}
//                                     </span>
//                                     <ChevronRight className="w-4 h-4 text-gray-400" />
//                                     <span className="text-blue-600 font-medium">
//                                         {job.position}
//                                     </span>
//                                 </div>
//                             </div>
//                         ))}
//                     </div>
//                 </div>
//             )}

//             <button
//                 onClick={handleEnhance}
//                 disabled={isEnhancing}
//                 className="w-full py-4 bg-gradient-to-r from-blue-500 to-blue-600 text-white rounded-xl font-medium flex items-center justify-center gap-2 hover:from-blue-600 hover:to-blue-700 transition-all disabled:opacity-50"
//             >
//                 {isEnhancing ? (
//                     <>
//                         <Loader2 className="w-5 h-5 animate-spin" />
//                         Enhancing Resume...
//                     </>
//                 ) : (
//                     <>
//                         <Sparkles className="w-5 h-5" />
//                         Enhance Resume
//                     </>
//                 )}
//             </button>

//             {jobAnalysis?.ideal_profile && (
//                 <div className="bg-white p-6 rounded-xl border border-gray-200">
//                     <h3 className="font-medium text-gray-800 mb-4">Ideal Profile</h3>
//                     <div
//                         className="text-sm text-gray-600 space-y-2"
//                         dangerouslySetInnerHTML={{ __html: jobAnalysis.ideal_profile }}
//                     />
//                 </div>
//             )}
//         </div>
//     );

//     const renderExportStep = () => (
//         <div className="space-y-6">
//             <div className="text-2xl font-bold text-gray-800">Export Your Resume</div>

//             <div className="bg-white p-6 rounded-xl border border-gray-200">
//                 <h3 className="font-medium text-gray-800 mb-4">
//                     Selected Template: {resumeTemplates[activeTemplateIndex].name}
//                 </h3>

//                 <ExportButtons
//                     basics={basics}
//                     work={work}
//                     education={education}
//                     skills={skills}
//                     projects={projects}
//                     jobAnalysis={jobAnalysis}
//                     selectedTheme={resumeTemplates[activeTemplateIndex].packageName}
//                 />

//                 <div className="mt-6 p-4 bg-gray-50 rounded-lg">
//                     <h4 className="text-sm font-medium text-gray-700 mb-2">Template Preview</h4>
//                     <img
//                         src={`/api/placeholder/600/400`}
//                         alt={`${resumeTemplates[activeTemplateIndex].name} template preview`}
//                         className="w-full rounded-lg border border-gray-200"
//                     />
//                     <p className="mt-2 text-sm text-gray-600">
//                         {resumeTemplates[activeTemplateIndex].preview}
//                     </p>
//                 </div>
//             </div>
//         </div>
//     );

//     // Step configuration
//     const steps = [
//         { title: 'Upload', component: RenderUploadStep },
//         {
//             title: 'Template',
//             component: () => (
//                 <TemplateSelector
//                     activeTemplateIndex={activeTemplateIndex}
//                     onSelectTemplate={handleTemplateSelect}
//                     containerRef={templateContainerRef}
//                 />
//             )
//         },
//         { title: 'Job Details', component: renderJobDetailsStep },
//         { title: 'Enhance', component: renderEnhanceStep },
//         { title: 'Export', component: renderExportStep }
//     ];


//     return (
//         <div className="min-h-screen bg-gray-50 p-8">
//             <div className="max-w-6xl mx-auto">
//                 {/* Header */}
//                 <div className="text-center mb-12">
//                     <h1 className="text-4xl font-bold text-gray-900 mb-2">
//                         AI Resume Builder
//                     </h1>
//                     <p className="text-gray-600">
//                         Create the perfect resume for your next job
//                     </p>
//                 </div>

//                 {/* Main Content */}

//                 {/* Main Content */}
//                 <div className="bg-white rounded-2xl shadow-xl p-8">                    {/* Progress Bar */}
//                     <div className="mb-8">
//                         <div className="flex justify-between mb-2">
//                             {steps.map((step, index) => (
//                                 <div
//                                     key={index}
//                                     className={`flex items-center ${index === steps.length - 1 ? '' : 'flex-1'}`}
//                                 >
//                                     <div
//                                         className={`w-8 h-8 rounded-full flex items-center justify-center ${index < currentStep
//                                             ? 'bg-green-500 text-white'
//                                             : index === currentStep
//                                                 ? 'bg-blue-500 text-white'
//                                                 : 'bg-gray-200'
//                                             }`}
//                                     >
//                                         {index < currentStep ? (
//                                             <Check className="w-5 h-5" />
//                                         ) : (
//                                             index + 1
//                                         )}
//                                     </div>
//                                     {index < steps.length - 1 && (
//                                         <div
//                                             className={`h-1 flex-1 mx-2 ${index < currentStep
//                                                 ? 'bg-green-500'
//                                                 : 'bg-gray-200'
//                                                 }`}
//                                         />
//                                     )}
//                                 </div>
//                             ))}
//                         </div>
//                         <div className="flex justify-between px-2">
//                             {steps.map((step, index) => (
//                                 <span key={index} className="text-sm text-gray-500">
//                                     {step.title}
//                                 </span>
//                             ))}
//                         </div>
//                     </div>

//                     {/* Current Step */}
//                     <div className="mb-12">
//                         {steps[currentStep].component()}

//                         {/* Navigation Buttons */}
//                         <div className="flex justify-between mt-8">
//                             <button
//                                 onClick={() => setCurrentStep(prev => Math.max(prev - 1, 0))}
//                                 disabled={currentStep === 0}
//                                 className="px-6 py-2 border rounded-lg flex items-center gap-2 disabled:opacity-50 hover:bg-gray-50 transition-colors"
//                             >
//                                 <ChevronLeft className="w-4 h-4" />
//                                 Previous
//                             </button>

//                             <button
//                                 onClick={() => setCurrentStep(prev => Math.min(prev + 1, steps.length - 1))}
//                                 disabled={currentStep === steps.length - 1 || isUploading}
//                                 className="px-6 py-2 bg-blue-500 text-white rounded-lg flex items-center gap-2 disabled:opacity-50 hover:bg-blue-600 transition-colors"
//                             >
//                                 Next
//                                 <ChevronRight className="w-4 h-4" />
//                             </button>
//                         </div>
//                     </div>

//                     {/* Preview Section */}
//                     <div className="mt-8 border-t pt-8">
//                         <div className="flex items-center justify-between mb-6">
//                             <h3 className="text-xl font-bold text-gray-800">
//                                 Resume Preview
//                             </h3>
//                             <div className="flex items-center gap-2 bg-blue-50 px-3 py-1 rounded-full">
//                                 <Star className="w-4 h-4 text-blue-500" />
//                                 <span className="text-blue-600 font-medium">
//                                     {resumeScore}%
//                                 </span>
//                             </div>
//                         </div>

//                         {/* Full Width Visual Preview */}
//                         <div className="border rounded-xl overflow-hidden bg-white max-w-4xl mx-auto">
//                             <div className="bg-gray-50 border-b p-4">
//                                 <input
//                                     type="text"
//                                     value={basics.name || ''}
//                                     onChange={(e) => setBasics(prev => ({ ...prev, name: e.target.value }))}
//                                     placeholder="Your Name"
//                                     className="text-lg font-medium text-gray-900 w-full bg-transparent border-none focus:ring-2 focus:ring-blue-500 rounded px-2"
//                                 />
//                                 <input
//                                     type="text"
//                                     value={jobAnalysis?.target_role || basics.label || ''}
//                                     onChange={(e) => setBasics(prev => ({ ...prev, label: e.target.value }))}
//                                     placeholder="Your Title"
//                                     className="text-sm text-gray-600 w-full bg-transparent border-none focus:ring-2 focus:ring-blue-500 rounded px-2 mt-1"
//                                 />
//                             </div>

//                             <div className="p-6 space-y-6">
//                                 {/* Contact Info */}
//                                 <div className="flex gap-4 text-sm text-gray-600">
//                                     <div className="flex items-center gap-1">
//                                         <FileText className="w-4 h-4" />
//                                         <input
//                                             type="email"
//                                             value={basics.email || ''}
//                                             onChange={(e) => setBasics(prev => ({ ...prev, email: e.target.value }))}
//                                             placeholder="Email"
//                                             className="bg-transparent border-none focus:ring-2 focus:ring-blue-500 rounded px-2"
//                                         />
//                                     </div>
//                                     <div className="flex items-center gap-1">
//                                         <FileText className="w-4 h-4" />
//                                         <input
//                                             type="tel"
//                                             value={basics.phone || ''}
//                                             onChange={(e) => setBasics(prev => ({ ...prev, phone: e.target.value }))}
//                                             placeholder="Phone"
//                                             className="bg-transparent border-none focus:ring-2 focus:ring-blue-500 rounded px-2"
//                                         />
//                                     </div>
//                                 </div>

//                                 {/* Summary */}
//                                 <div>
//                                     <textarea
//                                         value={basics.summary || ''}
//                                         onChange={(e) => setBasics(prev => ({ ...prev, summary: e.target.value }))}
//                                         placeholder="Professional Summary..."
//                                         className="w-full text-sm text-gray-700 bg-transparent border rounded-md focus:ring-2 focus:ring-blue-500 p-3 min-h-[120px]"
//                                     />
//                                 </div>

//                                 {/* Work Experience */}
//                                 {work.length > 0 && (
//                                     <div className="space-y-4">
//                                         <div className="font-medium text-gray-900 flex justify-between items-center">
//                                             <span>Experience</span>
//                                             <button
//                                                 onClick={() => setWork([...work, { position: '', name: '', startDate: '', endDate: '', highlights: [] }])}
//                                                 className="text-blue-500 hover:text-blue-600"
//                                             >
//                                                 <PlusCircle className="w-4 h-4" />
//                                             </button>
//                                         </div>
//                                         {work.map((job, index) => (
//                                             <div key={index} className="text-sm space-y-3 relative group border rounded-lg p-4 hover:shadow-sm transition-shadow">
//                                                 <button
//                                                     onClick={() => setWork(work.filter((_, i) => i !== index))}
//                                                     className="absolute -right-2 -top-2 opacity-0 group-hover:opacity-100 transition-opacity"
//                                                 >
//                                                     <Trash2 className="w-4 h-4 text-red-500 hover:text-red-600" />
//                                                 </button>
//                                                 <input
//                                                     value={job.position || ''}
//                                                     onChange={(e) => handleArrayChange(setWork, index, 'position', e.target.value)}
//                                                     placeholder="Position"
//                                                     className="font-medium w-full bg-transparent border-none focus:ring-2 focus:ring-blue-500 rounded px-2"
//                                                 />
//                                                 <input
//                                                     value={job.name || ''}
//                                                     onChange={(e) => handleArrayChange(setWork, index, 'name', e.target.value)}
//                                                     placeholder="Company"
//                                                     className="text-gray-600 w-full bg-transparent border-none focus:ring-2 focus:ring-blue-500 rounded px-2"
//                                                 />
//                                                 <div className="flex gap-2">
//                                                     <input
//                                                         type="date"
//                                                         value={job.startDate || ''}
//                                                         onChange={(e) => handleArrayChange(setWork, index, 'startDate', e.target.value)}
//                                                         className="text-gray-500 bg-transparent border rounded px-2"
//                                                     />
//                                                     -
//                                                     <input
//                                                         type="date"
//                                                         value={job.endDate || ''}
//                                                         onChange={(e) => handleArrayChange(setWork, index, 'endDate', e.target.value)}
//                                                         className="text-gray-500 bg-transparent border rounded px-2"
//                                                     />
//                                                 </div>
//                                                 <div className="space-y-2">
//                                                     {job.highlights.map((highlight, idx) => (
//                                                         <div key={idx} className="relative group flex gap-2 items-center">
//                                                             <span className="text-gray-400">•</span>
//                                                             <input
//                                                                 value={highlight}
//                                                                 onChange={(e) => {
//                                                                     const newWork = [...work];
//                                                                     newWork[index].highlights[idx] = e.target.value;
//                                                                     setWork(newWork);
//                                                                 }}
//                                                                 className="flex-1 text-gray-700 bg-transparent border-none focus:ring-2 focus:ring-blue-500 rounded px-2 py-1"
//                                                             />
//                                                         </div>
//                                                     ))}
//                                                     <button
//                                                         onClick={() => {
//                                                             const newWork = [...work];
//                                                             newWork[index].highlights.push('');
//                                                             setWork(newWork);
//                                                         }}
//                                                         className="text-sm text-blue-500 hover:text-blue-600 ml-6"
//                                                     >
//                                                         + Add Highlight
//                                                     </button>
//                                                 </div>
//                                             </div>
//                                         ))}
//                                     </div>
//                                 )}

//                                 {/* Education */}
//                                 {education.length > 0 && (
//                                     <div className="space-y-4">
//                                         <div className="font-medium text-gray-900 flex justify-between items-center">
//                                             <span>Education</span>
//                                             <button
//                                                 onClick={() => setEducation([...education, { institution: '', area: '', studyType: '', startDate: '', endDate: '' }])}
//                                                 className="text-blue-500 hover:text-blue-600"
//                                             >
//                                                 <PlusCircle className="w-4 h-4" />
//                                             </button>
//                                         </div>
//                                         {education.map((edu, index) => (
//                                             <div key={index} className="text-sm space-y-2 relative group border rounded-lg p-4 hover:shadow-sm transition-shadow">
//                                                 <button
//                                                     onClick={() => setEducation(education.filter((_, i) => i !== index))}
//                                                     className="absolute -right-2 -top-2 opacity-0 group-hover:opacity-100 transition-opacity"
//                                                 >
//                                                     <Trash2 className="w-4 h-4 text-red-500 hover:text-red-600" />
//                                                 </button>
//                                                 <input
//                                                     value={edu.studyType || ''}
//                                                     onChange={(e) => handleArrayChange(setEducation, index, 'studyType', e.target.value)}
//                                                     placeholder="Degree Type"
//                                                     className="font-medium w-full bg-transparent border-none focus:ring-2 focus:ring-blue-500 rounded px-2"
//                                                 />
//                                                 <input
//                                                     value={edu.area || ''}
//                                                     onChange={(e) => handleArrayChange(setEducation, index, 'area', e.target.value)}
//                                                     placeholder="Field of Study"
//                                                     className="w-full bg-transparent border-none focus:ring-2 focus:ring-blue-500 rounded px-2"
//                                                 />
//                                                 <input
//                                                     value={edu.institution || ''}
//                                                     onChange={(e) => handleArrayChange(setEducation, index, 'institution', e.target.value)}
//                                                     placeholder="Institution"
//                                                     className="text-gray-600 w-full bg-transparent border-none focus:ring-2 focus:ring-blue-500 rounded px-2"
//                                                 />
//                                                 <div className="flex gap-2">
//                                                     <input
//                                                         type="date"
//                                                         value={edu.startDate || ''}
//                                                         onChange={(e) => handleArrayChange(setEducation, index, 'startDate', e.target.value)}
//                                                         className="text-gray-500 bg-transparent border rounded px-2"
//                                                     />
//                                                     -
//                                                     <input
//                                                         type="date"
//                                                         value={edu.endDate || ''}
//                                                         onChange={(e) => handleArrayChange(setEducation, index, 'endDate', e.target.value)}
//                                                         className="text-gray-500 bg-transparent border rounded px-2"
//                                                     />
//                                                 </div>
//                                             </div>
//                                         ))}
//                                     </div>
//                                 )}
//                             </div>
//                         </div>
//                     </div>
//                 </div>
//             </div>
//         </div>
//     );
// };
