import { createClient } from '@supabase/supabase-js';

const supabaseUrl = process.env.REACT_APP_SUPABASE_URL || 'https://auth.roboapply.ai';
const supabaseKey = process.env.REACT_APP_SUPABASE_ANON_KEY || 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6InN1a21idnJwY3d6bm1ud3Rlb3liIiwicm9sZSI6ImFub24iLCJpYXQiOjE3Mjg4NzQ3ODYsImV4cCI6MjA0NDQ1MDc4Nn0.xR0tIF0NYNnnXa-N2-WpX0qgecGiwywdGwIVg3MgEyg';

const supabase = createClient(supabaseUrl, supabaseKey, {
    auth: {
        redirectTo: 'https://roboapply.ai'  // This should fix the localhost redirect
    }
});

export default supabase;