import React, { useState, useEffect } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { useNavigate } from 'react-router-dom';
import { X, Loader } from 'lucide-react';
import { useAutoAnimate } from '@formkit/auto-animate/react';
import ProfileCreation from './ProfileCreation';
import { toast } from 'react-hot-toast';
import supabase from '../supabaseConfig';

const LoginModal2 = ({ isOpen, onClose }) => {
    const navigate = useNavigate();
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(false);
    const [currentUser, setCurrentUser] = useState(null);

    // Check for existing session on mount and auth state changes
    useEffect(() => {
        const checkSession = async () => {
            const { data: { session } } = await supabase.auth.getSession();
            if (session?.user) {
                setCurrentUser(session.user);
            }
        };

        checkSession();

        // Listen for auth changes
        const { data: { subscription } } = supabase.auth.onAuthStateChange((_event, session) => {
            setCurrentUser(session?.user ?? null);
        });

        return () => {
            subscription?.unsubscribe();
        };
    }, []);

    const handleGoogleSignIn = async () => {
        try {
            setLoading(true);
            const { error: signInError, data } = await supabase.auth.signInWithOAuth({
                provider: 'google',
                options: {
                    redirectTo: `${window.location.origin}/resume`,
                    queryParams: {
                        access_type: 'offline',
                        prompt: 'consent',
                    }
                }
            });

            if (signInError) throw signInError;

            if (data?.user) {
                setCurrentUser(data.user);
            }

        } catch (error) {
            console.error('Google auth error:', error);
            setError(error.message);
            toast.error('Google sign in failed', {
                description: error.message || 'Please try again later',
                duration: 4000,
            });
        } finally {
            setLoading(false);
        }
    };

    const handleProfileComplete = () => {
        onClose();
        navigate('/resume');
    };

    if (!isOpen) return null;

    return (
        <AnimatePresence>
            <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                className="fixed inset-0 z-50"
            >
                <motion.div
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    className="absolute inset-0 bg-black/60 backdrop-blur-sm"
                />
                <div className="fixed inset-0 overflow-y-auto">
                    <div className="min-h-full flex items-center justify-center p-4">
                        <motion.div
                            initial={{ scale: 0.9, opacity: 0 }}
                            animate={{ scale: 1, opacity: 1 }}
                            transition={{ type: "spring", duration: 0.5 }}
                            className="relative w-full max-w-4xl"
                        >
                            <div className="bg-white/90 backdrop-blur-xl rounded-3xl shadow-2xl p-6 md:p-12">
                                {currentUser ? (
                                    <ProfileCreation
                                        user={currentUser}
                                        onComplete={handleProfileComplete}
                                    />
                                ) : (
                                    <div className="text-center">
                                        <div className="flex justify-between items-center mb-6">
                                            <h2 className="text-xl md:text-2xl font-bold text-gray-800">Connect Your Account</h2>
                                            <motion.button
                                                whileHover={{ scale: 1.1 }}
                                                whileTap={{ scale: 0.9 }}
                                                onClick={onClose}
                                                className="text-gray-500 hover:text-gray-700"
                                            >
                                                <X className="w-5 h-5 md:w-6 md:h-6" />
                                            </motion.button>
                                        </div>

                                        <button
                                            onClick={handleGoogleSignIn}
                                            disabled={loading}
                                            className="w-full py-3 px-4 rounded-xl bg-gradient-to-r from-blue-500 to-purple-500 text-white font-medium flex items-center justify-center space-x-2 hover:from-blue-600 hover:to-purple-600 transition-all disabled:opacity-50"
                                        >
                                            {loading ? (
                                                <>
                                                    <Loader className="animate-spin mr-2 w-4 h-4 md:w-5 md:h-5" />
                                                    <span>Connecting...</span>
                                                </>
                                            ) : (
                                                <>
                                                    <img
                                                        src="https://www.gstatic.com/firebasejs/ui/2.0.0/images/auth/google.svg"
                                                        alt="Google"
                                                        className="w-5 h-5 mr-2"
                                                    />
                                                    Continue with Google
                                                </>
                                            )}
                                        </button>

                                        {error && (
                                            <div className="mt-4 p-3 bg-red-50 text-red-600 rounded-lg text-sm">
                                                {error}
                                            </div>
                                        )}
                                    </div>
                                )}
                            </div>
                        </motion.div>
                    </div>
                </div>
            </motion.div>
        </AnimatePresence>
    );
};

export default LoginModal2;