import React, { useState, useEffect } from 'react';
import { LogOut, X, Menu, Sparkle, ArrowRightIcon, RefreshCwIcon, FileText, Briefcase } from 'lucide-react';
import { Link, useNavigate } from 'react-router-dom';
import { motion, AnimatePresence } from 'framer-motion';
import supabase from '../supabaseConfig';

const Button = ({ className, children, disabled, loading, ...props }) => {
    const baseStyles = "px-6 py-3 rounded-full font-semibold transition-all duration-300";
    const disabledStyles = disabled ? "opacity-50 cursor-not-allowed" : "hover:shadow-lg";
    const combinedClassName = `${baseStyles} ${className} ${disabledStyles}`;

    return (
        <button
            className={combinedClassName}
            disabled={disabled || loading}
            {...props}
        >
            {loading ? (
                <div className="flex items-center justify-center">
                    <svg className="animate-spin -ml-1 mr-3 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                        <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                        <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                    </svg>
                    Processing...
                </div>
            ) : children}
        </button>
    );
};

const UserProfileDisplay = ({ user, credits }) => {
    const [profile, setProfile] = useState(null);

    useEffect(() => {
        const fetchProfile = async () => {
            if (user?.id) {
                const { data, error } = await supabase
                    .from('profiles')
                    .select('*')
                    .eq('id', user.id)
                    .single();

                if (!error && data) {
                    setProfile(data);
                }
            }
        };

        fetchProfile();
    }, [user]);

    return (
        <div className="flex items-center space-x-4">
            <div className="bg-white/90 p-3 rounded-lg flex items-center space-x-4 shadow-md hover:shadow-lg transition-all duration-300">
                <img
                    src={profile?.avatar_url || "./einstein.png"}
                    alt={profile?.username || "User"}
                    className="w-10 h-10 rounded-full border-2 border-white shadow-sm"
                />
                <div>
                    <p className="text-gray-800 font-bold">
                        {profile?.username || user?.email?.split('@')[0] || "User"}
                    </p>
                    <p className="text-gray-600 text-sm">
                        {profile?.job_title || "Job Seeker"}
                    </p>
                </div>
                <div className="flex items-center space-x-1 bg-white px-3 py-1 rounded-full">
                    <Sparkle className="w-4 h-4 text-[#49DB84]" />
                    <span className="text-[#49DB84] font-semibold">
                        {profile?.credits || 0} Credits
                    </span>
                </div>
            </div>
        </div>
    );
};

const HeaderMain = ({
    user,
    hasProfile,
    credits,
    supabase,
    toast
}) => {
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const [showAuthModal, setShowAuthModal] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);
    const navigate = useNavigate();

    const handleGoogleSignIn = async () => {
        try {
            const { data, error } = await supabase.auth.signInWithOAuth({
                provider: 'google'
            });
            if (error) throw error;
            setShowAuthModal(false);
        } catch (error) {
            console.error('Google auth error:', error);
            setError(error.message);
            toast.error('Google sign in failed', {
                description: error.message || 'Please try again later',
                duration: 4000,
            });
        }
    };

    const handleLogout = async () => {
        try {
            const { error } = await supabase.auth.signOut();
            if (error) throw error;
            localStorage.removeItem(`sb-${process.env.REACT_APP_SUPABASE_PROJECT_ID}-auth-token`);
            localStorage.removeItem('userId');
            toast.success('Logged out successfully');
            navigate('/');
        } catch (error) {
            toast.error('Error logging out');
        }
    };

    const NavLink = ({ to, children, onClick }) => (
        <Link
            to={to}
            onClick={onClick}
            className="text-white/90 hover:text-white transition-colors flex items-center space-x-2 py-2"
        >
            {children}
        </Link>
    );

    const MobileNavLink = ({ to, children, onClick }) => (
        <Link
            to={to}
            onClick={() => {
                setIsMenuOpen(false);
                onClick?.();
            }}
            className="text-[#49DB84] hover:text-[#4098DA] transition-colors flex items-center space-x-2 py-3 px-4"
        >
            {children}
        </Link>
    );

    return (
        <>
            <style jsx global>{`
                @import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700;800&display=swap');
                
                :root {
                    --font-poppins: 'Poppins', sans-serif;
                }

                html {
                    font-family: var(--font-poppins);
                }

                h1, h2, h3 {
                    font-family: var(--font-poppins);
                    font-weight: 700;
                }

                p, span, div {
                    font-family: var(--font-poppins);
                    font-weight: 400;
                }

                button {
                    font-family: var(--font-poppins);
                    font-weight: 600;
                }
            `}</style>

            <header className="w-full bg-gradient-to-r from-[#49DB84] to-[#4098DA] shadow-md">
                <div className="max-w-6xl mx-auto flex items-center justify-between p-4 relative z-50">
                    <Link to="/" className="flex items-center space-x-3 group">
                        <img src="/upLine.svg" alt="RoboApply Logo" className="h-10 w-10 brightness-200 group-hover:scale-105 transition-transform" />
                        <span className="text-3xl font-bold text-white group-hover:text-white/90 transition-colors">
                            RoboApply
                        </span>
                    </Link>

                    {/* Desktop Navigation */}
                    <nav className="hidden md:flex items-center space-x-6">
                        <NavLink to="/features">Features</NavLink>
                        <NavLink to="/jobs">
                            <Briefcase className="w-4 h-4" />
                            <span>Jobs</span>
                        </NavLink>
                        <NavLink to="/pricing">Pricing</NavLink>
                        <NavLink to="/resume">
                            <FileText className="w-4 h-4" />
                            <span>Resume Builder</span>
                        </NavLink>

                        {user && hasProfile ? (
                            <>
                                <UserProfileDisplay user={user} credits={credits} />
                                <Button
                                    onClick={() => navigate('/apply')}
                                    className="bg-white text-transparent bg-clip-text bg-gradient-to-r from-[#49DB84] to-[#4098DA] hover:bg-white/90"
                                >
                                    Go to Apply
                                </Button>
                                <Button
                                    onClick={handleLogout}
                                    className="bg-red-500 hover:bg-red-600 text-white"
                                >
                                    <LogOut className="w-5 h-4 mr-1" />
                                    Log Out
                                </Button>
                            </>
                        ) : (
                            <Button
                                onClick={() => setShowAuthModal(true)}
                                className="bg-white hover:bg-white/90 text-transparent bg-clip-text bg-gradient-to-r from-[#49DB84] to-[#4098DA]"
                            >
                                Sign In
                            </Button>
                        )}
                    </nav>

                    {/* Mobile Menu Button */}
                    <Button
                        className="md:hidden text-white"
                        onClick={() => setIsMenuOpen(!isMenuOpen)}
                    >
                        {isMenuOpen ? <X /> : <Menu />}
                    </Button>
                </div>

                {/* Mobile Navigation */}
                <AnimatePresence>
                    {isMenuOpen && (
                        <motion.div
                            initial={{ opacity: 0, y: -10 }}
                            animate={{ opacity: 1, y: 0 }}
                            exit={{ opacity: 0, y: -10 }}
                            className="md:hidden fixed top-[4.5rem] left-0 right-0 bg-white shadow-lg rounded-b-xl z-50"
                        >
                            <div className="flex flex-col p-2">
                                <MobileNavLink to="/features">Features</MobileNavLink>
                                <MobileNavLink to="/jobs">
                                    <Briefcase className="w-4 h-4" />
                                    <span>Jobs</span>
                                </MobileNavLink>
                                <MobileNavLink to="/pricing">Pricing</MobileNavLink>
                                <MobileNavLink to="/resume">
                                    <FileText className="w-4 h-4" />
                                    <span>Resume</span>
                                </MobileNavLink>
                                <MobileNavLink to="/resume-converter">
                                    <FileText className="w-4 h-4" />
                                    <span>Resume Converter</span>
                                </MobileNavLink>

                                {user && hasProfile ? (
                                    <>
                                        <div className="p-4">
                                            <UserProfileDisplay user={user} credits={credits} />
                                        </div>
                                        <Button
                                            onClick={() => {
                                                setIsMenuOpen(false);
                                                navigate('/apply');
                                            }}
                                            className="m-2 bg-gradient-to-r from-[#49DB84] to-[#4098DA] text-white"
                                        >
                                            Go to Apply
                                        </Button>
                                        <Button
                                            onClick={() => {
                                                setIsMenuOpen(false);
                                                handleLogout();
                                            }}
                                            className="m-2 bg-red-500 text-white"
                                        >
                                            <LogOut className="w-4 h-4 mr-2" />
                                            Log Out
                                        </Button>
                                    </>
                                ) : (
                                    <Button
                                        onClick={() => {
                                            setIsMenuOpen(false);
                                            setShowAuthModal(true);
                                        }}
                                        className="m-2 bg-gradient-to-r from-[#49DB84] to-[#4098DA] text-white"
                                    >
                                        Sign In
                                    </Button>
                                )}
                            </div>
                        </motion.div>
                    )}
                </AnimatePresence>

                {/* Auth Modal */}
                {showAuthModal && (
                    <div className="fixed inset-0 bg-black/50 flex items-center justify-center p-4 z-50">
                        <AnimatePresence>
                            <motion.div
                                initial={{ opacity: 0, scale: 0.95 }}
                                animate={{ opacity: 1, scale: 1 }}
                                exit={{ opacity: 0, scale: 0.95 }}
                                className="bg-white rounded-2xl shadow-xl p-8 max-w-md w-full mx-auto relative overflow-hidden"
                            >
                                <div className="text-center space-y-6">
                                    <h2 className="text-2xl font-bold text-gray-800">
                                        Welcome to RoboApply
                                    </h2>
                                    <p className="text-gray-600">
                                        Let's get started with Google Sign In
                                    </p>

                                    <button
                                        onClick={handleGoogleSignIn}
                                        className="w-full flex items-center justify-center gap-2 bg-white border border-gray-300 rounded-full px-6 py-3 text-gray-700 hover:bg-gray-50 transition-all duration-300"
                                    >
                                        <img
                                            src="https://www.gstatic.com/firebasejs/ui/2.0.0/images/auth/google.svg"
                                            alt="Google"
                                            className="w-5 h-5"
                                        />
                                        Continue with Google
                                    </button>

                                    <button
                                        onClick={() => setShowAuthModal(false)}
                                        className="w-full bg-gray-100 text-gray-600 rounded-full px-6 py-3 hover:bg-gray-200 transition-all duration-300"
                                    >
                                        Cancel
                                    </button>
                                </div>
                            </motion.div>
                        </AnimatePresence>
                    </div>
                )}
            </header>
        </>
    );
};

export default HeaderMain;