// import React, { useState, useEffect } from 'react'
// import {
//     FileText,
//     Upload,
//     FileUp,
//     ExternalLink,
//     ScrollText,
//     Star,
//     Sparkles,
//     Zap,
//     ArrowDown,
//     Wand2,
//     Loader2,
//     Check,
//     X,
//     AlertCircle
// } from 'lucide-react'

// // Modal Component using pure Tailwind
// const Modal = ({ isOpen, onClose, children }) => {
//     if (!isOpen) return null

//     return (
//         <div className="fixed inset-0 z-50 overflow-y-auto">
//             <div className="flex min-h-screen items-center justify-center p-4 text-center">
//                 <div
//                     className="fixed inset-0 bg-black/30 transition-opacity"
//                     onClick={onClose}
//                 />
//                 <div className="relative w-full max-w-md transform rounded-2xl bg-white p-6 text-left shadow-xl transition-all">
//                     {children}
//                 </div>
//             </div>
//         </div>
//     )
// }

// // Alert Component using pure Tailwind
// const Alert = ({ variant = 'default', children }) => {
//     const baseStyles = "p-4 rounded-lg mb-4"
//     const styles = {
//         default: "bg-blue-50 text-blue-800 border border-blue-200",
//         error: "bg-red-50 text-red-800 border border-red-200",
//         success: "bg-green-50 text-green-800 border border-green-200"
//     }

//     return (
//         <div className={`${baseStyles} ${styles[variant]}`}>
//             {children}
//         </div>
//     )
// }

// const JobURLSelectorModal = ({ isOpen, onClose, onSelectURL }) => {
//     const [url, setUrl] = useState('')
//     const [selectedSite, setSelectedSite] = useState(null)

//     const jobSites = [
//         {
//             name: 'LinkedIn',
//             domain: 'linkedin.com',
//             icon: (
//                 <svg viewBox="0 0 40 40" className="w-8 h-8" fill="none">
//                     <rect width="40" height="40" rx="8" fill="#0A66C2" />
//                     <path d="M15 16h-4v12h4V16zm-2-6.8a2.4 2.4 0 110 4.8 2.4 2.4 0 010-4.8zM29 28h-4v-6.2c0-3.2-4-3-4 0V28h-4V16h4v1.8c1.4-2.6 8-2.8 8 2.5V28z" fill="white" />
//                 </svg>
//             )
//         },
//         {
//             name: 'Indeed',
//             domain: 'indeed.com',
//             icon: (
//                 <svg viewBox="0 0 40 40" className="w-8 h-8" fill="none">
//                     <rect width="40" height="40" rx="8" fill="#2164F3" />
//                     <path d="M12 10h6v20h-6z" fill="#FFFFFF" />
//                     <path d="M22 10h6v20h-6z" fill="#FFFFFF" />
//                 </svg>
//             )
//         },
//         {
//             name: 'Glassdoor',
//             domain: 'glassdoor.com',
//             icon: (
//                 <svg viewBox="0 0 40 40" className="w-8 h-8" fill="none">
//                     <rect width="40" height="40" rx="8" fill="#0CAA41" />
//                     <path d="M20 6c-7.7 0-14 6.3-14 14s6.3 14 14 14 14-6.3 14-14S27.7 6 20 6zm0 22c-4.4 0-8-3.6-8-8s3.6-8 8-8 8 3.6 8 8-3.6 8-8 8zm0-13c-2.8 0-5 2.2-5 5s2.2 5 5 5 5-2.2 5-5-2.2-5-5-5z" fill="white" />
//                 </svg>
//             )
//         },
//         {
//             name: 'Dice',
//             domain: 'dice.com',
//             icon: (
//                 <svg viewBox="0 0 40 40" className="w-8 h-8" fill="none">
//                     <rect width="40" height="40" rx="8" fill="#EB1C26" />
//                     <path d="M10 10h20v20H10z" fill="white" />
//                     <circle cx="15" cy="15" r="2" fill="#EB1C26" />
//                     <circle cx="25" cy="15" r="2" fill="#EB1C26" />
//                     <circle cx="15" cy="25" r="2" fill="#EB1C26" />
//                     <circle cx="25" cy="25" r="2" fill="#EB1C26" />
//                 </svg>
//             )
//         },
//         {
//             name: 'Monster',
//             domain: 'monster.com',
//             icon: (
//                 <svg viewBox="0 0 40 40" className="w-8 h-8" fill="none">
//                     <rect width="40" height="40" rx="8" fill="#6E45A5" />
//                     <path d="M20 10c-5.5 0-10 4.5-10 10s4.5 10 10 10 10-4.5 10-10-4.5-10-10-10zm0 15c-2.8 0-5-2.2-5-5s2.2-5 5-5 5 2.2 5 5-2.2 5-5 5z" fill="white" />
//                 </svg>
//             )
//         },
//         {
//             name: 'Talent.com',
//             domain: 'talent.com',
//             icon: (
//                 <svg viewBox="0 0 40 40" className="w-8 h-8" fill="none">
//                     <rect width="40" height="40" rx="8" fill="#1D2C4D" />
//                     <path d="M8 20.5L15 28l3-3-4-4 4-4-3-3L8 20.5z" fill="white" />
//                     <path d="M32 20.5L25 28l-3-3 4-4-4-4 3-3L32 20.5z" fill="white" />
//                 </svg>
//             )
//         },
//         {
//             name: 'Greenhouse',
//             domain: 'greenhouse.io',
//             icon: (
//                 <svg viewBox="0 0 40 40" className="w-8 h-8" fill="none">
//                     <rect width="40" height="40" rx="8" fill="#3AB549" />
//                     <path d="M20 8c-6.6 0-12 5.4-12 12s5.4 12 12 12 12-5.4 12-12S26.6 8 20 8zm0 18c-3.3 0-6-2.7-6-6s2.7-6 6-6 6 2.7 6 6-2.7 6-6 6z" fill="white" />
//                     <path d="M20 16c-2.2 0-4 1.8-4 4s1.8 4 4 4 4-1.8 4-4-1.8-4-4-4zm0 6c-1.1 0-2-.9-2-2s.9-2 2-2 2 .9 2 2-.9 2-2 2z" fill="white" />
//                 </svg>
//             )
//         }
//     ]

//     const handleContinue = () => {
//         if (url) {
//             onSelectURL(url)
//             onClose()
//             setUrl('')
//         }
//     }

//     return (
//         <Modal isOpen={isOpen} onClose={onClose}>
//             <div className="mb-6">
//                 <h2 className="text-xl font-bold text-gray-900">
//                     Paste a link to the job listing
//                 </h2>
//                 <p className="mt-2 text-sm text-gray-500">
//                     We'll analyze the job description to optimize your resume
//                 </p>
//             </div>

//             <div className="space-y-6">
//                 <div className="relative">
//                     <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
//                         <span className="text-gray-400">🔒</span>
//                     </div>
//                     <input
//                         type="url"
//                         value={url}
//                         onChange={(e) => setUrl(e.target.value)}
//                         className="w-full pl-10 pr-4 py-3 border rounded-lg bg-gray-50 text-gray-900 placeholder:text-gray-400"
//                         placeholder="jobs.com/company/position"
//                     />
//                 </div>

//                 <div>
//                     <h4 className="text-sm font-medium text-gray-500 mb-3">
//                         Supported Job Sites
//                     </h4>
//                     <div className="grid grid-cols-2 gap-4">
//                         {jobSites.map((site) => (
//                             <div
//                                 key={site.domain}
//                                 onClick={() => setSelectedSite(site.name)}
//                                 className={`flex items-center gap-3 p-3 rounded-lg cursor-pointer transition-all duration-200 ${selectedSite === site.name
//                                     ? 'bg-blue-50 border-2 border-blue-200'
//                                     : 'border-2 border-transparent hover:bg-gray-50'
//                                     }`}
//                             >
//                                 {site.icon}
//                                 <span className="text-sm font-medium text-gray-600">
//                                     {site.name}
//                                 </span>
//                             </div>
//                         ))}
//                     </div>
//                 </div>

//                 <div className="flex justify-end gap-4 mt-6">
//                     <button
//                         onClick={onClose}
//                         className="px-4 py-2 rounded-lg border hover:bg-gray-50"
//                     >
//                         Cancel
//                     </button>
//                     <button
//                         onClick={handleContinue}
//                         disabled={!url}
//                         className="px-4 py-2 rounded-lg bg-blue-600 text-white hover:bg-blue-700 disabled:opacity-50 disabled:cursor-not-allowed"
//                     >
//                         Continue
//                     </button>
//                 </div>
//             </div>
//         </Modal>
//     )
// }

// const ProcessingSteps = ({ currentStep, steps, progress }) => {
//     return (
//         <div className="space-y-4">
//             {steps.map((step, index) => (
//                 <div
//                     key={step}
//                     className={`flex items-center gap-3 ${index <= currentStep ? 'text-blue-600' : 'text-gray-400'
//                         }`}
//                 >
//                     <div className="w-6 h-6 flex-shrink-0">
//                         {index < currentStep ? (
//                             <Check className="w-6 h-6 text-green-500" />
//                         ) : index === currentStep ? (
//                             <Loader2 className="w-6 h-6 animate-spin" />
//                         ) : (
//                             <div className="w-6 h-6 rounded-full border-2 border-current" />
//                         )}
//                     </div>
//                     <span className="text-sm font-medium">{step}</span>
//                 </div>
//             ))}
//             <div className="mt-4">
//                 <div className="h-2 bg-gray-100 rounded-full">
//                     <div
//                         className="h-full bg-blue-600 rounded-full transition-all duration-500"
//                         style={{ width: `${progress}%` }}
//                     />
//                 </div>
//             </div>
//         </div>
//     )
// }

// export default function EnhancedResumeTool() {
//     const [file, setFile] = useState(null)
//     const [jobLink, setJobLink] = useState('')
//     const [jobText, setJobText] = useState('')
//     const [resumeContent, setResumeContent] = useState('')
//     const [isLoading, setIsLoading] = useState(false)
//     const [isScrapingJob, setIsScrapingJob] = useState(false)
//     const [pufferyLevel, setPufferyLevel] = useState(0.4)
//     const [resumeScore, setResumeScore] = useState(0)
//     const [scrapeError, setScrapeError] = useState('')
//     const [isURLModalOpen, setIsURLModalOpen] = useState(false)
//     const [processingStep, setProcessingStep] = useState(0)
//     const [processingProgress, setProcessingProgress] = useState(0)
//     const [loadingMessage, setLoadingMessage] = useState('')

//     const processingSteps = [
//         "Analyzing Job Requirements",
//         "Identifying Key Skills",
//         "Matching Experience",
//         "Optimizing Keywords",
//         "Calculating Match Score"
//     ]

//     const loadingMessages = [
//         "Parsing job description...",
//         "Extracting key requirements...",
//         "Analyzing your experience...",
//         "Optimizing content...",
//         "Fine-tuning matching...",
//         "Almost there..."
//     ]

//     useEffect(() => {
//         let messageIndex = 0
//         let interval

//         if (isLoading || isScrapingJob) {
//             interval = setInterval(() => {
//                 setLoadingMessage(loadingMessages[messageIndex])
//                 messageIndex = (messageIndex + 1) % loadingMessages.length
//             }, 2000)
//         }

//         return () => clearInterval(interval)
//     }, [isLoading, isScrapingJob])

//     const handleFileChange = (e) => {
//         if (e.target.files && e.target.files[0]) {
//             const selectedFile = e.target.files[0]
//             setFile(selectedFile)

//             const reader = new FileReader()
//             reader.onload = (e) => {
//                 const text = e.target?.result
//                 if (typeof text === 'string') {
//                     setResumeContent(text)
//                 }
//             }
//             reader.readAsText(selectedFile)
//         }
//     }

//     const handleJobLinkSelect = async (url) => {
//         setJobLink(url)
//         setIsScrapingJob(true)
//         setScrapeError('')
//         setProcessingStep(0)
//         setProcessingProgress(0)

//         try {
//             const response = await fetch('https://29e4-74-88-7-3.ngrok-free.app/scrape-job', {
//                 method: 'POST',
//                 headers: {
//                     'Content-Type': 'application/json',
//                 },
//                 body: JSON.stringify({ url })
//             })

//             const data = await response.json()

//             if (data.error) {
//                 setScrapeError(data.error)
//                 return
//             }

//             if (data.raw_text) {
//                 setJobText(data.raw_text)
//                 simulateProcessing()
//             }
//         } catch (error) {
//             setScrapeError('Failed to fetch job description. Please try again.')
//         } finally {
//             setIsScrapingJob(false)
//         }
//     }

//     const simulateProcessing = () => {
//         setProcessingStep(0)
//         setProcessingProgress(0)

//         const stepDuration = 1000
//         const progressInterval = 50
//         const totalSteps = processingSteps.length

//         let currentStep = 0
//         let currentProgress = 0

//         const stepTimer = setInterval(() => {
//             if (currentStep >= totalSteps) {
//                 clearInterval(stepTimer)
//                 return
//             }

//             setProcessingStep(currentStep)
//             currentStep++
//         }, stepDuration)

//         const progressTimer = setInterval(() => {
//             if (currentProgress >= 100) {
//                 clearInterval(progressTimer)
//                 return
//             }

//             currentProgress += 1
//             setProcessingProgress(currentProgress)
//         }, progressInterval)
//     }

//     const handleEnhance = () => {
//         if (!file || !jobText) return

//         setIsLoading(true)
//         simulateProcessing()

//         setTimeout(() => {
//             const newScore = Math.floor(Math.random() * 31) + 70 // Generate score between 70-100
//             setResumeScore(newScore)

//             let enhancedContent = `${resumeContent}\n\n`;
//             enhancedContent += `ENHANCED CONTENT (Optimization Level: ${(pufferyLevel * 100).toFixed()}%)\n`;
//             enhancedContent += `Match Score: ${newScore}%\n\n`;
//             enhancedContent += `• Added impact metrics and quantifiable results\n`;
//             enhancedContent += `• Optimized for ATS keywords\n`;
//             enhancedContent += `• Enhanced skill descriptions\n`;
//             enhancedContent += `• Aligned experience with job requirements\n`;

//             setResumeContent(enhancedContent)
//             setIsLoading(false)
//         }, 5000)
//     }

//     return (
//         <div className="min-h-screen p-4 md:p-8 bg-gradient-to-br from-blue-50 to-white">
//             {/* Header */}
//             <div className="max-w-screen-2xl mx-auto mb-8 bg-blue-50/50 rounded-3xl shadow-sm p-4 md:p-8">
//                 <h1 className="text-2xl sm:text-3xl md:text-7xl font-bold leading-tight bg-clip-text text-transparent bg-gradient-to-r from-green-600 to-blue-600 text-center">
//                     AI Resume Enhancer
//                     <div className="text-base sm:text-lg md:text-3xl mt-2 md:mt-4 font-medium bg-clip-text text-transparent bg-gradient-to-r from-blue-500 to-green-500">
//                         Optimize Your Resume with AI ✨
//                     </div>
//                 </h1>
//             </div>

//             {/* Main Content */}
//             <div className="max-w-screen-2xl mx-auto bg-white/30 rounded-3xl shadow-xl overflow-hidden backdrop-blur-sm">
//                 <div className="grid grid-cols-12 gap-4">
//                     {/* Left Column: Inputs */}
//                     <div className="col-span-12 lg:col-span-4 p-8 bg-gradient-to-br from-blue-50/50 to-white border-r border-blue-100">
//                         <div className="space-y-6">
//                             {/* Resume Upload */}
//                             <div className="bg-white/50 p-6 rounded-2xl shadow-sm border border-blue-100">
//                                 <label className="block text-lg font-semibold text-gray-700 mb-4">
//                                     <FileUp className="w-5 h-5 inline-block mr-2 text-blue-500" />
//                                     Upload Resume
//                                 </label>
//                                 <div className="relative">
//                                     <input
//                                         type="file"
//                                         onChange={handleFileChange}
//                                         accept=".txt,.doc,.docx,.pdf"
//                                         className="w-full text-sm text-gray-500 file:mr-4 file:py-2 file:px-4 file:rounded-full file:border-0 file:text-sm file:font-semibold file:bg-blue-50 file:text-blue-700 hover:file:bg-blue-100"
//                                     />
//                                 </div>
//                             </div>

//                             {/* Job URL Input */}
//                             <div className="bg-white/50 p-6 rounded-2xl shadow-sm border border-blue-100">
//                                 <label className="block text-lg font-semibold text-gray-700 mb-4">
//                                     <ExternalLink className="w-5 h-5 inline-block mr-2 text-blue-500" />
//                                     Job Listing URL
//                                 </label>
//                                 <div className="relative">
//                                     <input
//                                         type="url"
//                                         value={jobLink}
//                                         onClick={() => setIsURLModalOpen(true)}
//                                         placeholder="Click to add job URL..."
//                                         className="w-full px-4 py-3 border rounded-xl bg-white"
//                                         readOnly
//                                     />
//                                 </div>
//                             </div>

//                             {/* Processing Status */}
//                             {(isScrapingJob || isLoading) && (
//                                 <div className="bg-white/50 p-6 rounded-2xl shadow-sm border border-blue-100">
//                                     <div className="flex items-center justify-between mb-4">
//                                         <h3 className="text-lg font-semibold text-blue-700">
//                                             {loadingMessage}
//                                         </h3>
//                                         <Loader2 className="w-5 h-5 animate-spin text-blue-500" />
//                                     </div>
//                                     <ProcessingSteps
//                                         currentStep={processingStep}
//                                         steps={processingSteps}
//                                         progress={processingProgress}
//                                     />
//                                 </div>
//                             )}

//                             {/* Enhance Button */}
//                             <button
//                                 onClick={handleEnhance}
//                                 disabled={isLoading || !file || !jobText}
//                                 className="w-full bg-gradient-to-r from-green-500 to-blue-500 hover:from-green-600 hover:to-blue-600 text-white font-semibold py-4 rounded-xl flex items-center justify-center gap-2 disabled:opacity-50 disabled:cursor-not-allowed transition-all duration-300 shadow-lg hover:shadow-xl"
//                             >
//                                 {isLoading ? (
//                                     <>
//                                         <Loader2 className="w-5 h-5 animate-spin" />
//                                         Optimizing...
//                                     </>
//                                 ) : (
//                                     <>
//                                         <Sparkles className="w-5 h-5" />
//                                         Enhance Resume
//                                     </>
//                                 )}
//                             </button>
//                         </div>
//                     </div>

//                     {/* Middle Column: Resume Content */}
//                     <div className="col-span-12 lg:col-span-5 p-8 bg-white/20">
//                         <div className="flex items-center justify-between mb-6">
//                             <h2 className="text-xl font-bold text-gray-800">Enhanced Resume</h2>
//                             {resumeScore > 0 && (
//                                 <div className="flex items-center gap-2 bg-blue-50 px-4 py-2 rounded-xl">
//                                     <Star className="w-5 h-5 text-yellow-500" />
//                                     <span className="font-bold text-blue-600">{resumeScore}% Match</span>
//                                 </div>
//                             )}
//                         </div>

//                         {/* Optimization Level */}
//                         <div className="mb-6 bg-white/50 p-6 rounded-2xl shadow-sm border border-blue-100">
//                             <div className="flex items-center justify-between mb-4">
//                                 <label className="text-lg font-semibold text-gray-700">
//                                     <Wand2 className="w-5 h-5 inline-block mr-2 text-blue-500" />
//                                     Optimization Level
//                                 </label>
//                                 <span className="text-sm text-blue-600 font-medium">
//                                     {(pufferyLevel * 100).toFixed()}%
//                                 </span>
//                             </div>
//                             <input
//                                 type="range"
//                                 min="0"
//                                 max="1"
//                                 step="0.1"
//                                 value={pufferyLevel}
//                                 onChange={(e) => setPufferyLevel(parseFloat(e.target.value))}
//                                 className="w-full h-2 bg-blue-100 rounded-lg appearance-none cursor-pointer accent-blue-600"
//                             />
//                             <div className="flex justify-between text-sm text-gray-500 mt-2">
//                                 <span>Conservative</span>
//                                 <span>Balanced</span>
//                                 <span>Aggressive</span>
//                             </div>
//                         </div>

//                         {/* Resume Text Area */}
//                         <textarea
//                             value={resumeContent}
//                             onChange={(e) => setResumeContent(e.target.value)}
//                             placeholder="Your enhanced resume will appear here..."
//                             className="w-full h-[calc(100vh-20rem)] p-6 border rounded-2xl bg-white/70 shadow-sm resize-none font-mono text-gray-800"
//                         />
//                     </div>

//                     {/* Right Column: Job Description */}
//                     <div className="col-span-12 lg:col-span-3 p-8 bg-gradient-to-bl from-blue-50/50 to-white border-l border-blue-100">
//                         <h2 className="text-xl font-bold text-gray-800 mb-6">Job Description</h2>
//                         <textarea
//                             value={jobText}
//                             onChange={(e) => setJobText(e.target.value)}
//                             placeholder="Job description will appear here..."
//                             className="w-full h-[calc(100vh-12rem)] p-6 border rounded-2xl bg-white/70 shadow-sm resize-none text-gray-800"
//                         />
//                     </div>
//                 </div>
//             </div>

//             {/* URL Selector Modal */}
//             <JobURLSelectorModal
//                 isOpen={isURLModalOpen}
//                 onClose={() => setIsURLModalOpen(false)}
//                 onSelectURL={handleJobLinkSelect}
//             />
//         </div>
//     )
// }


// import React from 'react'
// import { useState, useEffect } from 'react'
// import {
//     FileText,
//     Upload,
//     FileUp,
//     ExternalLink,
//     ScrollText,
//     Star,
//     Sparkles,
//     Zap,
//     ArrowDown,
//     Wand2
// } from 'lucide-react'

// const templates = {
//     modern: `[MODERN PROFESSIONAL TEMPLATE]
// -----------------------------
// [Name]
// [Contact Info]

// SUMMARY
// -------
// Results-driven professional with proven expertise...

// EXPERIENCE
// ----------
// [Current Role]
// • Achievement 1
// • Achievement 2

// SKILLS
// ------
// • Skill Category 1
// • Skill Category 2`,

//     technical: `[TECHNICAL TEMPLATE]
// --------------------
// [Full Name]
// [Tech Stack Icons]

// TECHNICAL EXPERTISE
// ------------------
// Languages: [List]
// Frameworks: [List]
// Cloud: [List]

// PROJECTS
// --------
// [Project 1]
// • Technical details
// • Impact metrics`,

//     creative: `[CREATIVE TEMPLATE]
// ✦-------------------✦
// ~ [Name] ~
// [Creative Title]

// PORTFOLIO
// ---------
// ✺ Project 1
//   • Concept
//   • Execution
//   • Results`,

//     executive: `[EXECUTIVE TEMPLATE]
// =====================
// [Name], [Credentials]

// EXECUTIVE SUMMARY
// ----------------
// Visionary leader with track record...

// LEADERSHIP IMPACT
// ----------------
// • Revenue Growth
// • Team Development`,

//     startup: `🚀 [STARTUP TEMPLATE]
// -------------------
// [Name]
// [Startup Role]

// ENTREPRENEURIAL IMPACT
// --------------------
// 💡 Venture 1
//    • Growth metrics
//    • User acquisition`,

//     academic: `[ACADEMIC TEMPLATE]
// ====================
// [Name], Ph.D
// [Field of Study]

// RESEARCH FOCUS
// -------------
// • Area 1
// • Area 2`
// }

// export default function EnhancedResumeTool() {
//     const [file, setFile] = useState(null)
//     const [jobLink, setJobLink] = useState('')
//     const [jobText, setJobText] = useState('')
//     const [jobFile, setJobFile] = useState(null)
//     const [resumeContent, setResumeContent] = useState('')
//     const [isLoading, setIsLoading] = useState(false)
//     const [pufferyLevel, setPufferyLevel] = useState(0.4)
//     const [changeFirstJobTitle, setChangeFirstJobTitle] = useState(false)
//     const [resumeScore, setResumeScore] = useState(0)
//     const [selectedTemplate, setSelectedTemplate] = useState('modern')

//     const handleFileChange = (e) => {
//         if (e.target.files && e.target.files[0]) {
//             const selectedFile = e.target.files[0]
//             setFile(selectedFile)

//             const reader = new FileReader()
//             reader.onload = (e) => {
//                 const text = e.target?.result
//                 if (typeof text === 'string') {
//                     setResumeContent(text)
//                 }
//             }
//             reader.readAsText(selectedFile)
//         }
//     }

//     const handleJobFileChange = (e) => {
//         if (e.target.files && e.target.files[0]) {
//             const selectedFile = e.target.files[0]
//             setJobFile(selectedFile)

//             const reader = new FileReader()
//             reader.onload = (e) => {
//                 const text = e.target?.result
//                 if (typeof text === 'string') {
//                     setJobText(text)
//                 }
//             }
//             reader.readAsText(selectedFile)
//         }
//     }

//     const handleTemplateChange = (templateKey) => {
//         setSelectedTemplate(templateKey)
//         setResumeContent(templates[templateKey])
//     }

//     const handleSearch = () => {
//         if (jobLink) {
//             console.log("Searching job listing:", jobLink)
//         }
//     }

//     const handleEnhance = () => {
//         setIsLoading(true)

//         setTimeout(() => {
//             let enhancedContent = resumeContent

//             if (changeFirstJobTitle) {
//                 enhancedContent = enhancedContent.replace(
//                     /^(.*?Job Title:)(.*)$/m,
//                     `$1 Enhanced ${pufferyLevel.toFixed(1)} Specialist`
//                 )
//             }

//             enhancedContent += `\n\nEnhanced content (Puffery Level: ${pufferyLevel.toFixed(1)}):\n`
//             enhancedContent += `- Added more impactful action verbs\n`
//             enhancedContent += `- Quantified achievements where possible\n`
//             enhancedContent += `- Tailored skills to match job listing\n`
//             enhancedContent += `- Optimized keywords for the role\n`

//             setResumeContent(enhancedContent)
//             setResumeScore(prev => Math.min(prev + 20, 100))
//             setIsLoading(false)
//         }, 2000)
//     }

//     useEffect(() => {
//         if (resumeContent && (jobLink || jobText || jobFile)) {
//             const score = Math.floor(Math.random() * 61) + 20
//             setResumeScore(score)
//         }
//     }, [resumeContent, jobLink, jobText, jobFile])

//     const sparkleClass = "animate-[twinkle_1.5s_ease-in-out_infinite]"
//     const fontStyle = `
//     @import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700;800&display=swap');
//   `

//     return (
//         <div className="min-h-screen p-4 md:p-8 bg-gradient-to-br from-blue-50 to-white">
//             <div className="max-w-screen-2xl mx-auto mb-8 bg-blue-50/50 rounded-3xl shadow-sm p-4 md:p-8">
//                 <h1 className="text-2xl sm:text-3xl md:text-7xl font-bold leading-tight bg-clip-text text-transparent bg-gradient-to-r from-green-600 to-blue-600 text-center">
//                     AI Resume Converter
//                     <div className="text-base sm:text-lg md:text-3xl mt-2 md:mt-4 font-medium bg-clip-text text-transparent bg-gradient-to-r from-blue-500 to-green-500">
//                         Transform Your Resume with AI Magic ✨
//                     </div>
//                 </h1>
//             </div>

//             <div className="max-w-screen-2xl mx-auto bg-blue-50/30 rounded-3xl shadow-xl overflow-hidden backdrop-blur-sm">
//                 <div className="grid grid-cols-12 gap-4">
//                     {/* Left Column: Form Inputs */}
//                     <div className="col-span-12 lg:col-span-5 p-8 bg-gradient-to-br from-blue-50/50 to-white">
//                         <div className="flex items-center gap-3 text-2xl font-bold text-blue-600 mb-6">
//                             <Upload className="w-6 h-6" />
//                             Resume Input
//                             <Sparkles className={`w-5 h-5 text-yellow-400 ${sparkleClass}`} />
//                         </div>

//                         <div className="space-y-6">
//                             {/* Resume Upload */}
//                             <div className="bg-blue-50/50 p-6 rounded-2xl shadow-sm border border-blue-100">
//                                 <label className="text-lg font-semibold text-gray-700 flex items-center gap-2">
//                                     <FileUp className="w-5 h-5 text-blue-500" />
//                                     Upload Resume (TXT)
//                                 </label>
//                                 <input
//                                     type="file"
//                                     accept=".txt"
//                                     onChange={handleFileChange}
//                                     className="mt-2 w-full cursor-pointer rounded-xl bg-white/70"
//                                 />
//                             </div>

//                             {/* Job URL */}
//                             <div className="bg-blue-50/50 p-6 rounded-2xl shadow-sm border border-blue-100">
//                                 <label className="text-lg font-semibold text-gray-700 flex items-center gap-2">
//                                     <ExternalLink className="w-5 h-5 text-blue-500" />
//                                     Job Listing URL
//                                 </label>
//                                 <input
//                                     type="url"
//                                     placeholder="https://www.linkedin.com/jobs/view/..."
//                                     value={jobLink}
//                                     onChange={(e) => setJobLink(e.target.value)}
//                                     className="w-full px-4 py-3 mt-2 border rounded-xl bg-white/70"
//                                 />
//                                 <button
//                                     onClick={handleSearch}
//                                     className="w-full mt-2 bg-gradient-to-r from-green-500 to-blue-500 hover:from-green-600 hover:to-blue-600 text-white px-4 py-3 rounded-xl flex items-center justify-center gap-2 transition-all duration-300"
//                                 >
//                                     <ArrowDown className="w-4 h-4" />
//                                     Enter
//                                 </button>
//                             </div>

//                             {/* Job Description */}
//                             <div className="bg-blue-50/50 p-6 rounded-2xl shadow-sm border border-blue-100">
//                                 <label className="text-lg font-semibold text-gray-700 flex items-center gap-2">
//                                     <ScrollText className="w-5 h-5 text-blue-500" />
//                                     Job Description
//                                 </label>
//                                 <textarea
//                                     placeholder="Paste job description here..."
//                                     value={jobText}
//                                     onChange={(e) => setJobText(e.target.value)}
//                                     className="w-full h-40 px-4 py-3 mt-2 border rounded-xl bg-white/70 resize-none"
//                                 />
//                                 <input
//                                     type="file"
//                                     accept=".txt"
//                                     onChange={handleJobFileChange}
//                                     className="mt-2 w-full cursor-pointer rounded-xl bg-white/70"
//                                 />
//                             </div>

//                             {/* Enhance Button */}
//                             <button
//                                 onClick={handleEnhance}
//                                 disabled={isLoading || !file || (!jobLink && !jobText && !jobFile)}
//                                 className="w-full bg-gradient-to-r from-green-500 to-blue-500 hover:from-green-600 hover:to-blue-600 text-white font-semibold py-6 rounded-2xl flex items-center justify-center gap-2 disabled:opacity-50 shadow-lg transition-all duration-300 hover:scale-[1.02]"
//                             >
//                                 {isLoading ? (
//                                     <>
//                                         <Zap className="w-5 h-5 animate-spin" />
//                                         Enhancing...
//                                     </>
//                                 ) : (
//                                     <>
//                                         <Sparkles className={`w-5 h-5 ${sparkleClass}`} />
//                                         Enhance Resume ✨
//                                     </>
//                                 )}
//                             </button>
//                         </div>
//                     </div>

//                     {/* Middle Column: Enhanced Resume */}
//                     <div className="col-span-12 lg:col-span-4 p-8 border-l border-r border-blue-100 bg-gradient-to-br from-blue-50/30 to-white">
//                         <div className="flex items-center justify-between mb-6">
//                             <div className="flex items-center gap-3 text-2xl font-bold text-green-600">
//                                 <FileText className="w-6 h-6" />
//                                 Enhanced Resume
//                             </div>
//                             <div className="text-xl font-bold text-blue-600 flex items-center gap-2 bg-blue-50/80 px-4 py-2 rounded-xl">
//                                 <Star className="w-5 h-5" />
//                                 {resumeScore}%
//                             </div>
//                         </div>

//                         {/* Puffery Slider */}
//                         <div className="mb-6 bg-blue-50/50 rounded-2xl p-6 shadow-sm border border-blue-100">
//                             <div className="flex items-center gap-2 mb-2">
//                                 <Wand2 className="w-5 h-5 text-blue-500" />
//                                 <label className="text-lg font-semibold text-gray-700">
//                                     Puffery Level ✨
//                                 </label>
//                             </div>
//                             <input
//                                 type="range"
//                                 min="0"
//                                 max="1"
//                                 step="0.2"
//                                 value={pufferyLevel}
//                                 onChange={(e) => setPufferyLevel(parseFloat(e.target.value))}
//                                 className="w-full h-3 bg-blue-100 rounded-lg appearance-none cursor-pointer accent-blue-500"
//                             />
//                             <div className="text-right text-sm text-gray-600 mt-1">
//                                 Level: {pufferyLevel.toFixed(1)}
//                             </div>
//                         </div>

//                         {/* Resume Content */}
//                         <textarea
//                             value={resumeContent}
//                             onChange={(e) => setResumeContent(e.target.value)}
//                             placeholder="Your enhanced resume will appear here..."
//                             className="w-full h-[calc(100vh-24rem)] p-6 border border-blue-100 rounded-2xl resize-none font-mono bg-white/70 shadow-sm"
//                         />
//                     </div>

//                     {/* Right Column: Template Carousel */}
//                     <div className="col-span-12 lg:col-span-3 p-8 bg-blue-50/50 rounded-l-3xl">
//                         <div className="flex items-center gap-3 text-2xl font-bold text-blue-600 mb-6">
//                             <FileText className="w-6 h-6" />
//                             Templates
//                         </div>

//                         <div className="space-y-4 h-[calc(100vh-16rem)] overflow-y-auto pr-2 scrollbar-thin scrollbar-thumb-blue-200 scrollbar-track-transparent">
//                             {Object.entries(templates).map(([key, value]) => (
//                                 <div
//                                     key={key}
//                                     onClick={() => handleTemplateChange(key)}
//                                     className={`p-4 rounded-2xl border-2 transition-all duration-300 cursor-pointer hover:scale-105 ${selectedTemplate === key
//                                         ? 'border-blue-500 shadow-lg bg-blue-50/80'
//                                         : 'border-blue-100 hover:border-blue-300 bg-white/70'
//                                         }`}
//                                 >
//                                     <div className="flex flex-col">
//                                         <div className={`w-full h-24 mb-3 rounded-xl flex items-center justify-center ${key === 'modern' ? 'bg-gradient-to-br from-blue-400 to-blue-600' :
//                                             key === 'technical' ? 'bg-gradient-to-br from-gray-700 to-gray-900' :
//                                                 key === 'creative' ? 'bg-gradient-to-br from-purple-400 to-pink-500' :
//                                                     key === 'executive' ? 'bg-gradient-to-br from-blue-800 to-blue-900' :
//                                                         key === 'startup' ? 'bg-gradient-to-br from-green-400 to-blue-500' :
//                                                             'bg-gradient-to-br from-yellow-400 to-red-500'
//                                             }`}>
//                                             <div className="text-white text-4xl">
//                                                 {key === 'modern' && '📄'}
//                                                 {key === 'technical' && '💻'}
//                                                 {key === 'creative' && '🎨'}
//                                                 {key === 'executive' && '👔'}
//                                                 {key === 'startup' && '🚀'}
//                                                 {key === 'academic' && '🎓'}
//                                             </div>
//                                         </div>

//                                         <h4 className="font-semibold text-gray-800 mb-2">
//                                             {key.charAt(0).toUpperCase() + key.slice(1)}
//                                         </h4>

//                                         <p className="text-sm text-gray-600">
//                                             {key === 'modern' && "Clean and professional format"}
//                                             {key === 'technical' && "Technical skills focused"}
//                                             {key === 'creative' && "Visual creative layout"}
//                                             {key === 'executive' && "Leadership focused"}
//                                             {key === 'startup' && "Dynamic startup style"}
//                                             {key === 'academic' && "Academic structure"}
//                                         </p>
//                                     </div>
//                                 </div>
//                             ))}
//                         </div>
//                     </div>
//                 </div>
//             </div>
//         </div>
//     )
// }


import React, { useState, useEffect } from 'react';
import {
    FileText,
    Upload,
    FileUp,
    ExternalLink,
    ScrollText,
    Star,
    Sparkles,
    Zap,
    ArrowDown,
    Wand2,
    Loader2,
    Check,
    X,
    AlertCircle
} from 'lucide-react';

// Modal Component using pure Tailwind
const Modal = ({ isOpen, onClose, children }) => {
    if (!isOpen) return null;

    return (
        <div className="fixed inset-0 z-50 overflow-y-auto">
            <div className="flex min-h-screen items-center justify-center p-4 text-center">
                <div
                    className="fixed inset-0 bg-black/30 transition-opacity"
                    onClick={onClose}
                />
                <div className="relative w-full max-w-md transform rounded-2xl bg-white p-6 text-left shadow-xl transition-all">
                    {children}
                </div>
            </div>
        </div>
    );
};

// Alert Component using pure Tailwind
const Alert = ({ variant = 'default', children }) => {
    const baseStyles = "p-4 rounded-lg mb-4";
    const styles = {
        default: "bg-blue-50 text-blue-800 border border-blue-200",
        error: "bg-red-50 text-red-800 border border-red-200",
        success: "bg-green-50 text-green-800 border border-green-200"
    };

    return (
        <div className={`${baseStyles} ${styles[variant]}`}>
            {children}
        </div>
    );
};

const templates = {
    modern: `[MODERN PROFESSIONAL TEMPLATE]
-----------------------------
[Name]
[Contact Info]

SUMMARY
-------
Results-driven professional with proven expertise...

EXPERIENCE
----------
[Current Role]
• Achievement 1
• Achievement 2

SKILLS
------
• Skill Category 1
• Skill Category 2`,

    technical: `[TECHNICAL TEMPLATE]
--------------------
[Full Name]
[Tech Stack Icons]

TECHNICAL EXPERTISE
------------------
Languages: [List]
Frameworks: [List]
Cloud: [List]

PROJECTS
--------
[Project 1]
• Technical details
• Impact metrics`,

    creative: `[CREATIVE TEMPLATE]
✦-------------------✦
~ [Name] ~
[Creative Title]

PORTFOLIO
---------
✺ Project 1
  • Concept
  • Execution
  • Results`,

    executive: `[EXECUTIVE TEMPLATE]
=====================
[Name], [Credentials]

EXECUTIVE SUMMARY
----------------
Visionary leader with track record...

LEADERSHIP IMPACT
----------------
• Revenue Growth
• Team Development`,

    startup: `🚀 [STARTUP TEMPLATE]
-------------------
[Name]
[Startup Role]

ENTREPRENEURIAL IMPACT
--------------------
💡 Venture 1
   • Growth metrics
   • User acquisition`,

    academic: `[ACADEMIC TEMPLATE]
====================
[Name], Ph.D
[Field of Study]

RESEARCH FOCUS
-------------
• Area 1
• Area 2`
};

const JobURLSelectorModal = ({ isOpen, onClose, onSelectURL }) => {
    const [url, setUrl] = useState('');
    const [selectedSite, setSelectedSite] = useState(null);

    const jobSites = [
        {
            name: 'LinkedIn',
            domain: 'linkedin.com',
            icon: (
                <svg viewBox="0 0 40 40" className="w-8 h-8" fill="none">
                    <rect width="40" height="40" rx="8" fill="#0A66C2" />
                    <path d="M15 16h-4v12h4V16zm-2-6.8a2.4 2.4 0 110 4.8 2.4 2.4 0 010-4.8zM29 28h-4v-6.2c0-3.2-4-3-4 0V28h-4V16h4v1.8c1.4-2.6 8-2.8 8 2.5V28z" fill="white" />
                </svg>
            )
        },
        {
            name: 'Indeed',
            domain: 'indeed.com',
            icon: (
                <svg viewBox="0 0 40 40" className="w-8 h-8" fill="none">
                    <rect width="40" height="40" rx="8" fill="#2164F3" />
                    <path d="M12 10h6v20h-6z" fill="#FFFFFF" />
                    <path d="M22 10h6v20h-6z" fill="#FFFFFF" />
                </svg>
            )
        },
        {
            name: 'Glassdoor',
            domain: 'glassdoor.com',
            icon: (
                <svg viewBox="0 0 40 40" className="w-8 h-8" fill="none">
                    <rect width="40" height="40" rx="8" fill="#0CAA41" />
                    <path d="M20 6c-7.7 0-14 6.3-14 14s6.3 14 14 14 14-6.3 14-14S27.7 6 20 6zm0 22c-4.4 0-8-3.6-8-8s3.6-8 8-8 8 3.6 8 8-3.6 8-8 8zm0-13c-2.8 0-5 2.2-5 5s2.2 5 5 5 5-2.2 5-5-2.2-5-5-5z" fill="white" />
                </svg>
            )
        },
        {
            name: 'Dice',
            domain: 'dice.com',
            icon: (
                <svg viewBox="0 0 40 40" className="w-8 h-8" fill="none">
                    <rect width="40" height="40" rx="8" fill="#EB1C26" />
                    <path d="M10 10h20v20H10z" fill="white" />
                    <circle cx="15" cy="15" r="2" fill="#EB1C26" />
                    <circle cx="25" cy="15" r="2" fill="#EB1C26" />
                    <circle cx="15" cy="25" r="2" fill="#EB1C26" />
                    <circle cx="25" cy="25" r="2" fill="#EB1C26" />
                </svg>
            )
        },
        {
            name: 'Monster',
            domain: 'monster.com',
            icon: (
                <svg viewBox="0 0 40 40" className="w-8 h-8" fill="none">
                    <rect width="40" height="40" rx="8" fill="#6E45A5" />
                    <path d="M20 10c-5.5 0-10 4.5-10 10s4.5 10 10 10 10-4.5 10-10-4.5-10-10-10zm0 15c-2.8 0-5-2.2-5-5s2.2-5 5-5 5 2.2 5 5-2.2 5-5 5z" fill="white" />
                </svg>
            )
        },
        {
            name: 'Talent.com',
            domain: 'talent.com',
            icon: (
                <svg viewBox="0 0 40 40" className="w-8 h-8" fill="none">
                    <rect width="40" height="40" rx="8" fill="#1D2C4D" />
                    <path d="M8 20.5L15 28l3-3-4-4 4-4-3-3L8 20.5z" fill="white" />
                    <path d="M32 20.5L25 28l-3-3 4-4-4-4 3-3L32 20.5z" fill="white" />
                </svg>
            )
        },
        {
            name: 'Greenhouse',
            domain: 'greenhouse.io',
            icon: (
                <svg viewBox="0 0 40 40" className="w-8 h-8" fill="none">
                    <rect width="40" height="40" rx="8" fill="#3AB549" />
                    <path d="M20 8c-6.6 0-12 5.4-12 12s5.4 12 12 12 12-5.4 12-12S26.6 8 20 8zm0 18c-3.3 0-6-2.7-6-6s2.7-6 6-6 6 2.7 6 6-2.7 6-6 6z" fill="white" />
                    <path d="M20 16c-2.2 0-4 1.8-4 4s1.8 4 4 4 4-1.8 4-4-1.8-4-4-4zm0 6c-1.1 0-2-.9-2-2s.9-2 2-2 2 .9 2 2-.9 2-2 2z" fill="white" />
                </svg>
            )
        }
    ]

    const handleContinue = () => {
        if (url) {
            onSelectURL(url);
            onClose();
            setUrl('');
        }
    };

    return (
        <Modal isOpen={isOpen} onClose={onClose}>
            <div className="mb-6">
                <h2 className="text-xl font-bold text-gray-900">
                    Paste a link to the job listing
                </h2>
                <p className="mt-2 text-sm text-gray-500">
                    We'll analyze the job description to optimize your resume
                </p>
            </div>

            <div className="space-y-6">
                <div className="relative">
                    <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                        <span className="text-gray-400">🔒</span>
                    </div>
                    <input
                        type="url"
                        value={url}
                        onChange={(e) => setUrl(e.target.value)}
                        className="w-full pl-10 pr-4 py-3 border rounded-lg bg-gray-50 text-gray-900 placeholder:text-gray-400"
                        placeholder="jobs.com/company/position"
                    />
                </div>

                <div>
                    <h4 className="text-sm font-medium text-gray-500 mb-3">
                        Supported Job Sites
                    </h4>
                    <div className="grid grid-cols-2 gap-4">
                        {jobSites.map((site) => (
                            <div
                                key={site.domain}
                                onClick={() => setSelectedSite(site.name)}
                                className={`flex items-center gap-3 p-3 rounded-lg cursor-pointer transition-all duration-200 ${selectedSite === site.name
                                    ? 'bg-blue-50 border-2 border-blue-200'
                                    : 'border-2 border-transparent hover:bg-gray-50'
                                    }`}
                            >
                                {site.icon}
                                <span className="text-sm font-medium text-gray-600">
                                    {site.name}
                                </span>
                            </div>
                        ))}
                    </div>
                </div>

                <div className="flex justify-end gap-4 mt-6">
                    <button
                        onClick={onClose}
                        className="px-4 py-2 rounded-lg border hover:bg-gray-50"
                    >
                        Cancel
                    </button>
                    <button
                        onClick={handleContinue}
                        disabled={!url}
                        className="px-4 py-2 rounded-lg bg-blue-600 text-white hover:bg-blue-700 disabled:opacity-50 disabled:cursor-not-allowed"
                    >
                        Continue
                    </button>
                </div>
            </div>
        </Modal>
    );
};

const ProcessingSteps = ({ currentStep, steps, progress }) => {
    return (
        <div className="space-y-4">
            {steps.map((step, index) => (
                <div
                    key={step}
                    className={`flex items-center gap-3 ${index <= currentStep ? 'text-blue-600' : 'text-gray-400'
                        }`}
                >
                    <div className="w-6 h-6 flex-shrink-0">
                        {index < currentStep ? (
                            <Check className="w-6 h-6 text-green-500" />
                        ) : index === currentStep ? (
                            <Loader2 className="w-6 h-6 animate-spin" />
                        ) : (
                            <div className="w-6 h-6 rounded-full border-2 border-current" />
                        )}
                    </div>
                    <span className="text-sm font-medium">{step}</span>
                </div>
            ))}
            <div className="mt-4">
                <div className="h-2 bg-gray-100 rounded-full">
                    <div
                        className="h-full bg-blue-600 rounded-full transition-all duration-500"
                        style={{ width: `${progress}%` }}
                    />
                </div>
            </div>
        </div>
    );
};
// ... Previous code (Modal, Alert, Templates, etc.) remains the same ...

export default function EnhancedResumeTool() {
    const [file, setFile] = useState(null);
    const [jobLink, setJobLink] = useState('');
    const [jobText, setJobText] = useState('');
    const [jobFile, setJobFile] = useState(null);
    const [resumeContent, setResumeContent] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [isScrapingJob, setIsScrapingJob] = useState(false);
    const [pufferyLevel, setPufferyLevel] = useState(0.4);
    const [changeFirstJobTitle, setChangeFirstJobTitle] = useState(false);
    const [resumeScore, setResumeScore] = useState(0);
    const [selectedTemplate, setSelectedTemplate] = useState('modern');
    const [scrapeError, setScrapeError] = useState('');
    const [isURLModalOpen, setIsURLModalOpen] = useState(false);
    const [processingStep, setProcessingStep] = useState(0);
    const [processingProgress, setProcessingProgress] = useState(0);
    const [loadingMessage, setLoadingMessage] = useState('');

    const processingSteps = [
        "Analyzing Job Requirements",
        "Identifying Key Skills",
        "Matching Experience",
        "Optimizing Keywords",
        "Calculating Match Score"
    ];

    const loadingMessages = [
        "Parsing job description...",
        "Extracting key requirements...",
        "Analyzing your experience...",
        "Optimizing content...",
        "Fine-tuning matching...",
        "Almost there..."
    ];

    useEffect(() => {
        let messageIndex = 0;
        let interval;

        if (isLoading || isScrapingJob) {
            interval = setInterval(() => {
                setLoadingMessage(loadingMessages[messageIndex]);
                messageIndex = (messageIndex + 1) % loadingMessages.length;
            }, 2000);
        }

        return () => clearInterval(interval);
    }, [isLoading, isScrapingJob]);

    const handleFileChange = (e) => {
        if (e.target.files && e.target.files[0]) {
            const selectedFile = e.target.files[0];
            setFile(selectedFile);

            const reader = new FileReader();
            reader.onload = (e) => {
                const text = e.target?.result;
                if (typeof text === 'string') {
                    setResumeContent(text);
                }
            };
            reader.readAsText(selectedFile);
        }
    };

    const handleJobFileChange = (e) => {
        if (e.target.files && e.target.files[0]) {
            const selectedFile = e.target.files[0];
            setJobFile(selectedFile);

            const reader = new FileReader();
            reader.onload = (e) => {
                const text = e.target?.result;
                if (typeof text === 'string') {
                    setJobText(text);
                }
            };
            reader.readAsText(selectedFile);
        }
    };

    const handleTemplateChange = (templateKey) => {
        setSelectedTemplate(templateKey);
        setResumeContent(templates[templateKey]);
    };

    const handleSearch = () => {
        if (jobLink) {
            console.log("Searching job listing:", jobLink);
        }
    };

    const simulateProcessing = () => {
        setProcessingStep(0);
        setProcessingProgress(0);

        const stepDuration = 1000;
        const progressInterval = 50;
        const totalSteps = processingSteps.length;

        let currentStep = 0;
        let currentProgress = 0;

        const stepTimer = setInterval(() => {
            if (currentStep >= totalSteps) {
                clearInterval(stepTimer);
                return;
            }

            setProcessingStep(currentStep);
            currentStep++;
        }, stepDuration);

        const progressTimer = setInterval(() => {
            if (currentProgress >= 100) {
                clearInterval(progressTimer);
                return;
            }

            currentProgress += 1;
            setProcessingProgress(currentProgress);
        }, progressInterval);
    };

    const handleJobLinkSelect = async (url) => {
        setJobLink(url);
        setIsScrapingJob(true);
        setScrapeError('');
        setProcessingStep(0);
        setProcessingProgress(0);

        try {
            const response = await fetch('https://e0a0-74-88-7-3.ngrok-free.app/scrape-job', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ url })
            });

            const data = await response.json();

            if (data.error) {
                setScrapeError(data.error);
                return;
            }

            if (data.raw_text) {
                setJobText(data.raw_text);
                simulateProcessing();
            }
        } catch (error) {
            setScrapeError('Failed to fetch job description. Please try again.');
        } finally {
            setIsScrapingJob(false);
        }
    };

    const handleEnhance = () => {
        setIsLoading(true);
        simulateProcessing();

        setTimeout(() => {
            let enhancedContent = resumeContent;

            if (changeFirstJobTitle) {
                enhancedContent = enhancedContent.replace(
                    /^(.*?Job Title:)(.*)$/m,
                    `$1 Enhanced ${pufferyLevel.toFixed(1)} Specialist`
                );
            }

            enhancedContent += `\n\nEnhanced content (Puffery Level: ${pufferyLevel.toFixed(1)}):\n`;
            enhancedContent += `- Added more impactful action verbs\n`;
            enhancedContent += `- Quantified achievements where possible\n`;
            enhancedContent += `- Tailored skills to match job listing\n`;
            enhancedContent += `- Optimized keywords for the role\n`;

            setResumeContent(enhancedContent);
            setResumeScore(prev => Math.min(prev + 20, 100));
            setIsLoading(false);
        }, 2000);
    };

    const sparkleClass = "animate-[twinkle_1.5s_ease-in-out_infinite]";

    return (
        <div className="min-h-screen p-4 md:p-8 bg-gradient-to-br from-blue-50 to-white">
            {/* Header */}
            <div className="max-w-screen-2xl mx-auto mb-8 bg-blue-50/50 rounded-3xl shadow-sm p-4 md:p-8">
                <h1 className="text-2xl sm:text-3xl md:text-7xl font-bold leading-tight bg-clip-text text-transparent bg-gradient-to-r from-green-600 to-blue-600 text-center">
                    AI Resume Converter
                    <div className="text-base sm:text-lg md:text-3xl mt-2 md:mt-4 font-medium bg-clip-text text-transparent bg-gradient-to-r from-blue-500 to-green-500">
                        Make your resume perfect for any job with AI Magic ✨
                    </div>
                </h1>
            </div>

            {/* Main Content */}
            <div className="max-w-screen-2xl mx-auto bg-blue-50/30 rounded-3xl shadow-xl overflow-hidden backdrop-blur-sm">
                <div className="grid grid-cols-12 gap-4">
                    {/* Left Column: Form Inputs */}
                    <div className="col-span-12 lg:col-span-5 p-8 bg-gradient-to-br from-blue-50/50 to-white">
                        <div className="flex items-center gap-3 text-2xl font-bold text-blue-600 mb-6">
                            <Upload className="w-6 h-6" />
                            Your Current Resume
                            <Sparkles className={`w-5 h-5 text-yellow-400 ${sparkleClass}`} />
                        </div>

                        <div className="space-y-6">
                            {/* Resume Upload */}
                            <div className="bg-blue-50/50 p-6 rounded-2xl shadow-sm border border-blue-100">
                                <label className="text-lg font-semibold text-gray-700 flex items-center gap-2">
                                    <FileUp className="w-5 h-5 text-blue-500" />
                                    Upload Resume
                                </label>
                                <input
                                    type="file"
                                    accept=".txt"
                                    onChange={handleFileChange}
                                    className="mt-2 w-full cursor-pointer rounded-xl bg-white/70"
                                />
                            </div>

                            {/* Job URL */}
                            <div className="bg-blue-50/50 p-6 rounded-2xl shadow-sm border border-blue-100">
                                <label className="text-lg font-semibold text-gray-700 flex items-center gap-2">
                                    <ExternalLink className="w-5 h-5 text-blue-500" />
                                    Job Listing URL (the job your applying to)
                                </label>
                                <input
                                    type="url"
                                    placeholder="https://www.linkedin.com/jobs/view/..."
                                    value={jobLink}
                                    onClick={() => setIsURLModalOpen(true)}
                                    className="w-full px-4 py-3 mt-2 border rounded-xl bg-white/70"
                                    readOnly
                                />
                                <div className="mt-1">
                                    <p className="text-xs text-gray-500 mb-1">Paste a URL from any of these job sites:</p>
                                    <div className="grid grid-cols-7 gap-1 mx-auto max-w-md">
                                        {/* LinkedIn */}
                                        <div onClick={() => setIsURLModalOpen(true)}
                                            className="flex items-center justify-center p-1.5 rounded-lg hover:bg-blue-50 transition-all cursor-pointer">
                                            <svg viewBox="0 0 40 40" className="w-5 h-5" fill="none">
                                                <rect width="40" height="40" rx="8" fill="#0A66C2" />
                                                <path d="M15 16h-4v12h4V16zm-2-6.8a2.4 2.4 0 110 4.8 2.4 2.4 0 010-4.8zM29 28h-4v-6.2c0-3.2-4-3-4 0V28h-4V16h4v1.8c1.4-2.6 8-2.8 8 2.5V28z" fill="white" />
                                            </svg>
                                        </div>

                                        {/* Indeed */}
                                        <div onClick={() => setIsURLModalOpen(true)}
                                            className="flex items-center justify-center p-1.5 rounded-lg hover:bg-blue-50 transition-all cursor-pointer">
                                            <svg viewBox="0 0 40 40" className="w-5 h-5" fill="none">
                                                <rect width="40" height="40" rx="8" fill="#2164F3" />
                                                <path d="M12 10h6v20h-6z" fill="#FFFFFF" />
                                                <path d="M22 10h6v20h-6z" fill="#FFFFFF" />
                                            </svg>
                                        </div>

                                        {/* Glassdoor */}
                                        <div onClick={() => setIsURLModalOpen(true)}
                                            className="flex items-center justify-center p-1.5 rounded-lg hover:bg-blue-50 transition-all cursor-pointer">
                                            <svg viewBox="0 0 40 40" className="w-5 h-5" fill="none">
                                                <rect width="40" height="40" rx="8" fill="#0CAA41" />
                                                <path d="M20 6c-7.7 0-14 6.3-14 14s6.3 14 14 14 14-6.3 14-14S27.7 6 20 6zm0 22c-4.4 0-8-3.6-8-8s3.6-8 8-8 8 3.6 8 8-3.6 8-8 8zm0-13c-2.8 0-5 2.2-5 5s2.2 5 5 5 5-2.2 5-5-2.2-5-5-5z" fill="white" />
                                            </svg>
                                        </div>

                                        {/* Dice */}
                                        <div onClick={() => setIsURLModalOpen(true)}
                                            className="flex items-center justify-center p-1.5 rounded-lg hover:bg-blue-50 transition-all cursor-pointer">
                                            <svg viewBox="0 0 40 40" className="w-5 h-5" fill="none">
                                                <rect width="40" height="40" rx="8" fill="#EB1C26" />
                                                <path d="M10 10h20v20H10z" fill="white" />
                                                <circle cx="15" cy="15" r="2" fill="#EB1C26" />
                                                <circle cx="25" cy="15" r="2" fill="#EB1C26" />
                                                <circle cx="15" cy="25" r="2" fill="#EB1C26" />
                                                <circle cx="25" cy="25" r="2" fill="#EB1C26" />
                                            </svg>
                                        </div>

                                        {/* Monster */}
                                        <div onClick={() => setIsURLModalOpen(true)}
                                            className="flex items-center justify-center p-1.5 rounded-lg hover:bg-blue-50 transition-all cursor-pointer">
                                            <svg viewBox="0 0 40 40" className="w-5 h-5" fill="none">
                                                <rect width="40" height="40" rx="8" fill="#6E45A5" />
                                                <path d="M20 10c-5.5 0-10 4.5-10 10s4.5 10 10 10 10-4.5 10-10-4.5-10-10-10zm0 15c-2.8 0-5-2.2-5-5s2.2-5 5-5 5 2.2 5 5-2.2 5-5 5z" fill="white" />
                                            </svg>
                                        </div>

                                        {/* ZipRecruiter */}
                                        <div onClick={() => setIsURLModalOpen(true)}
                                            className="flex items-center justify-center p-1.5 rounded-lg hover:bg-blue-50 transition-all cursor-pointer">
                                            <svg viewBox="0 0 40 40" className="w-5 h-5" fill="none">
                                                <rect width="40" height="40" rx="8" fill="#1D2C4D" />
                                                <path d="M8 20.5L15 28l3-3-4-4 4-4-3-3L8 20.5z" fill="white" />
                                                <path d="M32 20.5L25 28l-3-3 4-4-4-4 3-3L32 20.5z" fill="white" />
                                            </svg>
                                        </div>

                                        {/* CareerBuilder */}
                                        <div onClick={() => setIsURLModalOpen(true)}
                                            className="flex items-center justify-center p-1.5 rounded-lg hover:bg-blue-50 transition-all cursor-pointer">
                                            <svg viewBox="0 0 40 40" className="w-5 h-5" fill="none">
                                                <rect width="40" height="40" rx="8" fill="#3AB549" />
                                                <path d="M20 8c-6.6 0-12 5.4-12 12s5.4 12 12 12 12-5.4 12-12S26.6 8 20 8zm0 18c-3.3 0-6-2.7-6-6s2.7-6 6-6 6 2.7 6 6-2.7 6-6 6z" fill="white" />
                                                <path d="M20 16c-2.2 0-4 1.8-4 4s1.8 4 4 4 4-1.8 4-4-1.8-4-4-4zm0 6c-1.1 0-2-.9-2-2s.9-2 2-2 2 .9 2 2-.9 2-2 2z" fill="white" />
                                            </svg>
                                        </div>
                                    </div>
                                </div>
                                {jobLink && (
                                    <button
                                        onClick={handleSearch}
                                        className="w-full mt-4 bg-gradient-to-r from-green-500 to-blue-500 hover:from-green-600 hover:to-blue-600 text-white px-4 py-3 rounded-xl flex items-center justify-center gap-2 transition-all duration-300"
                                    >
                                        <ArrowDown className="w-4 h-4" />
                                        Analyze Job
                                    </button>
                                )}
                            </div>

                            {/* Job Description */}
                            <div className="bg-blue-50/50 p-6 rounded-2xl shadow-sm border border-blue-100">
                                <label className="text-lg font-semibold text-gray-700 flex items-center gap-2">
                                    <ScrollText className="w-5 h-5 text-blue-500" />
                                    Job Description (pre-populates with link)
                                </label>
                                <textarea
                                    placeholder="Paste job description here..."
                                    value={jobText}
                                    onChange={(e) => setJobText(e.target.value)}
                                    className="w-full h-40 px-4 py-3 mt-2 border rounded-xl bg-white/70 resize-none"
                                />

                            </div>

                            {/* Processing Status */}
                            {(isScrapingJob || isLoading) && (
                                <div className="bg-white/50 p-6 rounded-2xl shadow-sm border border-blue-100">
                                    <div className="flex items-center justify-between mb-4">
                                        <h3 className="text-lg font-semibold text-blue-700">
                                            {loadingMessage}
                                        </h3>
                                        <Loader2 className="w-5 h-5 animate-spin text-blue-500" />
                                    </div>
                                    <ProcessingSteps
                                        currentStep={processingStep}
                                        steps={processingSteps}
                                        progress={processingProgress}
                                    />
                                </div>
                            )}

                            {/* Enhance Button */}
                            <button
                                onClick={handleEnhance}
                                disabled={isLoading || !file || (!jobLink && !jobText && !jobFile)}
                                className="w-full bg-gradient-to-r from-green-500 to-blue-500 hover:from-green-600 hover:to-blue-600 text-white font-semibold py-6 rounded-2xl flex items-center justify-center gap-2 disabled:opacity-50 shadow-lg transition-all duration-300 hover:scale-[1.02]"
                            >
                                {isLoading ? (
                                    <>
                                        <Zap className="w-5 h-5 animate-spin" />
                                        Enhancing...
                                    </>
                                ) : (
                                    <>
                                        <Sparkles className={`w-5 h-5 ${sparkleClass}`} />
                                        Enhance Resume ✨
                                    </>
                                )}
                            </button>
                        </div>
                    </div>

                    {/* Middle Column: Enhanced Resume */}
                    <div className="col-span-12 lg:col-span-4 p-8 border-l border-r border-blue-100 bg-gradient-to-br from-blue-50/30 to-white">
                        <div className="flex items-center justify-between mb-6">
                            <div className="flex items-center gap-3 text-2xl font-bold text-green-600">
                                <FileText className="w-6 h-6" />
                                Enhanced Resume
                            </div>
                            <div className="text-xl font-bold text-blue-600 flex items-center gap-2 bg-blue-50/80 px-4 py-2 rounded-xl">
                                <Star className="w-5 h-5" />
                                {resumeScore}%
                            </div>
                        </div>

                        {/* Puffery Slider */}
                        <div className="mb-6 bg-blue-50/50 rounded-2xl p-6 shadow-sm border border-blue-100">
                            <div className="flex items-center gap-2 mb-2">
                                <Wand2 className="w-5 h-5 text-blue-500" />
                                <label className="text-lg font-semibold text-gray-700">
                                    Puffery Level ✨
                                </label>
                            </div>
                            <input
                                type="range"
                                min="0"
                                max="1"
                                step="0.2"
                                value={pufferyLevel}
                                onChange={(e) => setPufferyLevel(parseFloat(e.target.value))}
                                className="w-full h-3 bg-blue-100 rounded-lg appearance-none cursor-pointer accent-blue-500"
                            />
                            <div className="text-right text-sm text-gray-600 mt-1">
                                Level: {pufferyLevel.toFixed(1)}
                            </div>
                        </div>

                        {/* Resume Content */}
                        <textarea
                            value={resumeContent}
                            onChange={(e) => setResumeContent(e.target.value)}
                            placeholder="Your enhanced resume will appear here..."
                            className="w-full h-[calc(100vh-24rem)] p-6 border border-blue-100 rounded-2xl resize-none font-mono bg-white/70 shadow-sm"
                        />
                    </div>

                    {/* Right Column: Template Carousel */}
                    <div className="col-span-12 lg:col-span-3 p-8 bg-blue-50/50 rounded-l-3xl">
                        <div className="flex items-center gap-3 text-2xl font-bold text-blue-600 mb-6">
                            <FileText className="w-6 h-6" />
                            Templates
                        </div>

                        <div className="space-y-4 h-[calc(100vh-16rem)] overflow-y-auto pr-2 scrollbar-thin scrollbar-thumb-blue-200 scrollbar-track-transparent">
                            {Object.entries(templates).map(([key, value]) => (
                                <div
                                    key={key}
                                    onClick={() => handleTemplateChange(key)}
                                    className={`p-4 rounded-2xl border-2 transition-all duration-300 cursor-pointer hover:scale-105 ${selectedTemplate === key
                                        ? 'border-blue-500 shadow-lg bg-blue-50/80'
                                        : 'border-blue-100 hover:border-blue-300 bg-white/70'
                                        }`}
                                >
                                    <div className="flex flex-col">
                                        <div className={`w-full h-24 mb-3 rounded-xl flex items-center justify-center ${key === 'modern' ? 'bg-gradient-to-br from-blue-400 to-blue-600' :
                                            key === 'technical' ? 'bg-gradient-to-br from-gray-700 to-gray-900' :
                                                key === 'creative' ? 'bg-gradient-to-br from-purple-400 to-pink-500' :
                                                    key === 'executive' ? 'bg-gradient-to-br from-blue-800 to-blue-900' :
                                                        key === 'startup' ? 'bg-gradient-to-br from-green-400 to-blue-500' :
                                                            'bg-gradient-to-br from-yellow-400 to-red-500'
                                            }`}>
                                            <div className="text-white text-4xl">
                                                {key === 'modern' && '📄'}
                                                {key === 'technical' && '💻'}
                                                {key === 'creative' && '🎨'}
                                                {key === 'executive' && '👔'}
                                                {key === 'startup' && '🚀'}
                                                {key === 'academic' && '🎓'}
                                            </div>
                                        </div>

                                        <h4 className="font-semibold text-gray-800 mb-2">
                                            {key.charAt(0).toUpperCase() + key.slice(1)}
                                        </h4>

                                        <p className="text-sm text-gray-600">
                                            {key === 'modern' && "Clean and professional format"}
                                            {key === 'technical' && "Technical skills focused"}
                                            {key === 'creative' && "Visual creative layout"}
                                            {key === 'executive' && "Leadership focused"}
                                            {key === 'startup' && "Dynamic startup style"}
                                            {key === 'academic' && "Academic structure"}
                                        </p>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>

            {/* URL Selector Modal */}
            <JobURLSelectorModal
                isOpen={isURLModalOpen}
                onClose={() => setIsURLModalOpen(false)}
                onSelectURL={handleJobLinkSelect}
            />

            {/* Error Alert */}
            {scrapeError && (
                <Alert variant="error">
                    <div className="flex items-center gap-2">
                        <AlertCircle className="w-5 h-5" />
                        {scrapeError}
                    </div>
                </Alert>
            )}
        </div>
    );
}