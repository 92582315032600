// components/ThemeSection.js
import React, { useState, useEffect } from 'react';
import { ChevronLeft, ChevronRight, Loader2, Edit2, Eye, PlusCircle, Trash2, Download, FileText } from 'lucide-react';

// Sample themes in case API fails
const DEFAULT_THEMES = [
    {
        id: 'elegant',
        name: 'Elegant',
        description: 'Clean and professional design with excellent typography'
    },
    {
        id: 'modern',
        name: 'Modern',
        description: 'Contemporary and bold design for creative professionals'
    },
    {
        id: 'classic',
        name: 'Classic',
        description: 'Traditional resume format, perfect for formal applications'
    }
];

const ThemeSection = ({
    basics,
    setBasics,
    work,
    setWork,
    education,
    setEducation,
    selectedTheme,
    setSelectedTheme,
    jobAnalysis
}) => {
    const [currentIndex, setCurrentIndex] = useState(0);
    const [loading, setLoading] = useState(false);
    const [isRocking, setIsRocking] = useState(false);
    const [direction, setDirection] = useState(0);
    const [themes, setThemes] = useState(DEFAULT_THEMES);
    const [previewHtml, setPreviewHtml] = useState('');
    const [viewMode, setViewMode] = useState('split');
    const [isExporting, setIsExporting] = useState(false);
    const [exportProgress, setExportProgress] = useState(0);

    // Fetch available themes on mount
    useEffect(() => {
        fetchThemes();
    }, []);

    // Fetch themes from API
    const fetchThemes = async () => {
        try {
            const response = await fetch('https://e0a0-74-88-7-3.ngrok-free.app/api/themes');
            const data = await response.json();
            if (data.themes && data.themes.length > 0) {
                setThemes(data.themes);
                // Set initial theme if none selected
                if (!selectedTheme) {
                    setSelectedTheme(data.themes[0].id);
                }
            }
        } catch (error) {
            console.error('Error fetching themes:', error);
            // Fall back to default themes
            setThemes(DEFAULT_THEMES);
        }
    };

    // Update preview when theme or data changes
    useEffect(() => {
        if (selectedTheme) {
            fetchPreview(selectedTheme);
        }
    }, [selectedTheme, basics, work, education]);

    const fetchPreview = async (themeId) => {
        setLoading(true);
        try {
            const response = await fetch('https://e0a0-74-88-7-3.ngrok-free.app/api/preview', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    resume_data: { basics, work, education },
                    theme_name: themeId
                })
            });

            if (!response.ok) {
                throw new Error('Failed to fetch preview');
            }

            const html = await response.text();
            setPreviewHtml(html);
        } catch (error) {
            console.error('Error fetching preview:', error);
        } finally {
            setLoading(false);
        }
    };

    const handleExport = async (format = 'pdf') => {
        setIsExporting(true);
        setExportProgress(0);

        try {
            // Simulate progress
            const progressInterval = setInterval(() => {
                setExportProgress(prev => Math.min(prev + 10, 90));
            }, 500);

            const response = await fetch('https://e0a0-74-88-7-3.ngrok-free.app/api/export-pdf', {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({
                    resume_data: { basics, work, education },
                    theme_name: selectedTheme
                })
            });

            clearInterval(progressInterval);
            setExportProgress(100);

            if (!response.ok) {
                throw new Error('Export failed');
            }

            const blob = await response.blob();
            const url = window.URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.href = url;
            a.download = `resume-${selectedTheme}-${new Date().toISOString().split('T')[0]}.${format}`;
            document.body.appendChild(a);
            a.click();
            window.URL.revokeObjectURL(url);
            document.body.removeChild(a);
        } catch (error) {
            console.error('Export error:', error);
        } finally {
            setIsExporting(false);
            setExportProgress(0);
        }
    };

    const handleNavigate = async (delta) => {
        if (loading) return;

        setIsRocking(true);
        setDirection(delta);

        const newIndex = (currentIndex + delta + themes.length) % themes.length;
        const newTheme = themes[newIndex];

        if (newTheme) {
            setCurrentIndex(newIndex);
            setSelectedTheme(newTheme.id);
        }

        setTimeout(() => setIsRocking(false), 500);
    };

    return (
        <div className="h-full bg-gray-50">
            {/* Header with theme navigation and export options */}
            <div className="flex items-center justify-between px-6 py-4 border-b bg-white">
                <div className="flex items-center gap-4">
                    {/* Theme Navigation */}
                    <div className="flex items-center gap-2">
                        <button
                            onClick={() => handleNavigate(-1)}
                            disabled={loading}
                            className={`p-2 rounded-full hover:bg-gray-100 disabled:opacity-50 ${isRocking && direction === -1 ? 'animate-rock-left' : ''
                                }`}
                        >
                            <ChevronLeft className="w-5 h-5" />
                        </button>
                        <div className="flex flex-col items-center">
                            <span className="text-sm font-medium text-gray-900">
                                {themes[currentIndex]?.name || 'Loading themes...'}
                            </span>
                            <span className="text-xs text-gray-500">
                                Theme {currentIndex + 1} of {themes.length}
                            </span>
                        </div>
                        <button
                            onClick={() => handleNavigate(1)}
                            disabled={loading}
                            className={`p-2 rounded-full hover:bg-gray-100 disabled:opacity-50 ${isRocking && direction === 1 ? 'animate-rock-right' : ''
                                }`}
                        >
                            <ChevronRight className="w-5 h-5" />
                        </button>
                    </div>

                    {/* View Mode Toggle */}
                    <div className="flex bg-gray-100 rounded-lg p-1">
                        <button
                            onClick={() => setViewMode('split')}
                            className={`px-4 py-2 rounded-md flex items-center gap-2 transition-colors ${viewMode === 'split'
                                    ? 'bg-white shadow-sm text-blue-600'
                                    : 'text-gray-600 hover:text-gray-900'
                                }`}
                        >
                            <Edit2 className="w-4 h-4" />
                            Split View
                        </button>
                        <button
                            onClick={() => setViewMode('preview')}
                            className={`px-4 py-2 rounded-md flex items-center gap-2 transition-colors ${viewMode === 'preview'
                                    ? 'bg-white shadow-sm text-blue-600'
                                    : 'text-gray-600 hover:text-gray-900'
                                }`}
                        >
                            <Eye className="w-4 h-4" />
                            Preview
                        </button>
                    </div>
                </div>

                {/* Export Options */}
                <div className="flex items-center gap-2">
                    <button
                        onClick={() => handleExport('pdf')}
                        disabled={isExporting || loading}
                        className="px-4 py-2 bg-blue-600 text-white rounded-lg flex items-center gap-2 hover:bg-blue-700 disabled:opacity-50"
                    >
                        {isExporting ? (
                            <Loader2 className="w-4 h-4 animate-spin" />
                        ) : (
                            <Download className="w-4 h-4" />
                        )}
                        Export PDF
                    </button>
                    <button
                        onClick={() => handleExport('docx')}
                        disabled={isExporting || loading}
                        className="px-4 py-2 border border-gray-300 rounded-lg flex items-center gap-2 hover:bg-gray-50 disabled:opacity-50"
                    >
                        <FileText className="w-4 h-4" />
                        Export DOCX
                    </button>
                </div>
            </div>

            {/* Theme Info Banner */}
            {themes[currentIndex] && (
                <div className="px-6 py-3 bg-gray-100 border-b">
                    <p className="text-sm text-gray-600">
                        {themes[currentIndex].description}
                    </p>
                </div>
            )}

            {/* Main Content */}
            <div className={`h-[calc(100vh-140px)] ${viewMode === 'split' ? 'grid grid-cols-2' : ''}`}>
                {/* Edit Form - Only show in split view */}
                {viewMode === 'split' && (
                    <div className="p-6 overflow-auto">
                        {/* Your existing form content */}
                        {/* ... */}
                    </div>
                )}

                {/* Preview Panel */}
                <div className={viewMode === 'split' ? 'border-l' : ''}>
                    <div className="h-full bg-white">
                        {loading ? (
                            <div className="h-full flex items-center justify-center">
                                <div className="flex flex-col items-center gap-3">
                                    <Loader2 className="w-8 h-8 animate-spin text-blue-500" />
                                    <p className="text-sm text-gray-500">Generating preview...</p>
                                </div>
                            </div>
                        ) : (
                            <iframe
                                srcDoc={previewHtml}
                                className="w-full h-full"
                                title="Resume Preview"
                                style={{ border: 'none' }}
                            />
                        )}
                    </div>
                </div>
            </div>

            {/* Animation Styles */}
            <style jsx>{`
                @keyframes rock-left {
                    0%, 100% { transform: translateX(0); }
                    50% { transform: translateX(-4px); }
                }
                @keyframes rock-right {
                    0%, 100% { transform: translateX(0); }
                    50% { transform: translateX(4px); }
                }
                .animate-rock-left {
                    animation: rock-left 0.3s ease-in-out;
                }
                .animate-rock-right {
                    animation: rock-right 0.3s ease-in-out;
                }
            `}</style>
        </div>
    );
};

export default ThemeSection;