// import React, { useState, useEffect } from 'react';
// import {
//     ExternalLink,
//     ScrollText,
//     ArrowDown,
//     Loader2,
//     Check,
//     AlertCircle,
//     Edit2,
//     Eye,
//     Star,
//     Download,
//     PlusCircle,
//     Trash2,
//     FileText,
//     Layout
// } from 'lucide-react';
// import supabase from '../supabaseConfig';

// // Constants
// const PROCESSING_STEPS = [
//     "Analyzing Job Requirements",
//     "Identifying Key Skills",
//     "Matching Experience",
//     "Optimizing Keywords",
//     "Calculating Match Score"
// ];

// const THEMES = [
//     { id: 'modern', name: 'Modern', description: 'Clean and professional design' },
//     { id: 'classic', name: 'Classic', description: 'Traditional resume format' },
//     { id: 'creative', name: 'Creative', description: 'Stand out with a unique layout' },
//     { id: 'minimal', name: 'Minimal', description: 'Simple and straightforward' },
// ];

// // Job URL Selector Modal Component
// const JobURLSelectorModal = ({ isOpen, onClose, onSelectURL }) => {
//     const [url, setUrl] = useState('');
//     const [selectedSite, setSelectedSite] = useState(null);

//     const jobSites = [
//         {
//             name: 'LinkedIn',
//             domain: 'linkedin.com',
//             icon: (
//                 <svg viewBox="0 0 40 40" className="w-8 h-8" fill="none">
//                     <rect width="40" height="40" rx="8" fill="#0A66C2" />
//                     <path d="M15 16h-4v12h4V16zm-2-6.8a2.4 2.4 0 110 4.8 2.4 2.4 0 010-4.8zM29 28h-4v-6.2c0-3.2-4-3-4 0V28h-4V16h4v1.8c1.4-2.6 8-2.8 8 2.5V28z" fill="white" />
//                 </svg>
//             )
//         },
//         {
//             name: 'Indeed',
//             domain: 'indeed.com',
//             icon: (
//                 <svg viewBox="0 0 40 40" className="w-8 h-8" fill="none">
//                     <rect width="40" height="40" rx="8" fill="#2164F3" />
//                     <path d="M12 10h6v20h-6z" fill="#FFFFFF" />
//                     <path d="M22 10h6v20h-6z" fill="#FFFFFF" />
//                 </svg>
//             )
//         },
//         {
//             name: 'Glassdoor',
//             domain: 'glassdoor.com',
//             icon: (
//                 <svg viewBox="0 0 40 40" className="w-8 h-8" fill="none">
//                     <rect width="40" height="40" rx="8" fill="#0CAA41" />
//                     <path d="M20 6c-7.7 0-14 6.3-14 14s6.3 14 14 14 14-6.3 14-14S27.7 6 20 6zm0 22c-4.4 0-8-3.6-8-8s3.6-8 8-8 8 3.6 8 8-3.6 8-8 8zm0-13c-2.8 0-5 2.2-5 5s2.2 5 5 5 5-2.2 5-5-2.2-5-5-5z" fill="white" />
//                 </svg>
//             )
//         },
//         {
//             name: 'Dice',
//             domain: 'dice.com',
//             icon: (
//                 <svg viewBox="0 0 40 40" className="w-8 h-8" fill="none">
//                     <rect width="40" height="40" rx="8" fill="#EB1C26" />
//                     <path d="M10 10h20v20H10z" fill="white" />
//                     <circle cx="15" cy="15" r="2" fill="#EB1C26" />
//                     <circle cx="25" cy="15" r="2" fill="#EB1C26" />
//                     <circle cx="15" cy="25" r="2" fill="#EB1C26" />
//                     <circle cx="25" cy="25" r="2" fill="#EB1C26" />
//                 </svg>
//             )
//         }
//     ];

//     const handleContinue = () => {
//         if (url) {
//             onSelectURL(url);
//             onClose();
//             setUrl('');
//         }
//     };

//     if (!isOpen) return null;

//     return (
//         <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4 z-50">
//             <div className="bg-white rounded-2xl p-6 max-w-lg w-full">
//                 <div className="mb-6">
//                     <h2 className="text-xl font-bold text-gray-900">
//                         Paste a link to the job listing
//                     </h2>
//                     <p className="mt-2 text-sm text-gray-500">
//                         We'll analyze the job description to optimize your resume
//                     </p>
//                 </div>

//                 <div className="space-y-6">
//                     <div className="relative">
//                         <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
//                             <span className="text-gray-400">🔒</span>
//                         </div>
//                         <input
//                             type="url"
//                             value={url}
//                             onChange={(e) => setUrl(e.target.value)}
//                             className="w-full pl-10 pr-4 py-3 border rounded-lg bg-gray-50 text-gray-900 placeholder:text-gray-400"
//                             placeholder="jobs.com/company/position"
//                         />
//                     </div>

//                     <div>
//                         <h4 className="text-sm font-medium text-gray-500 mb-3">
//                             Supported Job Sites
//                         </h4>
//                         <div className="grid grid-cols-2 gap-4">
//                             {jobSites.map((site) => (
//                                 <div
//                                     key={site.domain}
//                                     onClick={() => setSelectedSite(site.name)}
//                                     className={`flex items-center gap-3 p-3 rounded-lg cursor-pointer transition-all duration-200 ${selectedSite === site.name
//                                         ? 'bg-blue-50 border-2 border-blue-200'
//                                         : 'border-2 border-transparent hover:bg-gray-50'
//                                         }`}
//                                 >
//                                     {site.icon}
//                                     <span className="text-sm font-medium text-gray-600">
//                                         {site.name}
//                                     </span>
//                                 </div>
//                             ))}
//                         </div>
//                     </div>

//                     <div className="flex justify-end gap-4 mt-6">
//                         <button
//                             onClick={onClose}
//                             className="px-4 py-2 rounded-lg border hover:bg-gray-50"
//                         >
//                             Cancel
//                         </button>
//                         <button
//                             onClick={handleContinue}
//                             disabled={!url}
//                             className="px-4 py-2 rounded-lg bg-blue-600 text-white hover:bg-blue-700 disabled:opacity-50 disabled:cursor-not-allowed"
//                         >
//                             Continue
//                         </button>
//                     </div>
//                 </div>
//             </div>
//         </div>
//     );
// };
// // Theme Selector Component
// const ThemeSelector = ({ selectedTheme, onThemeSelect, themes }) => {
//     return (
//         <div className="grid grid-cols-2 sm:grid-cols-3 lg:grid-cols-4 gap-4">
//             {themes.map((theme) => (
//                 <div
//                     key={theme.id}
//                     onClick={() => onThemeSelect(theme.id)}
//                     className={`cursor-pointer p-4 rounded-lg border-2 transition-all ${selectedTheme === theme.id
//                         ? 'border-blue-500 bg-blue-50'
//                         : 'border-gray-200 hover:border-blue-200'
//                         }`}
//                 >
//                     <Layout className="w-8 h-8 text-gray-500 mb-2" />
//                     <h3 className="font-medium text-gray-900">{theme.name}</h3>
//                     <p className="text-sm text-gray-500">{theme.description}</p>
//                 </div>
//             ))}
//         </div>
//     );
// };

// // Processing Steps Component
// const ProcessingSteps = ({ currentStep, steps, progress }) => {
//     return (
//         <div className="space-y-4">
//             {steps.map((step, index) => (
//                 <div
//                     key={step}
//                     className={`flex items-center gap-3 ${index <= currentStep ? 'text-blue-600' : 'text-gray-400'}`}
//                 >
//                     <div className="w-6 h-6 flex-shrink-0">
//                         {index < currentStep ? (
//                             <Check className="w-6 h-6 text-green-500" />
//                         ) : index === currentStep ? (
//                             <Loader2 className="w-6 h-6 animate-spin" />
//                         ) : (
//                             <div className="w-6 h-6 rounded-full border-2 border-current" />
//                         )}
//                     </div>
//                     <span className="text-sm font-medium">{step}</span>
//                 </div>
//             ))}
//             <div className="mt-4">
//                 <div className="h-2 bg-gray-100 rounded-full">
//                     <div
//                         className="h-full bg-blue-600 rounded-full transition-all duration-500"
//                         style={{ width: `${progress}%` }}
//                     />
//                 </div>
//             </div>
//         </div>
//     );
// };

// // Main EnhanceSection Component
// const EnhanceSection = ({
//     isAuthenticated,
//     setShowLoginModal,
//     basics,
//     setBasics,
//     work,
//     setWork,
//     education,
//     setEducation,
//     setJobAnalysis,
//     jobAnalysis,
//     setResumeScore,
//     resumeScore,
//     selectedTheme,
//     setSelectedTheme,
//     previewHtml,
//     setPreviewHtml
// }) => {
//     // State declarations
//     const [isURLModalOpen, setIsURLModalOpen] = useState(false);
//     const [jobLink, setJobLink] = useState('');
//     const [jobText, setJobText] = useState('');
//     const [isEnhancing, setIsEnhancing] = useState(false);
//     const [isScrapingJob, setIsScrapingJob] = useState(false);
//     const [scrapeError, setScrapeError] = useState('');
//     const [processingStep, setProcessingStep] = useState(0);
//     const [processingProgress, setProcessingProgress] = useState(0);
//     const [loadingMessage, setLoadingMessage] = useState('');
//     const [error, setError] = useState(null);
//     const [previewMode, setPreviewMode] = useState('split');
//     const [isPreviewLoading, setIsPreviewLoading] = useState(false);
//     const [availableThemes, setAvailableThemes] = useState(THEMES);
//     const [isThemeSelectorOpen, setIsThemeSelectorOpen] = useState(false);

//     // Fetch available themes
//     useEffect(() => {
//         const fetchThemes = async () => {
//             try {
//                 const response = await fetch('https://e0a0-74-88-7-3.ngrok-free.app/api/v1/resume/themes');
//                 if (response.ok) {
//                     const themes = await response.json();
//                     setAvailableThemes(themes);
//                 }
//             } catch (error) {
//                 console.error('Error fetching themes:', error);
//             }
//         };
//         fetchThemes();
//     }, []);

//     // Preview update function
//     const updatePreview = async () => {
//         setIsPreviewLoading(true);
//         try {
//             const response = await fetch('https://e0a0-74-88-7-3.ngrok-free.app/api/v1/resume/preview', {
//                 method: 'POST',
//                 headers: { 'Content-Type': 'application/json' },
//                 body: JSON.stringify({
//                     resume_data: { basics, work, education },
//                     theme_name: selectedTheme
//                 })
//             });

//             if (!response.ok) {
//                 throw new Error('Failed to fetch preview');
//             }

//             const html = await response.text();
//             setPreviewHtml(html);
//         } catch (error) {
//             console.error('Error updating preview:', error);
//             setError('Failed to update preview. Please try again.');
//         } finally {
//             setIsPreviewLoading(false);
//         }
//     };

//     // Update preview when resume data or theme changes
//     useEffect(() => {
//         if (basics && work && education && selectedTheme) {
//             const debounceTimeout = setTimeout(() => {
//                 updatePreview();
//             }, 500);

//             return () => clearTimeout(debounceTimeout);
//         }
//     }, [basics, work, education, selectedTheme]);

//     // Theme change handler
//     const handleThemeChange = async (themeId) => {
//         setSelectedTheme(themeId);
//         await updatePreview();
//     };

//     const handleSearch = () => {
//         if (jobLink) {
//             handleJobLinkSelect(jobLink);
//         }
//     };

//     const handleJobLinkSelect = async (url) => {
//         setJobLink(url);
//         setIsScrapingJob(true);
//         setScrapeError('');
//         setProcessingStep(0);
//         setProcessingProgress(0);

//         try {
//             const response = await fetch('https://e0a0-74-88-7-3.ngrok-free.app/scrape-job', {
//                 method: 'POST',
//                 headers: {
//                     'Content-Type': 'application/json',
//                 },
//                 body: JSON.stringify({ url })
//             });

//             const data = await response.json();

//             if (data.error) {
//                 setScrapeError(data.error);
//                 return;
//             }

//             if (data.raw_text) {
//                 setJobText(data.raw_text);
//                 simulateProcessing();
//             }
//         } catch (error) {
//             setScrapeError('Failed to fetch job description. Please try again.');
//         } finally {
//             setIsScrapingJob(false);
//         }
//     };

//     const handleEnhance = async () => {
//         if (!isAuthenticated) {
//             setShowLoginModal(true);
//             return;
//         }

//         try {
//             const { data: { user }, error: authError } = await supabase.auth.getUser();
//             if (authError || !user) {
//                 throw new Error('Authentication error. Please sign in again.');
//             }

//             setIsEnhancing(true);
//             setError(null);
//             setScrapeError('');
//             setProcessingStep(0);
//             setProcessingProgress(0);

//             // Get required fields with validation
//             const userId = localStorage.getItem('user_id');
//             const recordId = localStorage.getItem('record_id');

//             if (!userId || !recordId || !jobText) {
//                 console.log('Missing required fields:', { userId, recordId, jobText });
//                 throw new Error('Missing required fields for enhancement');
//             }

//             let progress = 0;
//             const progressInterval = setInterval(() => {
//                 progress += 5;
//                 if (progress <= 90) {
//                     setProcessingProgress(progress);
//                 }
//                 setProcessingStep(Math.floor((progress / 100) * PROCESSING_STEPS.length));
//             }, 500);

//             // Create FormData object
//             const formData = new FormData();
//             formData.append('user_id', userId);
//             formData.append('job_description', jobText);
//             formData.append('record_id', recordId);
//             formData.append('match_job_titles', 'true');

//             const response = await fetch('https://e0a0-74-88-7-3.ngrok-free.app/api/v1/resume/enhance', {
//                 method: 'POST',
//                 body: formData
//             });

//             clearInterval(progressInterval);
//             setProcessingProgress(100);

//             if (!response.ok) {
//                 const errorData = await response.json();
//                 throw new Error(`Enhancement failed: ${JSON.stringify(errorData)}`);
//             }

//             const data = await response.json();

//             if (data.success) {
//                 if (data.enhanced_resume?.work) {
//                     const enhancedWork = data.enhanced_resume.work.map(job => ({
//                         ...job,
//                         original_position: job.original_position || job.position,
//                         startDate: formatDate(job.startDate),
//                         endDate: formatDate(job.endDate)
//                     }));
//                     setWork(enhancedWork);
//                 }

//                 if (data.job_analysis) {
//                     setJobAnalysis(data.job_analysis);
//                 }

//                 setResumeScore(prev => Math.min(prev + 35, 100));

//                 // Update preview after enhancement
//                 await updatePreview();
//             }

//             setLoadingMessage('Enhancement complete!');
//         } catch (err) {
//             console.error('Enhancement error:', err);
//             setError(err.message);
//             setScrapeError(err.message);
//         } finally {
//             setIsEnhancing(false);
//             setLoadingMessage('');
//         }
//     };

//     const handleExportPDF = async () => {
//         try {
//             const response = await fetch('https://e0a0-74-88-7-3.ngrok-free.app/api/v1/resume/export', {
//                 method: 'POST',
//                 headers: { 'Content-Type': 'application/json' },
//                 body: JSON.stringify({
//                     resume_data: { basics, work, education },
//                     theme_name: selectedTheme
//                 })
//             });

//             if (!response.ok) {
//                 throw new Error('Failed to export PDF');
//             }

//             const blob = await response.blob();
//             const url = window.URL.createObjectURL(blob);
//             const a = document.createElement('a');
//             a.href = url;
//             a.download = `resume-${selectedTheme}.pdf`;
//             document.body.appendChild(a);
//             a.click();
//             window.URL.revokeObjectURL(url);
//             document.body.removeChild(a);
//         } catch (error) {
//             console.error('Error exporting PDF:', error);
//             setError('Failed to export PDF. Please try again.');
//         }
//     };

//     const formatDate = (dateString) => {
//         if (!dateString) return null;

//         const cleaned = String(dateString).trim();

//         try {
//             if (/^\d{4}$/.test(cleaned)) {
//                 return `${cleaned}-01-01`;
//             }
//             if (/^\d{4}-\d{2}$/.test(cleaned)) {
//                 return `${cleaned}-01`;
//             }
//             if (/^\d{4}-\d{2}-\d{2}$/.test(cleaned)) {
//                 return cleaned;
//             }
//             const monthMatch = cleaned.match(/([a-zA-Z]+)\s+(\d{4})/);
//             if (monthMatch) {
//                 const month = getMonthNumber(monthMatch[1]);
//                 const year = monthMatch[2];
//                 return `${year}-${month}-01`;
//             }
//             console.warn(`Invalid date format: ${dateString}, defaulting to null`);
//             return null;
//         } catch (error) {
//             console.error(`Error formatting date: ${dateString}`, error);
//             return null;
//         }
//     };

//     const getMonthNumber = (month) => {
//         const months = {
//             'jan': '01', 'january': '01',
//             'feb': '02', 'february': '02',
//             'mar': '03', 'march': '03',
//             'apr': '04', 'april': '04',
//             'may': '05',
//             'jun': '06', 'june': '06',
//             'jul': '07', 'july': '07',
//             'aug': '08', 'august': '08',
//             'sep': '09', 'september': '09',
//             'oct': '10', 'october': '10',
//             'nov': '11', 'november': '11',
//             'dec': '12', 'december': '12'
//         };

//         const monthKey = month.toLowerCase().slice(0, 3);
//         return months[monthKey] || '01';
//     };

//     const simulateProcessing = () => {
//         setProcessingStep(0);
//         setProcessingProgress(0);

//         const stepDuration = 1000;
//         const progressInterval = 50;
//         const totalSteps = PROCESSING_STEPS.length;

//         let currentStep = 0;
//         let currentProgress = 0;

//         const stepTimer = setInterval(() => {
//             if (currentStep >= totalSteps) {
//                 clearInterval(stepTimer);
//                 return;
//             }
//             setProcessingStep(currentStep);
//             currentStep++;
//         }, stepDuration);

//         const progressTimer = setInterval(() => {
//             if (currentProgress >= 100) {
//                 clearInterval(progressTimer);
//                 return;
//             }
//             currentProgress += 1;
//             setProcessingProgress(currentProgress);
//         }, progressInterval);

//         return () => {
//             clearInterval(stepTimer);
//             clearInterval(progressTimer);
//         };
//     };

//     const handleArrayChange = (setter, index, field, value) => {
//         setter(prev => {
//             const newArray = [...prev];
//             newArray[index] = { ...newArray[index], [field]: value };
//             return newArray;
//         });
//     };

//     return (
//         <div className="space-y-6">
//             {/* Theme Selector */}
//             <div className="mb-8">
//                 <div className="flex items-center justify-between mb-4">
//                     <h3 className="text-xl font-bold text-gray-800">
//                         Resume Theme
//                     </h3>
//                     <button
//                         onClick={() => setIsThemeSelectorOpen(!isThemeSelectorOpen)}
//                         className="text-blue-600 hover:text-blue-700"
//                     >
//                         {isThemeSelectorOpen ? 'Hide Themes' : 'Change Theme'}
//                     </button>
//                 </div>

//                 {isThemeSelectorOpen && (
//                     <ThemeSelector
//                         selectedTheme={selectedTheme}
//                         onThemeSelect={handleThemeChange}
//                         themes={availableThemes}
//                     />
//                 )}
//             </div>

//             {/* Job Enhancement Section */}
//             <div className="mb-8">
//                 <div className="text-2xl font-bold text-gray-800 mb-6">AI Job Enhancement</div>

//                 {/* Job URL Input */}
//                 <div className="bg-blue-50/50 p-6 rounded-xl shadow-sm border border-blue-100">
//                     <label className="text-lg font-semibold text-gray-700 flex items-center gap-2">
//                         <ExternalLink className="w-5 h-5 text-blue-500" />
//                         Post the Link to the Job You Want
//                     </label>
//                     <input
//                         type="url"
//                         placeholder="https://www.linkedin.com/jobs/view/..."
//                         value={jobLink}
//                         onClick={() => setIsURLModalOpen(true)}
//                         className="w-full px-4 py-3 mt-2 border rounded-xl bg-white/70"
//                         readOnly
//                     />
//                     {jobLink && !isScrapingJob && (
//                         <button
//                             onClick={handleSearch}
//                             className="w-full mt-4 bg-gradient-to-r from-green-500 to-blue-500 hover:from-green-600 hover:to-blue-600 text-white px-4 py-3 rounded-xl flex items-center justify-center gap-2 transition-all duration-300"
//                         >
//                             <ArrowDown className="w-4 h-4" />
//                             Analyze Job
//                         </button>
//                     )}
//                 </div>

//                 {/* Manual Job Description */}
//                 <div className="mt-4">
//                     <label className="block text-sm font-medium text-gray-700 mb-2">
//                         Or copy & paste the Job Description
//                     </label>
//                     <div className="relative">
//                         <ScrollText className="absolute left-3 top-3 w-5 h-5 text-gray-400" />
//                         <textarea
//                             value={jobText}
//                             onChange={(e) => setJobText(e.target.value)}
//                             placeholder="Paste the job description here..."
//                             className="w-full pl-10 pr-4 py-2 border rounded-lg h-32 resize-none"
//                         />
//                     </div>
//                 </div>

//                 {/* Enhance Button */}
//                 {jobText && !isEnhancing && (
//                     <button
//                         onClick={handleEnhance}
//                         className="w-full mt-4 bg-gradient-to-r from-green-500 to-blue-500 hover:from-green-600 hover:to-blue-600 text-white px-4 py-3 rounded-xl flex items-center justify-center gap-2 transition-all duration-300"
//                     >
//                         <ArrowDown className="w-4 h-4" />
//                         Enhance Resume
//                     </button>
//                 )}

//                 {/* Processing Status */}
//                 {(isEnhancing || isScrapingJob) && (
//                     <div className="mt-4 bg-white/50 p-6 rounded-xl shadow-sm border border-blue-100">
//                         <div className="flex items-center justify-between mb-4">
//                             <h3 className="text-lg font-semibold text-blue-700">
//                                 {loadingMessage || (isEnhancing ? "Enhancing your resume..." : "Analyzing job description...")}
//                             </h3>
//                             <Loader2 className="w-5 h-5 animate-spin text-blue-500" />
//                         </div>
//                         <ProcessingSteps
//                             currentStep={processingStep}
//                             steps={PROCESSING_STEPS}
//                             progress={processingProgress}
//                         />
//                     </div>
//                 )}

//                 {/* Error Messages */}
//                 {(error || scrapeError) && (
//                     <div className="mt-4 bg-red-50 border border-red-200 rounded-xl p-4">
//                         <div className="flex items-center gap-2 text-red-600">
//                             <AlertCircle className="w-5 h-5" />
//                             <span>{error || scrapeError}</span>
//                         </div>
//                     </div>
//                 )}
//             </div>

//             {/* Preview Section */}
//             {basics && work && education && (
//                 <div className="mt-8 pt-8 border-t">
//                     {/* Header with Export Button */}
//                     <div className="flex items-center justify-between mb-6">
//                         <div className="flex items-center gap-4">
//                             <h3 className="text-xl font-bold text-gray-800">
//                                 Resume Preview
//                             </h3>
//                             <div className="flex bg-gray-100 rounded-lg p-1">
//                                 <button
//                                     onClick={() => setPreviewMode('split')}
//                                     className={`px-4 py-2 rounded-md flex items-center gap-2 transition-colors ${previewMode === 'split'
//                                             ? 'bg-white shadow-sm text-blue-600'
//                                             : 'text-gray-600 hover:text-gray-900'
//                                         }`}
//                                 >
//                                     <Edit2 className="w-4 h-4" />
//                                     Split View
//                                 </button>
//                                 <button
//                                     onClick={() => setPreviewMode('preview')}
//                                     className={`px-4 py-2 rounded-md flex items-center gap-2 transition-colors ${previewMode === 'preview'
//                                             ? 'bg-white shadow-sm text-blue-600'
//                                             : 'text-gray-600 hover:text-gray-900'
//                                         }`}
//                                 >
//                                     <Eye className="w-4 h-4" />
//                                     Preview
//                                 </button>
//                             </div>
//                         </div>
//                         <div className="flex items-center gap-4">
//                             <div className="flex items-center gap-2 bg-blue-50 px-3 py-1 rounded-full">
//                                 <Star className="w-4 h-4 text-blue-500" />
//                                 <span className="text-blue-600 font-medium">
//                                     {resumeScore}%
//                                 </span>
//                             </div>
//                             <button
//                                 onClick={handleExportPDF}
//                                 className="flex items-center gap-2 px-4 py-2 bg-blue-500 text-white rounded-lg hover:bg-blue-600 transition-colors"
//                             >
//                                 <Download className="w-4 h-4" />
//                                 Export PDF
//                             </button>
//                         </div>
//                     </div>

//                     {/* Grid Layout for Edit/Preview */}
//                     <div className={`grid ${previewMode === 'preview' ? 'grid-cols-1' : 'grid-cols-2'} gap-6`}>
//                         {previewMode === 'split' && (
//                             <div className="space-y-6">
//                                 {/* Basic Information */}
//                                 <div className="bg-gray-50 border-b p-4 rounded-lg">
//                                     <input
//                                         type="text"
//                                         value={basics.name || ''}
//                                         onChange={(e) => setBasics(prev => ({ ...prev, name: e.target.value }))}
//                                         placeholder="Your Name"
//                                         className="text-lg font-medium text-gray-900 w-full bg-transparent border-none focus:ring-2 focus:ring-blue-500 rounded px-2"
//                                     />
//                                     <input
//                                         type="text"
//                                         value={jobAnalysis?.target_role || basics.label || ''}
//                                         onChange={(e) => setBasics(prev => ({ ...prev, label: e.target.value }))}
//                                         placeholder="Your Title"
//                                         className="text-sm text-gray-600 w-full bg-transparent border-none focus:ring-2 focus:ring-blue-500 rounded px-2 mt-1"
//                                     />
//                                 </div>

//                                 {/* Contact Info */}
//                                 <div className="flex gap-4 text-sm text-gray-600">
//                                     <div className="flex items-center gap-1">
//                                         <FileText className="w-4 h-4" />
//                                         <input
//                                             type="email"
//                                             value={basics.email || ''}
//                                             onChange={(e) => setBasics(prev => ({ ...prev, email: e.target.value }))}
//                                             placeholder="Email"
//                                             className="bg-transparent border-none focus:ring-2 focus:ring-blue-500 rounded px-2"
//                                         />
//                                     </div>
//                                     <div className="flex items-center gap-1">
//                                         <FileText className="w-4 h-4" />
//                                         <input
//                                             type="tel"
//                                             value={basics.phone || ''}
//                                             onChange={(e) => setBasics(prev => ({ ...prev, phone: e.target.value }))}
//                                             placeholder="Phone"
//                                             className="bg-transparent border-none focus:ring-2 focus:ring-blue-500 rounded px-2"
//                                         />
//                                     </div>
//                                 </div>

//                                 {/* Summary */}
//                                 <div>
//                                     <textarea
//                                         value={basics.summary || ''}
//                                         onChange={(e) => setBasics(prev => ({ ...prev, summary: e.target.value }))}
//                                         placeholder="Professional Summary..."
//                                         className="w-full text-sm text-gray-700 bg-transparent border rounded-md focus:ring-2 focus:ring-blue-500 p-3 min-h-[120px]"
//                                     />
//                                 </div>

//                                 {/* Work Experience */}
//                                 {work && work.length > 0 && (
//                                     <div className="space-y-4">
//                                         <div className="font-medium text-gray-900 flex justify-between items-center">
//                                             <span>Experience</span>
//                                             <button
//                                                 onClick={() => setWork([...work, { position: '', name: '', startDate: '', endDate: '', highlights: [] }])}
//                                                 className="text-blue-500 hover:text-blue-600"
//                                             >
//                                                 <PlusCircle className="w-4 h-4" />
//                                             </button>
//                                         </div>
//                                         {work.map((job, index) => (
//                                             <div key={index} className="text-sm space-y-3 relative group border rounded-lg p-4 hover:shadow-sm transition-shadow">
//                                                 <button
//                                                     onClick={() => setWork(work.filter((_, i) => i !== index))}
//                                                     className="absolute -right-2 -top-2 opacity-0 group-hover:opacity-100 transition-opacity"
//                                                 >
//                                                     <Trash2 className="w-4 h-4 text-red-500 hover:text-red-600" />
//                                                 </button>
//                                                 {job.original_position && job.original_position !== job.position && (
//                                                     <div className="text-sm text-gray-500 italic mb-2">
//                                                         Original Position: {job.original_position}
//                                                     </div>
//                                                 )}
//                                                 <input
//                                                     value={job.position || ''}
//                                                     onChange={(e) => handleArrayChange(setWork, index, 'position', e.target.value)}
//                                                     placeholder="Position"
//                                                     className="font-medium w-full bg-transparent border-none focus:ring-2 focus:ring-blue-500 rounded px-2"
//                                                 />
//                                                 <input
//                                                     value={job.name || ''}
//                                                     onChange={(e) => handleArrayChange(setWork, index, 'name', e.target.value)}
//                                                     placeholder="Company"
//                                                     className="text-gray-600 w-full bg-transparent border-none focus:ring-2 focus:ring-blue-500 rounded px-2"
//                                                 />
//                                                 <div className="flex gap-2">
//                                                     <input
//                                                         type="date"
//                                                         value={job.startDate || ''}
//                                                         onChange={(e) => handleArrayChange(setWork, index, 'startDate', e.target.value)}
//                                                         className="text-gray-500 bg-transparent border rounded px-2"
//                                                     />
//                                                     -
//                                                     <input
//                                                         type="date"
//                                                         value={job.endDate || ''}
//                                                         onChange={(e) => handleArrayChange(setWork, index, 'endDate', e.target.value)}
//                                                         className="text-gray-500 bg-transparent border rounded px-2"
//                                                     />
//                                                 </div>
//                                                 <div className="space-y-2">
//                                                     {job.highlights && job.highlights.map((highlight, idx) => (
//                                                         <div key={idx} className="relative group flex gap-2 items-center">
//                                                             <span className="text-gray-400">•</span>
//                                                             <input
//                                                                 value={highlight}
//                                                                 onChange={(e) => {
//                                                                     const newWork = [...work];
//                                                                     newWork[index].highlights[idx] = e.target.value;
//                                                                     setWork(newWork);
//                                                                 }}
//                                                                 className="flex-1 text-gray-700 bg-transparent border-none focus:ring-2 focus:ring-blue-500 rounded px-2 py-1"
//                                                             />
//                                                             <button
//                                                                 onClick={() => {
//                                                                     const newWork = [...work];
//                                                                     newWork[index].highlights = newWork[index].highlights.filter((_, i) => i !== idx);
//                                                                     setWork(newWork);
//                                                                 }}
//                                                                 className="opacity-0 group-hover:opacity-100 transition-opacity"
//                                                             >
//                                                                 <Trash2 className="w-4 h-4 text-red-500 hover:text-red-600" />
//                                                             </button>
//                                                         </div>
//                                                     ))}
//                                                     <button
//                                                         onClick={() => {
//                                                             const newWork = [...work];
//                                                             if (!newWork[index].highlights) {
//                                                                 newWork[index].highlights = [];
//                                                             }
//                                                             newWork[index].highlights.push('');
//                                                             setWork(newWork);
//                                                         }}
//                                                         className="text-sm text-blue-500 hover:text-blue-600 ml-6"
//                                                     >
//                                                         + Add Highlight
//                                                     </button>
//                                                 </div>
//                                             </div>
//                                         ))}
//                                     </div>
//                                 )}

//                                 {/* Education Section */}
//                                 {education && education.length > 0 && (
//                                     <div className="space-y-4">
//                                         <div className="font-medium text-gray-900 flex justify-between items-center">
//                                             <span>Education</span>
//                                             <button
//                                                 onClick={() => setEducation([...education, { institution: '', area: '', studyType: '', startDate: '', endDate: '' }])}
//                                                 className="text-blue-500 hover:text-blue-600"
//                                             >
//                                                 <PlusCircle className="w-4 h-4" />
//                                             </button>
//                                         </div>
//                                         {education.map((edu, index) => (
//                                             <div key={index} className="text-sm space-y-2 relative group border rounded-lg p-4 hover:shadow-sm transition-shadow">
//                                                 <button
//                                                     onClick={() => setEducation(education.filter((_, i) => i !== index))}
//                                                     className="absolute -right-2 -top-2 opacity-0 group-hover:opacity-100 transition-opacity"
//                                                 >
//                                                     <Trash2 className="w-4 h-4 text-red-500 hover:text-red-600" />
//                                                 </button>
//                                                 <input
//                                                     value={edu.studyType || ''}
//                                                     onChange={(e) => handleArrayChange(setEducation, index, 'studyType', e.target.value)}
//                                                     placeholder="Degree Type"
//                                                     className="font-medium w-full bg-transparent border-none focus:ring-2 focus:ring-blue-500 rounded px-2"
//                                                 />
//                                                 <input
//                                                     value={edu.area || ''}
//                                                     onChange={(e) => handleArrayChange(setEducation, index, 'area', e.target.value)}
//                                                     placeholder="Field of Study"
//                                                     className="w-full bg-transparent border-none focus:ring-2 focus:ring-blue-500 rounded px-2"
//                                                 />
//                                                 <input
//                                                     value={edu.institution || ''}
//                                                     onChange={(e) => handleArrayChange(setEducation, index, 'institution', e.target.value)}
//                                                     placeholder="Institution"
//                                                     className="text-gray-600 w-full bg-transparent border-none focus:ring-2 focus:ring-blue-500 rounded px-2"
//                                                 />
//                                                 <div className="flex gap-2">
//                                                     <input
//                                                         type="date"
//                                                         value={edu.startDate || ''}
//                                                         onChange={(e) => handleArrayChange(setEducation, index, 'startDate', e.target.value)}
//                                                         className="text-gray-500 bg-transparent border rounded px-2"
//                                                     />
//                                                     -
//                                                     <input
//                                                         type="date"
//                                                         value={edu.endDate || ''}
//                                                         onChange={(e) => handleArrayChange(setEducation, index, 'endDate', e.target.value)}
//                                                         className="text-gray-500 bg-transparent border rounded px-2"
//                                                     />
//                                                 </div>
//                                             </div>
//                                         ))}
//                                     </div>
//                                 )}
//                             </div>
//                         )}

//                         {/* Preview Panel */}
//                         <div className={previewMode === 'preview' ? 'col-span-2' : ''}>
//                             <div className="border rounded-xl overflow-hidden bg-white h-[800px]">
//                                 {isPreviewLoading ? (
//                                     <div className="flex items-center justify-center h-full">
//                                         <Loader2 className="w-8 h-8 animate-spin text-blue-500" />
//                                     </div>
//                                 ) : (
//                                     <iframe
//                                         srcDoc={previewHtml}
//                                         className="w-full h-full"
//                                         title="Resume Preview"
//                                         style={{ border: 'none' }}
//                                         sandbox="allow-same-origin"
//                                     />
//                                 )}
//                             </div>
//                         </div>
//                     </div>
//                 </div>
//             )}

//             {/* Job URL Selector Modal */}
//             <JobURLSelectorModal
//                 isOpen={isURLModalOpen}
//                 onClose={() => setIsURLModalOpen(false)}
//                 onSelectURL={handleJobLinkSelect}
//             />
//         </div>
//     );
// };

// export default EnhanceSection;

import React, { useState, useEffect } from 'react';
import {
    ExternalLink,
    ScrollText,
    ArrowDown,
    Loader2,
    Check,
    AlertCircle,
    Edit2,
    Eye,
    Star,
    Download,
    PlusCircle,
    Trash2,
    FileText,
    Layout
} from 'lucide-react';
import supabase from '../supabaseConfig';

// API constants
const API_BASE = 'https://e0a0-74-88-7-3.ngrok-free.app';
const API_ENDPOINTS = {
    THEMES: '/api/themes',
    PREVIEW: '/api/preview',
    EXPORT: '/api/export-pdf',
    ENHANCE: '/api/v1/resume/enhance',
    SCRAPE_JOB: '/scrape-job'
};

// Processing steps
const PROCESSING_STEPS = [
    "Analyzing Job Requirements",
    "Identifying Key Skills",
    "Matching Experience",
    "Optimizing Keywords",
    "Calculating Match Score"
];

// Theme Selector Component
const ThemeSelector = ({ selectedTheme, onThemeSelect, themes }) => {
    return (
        <div className="grid grid-cols-2 sm:grid-cols-3 lg:grid-cols-4 gap-4">
            {themes.map((theme) => (
                <div
                    key={theme.id}
                    onClick={() => onThemeSelect(theme.id)}
                    className={`cursor-pointer p-4 rounded-lg border-2 transition-all ${selectedTheme === theme.id
                        ? 'border-blue-500 bg-blue-50'
                        : 'border-gray-200 hover:border-blue-200'
                        }`}
                >
                    <Layout className="w-8 h-8 text-gray-500 mb-2" />
                    <h3 className="font-medium text-gray-900">{theme.name}</h3>
                    <p className="text-sm text-gray-500">{theme.description}</p>
                </div>
            ))}
        </div>
    );
};

// Processing Steps Component
const ProcessingSteps = ({ currentStep, steps, progress }) => {
    return (
        <div className="space-y-4">
            {steps.map((step, index) => (
                <div
                    key={step}
                    className={`flex items-center gap-3 ${index <= currentStep ? 'text-blue-600' : 'text-gray-400'
                        }`}
                >
                    <div className="w-6 h-6 flex-shrink-0">
                        {index < currentStep ? (
                            <Check className="w-6 h-6 text-green-500" />
                        ) : index === currentStep ? (
                            <Loader2 className="w-6 h-6 animate-spin" />
                        ) : (
                            <div className="w-6 h-6 rounded-full border-2 border-current" />
                        )}
                    </div>
                    <span className="text-sm font-medium">{step}</span>
                </div>
            ))}
            <div className="mt-4">
                <div className="h-2 bg-gray-100 rounded-full">
                    <div
                        className="h-full bg-blue-600 rounded-full transition-all duration-500"
                        style={{ width: `${progress}%` }}
                    />
                </div>
            </div>
        </div>
    );
};

// Job URL Selector Modal Component
const JobURLSelectorModal = ({ isOpen, onClose, onSelectURL }) => {
    const [url, setUrl] = useState('');
    const [selectedSite, setSelectedSite] = useState(null);

    const jobSites = [
        {
            name: 'LinkedIn',
            domain: 'linkedin.com',
            icon: (
                <svg viewBox="0 0 40 40" className="w-8 h-8" fill="none">
                    <rect width="40" height="40" rx="8" fill="#0A66C2" />
                    <path
                        d="M15 16h-4v12h4V16zm-2-6.8a2.4 2.4 0 110 4.8 2.4 2.4 0 010-4.8zM29 28h-4v-6.2c0-3.2-4-3-4 0V28h-4V16h4v1.8c1.4-2.6 8-2.8 8 2.5V28z"
                        fill="white"
                    />
                </svg>
            )
        },
        {
            name: 'Indeed',
            domain: 'indeed.com',
            icon: (
                <svg viewBox="0 0 40 40" className="w-8 h-8" fill="none">
                    <rect width="40" height="40" rx="8" fill="#2164F3" />
                    <path d="M12 10h6v20h-6z" fill="#FFFFFF" />
                    <path d="M22 10h6v20h-6z" fill="#FFFFFF" />
                </svg>
            )
        },
        {
            name: 'Glassdoor',
            domain: 'glassdoor.com',
            icon: (
                <svg viewBox="0 0 40 40" className="w-8 h-8" fill="none">
                    <rect width="40" height="40" rx="8" fill="#0CAA41" />
                    <path
                        d="M20 6c-7.7 0-14 6.3-14 14s6.3 14 14 14 14-6.3 14-14S27.7 6 20 6zm0 22c-4.4 0-8-3.6-8-8s3.6-8 8-8 8 3.6 8 8-3.6 8-8 8zm0-13c-2.8 0-5 2.2-5 5s2.2 5 5 5 5-2.2 5-5-2.2-5-5-5z"
                        fill="white"
                    />
                </svg>
            )
        }
    ];

    const handleContinue = () => {
        if (url) {
            onSelectURL(url);
            onClose();
            setUrl('');
        }
    };

    if (!isOpen) return null;

    return (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4 z-50">
            <div className="bg-white rounded-2xl p-6 max-w-lg w-full">
                <div className="mb-6">
                    <h2 className="text-xl font-bold text-gray-900">
                        Paste a link to the job listing
                    </h2>
                    <p className="mt-2 text-sm text-gray-500">
                        We'll analyze the job description to optimize your resume
                    </p>
                </div>

                <div className="space-y-6">
                    <div className="relative">
                        <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                            <span className="text-gray-400">🔒</span>
                        </div>
                        <input
                            type="url"
                            value={url}
                            onChange={(e) => setUrl(e.target.value)}
                            className="w-full pl-10 pr-4 py-3 border rounded-lg bg-gray-50 text-gray-900 placeholder:text-gray-400"
                            placeholder="jobs.com/company/position"
                        />
                    </div>

                    <div>
                        <h4 className="text-sm font-medium text-gray-500 mb-3">
                            Supported Job Sites
                        </h4>
                        <div className="grid grid-cols-2 gap-4">
                            {jobSites.map((site) => (
                                <div
                                    key={site.domain}
                                    onClick={() => setSelectedSite(site.name)}
                                    className={`flex items-center gap-3 p-3 rounded-lg cursor-pointer transition-all duration-200 ${selectedSite === site.name
                                        ? 'bg-blue-50 border-2 border-blue-200'
                                        : 'border-2 border-transparent hover:bg-gray-50'
                                        }`}
                                >
                                    {site.icon}
                                    <span className="text-sm font-medium text-gray-600">
                                        {site.name}
                                    </span>
                                </div>
                            ))}
                        </div>
                    </div>

                    <div className="flex justify-end gap-4 mt-6">
                        <button
                            onClick={onClose}
                            className="px-4 py-2 rounded-lg border hover:bg-gray-50"
                        >
                            Cancel
                        </button>
                        <button
                            onClick={handleContinue}
                            disabled={!url}
                            className="px-4 py-2 rounded-lg bg-blue-600 text-white hover:bg-blue-700 disabled:opacity-50 disabled:cursor-not-allowed"
                        >
                            Continue
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};

// Main EnhanceSection Component
const EnhanceSection = ({
    isAuthenticated,
    setShowLoginModal,
    basics,
    setBasics,
    work,
    setWork,
    education,
    setEducation,
    setJobAnalysis,
    jobAnalysis,
    setResumeScore,
    resumeScore,
    selectedTheme,
    setSelectedTheme,
    previewHtml,
    setPreviewHtml
}) => {
    // State declarations
    const [isURLModalOpen, setIsURLModalOpen] = useState(false);
    const [jobLink, setJobLink] = useState('');
    const [jobText, setJobText] = useState('');
    const [isEnhancing, setIsEnhancing] = useState(false);
    const [isScrapingJob, setIsScrapingJob] = useState(false);
    const [scrapeError, setScrapeError] = useState('');
    const [processingStep, setProcessingStep] = useState(0);
    const [processingProgress, setProcessingProgress] = useState(0);
    const [loadingMessage, setLoadingMessage] = useState('');
    const [error, setError] = useState(null);
    const [previewMode, setPreviewMode] = useState('split');
    const [isPreviewLoading, setIsPreviewLoading] = useState(false);
    const [availableThemes, setAvailableThemes] = useState([]);
    const [isThemeSelectorOpen, setIsThemeSelectorOpen] = useState(false);

    // Helper functions

    const handleJobLinkSelect = async (url) => {
        setJobLink(url);
        setIsScrapingJob(true);
        setScrapeError('');
        setProcessingStep(0);
        setProcessingProgress(0);

        try {
            const response = await fetch(`${API_BASE}${API_ENDPOINTS.SCRAPE_JOB}`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ url })
            });

            const data = await response.json();

            if (data.error) {
                setScrapeError(data.error);
                return;
            }

            if (data.raw_text) {
                setJobText(data.raw_text);
                simulateProcessing();
            }
        } catch (error) {
            setScrapeError('Failed to fetch job description. Please try again.');
        } finally {
            setIsScrapingJob(false);
        }
    };

    const handleSearch = () => {
        if (jobLink) {
            handleJobLinkSelect(jobLink);
        }
    };

    // Handle resume enhancement
    const handleEnhance = async () => {
        // Add debug logging
        console.log('Starting enhancement process');
        console.log('Authentication status:', isAuthenticated);

        if (!isAuthenticated) {
            console.log('User not authenticated, showing login modal');
            setShowLoginModal(true);
            return;
        }

        try {
            const { data: { user }, error: authError } = await supabase.auth.getUser();
            console.log('Supabase auth check:', { user, authError });

            if (authError || !user) {
                throw new Error('Authentication error. Please sign in again.');
            }

            setIsEnhancing(true);
            setError(null);
            setScrapeError('');
            setProcessingStep(0);
            setProcessingProgress(0);

            const userId = localStorage.getItem('user_id');
            const recordId = localStorage.getItem('record_id');

            // Add debug logging for required fields
            console.log('Required fields check:', {
                userId,
                recordId,
                jobTextLength: jobText?.length,
                hasJobText: !!jobText
            });

            if (!userId || !recordId || !jobText) {
                console.error('Missing required fields:', { userId, recordId, jobText });
                throw new Error('Missing required fields for enhancement');
            }

            let progress = 0;
            const progressInterval = setInterval(() => {
                progress += 5;
                if (progress <= 90) {
                    setProcessingProgress(progress);
                }
                setProcessingStep(Math.floor((progress / 100) * PROCESSING_STEPS.length));
            }, 500);

            const formData = new FormData();
            formData.append('user_id', userId);
            formData.append('job_description', jobText);
            formData.append('record_id', recordId);
            formData.append('match_job_titles', 'true');

            // Log the request details
            console.log('Sending enhancement request to:', `${API_BASE}${API_ENDPOINTS.ENHANCE}`);
            console.log('FormData contents:', {
                user_id: userId,
                job_description_length: jobText.length,
                record_id: recordId,
                match_job_titles: true
            });

            const response = await fetch(`${API_BASE}${API_ENDPOINTS.ENHANCE}`, {
                method: 'POST',
                body: formData
            });

            clearInterval(progressInterval);
            setProcessingProgress(100);

            // Log response status
            console.log('Enhancement response status:', response.status);

            if (!response.ok) {
                const errorData = await response.json();
                console.error('Enhancement error response:', errorData);
                throw new Error(`Enhancement failed: ${JSON.stringify(errorData)}`);
            }

            const data = await response.json();
            console.log('Enhancement success response:', data);

            if (data.success) {
                if (data.enhanced_resume?.work) {
                    const enhancedWork = data.enhanced_resume.work.map((job) => ({
                        ...job,
                        original_position: job.original_position || job.position,
                        startDate: formatDate(job.startDate),
                        endDate: formatDate(job.endDate)
                    }));
                    setWork(enhancedWork);
                }

                if (data.job_analysis) {
                    setJobAnalysis(data.job_analysis);
                }

                setResumeScore((prev) => Math.min(prev + 35, 100));
                await updatePreview();
            }

            setLoadingMessage('Enhancement complete!');
        } catch (err) {
            console.error('Enhancement error:', err);
            setError(err.message);
            setScrapeError(err.message);
        } finally {
            setIsEnhancing(false);
            setLoadingMessage('');
        }
    };
    // Handle PDF export
    const handleExportPDF = async () => {
        try {
            const response = await fetch(`${API_BASE}${API_ENDPOINTS.EXPORT}`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'accept': 'application/pdf'
                },
                body: JSON.stringify({
                    resume_data: { basics, work, education },
                    theme_name: selectedTheme
                })
            });

            if (!response.ok) {
                throw new Error('Failed to export PDF');
            }

            const blob = await response.blob();
            const url = window.URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.href = url;
            a.download = `resume-${selectedTheme}.pdf`;
            document.body.appendChild(a);
            a.click();
            window.URL.revokeObjectURL(url);
            document.body.removeChild(a);
        } catch (error) {
            console.error('Error exporting PDF:', error);
            setError('Failed to export PDF. Please try again.');
        }
    };

    // Update preview
    const updatePreview = async () => {
        try {
            setIsPreviewLoading(true);
            const response = await fetch(`${API_BASE}${API_ENDPOINTS.PREVIEW}`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    resume_data: { basics, work, education },
                    theme_name: selectedTheme
                })
            });

            if (!response.ok) {
                throw new Error('Failed to update preview');
            }

            const html = await response.text();
            setPreviewHtml(html);
        } catch (error) {
            console.error('Preview error:', error);
            setError('Failed to update preview. Please try again.');
        } finally {
            setIsPreviewLoading(false);
        }
    };

    // Handle theme change
    const handleThemeChange = async (themeId) => {
        if (setSelectedTheme) {
            setSelectedTheme(themeId);
            await updatePreview();
        }
    };


    // Date formatting helper
    const formatDate = (dateString) => {
        if (!dateString) return null;

        const cleaned = String(dateString).trim();

        try {
            if (/^\d{4}$/.test(cleaned)) {
                return `${cleaned}-01-01`;
            }
            if (/^\d{4}-\d{2}$/.test(cleaned)) {
                return `${cleaned}-01`;
            }
            if (/^\d{4}-\d{2}-\d{2}$/.test(cleaned)) {
                return cleaned;
            }
            const monthMatch = cleaned.match(/([a-zA-Z]+)\s+(\d{4})/);
            if (monthMatch) {
                const month = getMonthNumber(monthMatch[1]);
                const year = monthMatch[2];
                return `${year}-${month}-01`;
            }
            console.warn(`Invalid date format: ${dateString}, defaulting to null`);
            return null;
        } catch (error) {
            console.error(`Error formatting date: ${dateString}`, error);
            return null;
        }
    };

    const getMonthNumber = (month) => {
        const months = {
            'jan': '01', 'january': '01',
            'feb': '02', 'february': '02',
            'mar': '03', 'march': '03',
            'apr': '04', 'april': '04',
            'may': '05',
            'jun': '06', 'june': '06',
            'jul': '07', 'july': '07',
            'aug': '08', 'august': '08',
            'sep': '09', 'september': '09',
            'oct': '10', 'october': '10',
            'nov': '11', 'november': '11',
            'dec': '12', 'december': '12'
        };

        const monthKey = month.toLowerCase().slice(0, 3);
        return months[monthKey] || '01';
    };

    const simulateProcessing = () => {
        setProcessingStep(0);
        setProcessingProgress(0);

        const stepDuration = 1000;
        const progressInterval = 50;
        const totalSteps = PROCESSING_STEPS.length;

        let currentStep = 0;
        let currentProgress = 0;

        const stepTimer = setInterval(() => {
            if (currentStep >= totalSteps) {
                clearInterval(stepTimer);
                return;
            }
            setProcessingStep(currentStep);
            currentStep++;
        }, stepDuration);

        const progressTimer = setInterval(() => {
            if (currentProgress >= 100) {
                clearInterval(progressTimer);
                return;
            }
            currentProgress += 1;
            setProcessingProgress(currentProgress);
        }, progressInterval);

        return () => {
            clearInterval(stepTimer);
            clearInterval(progressTimer);
        };
    };

    const handleArrayChange = (setter, index, field, value) => {
        setter(prev => {
            const newArray = [...prev];
            newArray[index] = { ...newArray[index], [field]: value };
            return newArray;
        });
    };

    // Load themes effect
    useEffect(() => {
        const fetchThemes = async () => {
            try {
                const response = await fetch(`${API_BASE}${API_ENDPOINTS.THEMES}`);
                if (!response.ok) {
                    throw new Error('Failed to fetch themes');
                }
                const data = await response.json();
                setAvailableThemes(data);
            } catch (error) {
                console.error('Error fetching themes:', error);
                setError('Failed to load themes. Please try again.');
            }
        };

        fetchThemes();
    }, []);

    // Effect for preview updates
    useEffect(() => {
        if (basics && work && education && selectedTheme) {
            const debounceTimeout = setTimeout(() => {
                updatePreview();
            }, 500);

            return () => clearTimeout(debounceTimeout);
        }
    }, [basics, work, education, selectedTheme]);

    // Render section
    return (
        <div className="space-y-6">
            {/* Theme Selector */}
            <div className="mb-8">
                <div className="flex items-center justify-between mb-4">
                    <h3 className="text-xl font-bold text-gray-800">
                        Resume Theme
                    </h3>
                    <button
                        onClick={() => setIsThemeSelectorOpen(!isThemeSelectorOpen)}
                        className="text-blue-600 hover:text-blue-700"
                    >
                        {isThemeSelectorOpen ? 'Hide Themes' : 'Change Theme'}
                    </button>
                </div>

                {isThemeSelectorOpen && (
                    <ThemeSelector
                        selectedTheme={selectedTheme}
                        onThemeSelect={handleThemeChange}
                        themes={availableThemes}
                    />
                )}
            </div>

            {/* Job Enhancement Section */}
            <div className="mb-8">
                <div className="text-2xl font-bold text-gray-800 mb-6">AI Job Enhancement</div>

                {/* Job URL Input */}
                <div className="bg-blue-50/50 p-6 rounded-xl shadow-sm border border-blue-100">
                    <label className="text-lg font-semibold text-gray-700 flex items-center gap-2">
                        <ExternalLink className="w-5 h-5 text-blue-500" />
                        Post the Link to the Job You Want
                    </label>
                    <input
                        type="url"
                        placeholder="https://www.linkedin.com/jobs/view/..."
                        value={jobLink}
                        onClick={() => setIsURLModalOpen(true)}
                        className="w-full px-4 py-3 mt-2 border rounded-xl bg-white/70"
                        readOnly
                    />
                    {jobLink && !isScrapingJob && (
                        <button
                            onClick={handleSearch}
                            className="w-full mt-4 bg-gradient-to-r from-green-500 to-blue-500 hover:from-green-600 hover:to-blue-600 text-white px-4 py-3 rounded-xl flex items-center justify-center gap-2 transition-all duration-300"
                        >
                            <ArrowDown className="w-4 h-4" />
                            Analyze Job
                        </button>
                    )}
                </div>

                {/* Manual Job Description */}
                <div className="mt-4">
                    <label className="block text-sm font-medium text-gray-700 mb-2">
                        Or copy & paste the Job Description
                    </label>
                    <div className="relative">
                        <ScrollText className="absolute left-3 top-3 w-5 h-5 text-gray-400" />
                        <textarea
                            value={jobText}
                            onChange={(e) => setJobText(e.target.value)}
                            placeholder="Paste the job description here..."
                            className="w-full pl-10 pr-4 py-2 border rounded-lg h-32 resize-none"
                        />
                    </div>
                </div>

                {/* Enhance Button */}
                {jobText && !isEnhancing && (
                    <button
                        onClick={handleEnhance}
                        className="w-full mt-4 bg-gradient-to-r from-green-500 to-blue-500 hover:from-green-600 hover:to-blue-600 text-white px-4 py-3 rounded-xl flex items-center justify-center gap-2 transition-all duration-300"
                    >
                        <ArrowDown className="w-4 h-4" />
                        Enhance Resume
                    </button>
                )}

                {/* Processing Status */}
                {(isEnhancing || isScrapingJob) && (
                    <div className="mt-4 bg-white/50 p-6 rounded-xl shadow-sm border border-blue-100">
                        <div className="flex items-center justify-between mb-4">
                            <h3 className="text-lg font-semibold text-blue-700">
                                {loadingMessage || (isEnhancing ? "Enhancing your resume..." : "Analyzing job description...")}
                            </h3>
                            <Loader2 className="w-5 h-5 animate-spin text-blue-500" />
                        </div>
                        <ProcessingSteps
                            currentStep={processingStep}
                            steps={PROCESSING_STEPS}
                            progress={processingProgress}
                        />
                    </div>
                )}

                {/* Error Messages */}
                {(error || scrapeError) && (
                    <div className="mt-4 bg-red-50 border border-red-200 rounded-xl p-4">
                        <div className="flex items-center gap-2 text-red-600">
                            <AlertCircle className="w-5 h-5" />
                            <span>{error || scrapeError}</span>
                        </div>
                    </div>
                )}
            </div>

            {/* Preview Section */}
            {basics && work && education && (
                <div className="mt-8 pt-8 border-t">
                    {/* Header with Export Button */}
                    <div className="flex items-center justify-between mb-6">
                        <div className="flex items-center gap-4">
                            <h3 className="text-xl font-bold text-gray-800">
                                Resume Preview
                            </h3>
                            <div className="flex bg-gray-100 rounded-lg p-1">
                                <button
                                    onClick={() => setPreviewMode('split')}
                                    className={`px-4 py-2 rounded-md flex items-center gap-2 transition-colors ${previewMode === 'split'
                                        ? 'bg-white shadow-sm text-blue-600'
                                        : 'text-gray-600 hover:text-gray-900'
                                        }`}
                                >
                                    <Edit2 className="w-4 h-4" />
                                    Split View
                                </button>
                                <button
                                    onClick={() => setPreviewMode('preview')}
                                    className={`px-4 py-2 rounded-md flex items-center gap-2 transition-colors ${previewMode === 'preview'
                                        ? 'bg-white shadow-sm text-blue-600'
                                        : 'text-gray-600 hover:text-gray-900'
                                        }`}
                                >
                                    <Eye className="w-4 h-4" />
                                    Preview
                                </button>
                            </div>
                        </div>
                        <div className="flex items-center gap-4">
                            <div className="flex items-center gap-2 bg-blue-50 px-3 py-1 rounded-full">
                                <Star className="w-4 h-4 text-blue-500" />
                                <span className="text-blue-600 font-medium">
                                    {resumeScore}%
                                </span>
                            </div>
                            <button
                                onClick={handleExportPDF}
                                className="flex items-center gap-2 px-4 py-2 bg-blue-500 text-white rounded-lg hover:bg-blue-600 transition-colors"
                            >
                                <Download className="w-4 h-4" />
                                Export PDF
                            </button>
                        </div>
                    </div>

                    {/* Grid Layout for Edit/Preview */}
                    <div className={`grid ${previewMode === 'preview' ? 'grid-cols-1' : 'grid-cols-2'} gap-6`}>
                        {previewMode === 'split' && (
                            <div className="space-y-6">
                                <div className="space-y-6">
                                    {/* Basic Information */}
                                    <div className="bg-gray-50 border-b p-4 rounded-lg">
                                        <input
                                            type="text"
                                            value={basics.name || ''}
                                            onChange={(e) => setBasics(prev => ({ ...prev, name: e.target.value }))}
                                            placeholder="Your Name"
                                            className="text-lg font-medium text-gray-900 w-full bg-transparent border-none focus:ring-2 focus:ring-blue-500 rounded px-2"
                                        />
                                        <input
                                            type="text"
                                            value={jobAnalysis?.target_role || basics.label || ''}
                                            onChange={(e) => setBasics(prev => ({ ...prev, label: e.target.value }))}
                                            placeholder="Your Title"
                                            className="text-sm text-gray-600 w-full bg-transparent border-none focus:ring-2 focus:ring-blue-500 rounded px-2 mt-1"
                                        />
                                    </div>

                                    {/* Contact Info and Other Sections */}
                                    <div className="p-4 space-y-6">
                                        {/* Contact Info */}
                                        <div className="flex gap-4 text-sm text-gray-600">
                                            <div className="flex items-center gap-1">
                                                <FileText className="w-4 h-4" />
                                                <input
                                                    type="email"
                                                    value={basics.email || ''}
                                                    onChange={(e) => setBasics(prev => ({ ...prev, email: e.target.value }))}
                                                    placeholder="Email"
                                                    className="bg-transparent border-none focus:ring-2 focus:ring-blue-500 rounded px-2"
                                                />
                                            </div>
                                            <div className="flex items-center gap-1">
                                                <FileText className="w-4 h-4" />
                                                <input
                                                    type="tel"
                                                    value={basics.phone || ''}
                                                    onChange={(e) => setBasics(prev => ({ ...prev, phone: e.target.value }))}
                                                    placeholder="Phone"
                                                    className="bg-transparent border-none focus:ring-2 focus:ring-blue-500 rounded px-2"
                                                />
                                            </div>
                                        </div>

                                        {/* Summary */}
                                        <div>
                                            <textarea
                                                value={basics.summary || ''}
                                                onChange={(e) => setBasics(prev => ({ ...prev, summary: e.target.value }))}
                                                placeholder="Professional Summary..."
                                                className="w-full text-sm text-gray-700 bg-transparent border rounded-md focus:ring-2 focus:ring-blue-500 p-3 min-h-[120px]"
                                            />
                                        </div>

                                        {/* Work Experience */}
                                        {work && work.length > 0 && (
                                            <div className="space-y-4">
                                                <div className="font-medium text-gray-900 flex justify-between items-center">
                                                    <span>Experience</span>
                                                    <button
                                                        onClick={() => setWork([
                                                            ...work,
                                                            {
                                                                position: '',
                                                                name: '',
                                                                startDate: '',
                                                                endDate: '',
                                                                highlights: []
                                                            }
                                                        ])}
                                                        className="text-blue-500 hover:text-blue-600"
                                                    >
                                                        <PlusCircle className="w-4 h-4" />
                                                    </button>
                                                </div>
                                                {work.map((job, index) => (
                                                    <div
                                                        key={index}
                                                        className="text-sm space-y-3 relative group border rounded-lg p-4 hover:shadow-sm transition-shadow"
                                                    >
                                                        <button
                                                            onClick={() => setWork(work.filter((_, i) => i !== index))}
                                                            className="absolute -right-2 -top-2 opacity-0 group-hover:opacity-100 transition-opacity"
                                                        >
                                                            <Trash2 className="w-4 h-4 text-red-500 hover:text-red-600" />
                                                        </button>
                                                        {job.original_position && job.original_position !== job.position && (
                                                            <div className="text-sm text-gray-500 italic mb-2">
                                                                Original Position: {job.original_position}
                                                            </div>
                                                        )}
                                                        <input
                                                            value={job.position || ''}
                                                            onChange={(e) => handleArrayChange(setWork, index, 'position', e.target.value)}
                                                            placeholder="Position"
                                                            className="font-medium w-full bg-transparent border-none focus:ring-2 focus:ring-blue-500 rounded px-2"
                                                        />
                                                        <input
                                                            value={job.name || ''}
                                                            onChange={(e) => handleArrayChange(setWork, index, 'name', e.target.value)}
                                                            placeholder="Company"
                                                            className="text-gray-600 w-full bg-transparent border-none focus:ring-2 focus:ring-blue-500 rounded px-2"
                                                        />
                                                        <div className="flex gap-2">
                                                            <input
                                                                type="date"
                                                                value={job.startDate || ''}
                                                                onChange={(e) => handleArrayChange(setWork, index, 'startDate', e.target.value)}
                                                                className="text-gray-500 bg-transparent border rounded px-2"
                                                            />
                                                            -
                                                            <input
                                                                type="date"
                                                                value={job.endDate || ''}
                                                                onChange={(e) => handleArrayChange(setWork, index, 'endDate', e.target.value)}
                                                                className="text-gray-500 bg-transparent border rounded px-2"
                                                            />
                                                        </div>
                                                        <div className="space-y-2">
                                                            {job.highlights && job.highlights.map((highlight, idx) => (
                                                                <div
                                                                    key={idx}
                                                                    className="relative group flex gap-2 items-center"
                                                                >
                                                                    <span className="text-gray-400">•</span>
                                                                    <input
                                                                        value={highlight}
                                                                        onChange={(e) => {
                                                                            const newWork = [...work];
                                                                            newWork[index].highlights[idx] = e.target.value;
                                                                            setWork(newWork);
                                                                        }}
                                                                        className="flex-1 text-gray-700 bg-transparent border-none focus:ring-2 focus:ring-blue-500 rounded px-2 py-1"
                                                                    />
                                                                    <button
                                                                        onClick={() => {
                                                                            const newWork = [...work];
                                                                            newWork[index].highlights = newWork[index].highlights.filter((_, i) => i !== idx);
                                                                            setWork(newWork);
                                                                        }}
                                                                        className="opacity-0 group-hover:opacity-100 transition-opacity"
                                                                    >
                                                                        <Trash2 className="w-4 h-4 text-red-500 hover:text-red-600" />
                                                                    </button>
                                                                </div>
                                                            ))}
                                                            <button
                                                                onClick={() => {
                                                                    const newWork = [...work];
                                                                    if (!newWork[index].highlights) {
                                                                        newWork[index].highlights = [];
                                                                    }
                                                                    newWork[index].highlights.push('');
                                                                    setWork(newWork);
                                                                }}
                                                                className="text-sm text-blue-500 hover:text-blue-600 ml-6"
                                                            >
                                                                + Add Highlight
                                                            </button>
                                                        </div>
                                                    </div>
                                                ))}
                                            </div>
                                        )}

                                        {/* Education Section */}
                                        {education && education.length > 0 && (
                                            <div className="space-y-4">
                                                <div className="font-medium text-gray-900 flex justify-between items-center">
                                                    <span>Education</span>
                                                    <button
                                                        onClick={() => setEducation([
                                                            ...education,
                                                            {
                                                                institution: '',
                                                                area: '',
                                                                studyType: '',
                                                                startDate: '',
                                                                endDate: ''
                                                            }
                                                        ])}
                                                        className="text-blue-500 hover:text-blue-600"
                                                    >
                                                        <PlusCircle className="w-4 h-4" />
                                                    </button>
                                                </div>
                                                {education.map((edu, index) => (
                                                    <div
                                                        key={index}
                                                        className="text-sm space-y-2 relative group border rounded-lg p-4 hover:shadow-sm transition-shadow"
                                                    >
                                                        <button
                                                            onClick={() => setEducation(education.filter((_, i) => i !== index))}
                                                            className="absolute -right-2 -top-2 opacity-0 group-hover:opacity-100 transition-opacity"
                                                        >
                                                            <Trash2 className="w-4 h-4 text-red-500 hover:text-red-600" />
                                                        </button>
                                                        <input
                                                            value={edu.studyType || ''}
                                                            onChange={(e) => handleArrayChange(setEducation, index, 'studyType', e.target.value)}
                                                            placeholder="Degree Type"
                                                            className="font-medium w-full bg-transparent border-none focus:ring-2 focus:ring-blue-500 rounded px-2"
                                                        />
                                                        <input
                                                            value={edu.area || ''}
                                                            onChange={(e) => handleArrayChange(setEducation, index, 'area', e.target.value)}
                                                            placeholder="Field of Study"
                                                            className="w-full bg-transparent border-none focus:ring-2 focus:ring-blue-500 rounded px-2"
                                                        />
                                                        <input
                                                            value={edu.institution || ''}
                                                            onChange={(e) => handleArrayChange(setEducation, index, 'institution', e.target.value)}
                                                            placeholder="Institution"
                                                            className="text-gray-600 w-full bg-transparent border-none focus:ring-2 focus:ring-blue-500 rounded px-2"
                                                        />
                                                        <div className="flex gap-2">
                                                            <input
                                                                type="date"
                                                                value={edu.startDate || ''}
                                                                onChange={(e) => handleArrayChange(setEducation, index, 'startDate', e.target.value)}
                                                                className="text-gray-500 bg-transparent border rounded px-2"
                                                            />
                                                            -
                                                            <input
                                                                type="date"
                                                                value={edu.endDate || ''}
                                                                onChange={(e) => handleArrayChange(setEducation, index, 'endDate', e.target.value)}
                                                                className="text-gray-500 bg-transparent border rounded px-2"
                                                            />
                                                        </div>
                                                    </div>
                                                ))}
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div>
                        )}

                        {/* Preview Panel */}
                        <div className={previewMode === 'preview' ? 'col-span-2' : ''}>
                            <div className="border rounded-xl overflow-hidden bg-white h-[800px]">
                                {isPreviewLoading ? (
                                    <div className="flex items-center justify-center h-full">
                                        <Loader2 className="w-8 h-8 animate-spin text-blue-500" />
                                    </div>
                                ) : (
                                    <iframe
                                        srcDoc={previewHtml}
                                        className="w-full h-full"
                                        title="Resume Preview"
                                        style={{ border: 'none' }}
                                        sandbox="allow-same-origin"
                                    />
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            )}

            {/* Job URL Selector Modal */}
            <JobURLSelectorModal
                isOpen={isURLModalOpen}
                onClose={() => setIsURLModalOpen(false)}
                onSelectURL={handleJobLinkSelect}
            />
        </div>
    );
};

export default EnhanceSection;