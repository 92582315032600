import React, { useState, useEffect } from 'react';
import { Upload, Search, FileSearch, Files, Send, RefreshCw, Briefcase, Sparkles, Brain, Shield, Zap, CheckCircle2, Code2 } from 'lucide-react';

const WorkFlow = () => {
    const [activeStep, setActiveStep] = useState(0);
    const [jobCount, setJobCount] = useState(0);
    const [messageIndex, setMessageIndex] = useState(0);
    const [featureIndex, setFeatureIndex] = useState(0);

    // Color palette based on #24C660
    const colors = {
        primary: '#24C660',    // Main green
        secondary: '#1B9E4C',  // Darker green
        accent: '#7BE49E',     // Light green
        complement: '#C62488', // Complementary pink
        neutral: '#E8F7ED'     // Very light green background
    };

    const steps = [
        { icon: Upload, label: 'Upload Resume' },
        { icon: Search, label: 'Find 1000s of Jobs' },
        { icon: FileSearch, label: 'Analyze Each Job' },
        { icon: Files, label: 'Tailor Resume' },
        { icon: Send, label: 'Apply Specifically' },
        { icon: RefreshCw, label: 'Repeat Process' },
    ];

    const features = [
        {
            icon: Brain,
            title: "AI Trained on 1M+ Resumes",
            description: "Our LLM understands successful career narratives across industries",
            bgGradient: "from-[#24C660] to-[#1B9E4C]"
        },
        {
            icon: Shield,
            title: "100% Truth-Based",
            description: "We enhance your genuine experience, never fabricate",
            bgGradient: "from-[#1B9E4C] to-[#24C660]"
        },
        {
            icon: Code2,
            title: "ATS Optimization",
            description: "Format optimized for all major ATS systems",
            bgGradient: "from-[#24C660] to-[#7BE49E]"
        },
        {
            icon: CheckCircle2,
            title: "Core Skills Focus",
            description: "Highlights your authentic technical and soft skills",
            bgGradient: "from-[#7BE49E] to-[#24C660]"
        }
    ];

    const messages = [
        "✨ Trained on over 1 million successful resumes for optimal enhancement",
        "🎯 Optimized for ATS while maintaining 100% truthful content",
        "💫 Preserves your authentic experience while maximizing impact",
        "⚡️ Advanced AI identifies and elevates your core strengths"
    ];

    useEffect(() => {
        const stepInterval = setInterval(() => {
            setActiveStep(prev => (prev + 1) % steps.length);
        }, 3000);

        const messageInterval = setInterval(() => {
            setMessageIndex(prev => (prev + 1) % messages.length);
        }, 5000);

        const featureInterval = setInterval(() => {
            setFeatureIndex(prev => (prev + 1) % features.length);
        }, 4000);

        return () => {
            clearInterval(stepInterval);
            clearInterval(messageInterval);
            clearInterval(featureInterval);
        };
    }, []);

    useEffect(() => {
        if (activeStep === 1) {
            const countInterval = setInterval(() => {
                setJobCount(prev => prev < 300 ? prev + 1 : prev);
            }, 10);
            return () => clearInterval(countInterval);
        } else {
            setJobCount(0);
        }
    }, [activeStep]);

    const sparkleClass = "animate-[twinkle_1.5s_ease-in-out_infinite]";

    return (
        <div className="bg-gradient-to-br from-[#E8F7ED] to-white p-4 md:p-8 rounded-3xl shadow-xl w-full max-w-6xl mx-auto border border-[#24C660]/20">
            <div className="text-center mb-6 md:mb-8">
                <h2 className="text-3xl md:text-4xl font-bold bg-clip-text text-transparent bg-gradient-to-r from-[#24C660] to-[#1B9E4C] mb-4 flex items-center justify-center gap-2">
                    <Sparkles className={`w-5 h-5 md:w-6 md:h-6 text-[#24C660] ${sparkleClass}`} />
                    The only complete solution
                    <Sparkles className={`w-5 h-5 md:w-6 md:h-6 text-[#1B9E4C] ${sparkleClass}`} />
                </h2>
                <p className="text-gray-600 text-base md:text-lg max-w-3xl mx-auto">
                    Our AI is fine-tuned on 1M+ resumes and is optimized to beat ATS while maintaining complete truthfulness.
                </p>
            </div>

            <div className="relative mb-8 md:mb-12">
                {/* Progress bar background */}
                <div className="hidden md:block absolute top-10 left-0 right-0 h-1.5 bg-[#E8F7ED] rounded-full" />

                {/* Active progress bar */}
                <div
                    className="hidden md:block absolute top-10 left-0 h-1.5 bg-gradient-to-r from-[#24C660] to-[#1B9E4C] rounded-full transition-all duration-500 ease-in-out"
                    style={{ width: `${(activeStep / (steps.length - 1)) * 100}%` }}
                />

                {/* Steps - Grid for mobile, Flex for desktop */}
                <div className="grid grid-cols-3 gap-4 md:flex md:justify-between md:items-center relative">
                    {steps.map((step, index) => (
                        <div key={index} className="flex flex-col items-center z-10">
                            <div
                                className={`w-16 md:w-20 h-16 md:h-20 rounded-full flex items-center justify-center shadow-lg transition-all duration-500
                                    ${index <= activeStep
                                        ? 'bg-gradient-to-br from-[#24C660] to-[#1B9E4C]'
                                        : 'bg-white border border-[#24C660]/20'
                                    }
                                    ${index === activeStep ? 'scale-110 shadow-[#24C660]/20' : 'scale-100 hover:scale-105'}
                                `}
                            >
                                <step.icon
                                    className={`w-8 md:w-10 h-8 md:h-10 transition-colors duration-500 
                                    ${index <= activeStep ? 'text-white' : 'text-[#24C660]'}`}
                                />
                            </div>

                            <p className={`mt-2 md:mt-4 text-xs md:text-sm font-medium text-center transition-colors duration-500
                                ${index <= activeStep ? 'text-[#1B9E4C]' : 'text-[#24C660]/70'}`}>
                                {step.label}
                            </p>

                            {index === 1 && activeStep === 1 && (
                                <div className="absolute top-20 md:top-24 left-1/2 transform -translate-x-1/2 animate-pulse">
                                    <p className="text-xl md:text-2xl font-bold text-[#24C660]">
                                        {jobCount}+ Jobs
                                    </p>
                                </div>
                            )}
                        </div>
                    ))}
                </div>
            </div>

            {/* Rotating Feature Card */}
            <div className="flex justify-center mb-8 md:mb-12">
                <div
                    className="w-full max-w-2xl bg-gradient-to-br from-[#24C660] to-[#1B9E4C] p-4 md:p-8 rounded-2xl shadow-lg transition-all duration-500 transform hover:scale-105"
                    style={{ minHeight: '200px' }}
                >
                    <div className="flex items-start gap-4 md:gap-6 text-white">
                        <div className="bg-white/20 p-3 md:p-4 rounded-xl">
                            {React.createElement(features[featureIndex].icon, { className: "w-8 h-8 md:w-12 md:h-12" })}
                        </div>
                        <div className="flex-1">
                            <h3 className="text-xl md:text-2xl font-bold mb-2 md:mb-4">{features[featureIndex].title}</h3>
                            <p className="text-base md:text-lg opacity-90">{features[featureIndex].description}</p>
                        </div>
                    </div>
                </div>
            </div>

            {/* Trust Statement */}
            <div className="text-center p-4 md:p-6 bg-[#E8F7ED] rounded-2xl border border-[#24C660]/20 mb-6 md:mb-8">
                <Briefcase className="w-6 h-6 md:w-8 md:h-8 text-[#24C660] mx-auto mb-3 md:mb-4" />
                <p className="text-gray-700 text-base md:text-lg leading-relaxed max-w-4xl mx-auto">
                    Our AI enhances your resume while maintaining absolute truthfulness. We analyze job requirements,
                    optimize formatting, and elevate your authentic experience — never adding false information.
                </p>
            </div>

            {/* Rotating messages */}
            <div className="text-center h-16 flex items-center justify-center px-2 md:px-0">
                <div className="transition-all duration-500 text-base md:text-xl font-semibold text-[#1B9E4C] flex items-center gap-2">
                    <Sparkles className={`w-4 h-4 md:w-5 md:h-5 text-[#24C660] ${sparkleClass}`} />
                    {messages[messageIndex]}
                    <Sparkles className={`w-4 h-4 md:w-5 md:h-5 text-[#24C660] ${sparkleClass}`} />
                </div>
            </div>
        </div>
    );
};

export default WorkFlow;