// components/UploadSection.js
import React, { useState, useCallback, useEffect } from 'react';
import { Upload, Loader2, Check } from 'lucide-react';
import { createClient } from '@supabase/supabase-js';

const supabase = createClient(
    'https://auth.roboapply.ai',
    'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6InN1a21idnJwY3d6bm1ud3Rlb3liIiwicm9sZSI6ImFub24iLCJpYXQiOjE3Mjg4NzQ3ODYsImV4cCI6MjA0NDQ1MDc4Nn0.xR0tIF0NYNnnXa-N2-WpX0qgecGiwywdGwIVg3MgEyg'
);

const UploadSection = ({
    isAuthenticated,
    setShowLoginModal,
    setBasics,
    setWork,
    setEducation,
    setResumeContent,
    setCurrentStep,
    setUserId,  // Add this
    setRecordId,  // Add this

}) => {
    const [file, setFile] = useState(null);
    const [isUploading, setIsUploading] = useState(false);
    const [error, setError] = useState(null);
    const [uploadProgress, setUploadProgress] = useState(0);
    const [simulatedProgress, setSimulatedProgress] = useState(0);
    const [analysisStep, setAnalysisStep] = useState(0);


    const analysisSteps = [
        "Initializing document scanner...",
        "Reading document structure...",
        "Extracting work experience...",
        "Analyzing job titles and roles...",
        "Processing educational background...",
        "Identifying key skills and technologies...",
        "Parsing professional achievements...",
        "Evaluating career progression...",
        "Organizing resume sections...",
        "Finalizing resume analysis..."
    ];

    // Simulate progress and rotate analysis steps
    useEffect(() => {
        let progressInterval;
        let analysisInterval;

        if (isUploading) {
            // Progress simulation
            progressInterval = setInterval(() => {
                setSimulatedProgress(prev => {
                    if (prev < 30) return prev + 0.8;
                    if (prev < 60) return prev + 0.5;
                    if (prev < 85) return prev + 0.2;
                    if (prev < 90) return prev + 0.1;
                    return prev;
                });
            }, 100);

            // Rotate through analysis steps
            analysisInterval = setInterval(() => {
                setAnalysisStep(prev => (prev + 1) % analysisSteps.length);
            }, 2000);
        }

        return () => {
            clearInterval(progressInterval);
            clearInterval(analysisInterval);
        };
    }, [isUploading]);

    const handleUpload = async (selectedFile) => {
        if (!isAuthenticated) {
            setShowLoginModal(true);
            return;
        }

        setIsUploading(true);
        setError(null);
        setUploadProgress(0);
        setSimulatedProgress(0);

        try {
            const { data: { user }, error: authError } = await supabase.auth.getUser();
            if (authError || !user) {
                throw new Error('Authentication error. Please sign in again.');
            }

            const userId = user.id;
            // Store user_id in localStorage
            localStorage.setItem('user_id', userId);

            const formData = new FormData();
            formData.append('file', selectedFile);
            formData.append('user_id', userId);
            formData.append('job_description', '');
            formData.append('enhance', 'false');
            formData.append('mode', 'new');

            const progressInterval = setInterval(() => {
                setUploadProgress((prev) => Math.min(prev + 10, 90));
            }, 500);

            const response = await fetch('https://e0a0-74-88-7-3.ngrok-free.app/api/v1/resume/upload_small', {
                method: 'POST',
                body: formData,
            });

            clearInterval(progressInterval);
            setUploadProgress(100);

            if (!response.ok) {
                const errorData = await response.json();
                throw new Error(`Upload failed: ${JSON.stringify(errorData, null, 2)}`);
            }

            const data = await response.json();

            // Store record_id in localStorage if it exists in the response
            if (data.record_id) {
                localStorage.setItem('record_id', data.record_id);
            }

            if (data.resume_json) {
                setBasics(data.resume_json.basics || {});
                setWork(data.resume_json.work || []);
                setEducation(data.resume_json.education || []);
                setResumeContent(data.resume_text || '');

                // If setUserId and setRecordId were passed as props, update them
                if (setUserId) {
                    setUserId(userId);
                }
                if (setRecordId && data.record_id) {
                    setRecordId(data.record_id);
                }

                setCurrentStep(1);
            }
        } catch (error) {
            console.error('Upload error:', error);
            setError(`Failed to upload resume: ${error.message}`);
        } finally {
            setIsUploading(false);
            setSimulatedProgress(0);
        }
    };

    const handleFileChange = (e) => {
        if (e.target.files?.[0]) {
            setFile(e.target.files[0]);
            handleUpload(e.target.files[0]);
        }
    };

    const handleDrop = useCallback((e) => {
        e.preventDefault();
        const droppedFile = e.dataTransfer.files[0];
        if (droppedFile) {
            setFile(droppedFile);
            handleUpload(droppedFile);
        }
    }, []);

    const handleDragOver = useCallback((e) => {
        e.preventDefault();
    }, []);

    return (
        <div className="space-y-6">
            <div className="text-2xl font-bold text-gray-800">Upload Your Resume</div>
            <div
                className="w-full"
                onDrop={handleDrop}
                onDragOver={handleDragOver}
            >
                <div className="relative">
                    {isUploading && (
                        <div className="absolute -inset-0.5 bg-gradient-to-r from-blue-300 via-blue-400 to-blue-300 rounded-lg blur opacity-75 animate-pulse-border" />
                    )}

                    <label className={`relative flex flex-col items-center justify-center w-full h-64 ${isUploading ? 'border-2 border-transparent' : 'border-2 border-dashed border-gray-300'} rounded-lg cursor-pointer bg-gray-50 hover:bg-gray-100 transition-all duration-300`}>
                        {isUploading ? (
                            <div className="space-y-4 text-center">
                                <div className="relative">
                                    <Loader2 className="w-10 h-10 animate-spin text-blue-500 mx-auto" />
                                    <div className="absolute -top-1 -right-1 w-3 h-3 bg-blue-500 rounded-full animate-ping" />
                                </div>

                                <div className="space-y-2">
                                    <p className="text-sm font-medium text-gray-800">
                                        {analysisSteps[analysisStep]}
                                    </p>
                                    <p className="text-xs text-gray-500">
                                        Processing your resume can take up to 60 seconds
                                    </p>
                                </div>

                                <div className="relative w-48 mx-auto">
                                    <div className="h-2 bg-gray-200 rounded-full overflow-hidden">
                                        <div
                                            className="h-full bg-blue-500 transition-all duration-300"
                                            style={{ width: `${Math.min(simulatedProgress, uploadProgress)}%` }}
                                        />
                                    </div>
                                    <span className="absolute -right-8 top-1/2 -translate-y-1/2 text-xs text-gray-500">
                                        {Math.round(Math.min(simulatedProgress, uploadProgress))}%
                                    </span>
                                </div>

                                <div className="flex justify-center gap-1">
                                    {[...Array(3)].map((_, i) => (
                                        <div
                                            key={i}
                                            className="w-1.5 h-1.5 rounded-full bg-blue-500 animate-bounce"
                                            style={{
                                                animationDelay: `${i * 200}ms`
                                            }}
                                        />
                                    ))}
                                </div>
                            </div>
                        ) : (
                            <div className="flex flex-col items-center justify-center pt-5 pb-6">
                                <Upload className="w-10 h-10 mb-3 text-gray-400" />
                                <p className="mb-2 text-sm text-gray-500">
                                    <span className="font-semibold">Click to upload</span> or drag and drop
                                </p>
                                <p className="text-xs text-gray-500">PDF, DOCX, or TXT (MAX. 5MB)</p>
                            </div>
                        )}
                        <input
                            type="file"
                            className="hidden"
                            onChange={handleFileChange}
                            accept=".pdf,.docx,.txt"
                            disabled={isUploading}
                        />
                    </label>
                </div>
            </div>

            {file && !isUploading && (
                <div className="flex items-center gap-2 text-sm text-gray-600">
                    <Check className="w-4 h-4 text-green-500" />
                    <span>File uploaded: {file.name}</span>
                </div>
            )}

            {error && (
                <div className="bg-red-50 border border-red-200 rounded-lg p-4">
                    <div className="text-sm text-red-600">
                        {error}
                    </div>
                </div>
            )}

            <style jsx>{`
                @keyframes pulse-border {
                    0% { opacity: 0.5; }
                    50% { opacity: 1; }
                    100% { opacity: 0.5; }
                }
                .animate-pulse-border {
                    animation: pulse-border 2s cubic-bezier(0.4, 0, 0.6, 1) infinite;
                }
            `}</style>
        </div>
    );
};

export default UploadSection;