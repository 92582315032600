import React from 'react';
import {
    User,
    FileText,
    Clock,
    Medal,
    Wallet,
    Download,
    Mail,
    Shield
} from 'lucide-react';

// Personal Details Component
const PersonalDetails = ({ userData }) => (
    <div className="bg-white rounded-lg shadow-sm border border-gray-200">
        <div className="px-6 py-4 border-b border-gray-200">
            <h2 className="text-xl font-semibold text-gray-900">Personal Details</h2>
        </div>
        <div className="p-6 space-y-6">
            <div className="grid grid-cols-2 gap-6">
                <div>
                    <label className="block text-sm font-medium text-gray-700">
                        Full Name
                    </label>
                    <input
                        type="text"
                        defaultValue={userData?.name}
                        className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-lg"
                    />
                </div>
                <div>
                    <label className="block text-sm font-medium text-gray-700">
                        Email
                    </label>
                    <input
                        type="email"
                        defaultValue={userData?.email}
                        className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-lg"
                    />
                </div>
                <div>
                    <label className="block text-sm font-medium text-gray-700">
                        Phone
                    </label>
                    <input
                        type="tel"
                        defaultValue={userData?.phone}
                        className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-lg"
                    />
                </div>
                <div>
                    <label className="block text-sm font-medium text-gray-700">
                        LinkedIn URL
                    </label>
                    <input
                        type="url"
                        defaultValue={userData?.linkedin}
                        className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-lg"
                    />
                </div>
            </div>
        </div>
    </div>
);

// Credits & Billing Component
const BillingContent = ({ userData }) => (
    <div className="space-y-6">
        {/* Credit Statistics */}
        <div className="grid grid-cols-4 gap-4">
            {[
                { label: "Available Credits", value: userData?.credits || 0, icon: Wallet },
                { label: "Applications Sent", value: userData?.total_applications || 0, icon: FileText },
                { label: "Success Rate", value: `${userData?.success_rate || 0}%`, icon: Medal },
                { label: "Active Since", value: new Date(userData?.joined_date).toLocaleDateString(), icon: Clock }
            ].map((stat, index) => (
                <div key={index} className="bg-white rounded-lg shadow-sm border border-gray-200 p-4">
                    <div className="flex items-center justify-between">
                        <stat.icon className="w-5 h-5 text-gray-400" />
                        <span className="text-sm text-gray-500">{stat.label}</span>
                    </div>
                    <div className="mt-2 text-2xl font-semibold text-gray-900">
                        {stat.value}
                    </div>
                </div>
            ))}
        </div>

        {/* Payment History */}
        <div className="bg-white rounded-lg shadow-sm border border-gray-200">
            <div className="px-6 py-4 border-b border-gray-200">
                <h2 className="text-xl font-semibold text-gray-900">Payment History</h2>
            </div>
            <div className="overflow-x-auto">
                <table className="w-full">
                    <thead>
                        <tr className="bg-gray-50">
                            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase">Date</th>
                            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase">Package</th>
                            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase">Credits</th>
                            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase">Amount</th>
                            <th className="px-6 py-3 text-right text-xs font-medium text-gray-500 uppercase">Receipt</th>
                        </tr>
                    </thead>
                    <tbody className="divide-y divide-gray-200">
                        {userData?.payments?.map(payment => (
                            <tr key={payment.id}>
                                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                                    {payment.date}
                                </td>
                                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                                    {payment.package_name}
                                </td>
                                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                                    {payment.credits}
                                </td>
                                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                                    ${payment.amount}
                                </td>
                                <td className="px-6 py-4 whitespace-nowrap text-right text-sm">
                                    <button className="text-blue-600 hover:text-blue-900 flex items-center justify-end gap-1">
                                        <Download className="w-4 h-4" />
                                        Receipt
                                    </button>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </div>
    </div>
);

// Notifications Settings
const NotificationSettings = ({ userData }) => (
    <div className="bg-white rounded-lg shadow-sm border border-gray-200">
        <div className="px-6 py-4 border-b border-gray-200">
            <h2 className="text-xl font-semibold text-gray-900">Notification Preferences</h2>
        </div>
        <div className="p-6 space-y-6">
            <div className="space-y-4">
                {[
                    { id: 'application_updates', label: 'Application Updates', description: 'Get notified about the status of your job applications' },
                    { id: 'credit_alerts', label: 'Credit Alerts', description: 'Receive alerts when your credits are running low' },
                    { id: 'job_recommendations', label: 'Job Recommendations', description: 'Get personalized job recommendations based on your profile' },
                    { id: 'product_updates', label: 'Product Updates', description: 'Stay informed about new features and improvements' }
                ].map(setting => (
                    <div key={setting.id} className="flex items-start space-x-4">
                        <input
                            type="checkbox"
                            id={setting.id}
                            defaultChecked={userData?.notifications?.[setting.id]}
                            className="mt-1"
                        />
                        <div>
                            <label htmlFor={setting.id} className="block text-sm font-medium text-gray-900">
                                {setting.label}
                            </label>
                            <p className="text-sm text-gray-500">{setting.description}</p>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    </div>
);

// Settings Content Component
const SettingsContent = ({ activeSection, userData }) => {
    switch (activeSection) {
        case 'personal':
            return <PersonalDetails userData={userData} />;
        case 'billing':
            return <BillingContent userData={userData} />;
        case 'notifications':
            return <NotificationSettings userData={userData} />;
        case 'security':
            return (
                <div className="bg-white rounded-lg shadow-sm border border-gray-200 p-6">
                    <h2 className="text-xl font-semibold text-gray-900 mb-4">Security Settings</h2>
                    <div className="space-y-6">
                        <div>
                            <h3 className="text-lg font-medium text-gray-900">Change Password</h3>
                            <div className="mt-4 space-y-4">
                                <input
                                    type="password"
                                    placeholder="Current Password"
                                    className="block w-full px-3 py-2 border border-gray-300 rounded-lg"
                                />
                                <input
                                    type="password"
                                    placeholder="New Password"
                                    className="block w-full px-3 py-2 border border-gray-300 rounded-lg"
                                />
                                <input
                                    type="password"
                                    placeholder="Confirm New Password"
                                    className="block w-full px-3 py-2 border border-gray-300 rounded-lg"
                                />
                                <button className="px-4 py-2 bg-blue-600 text-white rounded-lg text-sm font-medium hover:bg-blue-700">
                                    Update Password
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            );
        default:
            return null;
    }
};

export { SettingsContent, BillingContent, PersonalDetails, NotificationSettings };