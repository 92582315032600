// import React, { useState, useEffect } from 'react';
// import { Link } from 'react-router-dom';
// import {
//     Search,
//     MapPin,
//     Briefcase,
//     Clock,
//     Globe,
//     Building,
//     ChevronDown,
//     X,
//     ExternalLink,
//     FileText,
//     Share2,
//     BookmarkPlus,
//     Mail,
//     Calendar,
//     DollarSign,
//     Users,
//     Building2,
//     AlertCircle
// } from 'lucide-react';

// // JobCardSkeleton Component
// const JobCardSkeleton = () => (
//     <div className="bg-white rounded-xl shadow-sm hover:shadow-md transition-shadow duration-300 border-l-4 border-gray-200 animate-pulse">
//         <div className="p-6">
//             <div className="flex items-start space-x-4">
//                 <div className="flex-shrink-0">
//                     <div className="w-12 h-12 bg-gray-200 rounded-full" />
//                 </div>
//                 <div className="flex-1 min-w-0">
//                     <div className="h-6 bg-gray-200 rounded w-3/4 mb-2" />
//                     <div className="h-4 bg-gray-200 rounded w-1/2 mb-4" />
//                     <div className="space-y-2">
//                         <div className="h-3 bg-gray-200 rounded w-1/4" />
//                         <div className="h-3 bg-gray-200 rounded w-1/3" />
//                         <div className="h-3 bg-gray-200 rounded w-1/4" />
//                     </div>
//                     <div className="mt-4">
//                         <div className="h-8 bg-gray-200 rounded-full w-24" />
//                     </div>
//                 </div>
//             </div>
//         </div>
//     </div>
// );

// // JobCard Component
// const JobCard = ({ job, setSelectedJob, siteInfo }) => {
//     const getTimeAgo = (dateString) => {
//         const date = new Date(dateString);
//         const now = new Date();
//         const diffTime = Math.abs(now - date);
//         const diffDays = Math.floor(diffTime / (1000 * 60 * 60 * 24));
//         const diffHours = Math.floor(diffTime / (1000 * 60 * 60));

//         if (diffDays > 0) return `${diffDays}d ago`;
//         return `${diffHours}h ago`;
//     };

//     return (
//         <div className="group bg-white rounded-xl shadow-sm hover:shadow-md transition-all duration-300 border-l-4 border-emerald-400 hover:border-emerald-500">
//             <div className="p-6">
//                 <div className="flex items-start space-x-4">
//                     <div className="flex-shrink-0">
//                         <div className={`w-12 h-12 rounded-full flex items-center justify-center bg-opacity-80 group-hover:bg-opacity-100 transition-all duration-300 ${siteInfo.color}`}>
//                             <img
//                                 src={job.company_info?.logo_url || siteInfo.icon}
//                                 alt={job.company}
//                                 className="w-8 h-8 object-contain rounded-full bg-white p-1"
//                             />
//                         </div>
//                     </div>

//                     <div className="flex-1 min-w-0">
//                         <div className="flex justify-between items-start">
//                             <h3 className="text-lg font-medium text-gray-900 truncate group-hover:text-emerald-600 transition-colors duration-300">
//                                 {job.title}
//                             </h3>
//                             <img
//                                 src={siteInfo.icon}
//                                 alt={siteInfo.name}
//                                 className="w-5 h-5 flex-shrink-0 opacity-70"
//                             />
//                         </div>

//                         <p className="text-sm text-gray-600 truncate mt-1">
//                             {job.company}
//                         </p>

//                         <div className="mt-3 flex flex-wrap gap-2">
//                             <span className="inline-flex items-center text-xs text-gray-500 bg-gray-50 px-2 py-1 rounded-full">
//                                 <MapPin className="w-3 h-3 mr-1" />
//                                 {job.location.city || 'Location not specified'}, {job.location.state}
//                             </span>

//                             {job.job_type && (
//                                 <span className="inline-flex items-center text-xs text-gray-500 bg-gray-50 px-2 py-1 rounded-full">
//                                     <Briefcase className="w-3 h-3 mr-1" />
//                                     {job.job_type}
//                                 </span>
//                             )}

//                             <span className="inline-flex items-center text-xs text-gray-500 bg-gray-50 px-2 py-1 rounded-full">
//                                 <Clock className="w-3 h-3 mr-1" />
//                                 {getTimeAgo(job.date_posted)}
//                             </span>

//                             {job.is_remote && (
//                                 <span className="inline-flex items-center text-xs text-emerald-500 bg-emerald-50 px-2 py-1 rounded-full">
//                                     <Globe className="w-3 h-3 mr-1" />
//                                     Remote
//                                 </span>
//                             )}
//                         </div>

//                         <div className="mt-4 flex space-x-2">
//                             <button
//                                 onClick={() => setSelectedJob(job)}
//                                 className="px-4 py-2 text-sm font-medium text-emerald-600 bg-emerald-50 rounded-full transition-colors duration-200 hover:bg-emerald-100"
//                             >
//                                 View Details
//                             </button>
//                             <Link
//                                 to="/login"
//                                 className="px-4 py-2 text-sm font-medium text-gray-600 bg-gray-50 rounded-full transition-colors duration-200 hover:bg-gray-100 flex items-center"
//                             >
//                                 <FileText className="w-4 h-4 mr-1" />
//                                 Custom Resume
//                             </Link>
//                         </div>
//                     </div>
//                 </div>
//             </div>
//         </div>
//     );
// };

// // JobModal Component
// const JobModal = ({ job, onClose, siteInfo }) => {
//     const [isAnimating, setIsAnimating] = useState(false);

//     useEffect(() => {
//         setIsAnimating(true);
//         document.body.style.overflow = 'hidden';
//         return () => {
//             document.body.style.overflow = 'unset';
//         };
//     }, []);

//     const formatDate = (dateString) => {
//         return new Date(dateString).toLocaleDateString('en-US', {
//             year: 'numeric',
//             month: 'long',
//             day: 'numeric'
//         });
//     };

//     const handleClose = () => {
//         setIsAnimating(false);
//         setTimeout(onClose, 200);
//     };

//     const ActionButton = ({ icon: Icon, label, primary, onClick }) => (
//         <button
//             onClick={onClick}
//             className={`flex items-center space-x-2 px-4 py-2 rounded-full transition-all duration-200 ${primary
//                 ? 'bg-emerald-500 text-white hover:bg-emerald-600'
//                 : 'border border-gray-200 hover:border-gray-300 text-gray-700 hover:bg-gray-50'
//                 }`}
//         >
//             <Icon className="w-4 h-4" />
//             <span className="font-medium">{label}</span>
//         </button>
//     );

//     const InfoTag = ({ icon: Icon, text, color = "gray" }) => (
//         <div className={`flex items-center space-x-2 px-3 py-2 rounded-lg bg-${color}-50 text-${color}-700`}>
//             <Icon className="w-4 h-4" />
//             <span className="text-sm">{text}</span>
//         </div>
//     );

//     return (
//         <div
//             className={`fixed inset-0 bg-black/50 flex items-center justify-center p-4 z-50 transition-opacity duration-200 ${isAnimating ? 'opacity-100' : 'opacity-0'
//                 }`}
//             onClick={handleClose}
//         >
//             <div
//                 className={`bg-white rounded-2xl shadow-xl max-w-4xl w-full max-h-[90vh] overflow-hidden transition-all duration-300 ${isAnimating ? 'translate-y-0 opacity-100' : 'translate-y-4 opacity-0'
//                     }`}
//                 onClick={e => e.stopPropagation()}
//             >
//                 {/* Header Section */}
//                 <div className="p-6 border-b border-gray-100">
//                     <div className="flex justify-between items-start mb-6">
//                         <div className="flex items-center space-x-4">
//                             <div className={`w-16 h-16 rounded-2xl flex items-center justify-center ${siteInfo.color}`}>
//                                 <img
//                                     src={job.company_info?.logo_url || siteInfo.icon}
//                                     alt={job.company}
//                                     className="w-12 h-12 object-contain rounded-xl bg-white p-1"
//                                 />
//                             </div>
//                             <div>
//                                 <h2 className="text-2xl font-bold text-gray-900">{job.title}</h2>
//                                 <p className="text-lg text-gray-600 flex items-center space-x-2">
//                                     <span>{job.company}</span>
//                                     <span className="text-gray-300">•</span>
//                                     <img src={siteInfo.icon} alt={siteInfo.name} className="w-4 h-4 opacity-70" />
//                                 </p>
//                             </div>
//                         </div>
//                         <button
//                             onClick={handleClose}
//                             className="p-2 hover:bg-gray-100 rounded-full transition-colors duration-200"
//                         >
//                             <X className="w-6 h-6 text-gray-400" />
//                         </button>
//                     </div>

//                     {/* Quick Actions */}
//                     <div className="flex flex-wrap gap-3">
//                         <ActionButton icon={Share2} label="Share" onClick={() => { }} />
//                         <ActionButton icon={BookmarkPlus} label="Save" onClick={() => { }} />
//                         <ActionButton icon={Mail} label="Email" onClick={() => { }} />
//                         <Link to="/login" className="ml-auto">
//                             <ActionButton icon={FileText} label="Create Custom Resume" primary onClick={() => { }} />
//                         </Link>
//                     </div>
//                 </div>

//                 {/* Content Section */}
//                 <div className="flex divide-x divide-gray-100 h-[calc(90vh-200px)]">
//                     {/* Main Content */}
//                     <div className="flex-1 overflow-y-auto p-6">
//                         {/* Job Details Grid */}
//                         <div className="grid grid-cols-2 gap-4 mb-8">
//                             <InfoTag icon={MapPin} text={`${job.location.city}, ${job.location.state}`} />
//                             <InfoTag icon={Calendar} text={formatDate(job.date_posted)} />
//                             {job.job_type && <InfoTag icon={Briefcase} text={job.job_type} color="blue" />}
//                             {job.is_remote && <InfoTag icon={Globe} text="Remote Position" color="green" />}
//                             {job.salary_range && <InfoTag icon={DollarSign} text={job.salary_range} color="emerald" />}
//                             {job.company_size && <InfoTag icon={Users} text={job.company_size} />}
//                         </div>

//                         {/* Job Description */}
//                         <div className="prose max-w-none">
//                             <div className="space-y-6">
//                                 <section>
//                                     <h3 className="text-xl font-semibold text-gray-900 mb-4">About the Role</h3>
//                                     <div className="text-gray-700 whitespace-pre-wrap leading-relaxed">
//                                         {job.description}
//                                     </div>
//                                 </section>

//                                 {job.requirements && (
//                                     <section>
//                                         <h3 className="text-xl font-semibold text-gray-900 mb-4">Requirements</h3>
//                                         <div className="text-gray-700 whitespace-pre-wrap leading-relaxed">
//                                             {job.requirements}
//                                         </div>
//                                     </section>
//                                 )}

//                                 {job.benefits && (
//                                     <section>
//                                         <h3 className="text-xl font-semibold text-gray-900 mb-4">Benefits</h3>
//                                         <div className="text-gray-700 whitespace-pre-wrap leading-relaxed">
//                                             {job.benefits}
//                                         </div>
//                                     </section>
//                                 )}
//                             </div>
//                         </div>
//                     </div>

//                     {/* Company Sidebar */}
//                     <div className="w-80 overflow-y-auto p-6 bg-gray-50">
//                         <div className="space-y-6">
//                             <section>
//                                 <h3 className="text-lg font-semibold text-gray-900 mb-3">About {job.company}</h3>
//                                 <p className="text-gray-600 text-sm leading-relaxed">
//                                     {job.company_info?.description ||
//                                         `${job.company} is a leading company in their industry, focused on innovation and growth.`}
//                                 </p>
//                             </section>

//                             {job.company_info?.founded && (
//                                 <section>
//                                     <h4 className="text-sm font-medium text-gray-900 mb-2">Founded</h4>
//                                     <p className="text-gray-600 text-sm">{job.company_info.founded}</p>
//                                 </section>
//                             )}

//                             {job.company_info?.industry && (
//                                 <section>
//                                     <h4 className="text-sm font-medium text-gray-900 mb-2">Industry</h4>
//                                     <p className="text-gray-600 text-sm">{job.company_info.industry}</p>
//                                 </section>
//                             )}

//                             {job.company_info?.website && (
//                                 <section>
//                                     <h4 className="text-sm font-medium text-gray-900 mb-2">Website</h4>
//                                     <a
//                                         href={job.company_info.website}
//                                         target="_blank"
//                                         rel="noopener noreferrer"
//                                         className="text-emerald-600 hover:text-emerald-700 text-sm flex items-center space-x-1"
//                                     >
//                                         <span>Visit website</span>

//                                         <ExternalLink className="w-3 h-3" />
//                                     </a>
//                                 </section>
//                             )}
//                         </div>
//                     </div>
//                 </div>

//                 {/* Footer Actions */}
//                 <div className="p-6 border-t border-gray-100 bg-white">
//                     <div className="flex justify-between items-center">
//                         <div className="flex items-center space-x-4">
//                             <a
//                                 href={job.job_url}
//                                 target="_blank"
//                                 rel="noopener noreferrer"
//                                 className="px-6 py-2.5 bg-emerald-500 text-white font-medium rounded-full hover:bg-emerald-600 transition-colors duration-200 flex items-center space-x-2"
//                             >
//                                 <span>Apply on {new URL(job.job_url).hostname}</span>
//                                 <ExternalLink className="w-4 h-4" />
//                             </a>
//                             {job.company_url && (
//                                 <a
//                                     href={job.company_url}
//                                     target="_blank"
//                                     rel="noopener noreferrer"
//                                     className="px-6 py-2.5 border border-gray-200 hover:border-gray-300 rounded-full transition-colors duration-200 flex items-center space-x-2"
//                                 >
//                                     <Building2 className="w-4 h-4" />
//                                     <span>Company Profile</span>
//                                 </a>
//                             )}
//                         </div>
//                         <div className="text-sm text-gray-500">
//                             Posted {formatDate(job.date_posted)}
//                         </div>
//                     </div>
//                 </div>
//             </div>
//         </div>
//     );
// };

// // Main Component
// const JobSearchPortal = () => {
//     // State management with default values
//     const [searchTerm, setSearchTerm] = useState("data engineer");
//     const [location, setLocation] = useState("New York");
//     const [selectedSites, setSelectedSites] = useState(["indeed", "google", "glassdoor"]);
//     const [selectedJobTypes, setSelectedJobTypes] = useState(["fulltime"]);
//     const [resultsWanted, setResultsWanted] = useState(20);
//     const [hoursOld, setHoursOld] = useState(72);
//     const [isRemote, setIsRemote] = useState(false);
//     const [selectedJob, setSelectedJob] = useState(null);
//     const [isLoading, setIsLoading] = useState(false);
//     const [jobs, setJobs] = useState([]);
//     const [error, setError] = useState(null);


//     // Constants
//     const jobSites = [
//         { name: "linkedin", color: "bg-blue-400", icon: "https://logo.clearbit.com/linkedin.com" },
//         { name: "indeed", color: "bg-cyan-400", icon: "https://logo.clearbit.com/indeed.com" },
//         { name: "zip_recruiter", color: "bg-teal-400", icon: "https://logo.clearbit.com/ziprecruiter.com" },
//         { name: "glassdoor", color: "bg-green-400", icon: "https://logo.clearbit.com/glassdoor.com" },
//         { name: "google", color: "bg-red-400", icon: "https://logo.clearbit.com/google.com" }
//     ];

//     const jobTypes = ["fulltime", "parttime", "internship", "contract"];

//     // Auto-search on mount
//     useEffect(() => {
//         handleSearch();
//     }, []);

//     // Search handler
//     const handleSearch = async () => {
//         setIsLoading(true);
//         setError(null);

//         try {
//             const requestBody = {
//                 search_term: searchTerm,
//                 location: location || undefined,
//                 sites: selectedSites.length > 0 ? selectedSites : undefined,
//                 job_types: selectedJobTypes,
//                 results_wanted: resultsWanted,
//                 hours_old: hoursOld,
//                 is_remote: isRemote,
//                 country: "USA"
//             };

//             Object.keys(requestBody).forEach(key =>
//                 requestBody[key] === undefined && delete requestBody[key]
//             );

//             const response = await fetch('http://localhost:8000/api/search', {
//                 method: 'POST',
//                 headers: {
//                     'Content-Type': 'application/json',
//                     'Accept': 'application/json',
//                 },
//                 body: JSON.stringify(requestBody)
//             });

//             if (!response.ok) {
//                 const errorData = await response.json();
//                 throw new Error(errorData.detail || 'Search failed');
//             }

//             const data = await response.json();
//             setJobs(data.data?.jobs || data.jobs || []);

//         } catch (err) {
//             setError(err.message);
//             console.error('Search error:', err);
//         } finally {
//             setIsLoading(false);
//         }
//     };

//     return (
//         <div className="min-h-screen bg-gray-50">
//             {/* Header */}
//             <header className="bg-gradient-to-r from-emerald-600 to-emerald-500 shadow-md">
//                 <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
//                     <div className="flex justify-between items-center">
//                         <h1 className="text-3xl font-bold text-white">Job Search Portal</h1>
//                         <Link
//                             to="/login"
//                             className="px-6 py-2 bg-white text-emerald-600 rounded-full font-medium hover:bg-opacity-90 transition-all duration-200 flex items-center space-x-2"
//                         >
//                             <FileText className="w-5 h-5" />
//                             <span>Create Custom Resume</span>
//                         </Link>
//                     </div>
//                 </div>
//             </header>

//             {/* Main Content */}
//             <main className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
//                 {/* Search Form */}
//                 <div className="bg-white rounded-xl shadow-sm hover:shadow-md transition-all duration-300 p-6 mb-8">
//                     {/* Search Inputs */}
//                     <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-6">
//                         <div className="relative">
//                             <div className="absolute inset-y-0 left-0 pl-3 flex items-center">
//                                 <Search className="h-5 w-5 text-gray-400" />
//                             </div>
//                             <input
//                                 type="text"
//                                 placeholder="Job title or keywords"
//                                 value={searchTerm}
//                                 onChange={(e) => setSearchTerm(e.target.value)}
//                                 className="pl-10 w-full rounded-lg border-gray-300 focus:ring-2 focus:ring-emerald-500"
//                             />
//                         </div>
//                         <div className="relative">
//                             <div className="absolute inset-y-0 left-0 pl-3 flex items-center">
//                                 <MapPin className="h-5 w-5 text-gray-400" />
//                             </div>
//                             <input
//                                 type="text"
//                                 placeholder="Location"
//                                 value={location}
//                                 onChange={(e) => setLocation(e.target.value)}
//                                 className="pl-10 w-full rounded-lg border-gray-300 focus:ring-2 focus:ring-emerald-500"
//                             />
//                         </div>
//                     </div>

//                     {/* Job Sites */}
//                     <div className="mb-6">
//                         <h3 className="text-lg font-semibold mb-3">Job Sites</h3>
//                         <div className="flex flex-wrap gap-2">
//                             {jobSites.map((site) => (
//                                 <button
//                                     key={site.name}
//                                     onClick={() => setSelectedSites(prev =>
//                                         prev.includes(site.name)
//                                             ? prev.filter(s => s !== site.name)
//                                             : [...prev, site.name]
//                                     )}
//                                     className={`flex items-center px-4 py-2 rounded-full border transition-all duration-200 ${selectedSites.includes(site.name)
//                                         ? `${site.color} text-white border-transparent`
//                                         : 'border-gray-300 hover:border-gray-400'
//                                         }`}
//                                 >
//                                     <img src={site.icon} alt={site.name} className="w-4 h-4 mr-2" />
//                                     <span className="capitalize">{site.name.replace('_', ' ')}</span>
//                                 </button>
//                             ))}
//                         </div>
//                     </div>

//                     {/* Job Types */}
//                     <div className="mb-6">
//                         <h3 className="text-lg font-semibold mb-3">Job Types</h3>
//                         <div className="flex flex-wrap gap-2">
//                             {jobTypes.map((type) => (
//                                 <button
//                                     key={type}
//                                     onClick={() => setSelectedJobTypes(prev =>
//                                         prev.includes(type)
//                                             ? prev.filter(t => t !== type)
//                                             : [...prev, type]
//                                     )}
//                                     className={`px-4 py-2 rounded-full border transition-all duration-200 ${selectedJobTypes.includes(type)
//                                         ? 'bg-emerald-500 text-white border-transparent'
//                                         : 'border-gray-300 hover:border-gray-400'
//                                         }`}
//                                 >
//                                     {type.charAt(0).toUpperCase() + type.slice(1)}
//                                 </button>
//                             ))}
//                         </div>
//                     </div>

//                     {/* Remote Switch */}
//                     <div className="mb-6">
//                         <label className="flex items-center space-x-2 cursor-pointer">
//                             <div
//                                 className={`w-10 h-6 rounded-full p-1 transition-colors duration-200 ${isRemote ? 'bg-emerald-500' : 'bg-gray-300'
//                                     }`}
//                                 onClick={() => setIsRemote(!isRemote)}
//                             >
//                                 <div className={`w-4 h-4 rounded-full bg-white transition-transform duration-200 ${isRemote ? 'translate-x-4' : 'translate-x-0'
//                                     }`} />
//                             </div>
//                             <span className="text-sm font-medium">Remote Only</span>
//                         </label>
//                     </div>

//                     {/* Search Parameters */}
//                     <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mb-6">
//                         <div>
//                             <h3 className="text-sm font-medium mb-2">Results: {resultsWanted}</h3>
//                             <input
//                                 type="range"
//                                 min="1"
//                                 max="100"
//                                 value={resultsWanted}
//                                 onChange={(e) => setResultsWanted(Number(e.target.value))}
//                                 className="w-full h-2 bg-gray-200 rounded-lg appearance-none cursor-pointer"
//                             />
//                         </div>
//                         <div>
//                             <h3 className="text-sm font-medium mb-2">Hours Old: {hoursOld}</h3>
//                             <input
//                                 type="range"
//                                 min="1"
//                                 max="168"
//                                 value={hoursOld}
//                                 onChange={(e) => setHoursOld(Number(e.target.value))}
//                                 className="w-full h-2 bg-gray-200 rounded-lg appearance-none cursor-pointer"
//                             />
//                         </div>
//                     </div>

//                     {/* Search Button */}
//                     <button
//                         onClick={handleSearch}
//                         disabled={isLoading || !searchTerm}
//                         className={`w-full py-3 rounded-lg text-white font-medium transition-colors duration-200 ${isLoading || !searchTerm
//                             ? 'bg-gray-400 cursor-not-allowed'
//                             : 'bg-emerald-500 hover:bg-emerald-600'
//                             }`}
//                     >
//                         {isLoading ? 'Searching...' : 'Search Jobs'}
//                     </button>

//                     {/* Error Display */}
//                     {error && (
//                         <div className="mt-4 p-4 bg-red-50 text-red-700 rounded-lg">
//                             <p className="font-medium">Error: {error}</p>
//                         </div>
//                     )}
//                 </div>

//                 {/* Results Grid */}
//                 <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
//                     {isLoading ? (
//                         [...Array(6)].map((_, i) => <JobCardSkeleton key={i} />)
//                     ) : (
//                         jobs.map((job) => (
//                             <JobCard
//                                 key={job.id}
//                                 job={job}
//                                 setSelectedJob={setSelectedJob}
//                                 siteInfo={jobSites.find(site => site.name === job.source_site)}
//                             />
//                         ))
//                     )}
//                 </div>

//                 {/* No Results Message */}
//                 {!isLoading && jobs.length === 0 && !error && (
//                     <div className="text-center py-12">
//                         <AlertCircle className="w-12 h-12 text-gray-400 mx-auto mb-4" />
//                         <p className="text-gray-500 text-lg">No jobs found matching your criteria.</p>
//                     </div>
//                 )}
//             </main>

//             {/* Job Modal */}
//             {selectedJob && (
//                 <JobModal
//                     job={selectedJob}
//                     onClose={() => setSelectedJob(null)}
//                     siteInfo={jobSites.find(site => site.name === selectedJob.source_site)}
//                 />
//             )}
//         </div>
//     );
// };

// export default JobSearchPortal;
import React, { useState, useEffect, useRef, navigate, useMemo } from 'react';
import { Link } from 'react-router-dom';
import {
    Search,
    MapPin,
    Briefcase,
    Globe,
    Sparkle,
    Building,
    ChevronDown,
    ChevronLeft,
    ChevronRight,
    X,
    Brain, ChevronUp,

    ExternalLink,
    FileText,
    Share2,
    BookmarkPlus,
    Mail,
    Calendar,
    DollarSign,
    Users,
    Building2,
    Clock,
    AlertCircle
} from 'lucide-react';
import { createClient } from '@supabase/supabase-js';
import { toast } from 'react-hot-toast';
import HeaderMain from './HeaderMain';
import { formatDistanceToNow, parseISO } from 'date-fns';


const supabaseUrl = process.env.REACT_APP_SUPABASE_URL || 'https://auth.roboapply.ai';
const supabaseKey = process.env.REACT_APP_SUPABASE_ANON_KEY || 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6InN1a21idnJwY3d6bm1ud3Rlb3liIiwicm9sZSI6ImFub24iLCJpYXQiOjE3Mjg4NzQ3ODYsImV4cCI6MjA0NDQ1MDc4Nn0.xR0tIF0NYNnnXa-N2-WpX0qgecGiwywdGwIVg3MgEyg';

const supabase = createClient(supabaseUrl, supabaseKey, {
    auth: {
        redirectTo: 'https://roboapply.ai'  // This should fix the localhost redirect
    }
});

// Constants
const TECH_JOB_SUGGESTIONS = [
    "Software Engineer",
    "Data Scientist",
    "Product Manager",
    "DevOps Engineer",
    "Full Stack Developer",
    "Frontend Developer",
    "Backend Developer",
    "Machine Learning Engineer",
    "Cloud Architect",
    "Data Engineer",
    "UX Designer",
    "UI Developer",
    "Mobile Developer",
    "System Administrator",
    "Security Engineer"
];

const POPULAR_CITIES = [
    "New York, NY",
    "San Francisco, CA",
    "Seattle, WA",
    "Austin, TX",
    "Boston, MA",
    "Los Angeles, CA",
    "Chicago, IL",
    "Denver, CO",
    "Atlanta, GA",
    "Washington, DC",
    "San Jose, CA",
    "Portland, OR",
    "Raleigh, NC",
    "Miami, FL",
    "Dallas, TX",
    "Phoenix, AZ",
    "Houston, TX",
    "San Diego, CA",
    "Philadelphia, PA",
    "Minneapolis, MN"
];

// Components
const JobCardSkeleton = () => (
    <div className="bg-white rounded-xl shadow-sm hover:shadow-md transition-shadow duration-300 border-l-4 border-gray-200 animate-pulse">
        <div className="p-6">
            <div className="flex items-start space-x-4">
                <div className="flex-shrink-0">
                    <div className="w-12 h-12 bg-gray-200 rounded-full" />
                </div>
                <div className="flex-1 min-w-0">
                    <div className="h-6 bg-gray-200 rounded w-3/4 mb-2" />
                    <div className="h-4 bg-gray-200 rounded w-1/2 mb-4" />
                    <div className="space-y-2">
                        <div className="h-3 bg-gray-200 rounded w-1/4" />
                        <div className="h-3 bg-gray-200 rounded w-1/3" />
                    </div>
                    <div className="mt-4 flex space-x-2">
                        <div className="h-8 bg-gray-200 rounded-full w-24" />
                        <div className="h-8 bg-gray-200 rounded-full w-20" />
                    </div>
                </div>
            </div>
        </div>
    </div>
);

const CityAutocomplete = ({ value, onChange, onSelect }) => {
    const [isOpen, setIsOpen] = useState(false);
    const [filteredCities, setFilteredCities] = useState([]);
    const wrapperRef = useRef(null);

    useEffect(() => {
        function handleClickOutside(event) {
            if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
                setIsOpen(false);
            }
        }
        document.addEventListener('mousedown', handleClickOutside);
        return () => document.removeEventListener('mousedown', handleClickOutside);
    }, []);

    useEffect(() => {
        if (value) {
            const filtered = POPULAR_CITIES.filter(city =>
                city.toLowerCase().includes(value.toLowerCase())
            );
            setFilteredCities(filtered);
        } else {
            setFilteredCities([]);
        }
    }, [value]);

    return (
        <div className="relative" ref={wrapperRef}>
            <div className="relative">
                <div className="absolute inset-y-0 left-0 pl-3 flex items-center">
                    <MapPin className="h-5 w-5 text-gray-400" />
                </div>
                <input
                    type="text"
                    placeholder="City,state,zip code, or 'remote'"
                    value={value}
                    onChange={(e) => {
                        onChange(e.target.value);
                        setIsOpen(true);
                    }}
                    onFocus={() => setIsOpen(true)}
                    className="pl-10 w-full rounded-lg border-gray-300 focus:ring-2 focus:ring-emerald-500"
                />
            </div>
            {isOpen && filteredCities.length > 0 && (
                <div className="absolute z-10 mt-1 w-full bg-white rounded-lg shadow-lg max-h-60 overflow-auto">
                    {filteredCities.map((city) => (
                        <button
                            key={city}
                            className="w-full text-left px-4 py-2 hover:bg-gray-50 focus:bg-gray-50 focus:outline-none"
                            onClick={() => {
                                onSelect(city);
                                setIsOpen(false);
                            }}
                        >
                            <div className="flex items-center">
                                <MapPin className="h-4 w-4 text-gray-400 mr-2" />
                                {city}
                            </div>
                        </button>
                    ))}
                </div>
            )}
        </div>
    );
};

const JobSuggestions = ({ onSelect }) => (
    <div className="flex flex-wrap gap-2 mb-6 px-1">
        {TECH_JOB_SUGGESTIONS.map((job) => (
            <button
                key={job}
                onClick={() => onSelect(job)}
                className="text-sm px-3 py-1 rounded-full bg-gray-100 hover:bg-gray-200 transition-colors duration-200 text-gray-700"
            >
                {job}
            </button>
        ))}
    </div>
);

const Pagination = ({ currentPage, totalPages, onPageChange }) => {
    const getPageNumbers = () => {
        const pages = [];
        if (totalPages <= 5) {
            for (let i = 1; i <= totalPages; i++) {
                pages.push(i);
            }
        } else {
            if (currentPage <= 3) {
                for (let i = 1; i <= 5; i++) {
                    pages.push(i);
                }
            } else if (currentPage >= totalPages - 2) {
                for (let i = totalPages - 4; i <= totalPages; i++) {
                    pages.push(i);
                }
            } else {
                for (let i = currentPage - 2; i <= currentPage + 2; i++) {
                    pages.push(i);
                }
            }
        }
        return pages;
    };

    return (
        <div className="flex items-center justify-center space-x-2 mt-8">
            <button
                onClick={() => onPageChange(currentPage - 1)}
                disabled={currentPage === 1}
                className="p-2 rounded-full hover:bg-gray-100 disabled:opacity-50 disabled:cursor-not-allowed"
                aria-label="Previous page"
            >
                <ChevronLeft className="w-5 h-5" />
            </button>

            {getPageNumbers().map(page => (
                <button
                    key={page}
                    onClick={() => onPageChange(page)}
                    className={`w-10 h-10 rounded-full flex items-center justify-center ${currentPage === page
                        ? 'bg-emerald-500 text-white'
                        : 'hover:bg-gray-100 text-gray-700'
                        }`}
                    aria-label={`Page ${page}`}
                    aria-current={currentPage === page ? 'page' : undefined}
                >
                    {page}
                </button>
            ))}

            <button
                onClick={() => onPageChange(currentPage + 1)}
                disabled={currentPage === totalPages}
                className="p-2 rounded-full hover:bg-gray-100 disabled:opacity-50 disabled:cursor-not-allowed"
                aria-label="Next page"
            >
                <ChevronRight className="w-5 h-5" />
            </button>
        </div>
    );
};
// Action Button Component for Modal
const ActionButton = ({ icon: Icon, label, primary, onClick }) => (
    <button
        onClick={onClick}
        className={`flex items-center space-x-2 px-4 py-2 rounded-full transition-all duration-200 ${primary
            ? 'bg-emerald-500 text-white hover:bg-emerald-600'
            : 'border border-gray-200 hover:border-gray-300 text-gray-700 hover:bg-gray-50'
            }`}
    >
        <Icon className="w-4 h-4" />
        <span className="font-medium">{label}</span>
    </button>
);

// Info Tag Component for Modal
const InfoTag = ({ icon: Icon, text, color = "gray" }) => (
    <div className={`flex items-center space-x-2 px-3 py-2 rounded-lg bg-${color}-50 text-${color}-700`}>
        <Icon className="w-4 h-4" />
        <span className="text-sm">{text}</span>
    </div>
);


const Button = ({ className, children, disabled, loading, ...props }) => {
    const baseStyles = "px-6 py-3 rounded-full font-semibold transition-all duration-300";
    const disabledStyles = disabled ? "opacity-50 cursor-not-allowed" : "hover:shadow-lg";
    const combinedClassName = `${baseStyles} ${className} ${disabledStyles}`;

    return (
        <button
            className={combinedClassName}
            disabled={disabled || loading}
            {...props}
        >
            {loading ? (
                <div className="flex items-center justify-center">
                    <svg className="animate-spin -ml-1 mr-3 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                        <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                        <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                    </svg>
                    Processing...
                </div>
            ) : children}
        </button>
    );
};

// Main JobSearchPortal Component
const JobSearchPortal = () => {
    // State declarations
    const [searchTerm, setSearchTerm] = useState("data engineer");
    const [location, setLocation] = useState("New York, NY");
    const [selectedSites, setSelectedSites] = useState(["indeed", "google", "glassdoor"]);
    const [selectedJobTypes, setSelectedJobTypes] = useState(["fulltime"]);
    const [resultsWanted] = useState(25);
    const [isRemote, setIsRemote] = useState(false);
    const [selectedJob, setSelectedJob] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [jobs, setJobs] = useState([]);
    const [error, setError] = useState(null);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);

    // Constants
    const jobSites = [
        { name: "indeed", color: "bg-blue-400", icon: "https://logo.clearbit.com/indeed.com" },
        { name: "google", color: "bg-red-400", icon: "https://logo.clearbit.com/google.com" },
        { name: "glassdoor", color: "bg-green-400", icon: "https://logo.clearbit.com/glassdoor.com" },
        { name: "linkedin", color: "bg-blue-600", icon: "https://logo.clearbit.com/linkedin.com" },
        { name: "zip_recruiter", color: "bg-teal-400", icon: "https://logo.clearbit.com/ziprecruiter.com" }
    ];

    const jobTypes = ["fulltime", "internship", "contract"];

    // Google Sign-in handler
    const handleGoogleSignIn = async () => {
        try {
            const { data, error } = await supabase.auth.signInWithOAuth({
                provider: 'google',
                options: {
                    redirectTo: `${window.location.origin}/dashboard`
                }
            });
            if (error) throw error;
        } catch (error) {
            console.error('Google auth error:', error);
            toast({
                title: 'Authentication Error',
                description: error.message || 'Failed to sign in with Google',
                status: 'error',
                duration: 4000,
                isClosable: true,
            });
        }
    };

    // Share handler
    const handleShare = async (jobId) => {
        const shareUrl = `${window.location.origin}/jobs/${jobId}`;
        try {
            if (navigator.share) {
                await navigator.share({
                    title: `Job Opportunity`,
                    text: `Check out this job posting!`,
                    url: shareUrl
                });
            } else {
                await navigator.clipboard.writeText(shareUrl);
                toast({
                    title: 'Link Copied',
                    description: 'Job link copied to clipboard',
                    status: 'success',
                    duration: 2000,
                });
            }
        } catch (error) {
            console.error('Share failed:', error);
        }
    };

    // Search handler
    const handleSearch = async (page = 1) => {
        setIsLoading(true);
        setError(null);

        try {
            const requestBody = {
                search_term: searchTerm,
                location: location || undefined,
                sites: selectedSites,
                job_types: selectedJobTypes,
                results_wanted: resultsWanted,
                page: page,
                is_remote: isRemote,
                country: "USA"
            };

            Object.keys(requestBody).forEach(key =>
                requestBody[key] === undefined && delete requestBody[key]
            );

            const response = await fetch('https://e0a0-74-88-7-3.ngrok-free.app/api/search', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                },
                body: JSON.stringify(requestBody)
            });

            if (!response.ok) {
                const errorData = await response.json();
                throw new Error(errorData.detail || 'Search failed');
            }

            const data = await response.json();
            setJobs(data.data?.jobs || data.jobs || []);
            setTotalPages(Math.ceil((data.data?.total || data.total || 0) / resultsWanted));
            setCurrentPage(page);

        } catch (err) {
            setError(err.message);
            console.error('Search error:', err);
            toast({
                title: 'Search Error',
                description: err.message || 'Failed to fetch jobs',
                status: 'error',
                duration: 4000,
                isClosable: true,
            });
        } finally {
            setIsLoading(false);
        }
    };

    // Handle page change
    const handlePageChange = (page) => {
        handleSearch(page);
        window.scrollTo({ top: 0, behavior: 'smooth' });
    };

    // Auto-search on mount
    useEffect(() => {
        handleSearch();
    }, []);

    // Component JSX
    return (
        <div className="min-h-screen bg-gray-50">
            {/* Header */}
            <header className="bg-gradient-to-r from-emerald-600 to-emerald-500 shadow-md sticky top-0 z-40">
                <HeaderMain />
            </header>

            {/* Main Content */}
            <main className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
                {/* Search Form */}
                <div className="bg-white rounded-xl shadow-sm hover:shadow-md transition-all duration-300 p-4 md:p-6 mb-8">
                    {/* Search Inputs */}
                    <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-6">
                        <div className="relative">
                            <div className="absolute inset-y-0 left-0 pl-3 flex items-center">
                                <Search className="h-5 w-5 text-gray-400" />
                            </div>
                            <input
                                type="text"
                                placeholder="Job title, keywords, or company"
                                value={searchTerm}
                                onChange={(e) => setSearchTerm(e.target.value)}
                                className="pl-10 w-full rounded-lg border-gray-300 focus:ring-2 focus:ring-emerald-500"
                            />
                        </div>
                        <CityAutocomplete
                            value={location}
                            onChange={setLocation}
                            onSelect={setLocation}
                        />
                    </div>

                    {/* Job Suggestions */}
                    <JobSuggestions onSelect={setSearchTerm} />

                    {/* Job Sites */}
                    <div className="mb-6">
                        <h3 className="text-lg font-semibold mb-3">Job Sites</h3>
                        <div className="flex flex-wrap gap-2">
                            {jobSites.map((site) => (
                                <button
                                    key={site.name}
                                    onClick={() => setSelectedSites(prev =>
                                        prev.includes(site.name)
                                            ? prev.filter(s => s !== site.name)
                                            : [...prev, site.name]
                                    )}
                                    className={`flex items-center px-4 py-2 rounded-full border transition-all duration-200 ${selectedSites.includes(site.name)
                                        ? `${site.color} text-white border-transparent`
                                        : 'border-gray-300 hover:border-gray-400'
                                        }`}
                                >
                                    <img src={site.icon} alt={site.name} className="w-4 h-4 mr-2" />
                                    <span className="capitalize">{site.name.replace('_', ' ')}</span>
                                </button>
                            ))}
                        </div>
                    </div>

                    {/* Job Types */}
                    <div className="mb-6">
                        <h3 className="text-lg font-semibold mb-3">Job Types</h3>
                        <div className="flex flex-wrap gap-2">
                            {jobTypes.map((type) => (
                                <button
                                    key={type}
                                    onClick={() => setSelectedJobTypes(prev =>
                                        prev.includes(type)
                                            ? prev.filter(t => t !== type)
                                            : [...prev, type]
                                    )}
                                    className={`px-4 py-2 rounded-full border transition-all duration-200 ${selectedJobTypes.includes(type)
                                        ? 'bg-emerald-500 text-white border-transparent'
                                        : 'border-gray-300 hover:border-gray-400'
                                        }`}
                                >
                                    {type.charAt(0).toUpperCase() + type.slice(1)}
                                </button>
                            ))}
                        </div>
                    </div>

                    {/* Remote Switch */}
                    <div className="mb-6">
                        <label className="flex items-center space-x-2 cursor-pointer">
                            <div
                                className={`w-10 h-6 rounded-full p-1 transition-colors duration-200 ${isRemote ? 'bg-emerald-500' : 'bg-gray-300'
                                    }`}
                                onClick={() => setIsRemote(!isRemote)}
                            >
                                <div className={`w-4 h-4 rounded-full bg-white transition-transform duration-200 ${isRemote ? 'translate-x-4' : 'translate-x-0'
                                    }`} />
                            </div>
                            <span className="text-sm font-medium">Remote Only</span>
                        </label>
                    </div>

                    {/* Search Button */}
                    <button
                        onClick={() => handleSearch(1)}
                        disabled={isLoading || !searchTerm}
                        className={`w-full py-3 rounded-lg text-white font-medium transition-colors duration-200 ${isLoading || !searchTerm
                            ? 'bg-gray-400 cursor-not-allowed'
                            : 'bg-emerald-500 hover:bg-emerald-600'
                            }`}
                    >
                        {isLoading ? (
                            <div className="flex items-center justify-center">
                                <div className="h-5 w-5 border-2 border-white border-t-transparent rounded-full animate-spin mr-2" />
                                <span>Finding Your Next Role...</span>
                            </div>
                        ) : (
                            'Search Jobs'
                        )}
                    </button>

                    {/* Error Display */}
                    {error && (
                        <div className="mt-4 p-4 bg-red-50 text-red-700 rounded-lg">
                            <p className="font-medium">Error: {error}</p>
                        </div>
                    )}
                </div>

                {/* Results Section */}
                <div>
                    {/* Results Count */}
                    {!isLoading && jobs.length > 0 && (
                        <div className="mb-6">
                            <h2 className="text-lg font-semibold text-gray-900">
                                Found {jobs.length} jobs for "{searchTerm}" in {location}
                            </h2>
                        </div>
                    )}

                    {/* Results Grid */}
                    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
                        {isLoading ? (
                            Array.from({ length: 6 }).map((_, index) => (
                                <JobCardSkeleton key={index} />
                            ))
                        ) : (
                            jobs.map((job) => (
                                <JobCard
                                    key={job.id}
                                    job={job}
                                    setSelectedJob={setSelectedJob}
                                    siteInfo={jobSites.find(site => site.name === job.source_site)}
                                />
                            ))
                        )}
                    </div>

                    {/* Pagination */}
                    {!isLoading && jobs.length > 0 && (
                        <Pagination
                            currentPage={currentPage}
                            totalPages={totalPages}
                            onPageChange={handlePageChange}
                        />
                    )}

                    {/* No Results Message */}
                    {!isLoading && jobs.length === 0 && !error && (
                        <div className="text-center py-12">
                            <AlertCircle className="w-12 h-12 text-gray-400 mx-auto mb-4" />
                            <p className="text-gray-500 text-lg">No jobs found matching your criteria.</p>
                            <p className="text-gray-400 mt-2">Try adjusting your search filters</p>
                        </div>
                    )}
                </div>
            </main>

            {/* Job Modal */}
            {selectedJob && (
                <JobModal
                    job={selectedJob}
                    onClose={() => setSelectedJob(null)}
                    siteInfo={jobSites.find(site => site.name === selectedJob.source_site)}
                    handleGoogleSignIn={handleGoogleSignIn}
                    handleShare={() => handleShare(selectedJob.id)}
                />
            )}
        </div>
    );
};

// Advanced location parsing utility

// Time ago utility
const getTimeAgo = (dateString) => {
    try {
        const postDate = parseISO(dateString);
        const now = new Date();
        const diffInMs = now - postDate;
        const diffInMinutes = Math.floor(diffInMs / (1000 * 60));
        const diffInHours = Math.floor(diffInMs / (1000 * 60 * 60));
        const diffInDays = Math.floor(diffInMs / (1000 * 60 * 60 * 24));

        if (diffInMinutes < 1) return 'Just now';
        if (diffInMinutes < 60) return `${diffInMinutes}m ago`;
        if (diffInHours < 24) return `${diffInHours}h ago`;
        if (diffInDays === 1) return 'Yesterday';
        if (diffInDays <= 7) return `${diffInDays}d ago`;
        if (diffInDays <= 30) return `${Math.floor(diffInDays / 7)}w ago`;
        if (diffInDays <= 365) return `${Math.floor(diffInDays / 30)}mo ago`;
        return '1y+';
    } catch (error) {
        return 'Date unavailable';
    }
};

// Salary formatting utility
const formatSalary = (salary) => {
    if (!salary || (!salary.min_amount && !salary.max_amount)) return null;

    const formatAmount = (amount) => {
        if (amount >= 1000000) {
            return `$${(amount / 1000000).toFixed(1)}M`;
        }
        if (amount >= 1000) {
            return `$${(amount / 1000).toFixed(0)}K`;
        }
        return `$${amount}`;
    };

    const formatInterval = (interval) => {
        switch (interval) {
            case 'yearly': return '/yr';
            case 'monthly': return '/mo';
            case 'weekly': return '/wk';
            case 'hourly': return '/hr';
            default: return '';
        }
    };

    if (salary.min_amount && salary.max_amount) {
        return `${formatAmount(salary.min_amount)} - ${formatAmount(salary.max_amount)}${formatInterval(salary.interval)}`;
    }

    const amount = salary.min_amount || salary.max_amount;
    return `${formatAmount(amount)}${formatInterval(salary.interval)}`;
};

const skillsList = {
    technical: [
        'Python', 'Java', 'JavaScript', 'React', 'Node.js', 'AWS', 'Docker', 'Kubernetes',
        'SQL', 'MongoDB', 'Redis', 'GraphQL', 'REST', 'CI/CD', 'Git', 'TypeScript',
        'Vue.js', 'Angular', 'Spring Boot', 'Django', 'Flask', 'FastAPI', 'Ruby on Rails',
        'PHP', 'Laravel', 'Terraform', 'Ansible', 'Jenkins', 'Maven', 'Gradle',
        'Elasticsearch', 'Kafka', 'RabbitMQ', 'Microservices', 'System Design', 'APIs',
        'Machine Learning', 'TensorFlow', 'PyTorch', 'scikit-learn', 'Computer Vision',
        'NLP', 'Data Science', 'Big Data', 'Hadoop', 'Spark', 'Tableau', 'Power BI'
    ],
    soft: [
        'Leadership', 'Communication', 'Problem Solving', 'Team Collaboration', 'Agile',
        'Scrum', 'Project Management', 'Time Management', 'Critical Thinking', 'Creativity',
        'Adaptability', 'Initiative', 'Mentoring', 'Presentation Skills', 'Writing',
        'Research', 'Analysis', 'Strategic Planning', 'Decision Making', 'Negotiation'
    ],
    tools: [
        'VS Code', 'IntelliJ', 'Eclipse', 'PyCharm', 'Postman', 'Jira', 'Confluence',
        'Slack', 'GitHub', 'GitLab', 'Bitbucket', 'npm', 'yarn', 'webpack', 'Babel',
        'Linux', 'Unix', 'Windows', 'MacOS', 'Azure', 'GCP', 'Heroku', 'DigitalOcean'
    ],
    methodologies: [
        'TDD', 'BDD', 'DDD', 'Clean Code', 'SOLID', 'Design Patterns', 'Microservices',
        'SOA', 'REST', 'GraphQL', 'Agile', 'Scrum', 'Kanban', 'Lean', 'DevOps'
    ]
};

const parseSkills = (description) => {
    const foundSkills = new Set();
    const allSkills = [...skillsList.technical, ...skillsList.soft, ...skillsList.tools, ...skillsList.methodologies]
        .map(skill => skill.toLowerCase());

    const words = description.toLowerCase().split(/\W+/);
    words.forEach(word => {
        if (allSkills.includes(word)) {
            foundSkills.add(word.charAt(0).toUpperCase() + word.slice(1));
        }
    });

    // Convert set to array and sort by length (shorter skills first)
    return Array.from(foundSkills).sort((a, b) => a.length - b.length);
};

const parseSalary = (text) => {
    // Look for standard salary patterns in text
    const salaryPattern = /(?:Pay Range:|Salary Range:)\s*\$?([\d,]+(?:\.00)?)\s*(?:-|to)\s*\$?([\d,]+(?:\.00)?)/i;
    const match = text.match(salaryPattern);

    if (match) {
        const min = parseFloat(match[1].replace(/,/g, ''));
        const max = parseFloat(match[2].replace(/,/g, ''));

        return {
            min_amount: min,
            max_amount: max,
            currency: 'USD',
            interval: 'yearly'
        };
    }

    return null;
};

// Location parsing function
const parseLocationFromText = (text) => {
    // Look for location pattern in text
    const locationPattern = /Location:\s*([^,\n]+),\s*([A-Z]{2})/i;
    const match = text.match(locationPattern);

    if (match) {
        return {
            city: match[1].trim(),
            state: match[2].trim(),
            country: 'USA'
        };
    }

    return null;
};

const SkillBubble = ({ skill }) => (
    <span className="inline-flex items-center px-3 py-1 rounded-full text-xs font-medium bg-gradient-to-r from-[#49DB84]/10 to-[#4098DA]/10 text-[#49DB84] border border-[#49DB84]/20">
        <Brain className="w-3 h-3 mr-1" />
        {skill}
    </span>
);

const SalaryDisplay = ({ salary }) => {
    if (!salary) return null;

    return (
        <div className="mt-2">
            <span className="text-sm text-gray-600">
                ${salary.min_amount.toLocaleString()} - ${salary.max_amount.toLocaleString()}/year
            </span>
        </div>
    );
};


// Location parsing utility (exactly as provided)
const parseLocation = (locationString, locationObject) => {
    if (locationObject) {
        const { city, state, country } = locationObject;

        // Advanced location parsing
        const formatLocation = () => {
            // Match ZIP code pattern
            const zipRegex = /\b\d{5}(?:-\d{4})?\b/;

            // Match state pattern (including full names and abbreviations)
            const stateRegex = /\b(?:[A-Z]{2}|Alabama|Alaska|Arizona|Arkansas|California|Colorado|Connecticut|Delaware|Florida|Georgia|Hawaii|Idaho|Illinois|Indiana|Iowa|Kansas|Kentucky|Louisiana|Maine|Maryland|Massachusetts|Michigan|Minnesota|Mississippi|Missouri|Montana|Nebraska|Nevada|New\sHampshire|New\sJersey|New\sMexico|New\sYork|North\sCarolina|North\sDakota|Ohio|Oklahoma|Oregon|Pennsylvania|Rhode\sIsland|South\sCarolina|South\sDakota|Tennessee|Texas|Utah|Vermont|Virginia|Washington|West\sVirginia|Wisconsin|Wyoming)\b/i;

            // If we have both city and state
            if (city && state) {
                return `${city}, ${state}`;
            }

            // If we have only one location component
            if (city || state || country) {
                return [city, state, country].filter(Boolean)[0];
            }
        };

        const formattedLocation = formatLocation();
        if (formattedLocation) return formattedLocation;
    }

    // Parse from location string if object parsing failed
    if (locationString) {
        // Enhanced regex for various location formats
        const patterns = [
            // City, State ZIP
            /^([^,]+),\s*([A-Z]{2})\s*(\d{5}(?:-\d{4})?)?$/,
            // City, State
            /^([^,]+),\s*([A-Z]{2})$/,
            // City, Full State Name
            /^([^,]+),\s*(Alabama|Alaska|Arizona|Arkansas|California|Colorado|Connecticut|Delaware|Florida|Georgia|Hawaii|Idaho|Illinois|Indiana|Iowa|Kansas|Kentucky|Louisiana|Maine|Maryland|Massachusetts|Michigan|Minnesota|Mississippi|Missouri|Montana|Nebraska|Nevada|New\sHampshire|New\sJersey|New\sMexico|New\sYork|North\sCarolina|North\sDakota|Ohio|Oklahoma|Oregon|Pennsylvania|Rhode\sIsland|South\sCarolina|South\sDakota|Tennessee|Texas|Utah|Vermont|Virginia|Washington|West\sVirginia|Wisconsin|Wyoming)$/i,
            // Just City
            /^([^,\d]+)$/,
            // Just State
            /^(AL|AK|AZ|AR|CA|CO|CT|DE|FL|GA|HI|ID|IL|IN|IA|KS|KY|LA|ME|MD|MA|MI|MN|MS|MO|MT|NE|NV|NH|NJ|NM|NY|NC|ND|OH|OK|OR|PA|RI|SC|SD|TN|TX|UT|VT|VA|WA|WV|WI|WY)$/
        ];

        for (const pattern of patterns) {
            const match = locationString.match(pattern);
            if (match) {
                if (match[2]) {
                    return `${match[1]}, ${match[2]}`;
                }
                return match[1];
            }
        }
    }

    return 'Location not specified';
};

const JobCard = ({ job, setSelectedJob, siteInfo }) => {
    const [showAllSkills, setShowAllSkills] = useState(false);

    const { parsedSkills, salary, location } = useMemo(() => {
        const skills = parseSkills(job.description);
        const salaryInfo = parseSalary(job.description);
        const locationInfo = parseLocationFromText(job.description);
        return {
            parsedSkills: skills,
            salary: salaryInfo,
            location: locationInfo
        };
    }, [job.description]);

    const handleGoogleSignIn = async () => {
        try {
            const { data, error } = await supabase.auth.signInWithOAuth({
                provider: 'google',
                options: {
                    redirectTo: `${window.location.origin}/dashboard`
                }
            });
            if (error) throw error;
        } catch (error) {
            console.error('Google auth error:', error);
            toast({
                title: 'Authentication Error',
                description: error.message || 'Failed to sign in with Google',
                status: 'error',
                duration: 4000,
                isClosable: true,
            });
        }
    };

    const displayedSkills = showAllSkills ? parsedSkills : parsedSkills.slice(0, 5);

    return (
        <div className="group bg-white rounded-xl shadow-sm hover:shadow-md transition-all duration-300 border-l-4 border-[#49DB84] hover:border-[#4098DA]">
            <div className="p-6">
                <div className="flex items-start space-x-4">
                    {/* Company Logo Section */}
                    <div className="flex-shrink-0">
                        <div className="w-12 h-12 rounded-full flex items-center justify-center bg-gradient-to-r from-[#49DB84]/10 to-[#4098DA]/10">
                            <img
                                src={job.company_info?.logo_url || siteInfo.icon}
                                alt={job.company}
                                className="w-8 h-8 object-contain rounded-full bg-white p-1"
                            />
                        </div>
                    </div>

                    {/* Main Content */}
                    <div className="flex-1 min-w-0">
                        <div className="flex justify-between items-start">
                            <h3 className="text-lg font-medium text-gray-900 truncate group-hover:text-[#49DB84] transition-colors duration-300">
                                {job.title}
                            </h3>
                            <img
                                src={siteInfo.icon}
                                alt={siteInfo.name}
                                className="w-5 h-5 flex-shrink-0 opacity-70"
                            />
                        </div>

                        <p className="text-sm text-gray-600 truncate mt-1">
                            {job.company}
                        </p>

                        {/* Job Details */}
                        <div className="mt-3 flex flex-wrap gap-2">
                            {location && (
                                <span className="inline-flex items-center text-xs text-gray-500 bg-gray-50 px-2 py-1 rounded-full">
                                    <MapPin className="w-3 h-3 mr-1" />
                                    {location.city}, {location.state}
                                </span>
                            )}

                            {job.job_type && (
                                <span className="inline-flex items-center text-xs text-gray-500 bg-gray-50 px-2 py-1 rounded-full">
                                    <Briefcase className="w-3 h-3 mr-1" />
                                    {job.job_type}
                                </span>
                            )}

                            {job.is_remote && (
                                <span className="inline-flex items-center text-xs text-[#49DB84] bg-[#49DB84]/10 px-2 py-1 rounded-full">
                                    <Globe className="w-3 h-3 mr-1" />
                                    Remote
                                </span>
                            )}
                        </div>

                        {/* Salary Display */}
                        <SalaryDisplay salary={salary} />

                        {/* Skills Section */}
                        <div className="mt-4">
                            <div className="flex flex-wrap gap-2">
                                {displayedSkills.map((skill, index) => (
                                    <SkillBubble key={index} skill={skill} />
                                ))}
                                {parsedSkills.length > 5 && (
                                    <button
                                        onClick={() => setShowAllSkills(!showAllSkills)}
                                        className="inline-flex items-center px-3 py-1 rounded-full text-xs font-medium text-black hover:bg-[#4098DA]/5 transition-colors duration-200"
                                    >
                                        {showAllSkills ? (
                                            <>
                                                <ChevronUp className="w-3 h-3 mr-1" />
                                                Show Less
                                            </>
                                        ) : (
                                            <>
                                                <ChevronDown className="w-3 h-3 mr-1" />
                                                +{parsedSkills.length - 5} More
                                            </>
                                        )}
                                    </button>
                                )}
                            </div>
                        </div>

                        {/* Action Buttons */}
                        <div className="mt-4 flex flex-wrap gap-2">
                            <button
                                onClick={() => setSelectedJob(job)}
                                className="px-4 py-2 text-sm font-medium text-[#49DB84] bg-[#49DB84]/10 rounded-full transition-colors duration-200 hover:bg-[#49DB84]/20"
                            >
                                View Details
                            </button>
                            <a
                                href={job.job_url}
                                target="_blank"
                                rel="noopener noreferrer"
                                className="px-4 py-2 text-sm font-medium text-white bg-gradient-to-r from-[#49DB84] to-[#4098DA] rounded-full transition-colors duration-200 hover:opacity-90 flex items-center"
                            >
                                Apply Now
                                <ExternalLink className="w-4 h-4 ml-1" />
                            </a>
                            <div className="group/tooltip relative">
                                <button
                                    onClick={handleGoogleSignIn}
                                    className="px-4 py-2 text-sm font-medium bg-white hover:bg-white/90 text-transparent bg-clip-text bg-gradient-to-r from-[#49DB84] to-[#4098DA] border border-[#49DB84] hover:border-[#4098DA] rounded-full flex items-center transition-all duration-300"
                                >
                                    <Sparkle className="w-4 h-4 mr-1 animate-pulse text-[#49DB84]" />
                                    Custom Resume
                                </button>
                                <div className="absolute invisible group-hover/tooltip:visible opacity-0 group-hover/tooltip:opacity-100 transition-all duration-300 -bottom-12 left-1/2 transform -translate-x-1/2 bg-black text-white text-xs py-2 px-3 rounded-lg w-48 text-center shadow-lg z-10">
                                    AI-powered conversion to beat ATS (Automated Tracking Systems)
                                    <div className="absolute -top-2 left-1/2 transform -translate-x-1/2 border-8 border-transparent border-b-black"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};




// Main JobModal component
const JobModal = ({
    job,
    onClose,
    siteInfo,
    handleGoogleSignIn,
    handleShare,
    setIsPaywallOpen,
    navigate,
    user,
    hasProfile,
    credits,
    supabase,
    toast
}) => {
    const [isAnimating, setIsAnimating] = useState(false);
    const [isCompanyExpanded, setIsCompanyExpanded] = useState(window.innerWidth >= 768);
    const modalRef = useRef(null);

    useEffect(() => {
        setIsAnimating(true);
        document.body.style.overflow = 'hidden';

        const handleEscape = (event) => {
            if (event.key === 'Escape') onClose();
        };

        window.addEventListener('keydown', handleEscape);

        return () => {
            document.body.style.overflow = 'unset';
            window.removeEventListener('keydown', handleEscape);
        };
    }, [onClose]);

    const handleLogout = async () => {
        try {
            const { error } = await supabase.auth.signOut();
            if (error) throw error;
            localStorage.removeItem(`sb-${process.env.REACT_APP_SUPABASE_PROJECT_ID}-auth-token`);
            localStorage.removeItem('userId');
            toast.success('Logged out successfully');
        } catch (error) {
            toast.error('Error logging out');
        }
    };

    const location = parseLocation(job.location_string, job.location);
    const timeAgo = getTimeAgo(job.date_posted);

    return (
        <div
            className={`fixed inset-0 bg-black/50 flex items-center justify-center p-4 z-50 transition-opacity duration-200 
          ${isAnimating ? 'opacity-100' : 'opacity-0'}`}
            onClick={onClose}
        >
            <div
                ref={modalRef}
                className={`bg-white rounded-2xl shadow-xl w-full max-w-4xl max-h-[90vh] overflow-hidden transition-all duration-300 
            ${isAnimating ? 'translate-y-0 opacity-100' : 'translate-y-4 opacity-0'}`}
                onClick={(event) => event.stopPropagation()}
            >
                {/* Header Section */}
                <div className="p-6 border-b border-gray-100">
                    <div className="flex justify-between items-start mb-6">
                        <div className="flex items-center space-x-4">
                            <div className={`w-16 h-16 rounded-2xl flex items-center justify-center ${siteInfo.color}`}>
                                <img
                                    src={job.company_info?.logo_url || siteInfo.icon}
                                    alt={job.company}
                                    className="w-12 h-12 object-contain rounded-xl bg-white p-1"
                                />
                            </div>
                            <div>
                                <h2 className="text-2xl font-bold text-gray-900">{job.title}</h2>
                                <p className="text-lg text-gray-600 flex items-center space-x-2">
                                    <span>{job.company}</span>
                                    <span className="text-gray-300">•</span>
                                    <img src={siteInfo.icon} alt={siteInfo.name} className="w-4 h-4 opacity-70" />
                                </p>
                            </div>
                        </div>
                        <button
                            onClick={onClose}
                            className="p-2 hover:bg-gray-100 rounded-full transition-colors duration-200"
                        >
                            <X className="w-6 h-6 text-gray-400" />
                        </button>
                    </div>

                    {/* Quick Actions */}
                    <div className="flex flex-wrap gap-3">
                        <ActionButton icon={Share2} label="Share" onClick={handleShare} />
                        <ActionButton icon={BookmarkPlus} label="Save" onClick={() => { }} />
                        <ActionButton icon={Mail} label="Email" onClick={() => { }} />
                        <div className="ml-auto">
                            <ActionButton
                                icon={FileText}
                                label="Create Custom Resume"
                                primary
                                onClick={handleGoogleSignIn}
                            />
                        </div>
                    </div>
                </div>

                {/* Content Section */}
                <div className="flex flex-col md:flex-row divide-y md:divide-y-0 md:divide-x divide-gray-100 h-[calc(90vh-200px)]">
                    {/* Main Content */}
                    <div className="flex-1 overflow-y-auto p-6">
                        {/* Job Details Grid */}
                        <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 mb-8">
                            <InfoTag icon={MapPin} text={location} />
                            <InfoTag icon={Clock} text={timeAgo} color="gray" />
                            {job.job_type && (
                                <InfoTag icon={Briefcase} text={job.job_type} color="blue" />
                            )}
                            {job.is_remote && (
                                <InfoTag icon={Globe} text="Remote Position" color="green" />
                            )}
                            {job.salary_range && (
                                <InfoTag icon={DollarSign} text={job.salary_range} color="emerald" />
                            )}
                            {job.company_size && (
                                <InfoTag icon={Users} text={job.company_size} />
                            )}
                        </div>

                        {/* Job Description */}
                        <div className="prose max-w-none">
                            <div className="space-y-6">
                                <section>
                                    <h3 className="text-xl font-semibold text-gray-900 mb-4">About the Role</h3>
                                    <div className="text-gray-700 whitespace-pre-wrap leading-relaxed">
                                        {job.description}
                                    </div>
                                </section>

                                {job.requirements && (
                                    <section>
                                        <h3 className="text-xl font-semibold text-gray-900 mb-4">Requirements</h3>
                                        <div className="text-gray-700 whitespace-pre-wrap leading-relaxed">
                                            {job.requirements}
                                        </div>
                                    </section>
                                )}

                                {job.benefits && (
                                    <section>
                                        <h3 className="text-xl font-semibold text-gray-900 mb-4">Benefits</h3>
                                        <div className="text-gray-700 whitespace-pre-wrap leading-relaxed">
                                            {job.benefits}
                                        </div>
                                    </section>
                                )}
                            </div>
                        </div>
                    </div>

                    {/* Company Sidebar */}
                    <div className="md:w-80 w-full overflow-y-auto bg-gray-50">
                        <button
                            onClick={() => setIsCompanyExpanded(!isCompanyExpanded)}
                            className="w-full p-4 flex items-center justify-between md:hidden border-b border-gray-200"
                        >
                            <span className="font-medium">Company Information</span>
                            <ChevronDown
                                className={`w-5 h-5 transition-transform duration-200 
                    ${isCompanyExpanded ? 'transform rotate-180' : ''}`}
                            />
                        </button>
                        <div className={`p-6 ${isCompanyExpanded ? 'block' : 'hidden md:block'}`}>
                            <div className="space-y-6">
                                <section>
                                    <h3 className="text-lg font-semibold text-gray-900 mb-3">
                                        About {job.company}
                                    </h3>
                                    <p className="text-gray-600 text-sm leading-relaxed">
                                        {job.company_info?.description ||
                                            `${job.company} is a leading company in their industry, focused on innovation and growth.`}
                                    </p>
                                </section>

                                {job.company_info?.founded && (
                                    <section>
                                        <h4 className="text-sm font-medium text-gray-900 mb-2">Founded</h4>
                                        <p className="text-gray-600 text-sm">{job.company_info.founded}</p>
                                    </section>
                                )}

                                {job.company_info?.industry && (
                                    <section>
                                        <h4 className="text-sm font-medium text-gray-900 mb-2">Industry</h4>
                                        <p className="text-gray-600 text-sm">{job.company_info.industry}</p>
                                    </section>
                                )}

                                {job.company_info?.website && (
                                    <section>
                                        <h4 className="text-sm font-medium text-gray-900 mb-2">Website</h4>
                                        <a
                                            href={job.company_info.website}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            className="text-emerald-600 hover:text-emerald-700 text-sm flex items-center space-x-1"
                                        >
                                            <span>Visit website</span>
                                            <ExternalLink className="w-3 h-3" />
                                        </a>
                                    </section>
                                )}
                            </div>
                        </div>
                    </div>
                </div>

                {/* Footer Actions */}
                <div className="p-6 border-t border-gray-100 bg-white">
                    <div className="flex flex-wrap justify-between items-center gap-4">
                        <div className="flex flex-wrap items-center gap-4">
                            <a
                                href={job.job_url}
                                target="_blank"
                                rel="noopener noreferrer"
                                className="px-6 py-2.5 bg-emerald-500 text-white font-medium rounded-full hover:bg-emerald-600 transition-colors duration-200 flex items-center space-x-2"
                            >
                                <span>Apply Now</span>
                                <ExternalLink className="w-4 h-4" />
                            </a>

                            <div className="w-full mt-4">
                                <div className="relative">
                                    <div className="absolute inset-0 flex items-center">
                                        <div className="w-full border-t border-gray-300" />
                                    </div>
                                    <div className="relative flex justify-center text-sm">
                                        <span className="px-2 bg-white text-gray-500">
                                            or continue with
                                        </span>
                                    </div>
                                </div>

                                <button
                                    onClick={handleGoogleSignIn}
                                    className="mt-4 w-full flex items-center justify-center px-4 py-2.5 border border-gray-300 rounded-full shadow-sm bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-emerald-500"
                                >
                                    <img
                                        src="https://www.gstatic.com/firebasejs/ui/2.0.0/images/auth/google.svg"
                                        alt="Google"
                                        className="w-5 h-5 mr-2"
                                    />
                                    Continue with Google
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default JobSearchPortal;